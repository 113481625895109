import { Component , Input } from '@angular/core'
import { DataService } from '../../../core/index'

@Component({
	selector : 'update-profile-facebook-link' , 
	templateUrl : './updateProfileFacebookLink.html'
})
export class UpdateProfileFacebookLinkComponent {

	@Input() username : string
	facebookLink : string 
	err : string
	successUpdatingFacebookProfileLink : boolean

	constructor(
		private dataService : DataService
	) {}

	updateFacebookLink() {
		this.err = null
		let URI = "user/updateFacebookProfileLink"
		let params = {
			username : this.username , 
			facebookProfileLink : this.facebookLink 
		}
		this.dataService.postObject( URI , params )
			.subscribe( ( response : any ) => {
				if (response.error)
					return this.err = response.error
				return this.successUpdatingFacebookProfileLink = true
			})
	}	

	openModal() {
		document.getElementById('uploadProfileFacebookLinkModal').style.display = "block"
		document.getElementById('uploadProfileFacebookLinkModalContent').style.display = "block"
	}

	closeModal() {
		document.getElementById('uploadProfileFacebookLinkModal').style.display = "none"
		document.getElementById('uploadProfileFacebookLinkModalContent').style.display = "none"
		this.successUpdatingFacebookProfileLink = false 
	}

}