<div>
	<a *ngIf="!entryPrivacySelectorShown" (click)="showEntryPrivacySelector()">
		<label>Entries are {{ entryPrivacy }} (click to change)</label>
	</a>
	<div *ngIf="entryPrivacySelectorShown">
		<label>Entries are </label>
		<select
			class="roundBorders dark-purple-background input-styling" 
			[(ngModel)]="entryPrivacy" 
			(change)="updateContestEntryPrivacy(entryPrivacy)" 
			id="entryPrivacy" 
			type="text" 
			placeholder="Please Select One"
			style="margin: auto ; margin-bottom: 10px;" 
		>
			<option>Please Select One</option>
			<option value="private">Private</option>
			<option value="public">Public</option>
		</select>
	</div>
</div>