<div class="col-xs-12 footer" style="background-color: #251133;">
	<div class="col-sm-6 col-xs-12 col-md-4 white-text">
		<div class="col-xs-12 col-sm-12 col-md-9 col-md-offset-3">
			<h3>Learn More</h3>
			<a routerLink="/about"><p class="footer-item">About</p></a>
			<a routerLink="/faq"><p class="footer-item">FAQ</p></a>
			<a routerLink="/pricing"><p class="footer-item">Pricing</p></a>
			<a routerLink="/support"><p class="footer-item">Support</p></a>
			<a routerLink="/competitions"><p class="footer-item">Competitions</p></a>
		</div>
	</div>
	<div class="col-xs-12 col-sm-6 col-md-4">
		<div class="col-xs-12 col-sm-12 col-md-9 col-md-offset-3 white-text">
			<h3>Join Spotidol</h3>
			<a routerLink="/signUp"><p class="footer-item">Sign Up</p></a>
			<a routerLink="/login"><p class="footer-item">Log In</p></a>
			<a routerLink="/careers"><p class="footer-item">Careers</p></a>
			<a routerLink="/beASponsor"><p class="footer-item">Be A Sponsor</p></a>
			<a routerLink="/affiliates"><p class="footer-item">Be An Affiliate</p></a>
		</div>
	</div>

	<div class="col-xs-12 col-sm-6 col-md-4 white-text">
		<div class="col-xs-12 col-sm-12 col-md-9 col-md-offset-3 white-text">
			<span><h3 class="white-text">Follow Spotidol</h3></span>
			<a href="https://www.facebook.com/spotidol">
				<p class="footer-item">
					<img src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Ffacebook-logo-gray.png?alt=media&token=662ae525-76f4-477a-8128-73a17568a1c8" width="14" height="14">
					<span>Facebook</span>
				</p>
			</a>
			<a href="https://www.twitter.com/spotidolo">
				<p class="footer-item">
					<img src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Ftwitter-logo-gray.png?alt=media&token=3b45ad89-1c21-4b53-9abf-12f01d960730" width="14" height="14">
					<span>Twitter</span>
				</p>
			</a>
			<a href="https://www.instagram.com/spotidol">
				<p class="footer-item">
					<img src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Finstagram-logo-gray.png?alt=media&token=04d48d64-2f2b-4934-8c3e-c10f69430a6c" width="14" height="14">
					<span>Instagram</span>
				</p>
			</a>
		</div>
	</div>
	<div class="col-xs-12">
		<div style="margin-top: 30px; display: block;" class="col-centered">
			<img class="block col-centered" src="/app/assets/images/spotidolLogo.png" width="65" height="65">
		</div>
		<div class="col-centered">
			<p class="footer-item center-text">2023	&copy; Spotidol, Inc.</p>
		</div>
	</div>
</div>