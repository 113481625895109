import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()

export class CreateContestSubjectService {

	createContestInit : any = {
		contestName : null ,
		contestType : null , 
		entryPrivacy : null , 
		scorePrivacy : null , 
		contestPrice : null , 
		rewardFanpoints : null ,
		rewardCash : null , 
		group : null , 
		scoreCard : {} , 
		pricePerVote : null , 
		rewardOther : null ,
		votingType : null ,
		userContestHidden : null , 
		scorePerVote : null ,
		entryGoesToPot : null ,  
		maxSubmissionsPerEntry : null , 
		maxVotes : null , 
		positions : null ,
		chargeVoters : null , 
		startDate : null , 
		startTime : null , 
		endOfContest : null ,
		endOfContestTime : null , 
		winnerSelectedDate : null , 
		winnerSelectedTime : null , 
		kindOfFan : null , 
		dummyRewards : null , 
		description : null 
	}

	private createContestSubject = new BehaviorSubject<any>(this.createContestInit)
	public createContestObject : any = this.createContestSubject.asObservable()

	public currentCreateContestObjectValue : any = {
		contestName : null ,
		contestType : null , 
		entryPrivacy : null , 
		scorePrivacy : null , 
		contestPrice : null , 
		rewardFanpoints : null ,
		rewardCash : null , 
		scoreCard : {} , 
		group : null , 
		pricePerVote : null , 
		rewardOther : null ,
		votingType : null ,
		userContestHidden : null , 
		scorePerVote : null ,
		entryGoesToPot : null ,  
		maxSubmissionsPerEntry : null , 
		maxVotes : null , 
		positions : null ,
		chargeVoters : null , 
		startDate : null , 
		startTime : null , 
		endOfContest : null ,
		endOfContestTime : null , 
		winnerSelectedDate : null , 
		winnerSelectedTime : null , 
		kindOfFan : null , 
		dummyRewards : null , 
		description : null 
	}

	public renewCreateContestObject( newCreateContestObject : any ) {
		this.currentCreateContestObjectValue = newCreateContestObject
		this.createContestSubject.next(newCreateContestObject)
	}

}