import { Component , Output , EventEmitter } from '@angular/core'

@Component({
	selector : 'onboarding' , 
	templateUrl : './onboarding.html' , 
	styleUrls : [ './onboarding.css' ]
})

export class OnboardingComponent {
	
	page : string = 'menu'

	@Output() onboardingClosed : any = new EventEmitter()

	goToCreateCompetitions() {
		this.page = 'createCompetitions'
	}

	goToEnterCompetitions() {
		this.page = 'enterCompetitions'
	}

	goToShareDreams() {
		this.page = 'createDreams'
	}

	goToJoinOtherDreams() {
		this.page = 'joinDreams'
	}

	goToMenu() {
		this.page = 'menu'
	}

	closeOnboarding( ) {
		this.onboardingClosed.emit( )
	}


}