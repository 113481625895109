
import { trigger, state, style, transition, animate } from '@angular/animations';

export const FadeAnimation = trigger('fade', [
  state('fadeOut', style({
    opacity: 0
  })),
  state('fadeIn', style({
    opacity: 1
  })),
  transition('fadeOut => fadeIn', [
    animate('300ms')
  ]),
  transition('fadeIn => fadeOut', [
    animate('100ms')
  ])
])