import { Component , Input , OnInit } from '@angular/core'
import { IContest } from '../../shared/interfaces'

@Component({
	selector : 'featured-contests' ,
	templateUrl : './featuredContests.html'
})

export class FeaturedContestsComponent {
	
	@Input() contests : IContest[]

	displayedContest : IContest
	displayedContestIndex : number = 0

	constructor() {}

	ngOnInit() {
		this.displayedContest = this.contests[this.displayedContestIndex]
		setInterval(()=> { this.rotateContests() } , 18000 )
	}

	rotateContests() {
		if (this.displayedContestIndex < (this.contests.length - 1)) {
			this.displayedContestIndex ++
		} else {
			this.displayedContestIndex = 0 
		} 
		this.displayedContest = this.contests[this.displayedContestIndex]
	}
}