import { Component , Input } from '@angular/core'

@Component({
	selector: 'share-on-social' , 
	templateUrl : './shareOnSocial.html'
})

export class ShareOnSocialComponent {

	@Input() mediaObject : any
	@Input() mediaObjectType : string
	link : string
	copiedUrl : boolean

	ngOnInit() {
		this.setSocialShareLinks()
	}

	setSocialShareLinks() {
		switch (this.mediaObjectType) {
			case "contestEntry": {
				this.link = 'competitionEntry/' + this.mediaObject._id
				break;
			}
			case "contest": {
				this.link = 'competition/' + this.mediaObject._id
				break;
			}
			case "group": {
				this.link = 'group/' + this.mediaObject.groupUrl 
				break;
			}
		}
	}

	copyUrl() {
	    const selBox = document.createElement('textarea');
	    selBox.style.position = 'fixed';
	    selBox.style.left = '0';
	    selBox.style.top = '0';
	    selBox.style.opacity = '0';
	    selBox.value = "https://spotidol.com/" + this.link
	    document.body.appendChild(selBox);
	    selBox.focus();
	    selBox.select();
	    document.execCommand('copy');
	    document.body.removeChild(selBox);
	    this.copiedUrl = true
	    setTimeout(()=> {
	    	this.copiedUrl = false
	    } , 3000 )
	}

}