
<div style="color: white;">
	<h2 style="text-align: center;">Prize Details</h2>
	<div class="row">	
		<div class="col-xs-6">
			<a *ngIf="!showAddCashPrize" (click)="toggleAddCashPrize()" class="add-prize-section">
				<h4>Add Cash Prize</h4>
				<p>+</p>
			</a>
			<div *ngIf="showAddCashPrize" class="prize-input-section">
				<p class="col-xs-12" style="text-align: center; font-weight: 800;">{{ ordinal( currentCashPrizePosition ) }} Place Cash Prize</p>
				<div class="col-xs-12">
					<input class="roundBorders dark-purple-background input-styling col-xs-12" 
					placeholder="300, 125.50, etc." 
					type="text" mask="9*.99" [(ngModel)]="rewards[currentCashPrizePosition - 1].rewardCash">	
					<a (click)="addCashPrize()"><p class="col-xs-12" style="text-align: center; color : #42f548 ; ">+Add Prize</p></a>
				</div>
			</div>
		</div>
		<div class="col-xs-6">
			<a *ngIf="!showAddOtherPrize" (click)="toggleAddOtherPrize()" class="add-prize-section">
				<h4>Add Other Prize</h4>
				<p>+</p>
			</a>
			<div *ngIf="showAddOtherPrize" class="prize-input-section">
				<p class="col-xs-12" style="text-align: center; font-weight: 800;">Describe your {{ ordinal( currentOtherPrizePosition ) }} Prize</p>
				<div class="col-xs-12">
					<textarea class="roundBorders dark-purple-background input-styling col-xs-12" 
					placeholder="Describe products or experiences you will provide." 
					type="text" [(ngModel)]="rewards[currentOtherPrizePosition - 1].rewardOther"></textarea>
					<a (click)="addOtherPrize()"><p class="col-xs-12" style="text-align: center; color : #42f548 ; ">+Add Prize</p></a>
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<h3 *ngIf="addedRewards && addedRewards.length > 0" class="col-xs-12">
			Your Prizes
		</h3>
		<div class="col-xs-6">
			<div *ngFor="let reward of addedRewards ; let i = index" [attr.data-index]="i">
				<p *ngIf="reward.rewardCash > 0">
					<a *ngIf="i + 2 == currentCashPrizePosition " (click)="removeCashPrize( i )">
						<img src="/app/assets/images/cancel-btn.png" style="width: 20px ; height: 20px;" />
					</a>
					<span>
						{{ ordinal(i + 1 )}} Place Cash Prize: {{ currencySymbol }}{{ reward.rewardCash }}
					</span>
				</p>
			</div>
		</div>
		<div class="col-xs-6">
			<div *ngFor="let reward of addedRewards ; let i = index" [attr.data-index]="i">
				<p *ngIf="reward.rewardOther">
					<a *ngIf="i + 2 == currentOtherPrizePosition " (click)="removeOtherPrize( i )">
						<img src="/app/assets/images/cancel-btn.png" style="width: 20px ; height: 20px;" />
					</a>
					<span>
						{{ ordinal(i + 1 )}} Prize: {{ reward.rewardOther }}
					</span>
				</p>
			</div>
		</div>
	</div>
	<input (click)="submitDetails()" class="btn btn-info btn-lg" type= "submit" value="Submit">
</div>