<div class="row">	
	<div class="col-xs-12" style="height: 20vh; max-height: 900px;" *ngIf="group">
		<div 
			style="
				width: 100% ; 
				height: 100% ; 
		    background-repeat: no-repeat;
		    background-size: cover;
		    background-position: center center;
		    padding: 0px;
			" 
			class="col-xs-12"
			[ngStyle]="{ 'background-image' : profilePictureUrl }"
		>
			<a routerLink="/group/{{ group?.groupUrl }}"><p style="
		    bottom: 0px;
		    position: absolute;
		    color: white;
		    margin: auto;
		    background-color: rgba(152, 140, 229, 0.6);
		    width: 100%;
		    text-align: center;
    		font-size: 16px;
			"><span style="font-weight: 700">{{ group?.groupName }}</span> - {{ group?.totalFans }} Players</p></a>	
		</div>
	</div>
	<div *ngIf="!group">Currently No Group Found</div>
</div>