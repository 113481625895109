import { Component, Input, OnInit } from '@angular/core'
import { DataService, SessionService, UserDataService } from '../../core/index'
import { IContest, IUser } from '../../shared/interfaces'


@Component({
	selector : 'viewer' ,
	templateUrl : './viewer.html'
})

export class ViewerComponent implements OnInit {

	@Input() contest : IContest 
	@Input() user : IUser
	err : string
	viewers : [{
		username : string,
		profilePicture? : string
	}]
	username : string
	profilePicture : string
	isGroupAdmin : boolean = false
	addViewerUsername : string

	constructor(
		private dataService : DataService ,
		private userDataService : UserDataService ,
		private sessionService : SessionService
	) {}

	ngOnInit() {
		this.sessionService.session
			.subscribe( (session : any) => {
				if (session.username && this.contest.groupAdmins.filter( (viewerObj) => { return viewerObj.username === session.username })[0] )
					this.isGroupAdmin = true
				for (var i = 0 ; i < this.contest.viewers.length; i++) {
					this.contest.viewers[i].profilePicture = this.contest.viewers[i].profilePicture ? this.contest.viewers[i].profilePicture : '/app/assets/images/idol-icon-white.png'
				}
				this.viewers = this.contest.viewers
			})
	}

	addViewer(username : string) {
		let URI =  'contest/addViewer'
		let body = {
			contestId : this.contest._id ,
			username : username
		}
		this.dataService.postObject(URI, body)
			.subscribe( (response : any) => {
				if (response.error) {
					return this.err = response.error
				}
				let tempUserData
				this.userDataService.userData
					.subscribe( (userData : any) => {
						tempUserData = userData
						tempUserData.contests = tempUserData.contests.filter( (contest : IContest) => {
							return (contest._id === this.contest._id)
						})
						tempUserData.contests.push(response.contest)
						this.userDataService.renewUserData(tempUserData)
					})
				this.err = null
				this.closeModal()
				let viewerDetails = {
					username : username ,
					profilePicture : '/app/assets/images/idol-icon-white.png'
				}
				this.viewers.push(viewerDetails)
				return this.contest.viewers.push(viewerDetails)
			})
	}


	//removeViewer() {}


	closeModal() {
		document.getElementById('addViewerModal').style.display = "none"
		document.getElementById('addViewerModalContent').style.display = "none"
	}

	openModal() {
		document.getElementById('addViewerModal').style.display = "block"
		document.getElementById('addViewerModalContent').style.display = "block"
	}
}