<div
	style="
	    width: 100%;
	    overflow: hidden;
	    color : white ;
	    padding-bottom: 100px;
	"
>
	<div class="col-md-10 col-md-offset-1">
		<h1 class="jumbo-text">SPOTIDOL'S VALUES</h1>
		<h1>MONETZIZATION</h1>
		<p>All people need to have their own way of living. That way of living must serve the life of their dreams & the dreams of people within their communities. People who generate large amounts of money are seen as idols because they created a path to earn a living. They show others how to create a living & inspire others to find their own paths.</p>
		<h1>CURATION</h1>
		<p>All people in society need their way of living recognized within their fields. Not all work & peak experiences are compatible with every person. Curation is necessary to connect people with their peak experiences. People who have a high level of curation are seen as idols because they created a path for their work to be experienced by others.</p>
		<h1>PURPOSE</h1>
		<p>All people need their way of living to serve a purpose within their fields. The most purposeful life is the one where we all live our dreams. The responsibility for everyone to help each other live their dreams gives all people purpose. People who provide a strong purpose are seen as idols because they created a path for their work to be needed.</p>
		<h1>OUR BOTTOM LINES</h1>
		<div>
			<div class="col-xs-12 col-sm-4 bottom-line-section">
				<div class="bottom-line-container">
					<p class="bottom-line-header-text">Profit</p>			
					<div
						style="
					    height: 100px; 
				    "
					>
						<p class="bottom-line-comparison-text">Monetization</p>
						<p class="bottom-line-equal-text">=</p>
						<p class="bottom-line-comparison-text">Profit</p>
					</div>
					<p class="bottom-line-details-text">We help all idols profit.</p>
				</div>
			</div>
			<div class="col-xs-12 col-sm-4 bottom-line-section">
				<div class="bottom-line-container">
					<p class="bottom-line-header-text">People</p>			
					<div
						style="
					    height: 100px;
				    "
			    >
						<p class="bottom-line-comparison-text">Curation</p>
						<p class="bottom-line-equal-text">=</p>
						<p class="bottom-line-comparison-text">People</p>
					</div>
					<p class="bottom-line-details-text">We help all idols connect with people.</p>
				</div>
			</div>
			<div class="col-xs-12 col-sm-4 bottom-line-section">
				<div class="bottom-line-container">
					<p class="bottom-line-header-text">Dreams</p>			
					<div
						style="
					    height: 100px;
				    "
					>
						<p class="bottom-line-comparison-text">Curation</p>
						<p class="bottom-line-equal-text">=</p>
						<p class="bottom-line-comparison-text">Dreams</p>
					</div>
					<p class="bottom-line-details-text">We help all idols provide peak experiences.</p>
				</div>
			</div>
		</div>
	</div>
	<div class="col-xs-12">
		<sign-up-redirect></sign-up-redirect>
	</div>
	<footer></footer>
</div>