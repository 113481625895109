import { Component , Input } from '@angular/core'

@Component({
	selector : 'media-menu' , 
	templateUrl : './mediaMenu.html'
})

export class MediaMenuComponent {
	@Input() mediaObject : any
	@Input() mediaObjectType : string 
	@Input() reportedUserDetails : any
	@Input() whiteOrBlack : string
	@Input() showShareButtons : boolean
	showMediaMenu : boolean = false

	toggleMediaMenu() {
		this.showMediaMenu = this.showMediaMenu ? false : true 
	}
}