import { Component , OnInit , Input } from '@angular/core'
import { ActivatedRoute , Router } from '@angular/router'

import { DataService , SessionService } from '../../core/index'

import { IGroup , IContest , IFan , IReportedUser } from '../../shared/interfaces'

@Component({
	selector: 'fans', 
	templateUrl: "./fans.html" , 
	styleUrls : ['./fans.css']
})

export class FansComponent implements OnInit{

	groupUrl : string
	myUsername : string
	group : IGroup
	myFan : IFan
	myPlace : number
	pages : number = 1
	isMyGroup : boolean = false
	kindOfFan : string = 'fan'
	fans : IFan[]
	err : string
	page : number = 1
	lastFanPosition : number
	noFoundFans : boolean = false
	searchValue : any

	constructor(
		private dataService : DataService , 
		private sessionService : SessionService , 
		private route : ActivatedRoute , 
		private router : Router 
	) {}

	ngOnInit () {
		this.groupUrl = this.route.snapshot.params['groupUrl']
		this.sessionService.session.subscribe( (session:any) => {
			this.myUsername = session.username
		})
		this.getGroup(this.groupUrl)
		this.getFans( this.groupUrl )
		this.getMyFan(this.groupUrl , 'fan')
	}

	changeKindOfFan( kindOfFan : string ) {
		this.page = 1
		this.kindOfFan = kindOfFan
		this.fans = []
		this.getFans(this.groupUrl )
		this.getMyFan(this.groupUrl , kindOfFan )
	}

	getGroup( url : string ) {
		let URI = 'group/url/' + url
		this.dataService.getObject(URI)
			.subscribe((response) => {
				if (response.error !== null)
					return this.err = response.error
			  if (response.group === null)
					return this.err = 'no group found'
				if (response.group.admins.filter((admin : { username : string } ) => { return ( admin.username === this.myUsername ) })[0] ){
					console.log('this is my group!')
					this.isMyGroup = true
				}
			  this.group = response.group;
			},
		(err: any) => console.log(err),
		() => console.log("group"));
	}

	getMyFan( url : string , kindOfFan : string) {
		let URI = 'fan/getMyFanByGroupUrlAndKind/?groupUrl=' + url + '&kindOfFan=' + kindOfFan
		this.dataService.getObject(URI)
			.subscribe((response) => {
			  this.myFan = response.fan;
			},
		(err: any) => console.log(err),
		() => console.log("myFan"));
	}

	getFans( url : string ) {
		let URI = 'fan/getFansByGroupUrl/?groupUrl=' + url+ '&page=' + this.page
		this.dataService.getObject(URI)
			.subscribe((response) => {
				if (this.page === 1)
					this.fans = response.fans;
				else 
					this.fans.push(...response.fans)
				this.fans.sort(( f1 : IFan , f2 : IFan ) => { return f2.score - f1.score })
				for ( var i = 0 ; i < this.fans.length ; i++ ) {
					this.fans[i].position = this.fans[i - 1] && this.fans[i-1].score == this.fans[i].score ? this.fans[i - 1].position : i + 1
				}
				this.pages = response.pages
			},
		(err: any) => console.log(err),
		() => console.log("fans"));
	}

	setGetFans() {
		if ( this.page >= this.pages )
			return
		this.page ++ 
		this.getFans( this.groupUrl )
	}

	// searchFans( username : string ) {
	// 	let URI = 'fan/getFanByUsernameAndGroupIdAndKind/?username=' + username + '&groupId=' + this.group._id + '&kindOfFan=fan'
	// 	this.dataService.getObject(URI)
	// 		.subscribe((response) => {
	// 			console.log(response)
	// 			if (response.fan !== null) {
	// 				this.fans = [response.fan]
	// 				this.noFoundFans = false
	// 			} else {
	// 				this.fans = null
	// 				this.noFoundFans = true
	// 			}
	// 		},
	// 	(err: any) => console.log(err),
	// 	() => console.log("fans"));
	// }

}
