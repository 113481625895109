import { Component , Input , OnInit , ViewChild , ElementRef , AfterContentInit } from '@angular/core'
import { IContestEntry } from '../../shared/interfaces'
import { setImageProps } from '../../shared/functions/setImageProps.function'


@Component({
	selector : 'picture-gallery-submission' , 
	templateUrl : './pictureGallerySubmission.html'
})

export class PictureGallerySubmissionComponent {
	@Input() contestEntry : IContestEntry 
	@Input() attachment : string

	@ViewChild("picture") pictureElement : ElementRef

	pictureWidth : number = 0
	pictureHeight : number = 0 
	imgElement : any 

	ngAfterContentInit() {
		console.log(this.attachment)
        this.pictureWidth = ( window.innerWidth > 767 ) ? (window.innerWidth * 5 / 12) : (window.innerWidth * 11 / 12) 
      	this.imgElement = setImageProps( window.innerWidth , this.attachment)
        this.imgElement.className = "center-pic"
        this.pictureElement.nativeElement.append(this.imgElement)

	}
}