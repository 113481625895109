import { Injectable } from '@angular/core'
import { SessionService, DataService, UserDataService } from '../../core/index'
import { IGroup, IFan } from '../../shared/interfaces'

@Injectable()
export class GroupService {

	myFan : IFan
	loadedFan : boolean
	group : IGroup
	
	constructor(
		private sessionService : SessionService,
		private dataService : DataService,
		private userDataService : UserDataService
	) {}

	checkIfIsGroup( admins : string[] ) {
		let isGroup : boolean = false
		this.sessionService.session.subscribe( ( session : any ) => {
			isGroup = (admins.indexOf(session.username) > - 1) ? true : false 
		})
		return isGroup
	}

	makeTagsArray (tagString : string) {
		let err = null
		if (tagString && !tagString.match('[#].*')) 
			return err = "Your tags must start with a #"
		let tags = tagString.split(" #")
		for ( var i = 1 ; i < tags.length ; i++ ) {
			tags[i] = '#' + tags[i]
		}
		return tags
	}
}