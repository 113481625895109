import { Component , OnInit } from '@angular/core'
import { FormControl , FormGroup , Validators } from '@angular/forms'
import { DataService } from '../../core/data.service'
import { ISupportMessage} from '../../shared/interfaces'

@Component({
	selector: 'support-messages', 
	templateUrl: "./supportMessages.html"
})

export class SupportMessagesComponent implements OnInit {

	contactSupportForm : FormGroup
	supportMessages : ISupportMessage[]
	err : string
	page : number = 1

	ngOnInit() {
		this.getSupportMessages( this.page )
	}

	constructor( private dataService : DataService) {}

	getSupportMessages( page : number ) {
		var URI = 'support/' + page
    	this.dataService.getObject( URI )
	        .subscribe((response) => {
	        	if (response.error)
	        		return this.err = response.error
	        	if (page === 1)
	        		this.supportMessages = response.messages
	        	else
	        		this.supportMessages.push(...response.messages)
			})
	}
	getNextSupportMessagesPage() {
		this.page ++
		this.getSupportMessages(this.page)
	}

}