import { Component } from '@angular/core'

import { IWinner } from '../index'
import { DataService } from '../../core/index'

@Component({
	selector : 'featured-winners' , 
	templateUrl : './featuredWinners.html'
})

export class FeaturedWinnersComponent {

	featuredWinners : IWinner[] = []
	featuredWinner : IWinner
	featuredWinnerIndex : number = 0

	constructor(
		private dataService : DataService
	) {}

	ngOnInit() {
		this.getFeaturedWinners()
	}

	getFeaturedWinners( ) { 
		let URI = 'winner/getLastThreeWinners'
		this.dataService.getObject( URI )
			.subscribe( ( response : { error : string , winners : IWinner[]} ) => {
				if ( response.error )
					return
				this.featuredWinners = response.winners
				this.featuredWinner = this.featuredWinners[this.featuredWinnerIndex]
				setInterval(()=> { this.rotateWinners() } , 18000 )
			})
	}

	rotateWinners() {
		if (this.featuredWinnerIndex < (this.featuredWinners.length - 1)) {
			this.featuredWinnerIndex ++
		} else {
			this.featuredWinnerIndex = 0 
		} 
		this.featuredWinner = this.featuredWinners[this.featuredWinnerIndex]
	}


}