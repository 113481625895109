import { Component , Input , OnInit , EventEmitter } from '@angular/core'
import { DataService , SessionService , UserDataService } from '../../core/index'

import { UploadOutput, UploadInput, UploadFile, humanizeBytes , UploadProgress } from 'ngx-uploader';

import { IContest , IFan , IGroup , IContestEntry } from '../../shared/interfaces'

import { InternationalService } from '../../shared/international.service'
import { zeroDecimalCurrencies } from '../../shared/international/zeroDecimalCurrencies'

import { stripePublishableKey } from '../../shared/keys/keys'

import moment from 'moment' 
import 'moment-timezone'

@Component({
  selector: 'upload-contest-entry', 
  templateUrl: "./uploadContestEntry.html"
})

export class UploadContestEntryComponent {    

  files: UploadFile[]
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;

  modalId : string

  URL : string = 'api/contestEntry/'
 
  @Input() myFan : IFan | boolean
  @Input() contest : any
  @Input() group : any
  contestEntry : IContestEntry
  tip : any = 0
  entryPrice : number 

  submitting : boolean
  percentUploaded : any
  session : any
  err : string
  successSubmittingContestEntryToServer : boolean = false
  alreadyEnteredContest : boolean = false
  userLoaded : boolean = false
  fanNeedsApproval : boolean = false 
  fanLoaded : boolean = false
  showSignin: boolean = false 
  contestEntryLoaded : boolean = false
  finishedUploading : boolean
  priceToBecomeKindOfFan : number 
  currencyWasSet : boolean
  userData : any
  showSignup : boolean = true 
  userNotSignedIn : boolean = true

  constructor(
    private dataService : DataService ,
    private sessionService : SessionService ,
    private internationalService : InternationalService , 
    private userDataService : UserDataService
  ) {
    this.files = []; // local uploading files array
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
    this.humanizeBytes = humanizeBytes;
  }

  ngOnInit() {

    this.modalId = 'uploadContestEntryModal' + this.contest._id
    this.getPriceToBecomeAKindOfFan()
    this.sessionService.session.subscribe( (session : any ) => {
      this.session = session
      this.setEntryPrice()
    })

    this.userDataService.userData.subscribe( ( userData : any ) => {
      this.userData = userData
    })

  }

  setEntryPrice( ) {
    this.entryPrice = this.contest.earlyRegistration && moment().isBefore(this.contest.earlyRegistration.registrationTime) ?
      this.contest.earlyRegistration.registrationPrice 
      :
      this.contest.registration && moment().isBefore(this.contest.registration.registrationTime) ?
        this.contest.registration.registrationPrice
        :
        this.contest.lateRegistration && moment().isBefore(this.contest.lateRegistration.registrationTime) ?
          this.contest.lateRegistration.registrationPrice
          :
          this.contest.contestPrice
    if (this.internationalService.getCurrencyCode( this.session.country ) !== this.contest.currency ) {
      this.entryPrice = this.entryPrice * this.session.exchangeRate / this.contest.exchangeRate 
    }
  }

  getPriceToBecomeAKindOfFan() {
    if (this.contest.kindOfFan ) {
      if (this.contest.kindOfFan !== 'fan') {
        this.priceToBecomeKindOfFan = this.group.kindsOfFans.filter(( kindOfFan : any ) => {
          return (kindOfFan.fanTitle === this.contest.kindOfFan)
        })[0].priceToBecomeKindOfFan
      } else {
        this.priceToBecomeKindOfFan = this.group.priceToBecomeFan
      }
    }
  }

  onUploadOutput(output: UploadOutput) {
    if (output.file && output.file !== undefined)
        this.percentUploaded = output.file.progress.data.percentage
    if(this.percentUploaded === 100)
      this.finishedUploading = true

    if (output.type === 'allAddedToQueue') { // when all files added in queue
      // uncomment this if you want to auto upload files when added
      // const event: UploadInput = {
      //   type: 'uploadAll',
      //   url: '/upload',
      //   method: 'POST',
      //   data: { foo: 'bar' },
      //   concurrency: 0
      // };
      // this.uploadInput.emit(event);
    } else if (output.type === 'addedToQueue') {
      this.files.push(output.file); // add file to array when added
    } else if (output.type === 'uploading') {
      // update current data in files array for uploading file
      const index = this.files.findIndex(file => file.id === output.file.id);
      this.files[index] = output.file;
    } else if (output.type === 'removed') {
      // remove file from array when removed
      this.files = this.files.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'dragOver') { // drag over event
      this.dragOver = true;
    } else if (output.type === 'dragOut') { // drag out event
      this.dragOver = false;
    } else if (output.type === 'drop') { // on drop event
      this.dragOver = false;
    }
    if (output.type === 'done') {
      console.log(this.files[0].response)
      if ( this.files[0].response.error !== null ) {
        this.submitting = false 
        this.successSubmittingContestEntryToServer = false 
        return this.err =  this.files[0].response.error
      }
      if (this.entryPrice == 0 && this.tip == 0) {
        this.successSubmittingContestEntryToServer = true      
        this.err = null
        this.closeModal()
        this.userData.myContestEntries.push(this.files[0].response.newContestEntry)
        this.userDataService.renewUserData(this.userData)
        return this.submitting = false 
      } 
      if (this.internationalService.getCurrencyCode( this.session.country ) !== this.contest.currency ) {
        this.entryPrice = this.entryPrice * this.session.exchangeRate / this.contest.exchangeRate 
      }

      if (this.entryPrice < 1 && this.entryPrice > 0)
        var stripeCharge = this.entryPrice * 100 + 3 + this.tip * 100 
      else 
        var stripeCharge =  this.entryPrice * 100 * 1.05 + this.tip * 100
      if ((<any>zeroDecimalCurrencies).indexOf(this.internationalService.getCurrencyCode(this.session.country) ) > -1 )
        stripeCharge = Math.ceil(stripeCharge / 100)
      var handler = (<any>window).StripeCheckout.configure({
        key: stripePublishableKey,
        locale: 'auto',
        currency : this.internationalService.getCurrencyCode(this.session.country) , 
        token: (token: any) => {
          var URL = 'contestEntry/updateContestEntryPaid'
          this.files[0].response.newContestEntry.tip = this.tip
          this.files[0].response.newContestEntry.contestPrice = this.entryPrice
          this.files[0].response.newContestEntry.token = token
          this.files[0].response.newContestEntry.group = this.group
          this.files[0].response.newContestEntry.currency = this.internationalService.getCurrencyCode(this.session.country)
          this.dataService.postObject(URL, this.files[0].response.newContestEntry)
            .subscribe(response => {
              if (response.error) {
                this.submitting = false 
                return this.err = response.error
              }
              
              this.userData.myContestEntries.push(response.newContestEntry)
              this.userDataService.renewUserData(this.userData)
              this.successSubmittingContestEntryToServer = true      
              this.err = null
              this.closeModal()
              this.submitting = false 
            })
        }
      });

      handler.open({
        name: this.contest.contestName ,
        description: 'enter contest' , 
        amount: stripeCharge
      });
    }
  }

  getFanAndContest() {
    this.userLoaded = true
    this.sessionService.session.subscribe( (session:any) => {
      if (session.loggedIn !== true) {
        this.showSignin = false 
        this.showSignup = true
        return this.userNotSignedIn = true
      } else {
        this.userNotSignedIn = false
      } 
    })
    this.getMyFan(this.contest.groupUrl)
    this.selectContest()
  }

  toggleNotSignedInPage() {
    this.showSignup = !this.showSignup
    this.showSignin = !this.showSignin
  }

  signedUserIn( notSignedUp : boolean ) {
    this.userNotSignedIn = notSignedUp 
  }

  becameAFan( newFan : IFan ) {
    if (newFan.approved === false)
      return this.fanNeedsApproval = true 
    this.myFan = newFan
  }

  getMyFan( url : string ) {
    if ( !this.group)
      return this.myFan = true
    this.userDataService.userData.subscribe( ( userData : any ) => {
      this.myFan = userData.myFans.filter( ( fan : IFan ) => {
        return ( fan.groupUrl === url && fan.kindOfFan === this.contest.kindOfFan)
      })[0]
      if (!!!this.myFan)
        this.fanNeedsApproval = (userData.myFanRequests.filter( ( fan : IFan ) => {
          return ( fan.groupUrl === url && fan.kindOfFan === this.contest.kindOfFan)
        })[0]) ? true : false 
    })
  }

  selectContest() {
    let URI = 'contest/getContestById/' + this.contest._id
    this.dataService.getObject(URI)
      .subscribe((response) => {
        this.contest = response.contest
        this.selectContestEntry()
      })
  }

  selectContestEntry() {
    let URI = 'contestEntry/getMyContestEntryByContestantUsername'
    this.dataService.getObject(URI)
      .subscribe((response : any) => {
        this.contestEntryLoaded = true
        if (response.contestEntry !== null) {
          if (response.contestEntry.paid !== null)
            return this.alreadyEnteredContest = true
        }
        this.alreadyEnteredContest = false

      })
  }

  openModal() {  
    if (this.contest.startTime && moment().isBefore(this.contest.startTime))  

    document.getElementById('uploadContestEntryModal' + this.contest._id).style.display = "block"
    document.getElementById('uploadContestEntryModalContent' + this.contest._id).style.display = "block"
  }

  closeModal() {
    document.getElementById('uploadContestEntryModal' + this.contest._id ).style.display = "none"
    document.getElementById('uploadContestEntryModalContent' + this.contest._id ).style.display = "none"
  }

  registerForContest() {
    this.sessionService.session.subscribe( (session:any) => {
      if (session.loggedIn !== true) 
        return this.userNotSignedIn = true
    })

    let URI = 'contestEntry/' , formValues = {
      contestId : this.contest._id
    }
    this.dataService.postObject( URI , formValues )
  }

  enterContest()  {
    console.log(this.contest)
    this.sessionService.session.subscribe( (session:any) => {
      if (session.loggedIn !== true) 
        return this.userNotSignedIn = true
    })
    if (this.tip > 0 && this.tip < 0.75) {
      this.successSubmittingContestEntryToServer = false 
      return this.err = 'The tip must be at least 75 cents'
    }
    this.submitting = true 
    const event: UploadInput = {
      type: 'uploadAll',
      fieldName: 'upload', 
      url: this.URL,
      method: 'POST',
      data: { 
        tip: this.tip , 
        groupName : this.group.groupName , 
        groupUrl : this.group.groupUrl , 
        groupId : this.group._id , 
        groupUserId : this.group.userId ,
        maxVotes : this.contest.maxVotes , 
        kindOfFan : this.contest.kindOfFan , 
        entryPrivacy : this.contest.entryPrivacy , 
        contestType : this.contest.contestType ,
        contestName : this.contest.contestName , 
        contestPrice : this.entryPrice.toString() , 
        contestId : this.contest._id
      }    
    }

    this.uploadInput.emit(event);
  }

  onUploadFinished( contestEntry : IContestEntry ) {
    if ( !(this.entryPrice > 0) ) {
      this.userData.myContestEntries.push(contestEntry)
      this.userDataService.renewUserData(this.userData)
      return this.contestEntry = contestEntry
    }

    if (this.entryPrice < 1 && this.entryPrice > 0)
      var stripeCharge = this.entryPrice * 100 + 3 + this.tip * 100 
    else 
      var stripeCharge =  this.entryPrice * 100 * 1.05 + this.tip * 100
    if ((<any>zeroDecimalCurrencies).indexOf(this.internationalService.getCurrencyCode(this.session.country) ) > -1 )
      stripeCharge = Math.ceil(stripeCharge / 100)
    var handler = (<any>window).StripeCheckout.configure({
      key: stripePublishableKey,
      locale: 'auto',
      currency : this.internationalService.getCurrencyCode(this.session.country) , 
      token: (token: any) => {
        var URL = 'contestEntry/updateContestEntryPaid'
        this.files[0].response.newContestEntry.tip = this.tip
        this.files[0].response.newContestEntry.contestPrice = this.entryPrice
        this.files[0].response.newContestEntry.token = token
        this.files[0].response.newContestEntry.group = this.group
        this.files[0].response.newContestEntry.currency = this.internationalService.getCurrencyCode(this.session.country)
        this.dataService.postObject(URL, this.files[0].response.newContestEntry)
          .subscribe(response => {
            if (response.error) {
              this.submitting = false 
              return this.err = response.error
            }
            this.userData.myContestEntries.push(response.newContestEntry)
            this.userDataService.renewUserData(this.userData)
            this.successSubmittingContestEntryToServer = true      
            this.err = null
            this.closeModal()
            this.contestEntry = contestEntry
            this.submitting = false 
          })
      }
    });

    handler.open({
      name: this.contest.contestName ,
      description: 'enter contest' , 
      amount: stripeCharge
    });
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  }
}
