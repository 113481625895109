<div  class="col-xs-12" style="color: white; margin : 0px; padding : 0px;">
	<div>
		<div class="col-xs-12" style="height: 75vh; margin : 0px; padding : 0px; position: relative; background-image: url(https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fclouds.jpg?alt=media&token=739a8b62-18bd-49ce-96be-bc07a601c1c5); background-repeat: no-repeat; background-size: cover; background-position: center;">
			<div
				style="padding: 10px;" 
				class="image-shadow text-shadow"
			>
				<h1 class="jumbo-text">
					Spotidol's Portfolio
				</h1>
			</div>
		</div>
	</div>
	<div *ngFor="let item of portfolio">
		<div class="col-xs-12" [ngStyle]=" item.picture " style="height: 75vh; margin : 0px; padding : 0px; position: relative; background-repeat: no-repeat; background-size: cover; background-position: center;">
			<div
				style="padding: 10px;" 
				class="image-shadow text-shadow"
			>
				<h1 class="jumbo-text">
					{{ item.header }}
				</h1>
				<div style="position: absolute; bottom: 20px; font-size : 20px; max-width: 600px;">
					<h2>{{ item.description }}</h2>
					<a routerLink="{{ item.link }}"><p>Read More</p></a>
				</div>
			</div>
		</div>
	</div>
</div>