<div *ngIf="!contestEntry"> 
  <div (click)="getFanAndContest()">
    <button 
      (click)="openModal()" 
      type="button" 
      class="white-text btn btn-info btn-lg purple-gray-background"
      style="
        border-width: 0px;
        margin: auto;
        display: block;
      "
    >Enter</button>
  </div>
  <div id="uploadContestEntryModal{{ contest._id }}" class="cssModal">
    <div id="uploadContestEntryModalContent{{ contest._id }}" class="cssModalContent modal-content row">
      <button type="button" class="btn btn-default" (click)="closeModal()">Close</button>
      <div class="black-text  medium-text" *ngIf="!!err && !submitting"><p> {{ err }} </p></div>
      <div class="black-text  medium-text" *ngIf="successSubmittingContestEntryToServer"> success! please click "close" to close.</div>
      <div *ngIf="submitting">Submitting entry. One Moment please. {{ percentUploaded }} % 
        <div *ngIf="finishedUploading"><p>Almost done...</p></div>
      </div>
      <div class="white-text modal-header">
        Enter
      </div>
      <div class="medium-text" *ngIf="userNotSignedIn">
        <div *ngIf="showSignup">
          <upload-contest-entry-sign-up (signedUserIn)="submittedSignUp($event)"></upload-contest-entry-sign-up>
      
          <div style="border-radius: 5px; border-width: 1px; border-color: white; border-style : solid ; padding: 15px; margin-top: 20px; width: 300px; color : white;" class="col-centered bold">
              <span>Already a Member?</span>
              <a (click)="toggleNotSignedInPage()" style="color: white;"><span>Log In</span></a>
          </div>
        </div>

        <div *ngIf="showSignin">
          <upload-contest-entry-login (signedUserIn)="submittedSignUp($event)"></upload-contest-entry-login>

          <div style="border-radius: 5px; border-width: 1px; border-color: white; border-style : solid ; padding: 15px; margin-top: 20px;  width: 300px; color : white;" class="col-centered bold">
              <span>Not a Member?</span>
              <a (click)="toggleNotSignedInPage()"><span>Sign Up</span></a>
          </div>
        </div>
      </div>
      <update-profile-modal 
        *ngIf="showUpdateProfile"
        [scene]="'uploadCompetitionEntry'"
        (closeUpdateProfileModal)="closeUpdateProfileModal($event)"
      ></update-profile-modal>
      <div id="enterContestFormDetails" *ngIf="!showUpdateProfile && !successSubmittingContestEntryToServer && (group === undefined || myFan) && !userNotSignedIn" [hidden]="submitting">
        <div *ngIf="myFan && !userNotSignedIn && !alreadyEnteredContest" id="fileUploadSection">
          <upload [competitionEntryOrGroupPicture]="'competitionEntry'" [objectId]="contest._id" [maxFiles]="1" (uploadFinished)="onUploadFinished($event)" ></upload>
        </div>
        <p class="black-text  medium-text" *ngIf="group && fanLoaded && !myFan">You must be a {{ contest.kindOfFan }} to enter into competitions by this group!</p>
        <p class="black-text  medium-text" *ngIf="contestEntryLoaded && alreadyEnteredContest">You have already entered this competition!</p>
      </div>
    </div>
  </div>
</div>

  <div *ngIf="contestEntry">
    <div>
      <h2>My Entry</h2>
      <contest-entries-page-contest-entry 
        [contestEntry]="contestEntry" 
        [contest]="contest" 
        [isMyGroup]="false" 
        [fan]="myFan" 
        [group]="group" 
        [userInstance]="null"
      ></contest-entries-page-contest-entry>
    </div>
  </div>