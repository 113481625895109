
import "froala-editor/js/froala_editor.pkgd.min.js";
import {Observable} from 'rxjs';

import {prebootClient} from 'preboot/__build/src/browser/preboot_browser';

import { NgModule , NgZone } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { HttpClientModule } from '@angular/common/http';
import { NavigationEnd, NavigationError, RouterModule , Router } from '@angular/router';
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from '@angular/forms' 

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';

import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
 
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';


import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage'
import { AngularFireAuthModule } from '@angular/fire/auth';

var firebaseConfig = {
  apiKey: "AIzaSyAZYQ8lMxtJFt2ij9XZXGsp6yf82NpglXM",
  authDomain: "spotidol-169915.firebaseapp.com",
  databaseURL: "https://spotidol-169915.firebaseio.com",
  projectId: "spotidol-169915",
  storageBucket: "spotidol-169915.appspot.com",
  messagingSenderId: "500111159777"
}

import { SpotidolAppComponent } from './spotIdol-app.component'

import { RootComponent, RootResolveService } from './root/index'

import { SearchService } from './search/search.service'

import { AudioPlayerComponent , AudioPlayerSubject } from './audioPlayer/index'

import { SearchBarComponent , WelcomeSearchComponent } from './search/index'

import { ProfileComponent , UpdateProfileModalComponent , ProfileResolveService , ProfileThumbnailComponent , 
    UpdateProfileFacebookLinkComponent , UpdateProfileInstagramLinkComponent , UpdateProfileTwitterLinkComponent , UpdateUserProfilePictureComponent } from './profile/index'

import { UnderConstructionComponent } from './underConstruction/underConstruction.component'

import { GroupService } from './group/groupService/group.service'

import { 
  GroupModule ,RecentGroupsResolveService , GroupResolveService , 
  GroupComponent , CreateGroupComponent , 
  GroupUploadComponent , GroupDetailsComponent ,  GroupThumbnailComponent , 
  MyGroupsComponent , GroupHeaderComponent , RecentGroupsComponent , 
  FansComponent , AdminComponent
} from './group/index';

import { ScienceFairComponent , WelcomeScienceFairComponent  } from './scienceFair/index'

import { MediaMenuComponent , ShareOnSocialComponent , CountdownComponent , LoaderComponent , ShareModalComponent , PdfReaderComponent  } from './shared/components/index'

import { FooterComponent } from './footer/footer.component'

import { NewCommentButtonComponent , NewCommentSectionComponent , ContestEntryCommentsComponent } from './contestEntryComment/index'

import { AlertComponent , BecomeARoleAlertComponent , CreatedCompetitionAlertComponent , EndedCompetitionAlertComponent , EnteredCompetitionAlertComponent , RequestToBeRoleAlertComponent , AlertsComponent } from './alert/index'

import { UploadComponent } from './shared/components/upload/upload.component'

import { CompetitionEntryResolveService, ContestEntryPictureGalleryResolveService , 
         ContestEntriesResolveService, ContestVoteSubjectService, ContestEntryModule ,  CompetitionEntryComponent, 
  ScoreEntryComponent, ContestVoteComponent, ScoreCardContestVoteComponent , 
  PictureGallerySubmissionComponent, ContestEntryPictureGalleryComponent, 
  UploadContestEntryComponent, UploadContestEntriesComponent, UploadContestEntriesMobileModalComponent , VoterScoreCardsModalComponent , 
  UploadTextContestEntryComponent, ContestEntriesComponent, ScoreCardContestVoteResolveService , 
  ContestEntryComponent, ContestEntriesPageContestEntryComponent, 
  MyContestEntriesComponent, UploadCodeContestEntryComponent, FeaturedContestEntryComponent 
} from './contestEntry/index'

import { PricingComponent } from './pricing/pricing.component'

import { HipHopComponent } from './hipHop/hipHop.component'

import { OnboardingComponent } from './onboarding/onboarding.component'

import { ReferralCodeComponent } from './navbar/referralCode/referralCode.component'

import { PaymentHistoryComponent } from './paymentHistory/paymentHistory.component'
import { SearchComponent } from './search/search.component'

import { NavbarComponent } from './navbar/navbar.component'
import { NavbarNotLoggedInComponent , NavbarLoggedInComponent } from './navbar/index'
import { CreateContentButton } from './navbar/createContentButton/createContentButton.component'
import { HomeComponent } from './home/home.component'
import { OneOnOnePrivacyPolicyComponent , OneOnOneTermsOfUseComponent , FaqComponent , CMOComponent , CareersComponent , BlogPostsComponent , SupportComponent , SupportMessageComponent , SupportMessagesComponent ,
         ContestRulesComponent , PrivacyPolicyComponent , TermsOfUseComponent , ValuesComponent , PortfolioComponent ,
         UpdateLogsComponent , HowToComponent , VisionComponent , MissionComponent , AboutComponent , StoryComponent , BeASponsorComponent , AffiliatesComponent } from './support/index'

import { LoginComponent , UploadContestEntrySignUpComponent , 
         UpdateUserComponent , 
         SignUpComponent , SignUpRedirectComponent ,
         EmailVerificationComponent , PhoneVerificationComponent , 
         LogoutComponent , BannedComponent , UploadContestEntryLoginComponent , 
         ResetPasswordComponent , UnsubscribeComponent , TotalOwedComponent , CashOutComponent 
       } from './user/index'

import { DreamComponent , CreateDreamComponent , ProfileDreamsComponent , JoinDreamComponent , DreamJoinersComponent } from './dream/index'

import { WelcomeComponent } from './welcome/welcome.component'
import { WelcomeVideoComponent } from './welcome/welcomeVideo/welcomeVideo.component'
import { WelcomeResolveService } from './welcome/welcome.resolve.service'

import { WelcomePlaylistComponent , WelcomePlayerComponent } from './welcome/index'

import { ReportUserComponent } from './shared/reportUser/reportUser.component'

import { InfoButtonComponent } from './infoButton/infoButton.component'

import { InviteComponent , InviteInputComponent } from './invite/index'

import { FeaturedWinnerComponent , FeaturedWinnersComponent , WinnersComponent , WinnerComponent } from './winner/index'

import { PageNotFoundComponent  } from './pageNotFound/pageNotFound.component'

import { NumberOnlyDirective } from './shared/directives/numberOnly.directive'

import { DataService , OpenAppService , SessionService , UserDataService , ValidationService } from './core/index'
import { DateService } from './shared/date.service'
import { InternationalService } from './shared/international.service'
import { appRoutes } from './routes'

import {
  RecentContestsResolveService , CreateContestSubjectService , CreateContestComponent , CreateContestEntryDetailsComponent , CreateContestVotingDetailsComponent , CreateContestJudgingDetailsComponent , CreateContestPrizeDetailsComponent , ChooseGroupThumbnailComponent , CreateContestNavbarComponent , CreateGroupCreateContestComponent , ChooseGroupComponent , WelcomeContestsComponent , ContestInviteModalComponent , YourCompetitionsComponent ,
  RewardsService , CreateContestDetailsComponent , ReviewContestComponent , CreateContestReviewComponent , UpdateContestEntryPrivacyComponent , ProfileContestThumbnailComponent , 
  ContestModule  , JudgeComponent , ViewerComponent , CommenterComponent , FeaturedContestPhoneComponent , ContestTagsThumbnailComponent , FeaturedContestComponent , FeaturedContestsComponent , FeaturedContestRewardsComponent , 
  ContestComponent , ContestHeaderComponent , MyContestsComponent , OpenContestThumbnailComponent , ContestThumbnailComponent , 
         SelectGroupCreateContestComponent , RecentContestsComponent , ContestVideoUploadComponent , ExampleWinnerComponent , PhotoOfTheWeekWinnerComponent
} from './contests/index'

import { 
  ContestService
} from './contests/contest.service'

@NgModule({
  imports: [

    NgxMaskModule.forRoot(),
    NgbModule, 
    BrowserModule,
    BrowserAnimationsModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule, 
    HttpClientModule,
    RouterModule.forRoot(appRoutes), 
    FormsModule, 
    ReactiveFormsModule,
    FroalaEditorModule.forRoot(), 
    FroalaViewModule.forRoot(),
    InfiniteScrollModule , 
    GroupModule ,
    ContestModule , 
    NgxExtendedPdfViewerModule, 
    AngularFireModule.initializeApp( firebaseConfig ) , 
    ContestEntryModule,
    NgxGoogleAnalyticsModule.forRoot('210316161')
  ],
  declarations: [
    AudioPlayerComponent , 

    AffiliatesComponent , 

    CMOComponent , 

    AlertComponent , 
    BecomeARoleAlertComponent , 
    CreatedCompetitionAlertComponent , 
    EndedCompetitionAlertComponent , 
    EnteredCompetitionAlertComponent , 
    RequestToBeRoleAlertComponent , 
    AlertsComponent ,
    PageNotFoundComponent, 
    SpotidolAppComponent,
    WelcomeComponent ,
    WelcomeVideoComponent ,  
    HomeComponent, 
    NavbarComponent , 
    CreateContentButton , 
    RootComponent ,
    FeaturedContestPhoneComponent,
    ContestTagsThumbnailComponent,
    ProfileContestThumbnailComponent , 
    FeaturedContestComponent,
    FeaturedContestsComponent,
    FeaturedContestRewardsComponent,
    ContestComponent,
    ContestHeaderComponent,
    MyContestsComponent,
    ContestThumbnailComponent,
    OpenContestThumbnailComponent , 
    SelectGroupCreateContestComponent,
    ContestInviteModalComponent , 
    RecentContestsComponent,
    ContestVideoUploadComponent,
    ExampleWinnerComponent,
    PhotoOfTheWeekWinnerComponent, 
    JudgeComponent , 
    CommenterComponent , 
    ViewerComponent , 
    WelcomeContestsComponent , 
    CreateContestComponent , 
    CreateContestNavbarComponent , 
    ChooseGroupComponent , 
    MyGroupsComponent,
    GroupComponent, 
    GroupUploadComponent, 
    CreateGroupComponent, 
    GroupDetailsComponent , 
    GroupThumbnailComponent ,
    GroupHeaderComponent ,
    RecentGroupsComponent ,  
    FansComponent , 
    AdminComponent ,
    CreateGroupCreateContestComponent , 
    ChooseGroupThumbnailComponent , 
    CreateContestDetailsComponent ,
    CreateContestEntryDetailsComponent , 
    CreateContestVotingDetailsComponent , 
    CreateContestJudgingDetailsComponent , 
    CreateContestPrizeDetailsComponent , 
    CreateContestReviewComponent , 
    ReviewContestComponent , 

    YourCompetitionsComponent , 

    ScienceFairComponent , 
    WelcomeScienceFairComponent ,

    NavbarNotLoggedInComponent , 
    NavbarLoggedInComponent ,

    PricingComponent , 

    PdfReaderComponent , 

    OnboardingComponent , 

    UpdateContestEntryPrivacyComponent , 

    UploadContestEntryLoginComponent , 

    CompetitionEntryComponent , 
    ScoreEntryComponent , 
    PictureGallerySubmissionComponent , 
    ContestEntryPictureGalleryComponent , 
    UploadContestEntryComponent , 
    UploadContestEntriesComponent , 
    UploadContestEntriesMobileModalComponent ,
    UploadTextContestEntryComponent , 
    ContestEntriesComponent , 
    ContestEntryComponent , 
    ContestEntriesPageContestEntryComponent , 
    MyContestEntriesComponent , 
    UploadCodeContestEntryComponent , 
    FeaturedContestEntryComponent, 
    
    ScoreCardContestVoteComponent , 
    ContestVoteComponent , 
    VoterScoreCardsModalComponent , 

    FeaturedWinnerComponent , 
    FeaturedWinnersComponent , 
    WinnersComponent ,
    WinnerComponent , 

    FaqComponent , 
    SupportComponent , 
    SupportMessageComponent , 
    SupportMessagesComponent , 
    UpdateLogsComponent , 
    HowToComponent , 
    BlogPostsComponent ,
    AboutComponent , 
    ValuesComponent , 
    PortfolioComponent , 

    HipHopComponent , 

    OneOnOnePrivacyPolicyComponent , 
    OneOnOneTermsOfUseComponent ,

    UploadComponent ,  

    NewCommentButtonComponent , 
    NewCommentSectionComponent , 
    ContestEntryCommentsComponent , 

    UnderConstructionComponent , 

    VisionComponent , 
    MissionComponent , 
    StoryComponent , 

    FooterComponent , 

    CareersComponent , 
    ContestRulesComponent , 
    PrivacyPolicyComponent , 
    TermsOfUseComponent , 

    PaymentHistoryComponent,

    MediaMenuComponent , 
    ShareOnSocialComponent ,  
    ShareModalComponent , 

    DreamComponent , 
    CreateDreamComponent , 
    ProfileDreamsComponent ,
    JoinDreamComponent , 
    DreamJoinersComponent ,
    CountdownComponent , 
    LoaderComponent , 

    ProfileComponent , 
    ProfileThumbnailComponent , 
    UpdateProfileFacebookLinkComponent , 
    UpdateProfileInstagramLinkComponent , 
    UpdateProfileTwitterLinkComponent , 
    UpdateUserProfilePictureComponent , 
    UpdateProfileModalComponent , 
    UnsubscribeComponent , 

    InviteComponent , 
    InviteInputComponent , 
    EmailVerificationComponent, 
    PhoneVerificationComponent, 
    LoginComponent, 
    SignUpComponent, 
    SignUpRedirectComponent, 
    UploadContestEntrySignUpComponent , 
    UpdateUserComponent, 
    LogoutComponent , 
    AlertsComponent , 
    BannedComponent , 
    ResetPasswordComponent , 
    TotalOwedComponent , 
    CashOutComponent , 

    ReferralCodeComponent , 

    WelcomePlaylistComponent , 
    WelcomePlayerComponent ,

    ReportUserComponent , 
    SearchBarComponent , 

    SearchComponent , 
    WelcomeSearchComponent , 
    InfoButtonComponent , 
    BeASponsorComponent , 
    
    NumberOnlyDirective
    ],
    providers : [
      DataService , 
      SessionService , 
      OpenAppService , 
      ValidationService , 

      AudioPlayerSubject , 

      CreateContestSubjectService ,
      ContestEntriesResolveService , 
      ContestVoteSubjectService , 
      CompetitionEntryResolveService , 
      ContestEntryPictureGalleryResolveService , 
      DateService , 
      InternationalService ,
      GroupService , 
      GroupResolveService ,
      ProfileResolveService , 
      RewardsService , 
      RecentGroupsResolveService , 
      RecentContestsResolveService, 
      RootResolveService , 
      ScoreCardContestVoteResolveService , 
      SearchService ,
      UserDataService , 
      WelcomeResolveService ,
      ContestService
    ], 
    bootstrap: [ SpotidolAppComponent ]
})
export class AppModule {
}