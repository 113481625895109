import { Component , Input , OnInit } from '@angular/core'

import { IContestEntry , IContest } from '../../shared/interfaces'
import { IScoreCard } from '../../contests/scoreCard/scoreCard.interface'
import { IContestEntryComment } from '../../contestEntryComment/index'
import { DataService , SessionService } from '../../core/index'

@Component({
	selector : 'score-entry' , 
	templateUrl : "./scoreEntry.html"
})

export class ScoreEntryComponent {

	@Input() contestEntry : IContestEntry
	@Input() contest : IContest
	@Input() scoreCard : IScoreCard

	entryScoreCard : IScoreCard

	session : any

	score : number 
	commentText : string

	scoredWinner : boolean
	scoringWinner : boolean 
	err : string

	constructor(
		private dataService : DataService , 
		private sessionService : SessionService 
	) {} 

	ngOnInit() {
		this.getSession()
		this.score = this.contestEntry.score
		this.entryScoreCard = this.scoreCard
	}

	getSession() {
		this.sessionService.session
			.subscribe( ( session : any ) => {
				this.session = session
			})
	}

	submitScoring() {
		return new Promise<void>( ( res ) => {
			Promise.all( [
				this.scoreEntry( this.contestEntry ) ,
				this.submitComment()
			]).then( () => {
				this.closeScoreEntryModal()
				return res()
			})
		}) 
	}

	scoreEntry( contestEntry : IContestEntry ) {
		return new Promise<void>( ( res ) => {
			let scoreCardToSend = this.entryScoreCard
			for ( var i = 0 ; i < this.entryScoreCard.judgingCriteria.length ; i++ ) {
				scoreCardToSend.judgingCriteria[i].score = this.entryScoreCard.judgingCriteria[i].points
			}
			scoreCardToSend.judge = this.session.username
			let URI = 'contestEntry/scoreContestEntry/' + contestEntry._id ,  
				scoreBody = {
					groupUserId : this.contest.creatorId , 
					admins : this.contest.admins , 
					contestWinnerSelected : this.contest.winnerSelected , 
					scoreCard : scoreCardToSend
				}

			this.dataService.postObject(URI, scoreBody)
	      .subscribe((response : any) => {
	      	console.log( response )
	      	if (response.error !== null) 
	      		return this.err = response.error
	      	console.log(response)
      		this.score = response.contestEntry.score
	  		this.scoredWinner = true
	  		return res()
	      })
    })
	}

	submitComment() {
		return new Promise<void>( ( res ) => {
			if ( this.commentText == null || this.commentText.length === 0)
				return res()
			let URI = 'contestEntryComment/' ,
				postCommentBody = {
					contestEntryId : this.contestEntry._id , 
					commentBody : this.commentText
				}

			this.dataService
				.postObject( URI , postCommentBody ) 
				.subscribe( ( response : {
					error : boolean | string , 
					contestEntryComment : IContestEntryComment
				}) => {
					return res()
				})
		})
	}

	openScoreEntryModal() {
		document.getElementById('scoreEntryModal' + this.contestEntry._id).style.display = "block"
		document.getElementById('scoreEntryModalContent' + this.contestEntry._id).style.display = "block"
	}

	closeScoreEntryModal() {
		document.getElementById('scoreEntryModal' + this.contestEntry._id).style.display = "none"
		document.getElementById('scoreEntryModalContent' + this.contestEntry._id).style.display = "none"
	}

}