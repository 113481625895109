<div 
	class="col-xs-12 purple-gray-background" 
	style="
		height: 40px; 
		position: relative; 
		top: -3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
	"
> 
  <div *ngIf="!loaded" style=" margin : 0 auto; width : 32px; height: 32px;" class="loader"></div>
	<div *ngIf="loaded" class="col-xs-12 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3 purple-gray-background no-padding col-centered center-text" style="height: 40px; padding-top : 4px; z-index: 1 ; ">
		<button *ngIf="myVotes > 0 || totalVotes == 0" (click)="removeVoteOnContestEntry()" style="position : relative ; margin-right : 10px ; height: 32px; width: 32px ; border-radius: 10px ;display: inline ; margin: 0px; padding: 0px; color : white ; font-size: 22px; font-weight: bolder; background-color: red; border-width: 0px;"> - </button>
		<span style="display: inline; margin: 0px; padding: 0px;" class="center-text  col-centered">
			<span 
				class="col-centered center-text" 
				style="
					color : white ; 
				  font-family: sans-serif;
					font-size: 18px;
					font-weight: 1000;
				"
			>{{ totalVotes }} VOTE<span *ngIf="totalVotes !== 1">S</span></span>
		</span>
		<button *ngIf="(myVotes <= maxVotes || contestEntry.pricePerVote > 0 )" (click)="voteHandler()" style="position : relative ; margin-left : 10px ; height: 32px; width: 32px ; border-radius: 10px ;display: inline ; margin: 0px; padding: 0px; color : white ; font-size: 22px; font-weight: bolder; background-color: green; border-width: 0px;"> + </button>
		<span *ngIf="justVoted " style="color : white ; font-size: 18px;">{{ maxVotes - myVotes }} <span *ngIf="contestEntry.pricePerVote > 0">free </span>left</span>
	</div>

  <div id="signUpContestVoteModal" class="cssModal" role="dialog" style="z-index: 5;">
    <div id="signUpContestVoteModalContent" class="cssModalContent modal-content row" style="z-index: 5;">
      <div>
          <button type="button" class="btn btn-default" (click)="closeSignUpModal()" style="position: absolute; top: 0px; left: 0px;">Close</button>
          <div class="medium-text">
            <div *ngIf="showSignup" style="z-index: 5;">
              <upload-contest-entry-sign-up (signedUserIn)="submittedSignUp($event)"></upload-contest-entry-sign-up>
          
              <div style="border-radius: 5px; border-width: 1px; border-color: white; border-style : solid ; padding: 15px; margin-top: 20px; max-width: 300px; color : white; text-align: center;" class="col-centered bold">
                  <span>Already a Member?</span>
                  <a (click)="toggleNotSignedInPage()" style="color: white;"><p>Log In</p></a>
              </div>
            </div>

            <div *ngIf="showSignin" style="z-index: 5;">
              <upload-contest-entry-login (signedUserIn)="submittedSignUp($event)"></upload-contest-entry-login>

              <div style="border-radius: 5px; border-width: 1px; border-color: white; border-style : solid ; padding: 15px; margin-top: 20px; max-width: 300px; color : white; text-align: center;" class="col-centered bold">
                  <span>Not a Member?</span>
                  <a (click)="toggleNotSignedInPage()"><p>Sign Up</p></a>
              </div>
            </div>
          </div>
     	</div>
    </div>
  </div>

  <div id="buyVotesModal{{ contestEntry._id }}" class="cssModal" role="dialog" style="z-index: 5;">
    <div id="buyVotesModalContent{{ contestEntry._id }}" class="cssModalContent modal-content row" style="z-index: 5;">
      <div>
        <button type="button" class="btn btn-default" (click)="closeBuyVotesModal()" style="position: absolute; top: 0px; left: 0px;">Close</button>
        <div class="medium-text">
          <div *ngIf="!buyingVotes">          
            <h2 style="text-align: center;">Buy More Votes</h2>
            <p style="text-align: center;">You ran out of free votes!</p>
            <p style="text-align: center; font-size: 14px;">Enter how many votes you want to buy</p>
            <p style="text-align: center;">
              <input 
                type="text" 
                name="buyVotesInput" 
                placeholder="1" 
                (change)="convertTotalVotesPrice()"
                mask="0*"
                [(ngModel)]="totalVotesToBuy" 
                style="
                  border: none;
                  background: none;
                  border-bottom: solid 1px white;
                  width: 40px;
                " 
              >
              <span> Votes for {{ currencySymbol}}{{ totalVotesPrice }}</span>
            </p>
            <p style="text-align: center;">
              <button 
                style="
                  text-align: center;
                  background-color: #38194C;
                  border: solid 2px white;
                  border-radius: 7px;
                  padding: 5px;
                  font-size: 24px;
                "
                (click)="processPayment( contestEntry )"
              >
                Buy {{ totalVotesToBuy }} Votes
              </button>
            </p>
          </div>
          <div *ngIf="buyingVotes">
            <h2>
              Processing Payment...
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>  
</div>


