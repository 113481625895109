<div class="sidebar col-sm-3 col-md-2">
		<a 
			*ngFor="let button of buttons" 
			(click)="selectNavigation( button )"
			[ngStyle]=" button.style "
		>
			<span>
				{{ button.name }}
			</span>
			<img 
				*ngIf="button.completed"
				src="/app/assets/images/checkmark.png"
				style="width : 30px; height: 30px;"  
			/>
		</a>
		<a 
			(click)="createContest()"
		>
			<span>
				Create
			</span>
        	<div 
        		*ngIf="creatingContest"
        		class="loader" 
        		style="width : 40px ; height: 40px ;"
        	></div>
		</a>
		<div style="display: flex; flex-direction: column; flex: 1; align-items: center; justify-content: center;">
			<img src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fspotidolcom%20logo%20white.png?alt=media&token=1dc3985f-7450-4e70-a2a0-a182283d028c" style="height: 65px; width: auto; margin: 0 auto;" />
			<p style="text-align: center; color: white;">Live Your Dreams</p>
		</div>
</div>