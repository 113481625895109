import { Component } from '@angular/core'

@Component({
	selector: 'careers',
	templateUrl: './careers.html' , 
	styleUrls : ['./careers.css']
})

export class CareersComponent {

}
