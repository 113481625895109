import { Component , Input , OnInit , AfterContentInit } from '@angular/core'
import { IContest } from '../../../shared/interfaces'
import { RewardsService } from '../../index'
import { SessionService } from '../../../core/index'
import { ContestService } from '../../contest.service'

@Component({
	selector : 'featured-contest-rewards' , 
	templateUrl : './featuredContestRewards.html'
})

export class FeaturedContestRewardsComponent {

	@Input() contest : IContest
	session : any
	rewardCurrencySymbol : string = '$'
	noRewards : boolean = true

	constructor(
		private rewardsService : RewardsService , 
		private sessionService : SessionService , 
		private contestService : ContestService
	) {	}

	ngAfterContentInit() {
		this.noRewards = this.contestService.checkIfThereAreNoRewards(this.contest)
		this.sessionService.session.subscribe( ( session : any ) => {
			this.session = session
			this.contest = this.rewardsService.setRewardCurrency( this.contest , this.session).contest
			this.rewardCurrencySymbol = this.rewardsService.setRewardCurrency( this.contest , this.session).rewardCurrencySymbol
		})
	}

}