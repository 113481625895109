<div class="col-sm-12 col-md-4 col-lg-offset-1 col-lg-3">
	<div 
		style="
		    padding : 5px;
		    color: white;
		    margin : 10px; 
		"
	>
		<div style="margin: 0 auto;">
			<div style="background-position: center; background-color: black; background-size: contain; background-repeat: no-repeat; width: 180px; height : 180px; border-radius: 60px; margin: 10px auto; display: block; position: relative; " [ngStyle]="{'background-image': profilePicture }">
				<div>
					<update-user-profile-picture *ngIf="isUser" [username]="username"></update-user-profile-picture>
				</div>
				
			</div>
			<div class="no-padding no-margins" style="top: -20px; position: relative; margin: 0px auto;">	
				<h2 class="col-xs-12" style="text-align: center;">{{ username }}</h2>
				<p style="
				    text-align: center;
				    left: -12px;
				    position: relative;
			    ">
					<span *ngIf="isUser || userData.facebookLink">
						<update-profile-facebook-link *ngIf="isUser" style="z-index: 1.5; position: relative; left:20px; top:-9px;" [username]="username" ></update-profile-facebook-link>
						<a href="https://www.facebook.com/{{ userData.facebookLink }}">
							<img style="margin-right: 10px;" src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Ffacebook-logo-gray.png?alt=media&token=662ae525-76f4-477a-8128-73a17568a1c8" width="30px" height="30px" />
						</a>
					</span>
					<span *ngIf="isUser || userData.twitterLink">
						<update-profile-twitter-link *ngIf="isUser" style="z-index: 1.5; position: relative; left:20px; top:-9px;" [username]="username" ></update-profile-twitter-link>
						<a href="https://www.twitter.com/{{ userData.twitterLink }}">
							<img src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Ftwitter-logo-gray.png?alt=media&token=3b45ad89-1c21-4b53-9abf-12f01d960730" width="30px" height="30px" />
						</a>
					</span>
					<span *ngIf="isUser || userData.instagramLink">
						<update-profile-instagram-link *ngIf="isUser" style="z-index: 1.5; position: relative; left:20px; top:-9px;" [username]="username" ></update-profile-instagram-link>
						<a href="https://www.instagram.com/{{ userData.instagramLink }}">
							<img src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Finstagram-logo-gray.png?alt=media&token=04d48d64-2f2b-4934-8c3e-c10f69430a6c" width="30px" height="30px" />
						</a>
					</span>
				</p>
				<p style="font-size: 12px;">Referral Code: {{ userData.referralCode }}</p>
			</div>
		</div>
	</div>
	<div
		style="
	    padding: 5px;
	    color : white ;
	    margin: 10px;
	    overflow: hidden;
		"
	>	
		<h2 style="text-align: center;">{{ username }}'s Groups</h2>
		<p style="text-align: center;" *ngIf="groups.length > 0" >
      <groupThumbnail class="inline contest-thumbnail-height" [group]="group" *ngFor = "let group of groups"></groupThumbnail>
		</p>
    <div *ngIf="!(groups.length > 0)">
    	<p>{{ username }} has no groups.</p>
    </div>
	</div>
	<div>
		<h2 style="text-align: center; color: white;">{{ username }}'s Competitions</h2>
	    <div class="col-xs-12">
	      <div class="col-xs-12" style="margin-top: 5px; padding: 0px;">
	        <div class="col-centered">
	          <profile-contest-thumbnail *ngFor = "let contest of competitions" [contest]="contest"></profile-contest-thumbnail>
	        </div>
	      </div>
	    </div>
	    <div *ngIf="!competitions && !(competitions.length > 0)">
	    	<p style="color: white;">{{ username }} doesn't have any competitions</p>
	    </div>
	</div>
</div>
<!-- <div class="col-md-6 col-sm-12 col-xs-12 no-margins no-padding">
	<div class="transparent-background profile-picture-height" style="width: 95%; margin: 0 auto; margin-top: 2.5%; border-radius: 15px; height: inherit;">
		<div class="col-xs-12 center-text">
			<h2>{{ username }}'s Roles</h2>
		</div>
		<span *ngIf="fans.length > 0">	
			<table class="table noTopOrBottomMargin noTopOrBottomPadding" style="width: 100%; ">
				<tr>
					<th>#</th>
					<th>Role</th>
					<th>Group</th>
					<th><img height="18" width="18" src="/app/assets/images/fanpoint.png" />
					<info-btn [infoInstance]="'points'" ></info-btn>
					</th>
				</tr>
				<tr *ngFor="let fan of fans">
						<td>{{ fan.position }}</td>
						<td>{{ fan.kindOfFan }}</td>
					
						<td>
							<a routerLink="/group/{{ fan.groupUrl }}">{{ fan.groupName }}</a>
						</td>
						<td>{{ fan.fanpoints }}</td>
				</tr>
			</table>
			<div class="col-xs-12 center-text">
				<p>{{ username }} has no more roles</p>
			</div>
		</span>
		<span *ngIf="!(fans.length > 0)">
			<div class="col-xs-12 center-text">
				<p class="center-text">{{ username }} has no roles</p>
			</div>
		</span>
	</div>
</div> -->
<!-- Groups Next -->

<!-- competitions -->
<!-- entries -->
<div class="col-sm-12 col-md-8 col-lg-7">
	<create-dream *ngIf="isUser"></create-dream>
	<profile-dreams [username]="username"></profile-dreams>
</div>
<div class="col-sm-12 col-md-8 col-lg-7">
	<h2 class="col-xs-11 col-xs-offset-1" style="color: white;">{{ username }}'s Competition Entries</h2>

	<div style="width: 100%;" class="show-on-phones">
		<div class="col-xs-offset-1">
			<p>
			    <button 
			      type="button" 
			      class="white-text btn btn-info btn-lg purple-gray-background" 
			      style="
			        margin: auto;
			        border-width: 0px;
			        display: block;
			      "
			    ><a href="https://apps.apple.com/us/app/spotidol-competitions/id1441184363">View Entries</a></button>
			</p>
		</div>
	</div>
	<div class="hide-on-phones" *ngIf="competitionEntries.length > 0">
		<contest-entry *ngFor="let contestEntry of competitionEntries" [contestEntry]="contestEntry"></contest-entry>
	</div>
	<div class="col-xs-offset-1 col-xs-10" *ngIf="!(competitionEntries.length > 0)">
		<p style="color: white;">{{ username }} doesn't have any competition entries</p>
	</div>
</div>
