<div style="height: 100vh" class="row no-padding no-margins text-center col-centered"> 
    <div 
        class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1"
        style="padding : 0px ;"
    >
        <div class="row sign-up-container">
            <div 
                class="col-xs-12 col-sm-12 col-md-6 sign-up-section"
            >
                <img class="col-centered" style="margin-top: 20px; " width="80" height="80" src="/app/assets/images/spotidolLogo-white.png">
                <p *ngIf="!err" style="font-weight: 700;">Create &amp; Enter Competitions</p>
                <p>{{err}}</p>
                <div class="col-centered" style="width: 80px; border-bottom-color: rgb(0 , 0 , 0) ; border-bottom-width: 1px; border-bottom-style: solid; margin-bottom: 5px; height: 2px;"><br /></div>
                <form clsas="margin-bottom login-input col-centered" *ngIf="!submittingSignUpToServer && !successSubmittingSignUp" [formGroup]="signUpForm" autocomplete="off" novalidate (ngSubmit)="signUp(signUpForm.value)">
                    <div class="margin-bottom">
                        <input class="margin-bottom col-centered black-border input-styling block sign-up-styling" formControlName="email" id="email" type="text" placeholder="email">
                    </div>
                    <div class="margin-bottom">
                        <input class="margin-bottom col-centered black-border input-styling block sign-up-styling" formControlName="username" id="username" type="text" placeholder="username">
                    </div>
                    <div class="margin-bottom">
                        <input class="margin-bottom col-centered black-border input-styling block sign-up-styling" formControlName="password" id="password" type="password" placeholder="password">
                    </div>
                    <div class="margin-bottom">
                        <input class="margin-bottom col-centered black-border input-styling block sign-up-styling" formControlName="confirmPassword" id="confirmPassword" type="password" placeholder="re-enter password">
                    </div>
                    <a (click)="toggleReferralInput()" *ngIf="!showReferralInput"><p style="text-align: center;">+Add Referral Code (Optional)</p></a>
                    <div *ngIf="showReferralInput" class="margin-bottom">
                        <input class="margin-bottom col-centered white-border input-styling block sign-up-styling"  style="background-color: #251133; border: 1px solid white;" formControlName="referralCode" id="referralCode" type="text" placeholder="Referral Code (Optional)">
                    </div>
                    <div class="margin-bottom">
                        <button type="submit" class="margin-bottom col-centered btn btn-md block no-padding no-margins purple-background medium-small-text white-text" style="padding: 6px;"><b>Sign Up</b></button>
                    </div>
                </form>
                <p *ngIf="submittingSignUpToServer">Signing up, one moment please.</p>
                <p *ngIf="successSubmittingSignUp">Congratulations! You have signed up! Just one moment while we redirect you to your home page.</p>
                <div style="border-radius: 5px; border-width: 1px; border-color: white; border-style : solid ; padding: 15px; margin-top: 20px; background-color: #E2DFFF; max-width: 300px;" class="col-centered bold">
                    <span>Already a Member? </span>
                    <a routerLink="/login"><span>Log In</span></a>
                </div>
                <div class="col-centered" style="margin-top: 8px;"><a routerLink="/termsOfUse">Terms</a> - <a routerLink="/privacyPolicy">Privacy</a> - <a>2023  &copy; Spotidol, Inc.</a></div>
            </div>
            <div
                style="
                    background: url(https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fhiphoppic.jpg?alt=media&token=5bc50196-a22d-4d35-a1db-9407bbf62d9b);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    text-align: left;
                    padding: 0px;
                "
                class="col-xs-12 col-sm-12 col-md-6 hide-on-phones"
            >
                <div 
                    class="transparent-background"
                    style="
                        display: flex;
                        flex-direction: column;
                        height: 100%;
                        width: 100%;
                        padding: 50px;
                        justify-content: space-between;
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px; 
                    "
                >
                    <h2 style="color: white;">
                        Join in Creating the Dream Ecosystem With Others.
                    </h2>
                    <div>
                        <h3 style="color: white; margin-bottom: 2px;">
                            Spotidol is free to use. 
                        </h3>
                        <a style="color : white ; margin-top: 2px; font-weight: 700 ;"routerLink="/pricing">Read more about pricing.</a>
                    </div>
                    <div>
                        <h3 style="color: white; margin-bottom: 2px;">
                            Spotidol values privacy. We do not share your data unless explicitly stated in individual competitions. 
                        </h3>
                        <a style="color : white ; margin-top: 2px; font-weight: 700 ;"routerLink="/privacyPolicy">Read more about our privacy policy.</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <footer></footer>
</div>