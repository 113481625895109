import { Component , Input , OnInit } from '@angular/core'

import moment from 'moment' 
import 'moment-timezone'

@Component({
	selector : 'countdown' , 
	templateUrl : './countdown.html'
})

export class CountdownComponent {

	@Input() startTime : string  
	jumboText : string = ''
	otherText : string 

	ngOnInit() {
		this.initializeCountdownTimer()
		setInterval(()=>{ this.initializeCountdownTimer() } , 1000 )
	}

	initializeCountdownTimer() {

		let days : any = moment().diff( this.startTime , 'days' )
		days  = ( days > 0 ) ? 0 : - days
		let daysToRemove = days * 24
		let hours : any = moment().diff( this.startTime , 'hours' ) + daysToRemove
		hours = ( hours > 0 ) ? 0 : - hours
		let hoursToRemove = 60 * ( hours + daysToRemove )		
		let minutes : any = moment().diff( this.startTime , 'minutes' ) + hoursToRemove
		minutes = ( minutes > 0 ) ? 0 : - minutes
		let minutesToRemove = 60 * ( minutes + hoursToRemove )		
		let seconds : any = moment().diff( this.startTime , 'seconds' ) + minutesToRemove
		seconds = ( seconds > 0 ) ? 0 : - seconds
		if ( days === 0 && hours === 0 && minutes === 0 && seconds === 0 )
			return
		if ( days.toString().length === 1)
			days = '0' + days
		if ( hours.toString().length === 1 ) 
			hours = '0' + hours 
		if ( minutes.toString().length === 1 )
			minutes = '0' + minutes
		if ( seconds.toString().length === 1 )
			seconds = '0' + seconds
		this.jumboText = ''
		if (days !== '00')
			this.jumboText = days + ':' 
		if ((days === '00' && hours !== '00') || ( days !== '00' ))
			this.jumboText = this.jumboText + hours + ':'
		if ((days === '00' && hours === '00' && minutes !== '00') || ( (hours !== '00' || days !== '00') ) )
			this.jumboText = this.jumboText + minutes + ':'
		this.jumboText = this.jumboText + seconds  
	}

}
