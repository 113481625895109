import { Component , Input , OnInit , AfterContentInit } from '@angular/core'
import { IContest } from '../../shared/interfaces'
import { FadeAnimation } from '../../shared/animations/index'

@Component({
	selector: 'featured-contest' , 
	templateUrl : './featuredContest.html' , 
	styleUrls : [ './featuredContest.css' ] , 
  animations: [
  	FadeAnimation 
  ]
})

export class FeaturedContestComponent implements OnInit , AfterContentInit {
	
	@Input() contest : IContest

  isDisplayed = false;

  toggleAnimation() {
    this.isDisplayed = !this.isDisplayed;
  }

	constructor() {	}

	ngOnInit() {
		console.log( this.contest.groupProfilePicture )
		if (!this.contest.groupProfilePicture)
			this.contest.groupProfilePicture = 'https://storage.googleapis.com/spotidol/spotidolBannerPicture.png'
	}

	ngAfterContentInit() {
		this.toggleAnimationTimeouts()
		setInterval(()=> { this.toggleAnimationTimeouts() } , 18000 )
		if (this.contest.description.length > 148 && window.innerWidth < 425)
			this.contest.description = this.contest.description.substr(0 , 148) + '... (Read More)'
	}

	toggleAnimationTimeouts() {
		setTimeout(()=>{this.toggleAnimation()} , 1000 )	
		setTimeout(()=>{this.toggleAnimation()} , 17900 )			
	}

}