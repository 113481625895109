
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'

import { IContest } from '../shared/interfaces'

@Injectable()
 
export class DataService {
	baseUrl: string = 'https://spotidol.com/api/'

	constructor(private http: HttpClient) {

	}

	getArray( URIEncode : string ) : any {
		let url = this.baseUrl + URIEncode
		return this.http.get(url)
	}

	getObject( URIEncode : string ) : any {
		let url = this.baseUrl + URIEncode
		return this.http.get(url)
	}

	postObject( URIEncode : string , body? : any ) : any {
		let url = this.baseUrl + URIEncode
		return this.http.post(url, body)
	}
}