import { Component , OnInit } from '@angular/core'

import { ActivatedRoute } from '@angular/router'

import { IContest , IContestEntry , IFan , IGroup } from '../../shared/interfaces'

@Component({
	templateUrl : './competitionEntry.html' ,
	styleUrls : [ './competitionEntry.css' ]
})

export class CompetitionEntryComponent {

	contest : IContest
	contestEntry : IContestEntry
	contestEntries : IContestEntry[]
	totalPages : number
	isMyGroup : boolean
	fan : IFan
	group : IGroup
	userInstance : any  

	constructor(
		private route : ActivatedRoute
	) {}

	ngOnInit() {
	    this.route.data
	      .subscribe(( data : any ) => {
	      	let contestEntriesData = data.contestEntriesData
	      	Object.keys(contestEntriesData).forEach(( key ) => {
	      		this[key] = contestEntriesData[key]
	      	})
	      })
	}

}