import { Component , AfterContentInit  , ElementRef , ViewChild } from '@angular/core'

@Component({
	templateUrl : './howTo.html'
})

export class HowToComponent implements AfterContentInit {

	width: number
	height : number

	displayHowToCreateCompetitionOnComputer : boolean = false 
	displayHowToCreateCompetitionOnMobile : boolean = false 

	@ViewChild("picture") pictureElement : ElementRef

	ngAfterContentInit() {
		var img = new Image()
		img.width = (window.innerWidth > 768) ? window.innerWidth * 5/6 : window.innerWidth
		img.height = img.width * 3300/3747
		img.src = 'https://storage.googleapis.com/spotidol/howToMakeCompetitions.jpg'
        this.pictureElement.nativeElement.append(img)
	}

	toggleDisplayHowToCreateCompetitionOnComputer() {
		this.displayHowToCreateCompetitionOnComputer = !this.displayHowToCreateCompetitionOnComputer
	}

	toggleDisplayHowToCreateCompetitionOnMobile() {
		this.displayHowToCreateCompetitionOnMobile = !this.displayHowToCreateCompetitionOnMobile
	}
	
}