<div class="row no-padding no-margins col-centered"> 
    <div 
        class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1"
        style="padding : 0px ;"
    >
        <div class="row cash-out-container">
            <div 
                class="col-xs-12 col-sm-12 col-md-6 cash-out-section"
            >
				<h3 style="text-align: center; font-weight: 600;">Cash Out</h3>
				<div *ngIf="success"><p>Congratulations! You just cashed out. Please allow up to 2 weeks for your cash to reach your account.</p></div>
				<table class="table-condensed">
					<tr>
						<th style="padding : 3px ;">Currency</th>
						<th style="padding : 3px ;">Tot. Earned</th>
						<th style="padding : 3px ;">Amt. Owed</th>
						<th style="padding : 3px ;">Cash Out</th>
					</tr>
					<tr *ngFor="let currencyValue of currencyValues">
						<td style="padding : 3px ;">{{ currencyValue.currency }}</td>
						<td style="padding : 3px ;">{{ currencyValue.totalEarned }}</td>
						<td style="padding : 3px ;">{{ currencyValue.amountOwed }}</td>
						<td style="padding : 3px ;">
							<button *ngIf="user.country == 'US' || session.country == 'US'" type="button" class="btn btn-info btn-xs" (click)="cashOut()">cash out</button>
							<a *ngIf="user.country != 'US' && session.country != 'US'" routerLink="/support" >Please Contact Support</a>
						</td>
					</tr>
				</table>
				<h3>{{ err }}</h3>
	        </div>    
	        <div
		        style="
		            background: url(/app/assets/images/person-with-money.jpg);
		            background-position: center;
		            background-repeat: no-repeat;
		            background-size: cover;
		            text-align: left;
		            padding: 0px;
	                border-top-right-radius: 5px;
	                border-bottom-right-radius: 5px; 
	                overflow: hidden;
		        "
		        class="col-xs-12 col-sm-12 col-md-6 hide-on-phones"
		    >
		        <div 
		            class="transparent-background"
		            style="
		                display: flex;
		                flex-direction: column;
		                height: 100%;
		                width: 100%;
		                padding: 50px;
		                justify-content: center;
		                border-top-right-radius: 5px;
		                border-bottom-right-radius: 5px; 
		                overflow: hidden;
		            "
		        >
		            <div>
		                <h2 style="color: white; margin-bottom: 2px;">
		                    It's time to get paid. 
		                </h2>
		                <a style="color : white ; margin-top: 2px; font-weight: 700 ;">Payments take up to 2 weeks to process.</a>
		            </div>
		        </div>
		    </div>
	    </div>

	</div>
	<div
        class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1"
        style="
        	padding : 0px ;
    		background-color: #E2DFFF;
    		border-radius: 5px;
    	"
    >	
		<payment-history></payment-history>
	</div>
</div>