<div class="col-xs-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3" style="color: white; ">
	<a routerLink="/careers" style="color: white ; margin-top : 20px; position: relative; display: block;">See All Career Options</a>
	<h1>Chief Marketing Officer (CMO)</h1>
	<p>Spotidol is a technology company building an ecosystem for businesses & people to live their dreams. We are building towards a future where everyone will be living their dreams. We focus on providing peak experiences to an international community to democratize access to the greatest experiences people can have.</p>
	<div>
		<h2>Responsibilities</h2>
		<ul>
			<li>Oversee the planning, development, & execution of our marketing activities.</li>
			<li>Report to the CEO to set & meet marketing & sales objectives.</li>
			<li>Coordinate with the marketing team to delegate responsibilities.</li>
			<li>Coordinate with the product manager & development team to determine the technical roadmap.</li>
			<li>Set the marketing budget.</li>
			<li>Identify & leverage key distribution channels.</li>
			<li>Identify & attain key strategic partners.</li>
		</ul>
	</div>
	<div>
		<h2>Requirements</h2>
		<ul>
			<li>6-10 years of experience in a related field.</li>
			<li>Thorough understanding of marketing, revenue, & brand dynamics.</li>
			<li>Deep desire to help people live their dreams.</li>
			<li>Phenomenal interpersonal & communication skills.</li>
			<li>Flexibility, creativity, & self-discipline.</li>
			<li>Experience with photo & video editing applications.</li>
			<li>Experience with with web development (html, javascript, css) is a plus.</li>
			<li>Experience in the music industry is a plus.</li>
			<li>Experience organizing events/competitions is a plus.</li>
		</ul>
	</div>
	<p>To apply, email your resume & cover letter to support@spotidol.com</p>
</div>