<div class="group-details col-xs-10 col-xs-offset-1 ">
	<a routerLink="/group/{{ group?.groupUrl }}">
		<div class="floatLeft" style="margin-right: 10px;">
			<img *ngIf= "!!group.profilePicture" src="{{ group?.profilePicture }}" height="160px" width="190px">
			<img *ngIf= "!!!group.profilePicture" src="/app/assets/images/spotidolBannerPicture.png" height="160px" width="190px">
		</div>
		<h2> {{ group?.groupName }} </h2>
		<h3>{{ group?.totalFans }} Players</h3>
		<div *ngIf="group.tags">
			<a *ngFor="let tag of group.tags" routerLink="/search/{{ tag }}"> {{ tag }}  </a>
		</div> 
	</a>
</div>