<a (click) = "openModal()"><p style="color: white;">{{ dream.joiners.length }} Joined</p></a>
<div id = "dreamJoinersModal{{dream._id}}" class = "cssModal" role = "dialog">
	<div id = "dreamJoinersModalContent{{dream._id}}" class = "cssModalContent modal-content row">
		<div>
			<button type = "button" class = "btn btn-default" (click) = "closeModal()" style = " position: absolute; top: 0px; left: 0px;">Close</button>
			<h2 style = "text-align: center;">{{ dream.joiners.length }} Joined</h2>
			<div *ngFor = "let dreamJoiner of dreamJoiners">
				<img style="height: 32px; width: 32px; border-radius: 16px;" src="{{ dreamJoiners.profilePicture }}">
				<p>{{ dreamJoiners.username }}</p>
			</div>
		</div>
	</div>
</div>