import { Component , OnInit , NgModule , EventEmitter , Input} from '@angular/core'
import { DataService } from '../../core/data.service'
import { UploadOutput, UploadInput, UploadFile, humanizeBytes } from 'ngx-uploader';
import { IContest } from '../../shared/interfaces'
// const URL = '/api/';

@Component({
  selector : 'contest-video-upload' , 
  templateUrl: "./contestVideoUpload.html"
})

export class ContestVideoUploadComponent {

  submittingFile : boolean

  URL : string = 'api/contest/update/'

  errorSubmittingToServer : boolean
  submittedToServer : boolean
  err : string

  uploadPercentage : number
  files: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;

  @Input() contest : IContest


	constructor( private dataService : DataService ) {
    this.files = []; // local uploading files array
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
    this.humanizeBytes = humanizeBytes;
  }

  onUploadOutput(output: UploadOutput): void {
    if (output.file && output.file !== undefined)
        this.uploadPercentage = output.file.progress.data.percentage
    if (output.type === 'allAddedToQueue') { // when all files added in queue
      // uncomment this if you want to auto upload files when added
      // const event: UploadInput = {
      //   type: 'uploadAll',
      //   url: '/upload',
      //   method: 'POST',
      //   data: { foo: 'bar' },
      //   concurrency: 0
      // };
      // this.uploadInput.emit(event);
    } else if (output.type === 'addedToQueue') {
      this.files.push(output.file); // add file to array when added
    } else if (output.type === 'uploading') {
      // update current data in files array for uploading file
      const index = this.files.findIndex(file => file.id === output.file.id);
      console.log(output.file.progress.data.percentage)
      this.files[index] = output.file;
    } else if (output.type === 'removed') {
      // remove file from array when removed
      this.files = this.files.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'dragOver') { // drag over event
      this.dragOver = true;
    } else if (output.type === 'dragOut') { // drag out event
      this.dragOver = false;
    } else if (output.type === 'drop') { // on drop event
      this.dragOver = false;
    }
    if (output.type === 'done') {
      this.submittingFile = false
      if ( this.files[0].response.error !== null ) {
        this.submittedToServer = false 
        this.errorSubmittingToServer = true
        return this.err =  this.files[0].response.error
      }
      this.submittedToServer = true   
      this.errorSubmittingToServer = false   
      return this.err = null
    }
  }

  startUpload(): void {  // manually start uploading
    this.submittingFile = true
    const event: UploadInput = {
      type: 'uploadAll',
      fieldName: 'upload', 
      url: this.URL + this.contest._id,
      method: 'POST'
    }

    this.uploadInput.emit(event);
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  }
}
