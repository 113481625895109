import { Component } from '@angular/core'

@Component({
	templateUrl : './portfolio.html' , 
	selector: 'portfolio', 
	styleUrls : ['./portfolio.css']
})

export class PortfolioComponent {

	portfolio : any[] = [
		{
			header : 'Spotidol Competitions',
			description : 'Create & Enter Competitions to Win Cash & Other Prizes.' ,
			picture : { 'background-image': 'url(/app/assets/images/spotidolHomeBanner.png)'} ,
			link : '/welcome'
		} , 
		{
			header : 'One On One Competitions',
			description : 'Create & Enter Private One On One Video Competitions.' ,
			picture : { 'background-image': 'url(https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Foneonone.jpg?alt=media&token=22864018-50a0-4a9f-a639-6f089058d1b3)'}
		} , 
		{
			header : 'Cooking Crown',
			description : 'Competitions for Culinary Artists to Be Their Best & Live Their Dreams' ,
			picture : { 'background-image': 'url(https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fcookingcrown.jpg?alt=media&token=f0df153c-1bb4-4f1f-bc9a-acadc41be88f)'} ,
			link : '/group/cookingcrown'
		} , 
		{
			header : 'Hiphopcompetitions.com',
			description : 'The #1 Platform for Creating & Entering Hip-Hop Competitions' ,
			picture : { 'background-image': 'url(https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fhiphoppic.jpg?alt=media&token=5bc50196-a22d-4d35-a1db-9407bbf62d9b)'} , 
			link : '/hiphop'
		} , 
		{
			header : 'Spotidol Dreams',
			description : "Coming Winter 2021: Share & Join People's Dreams" ,
			picture : { 'background-image': 'url(https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fdreamsbackground.jpg?alt=media&token=16f4f4c5-0c51-4737-8454-a8016b566410)'} 
		}
	]
}