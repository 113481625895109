import { Component } from '@angular/core'

@Component({
	templateUrl : './scienceFair.html' ,
	styleUrls : ['./scienceFair.css']
})

export class ScienceFairComponent {

	homeDetails = [
		{
			title : 'For Middle Schoolers &amp; High Schoolers' , 
			details : 'Enter a Virtual Science Fair' ,
			image : 'assets/school.jpg'
		} , {
			title : 'For Judges' , 
			details : 'Judge a Virtual Science Fair' ,
			image : 'assets/judges.jpg'
		} , {
			title : 'For Community Organizations', 
			details : 'Support a Virtual Science Fair' ,
			image : 'assets/community.jpg'
		} , {
			title : 'For Sponsors' , 
			details : 'Sponsor a Virtual Science Fair' ,
			image : 'assets/sponsor.jpg'
		
		} , {
			title : 'For Schools' , 
			details : 'Host a Virtual Science Fair' ,
			image : 'assets/school.jpg'
		
		} , {
			title : 'For Museums &amp; Libraries' , 
			details : 'Host a Virtual Science Fair' ,
			image : 'assets/museum.jpg'
		}

	]

}