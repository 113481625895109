<div>
	<h3 style="text-align: center;">Payment History</h3>
	<p class="col-xs-offset-1">Click to Open</p>
	<p style="font-weight : 700; color : black;" class="col-xs-offset-1" (click)="toggleSales()"><a>Sales</a></p>
	<div *ngIf="showSales">
		<table class="table-condensed" style="overflow: scroll; margin : 0 auto ;">
			<tr>
				<th style="padding: 3px;">purchaser</th>
				<th style="padding: 3px;">item name</th>
				<th style="padding: 3px;">item type</th>
				<th style="padding: 3px;">currency</th>
				<th style="padding: 3px;">price</th>
				<th style="padding: 3px;">time</th>
			</tr>
			<tr *ngFor="let paymentHistoryEntryBySeller of paymentHistoryBySeller">
				<td style="padding: 3px;"> {{paymentHistoryEntryBySeller.purchaser}} </td>
				<td style="padding: 3px;"> {{paymentHistoryEntryBySeller.itemName}} </td>
				<td style="padding: 3px;"> {{paymentHistoryEntryBySeller.itemType}} </td>
				<td style="padding: 3px;">
					{{ paymentHistoryEntryBySeller.currency }}
				</td>
				<td style="padding: 3px;"> {{paymentHistoryEntryBySeller.price}} </td>
				<td style="padding: 3px;"> {{paymentHistoryEntryBySeller.created}} </td>
			</tr>
		</table>
	</div>
	<p style="font-weight : 700; color : black;" class="col-xs-offset-1" (click)="togglePurchases()"><a>Purchases</a></p>
	<div *ngIf="showPurchases">
		<table class="table-condensed" style="overflow: scroll; margin : 0 auto ;">
			<tr>
				<th style="padding: 3px;">item name</th>
				<th style="padding: 3px;">item type</th>
				<th style="padding: 3px;">currency</th>
				<th style="padding: 3px;">price</th>
				<th style="padding: 3px;">time</th>
			</tr>
			<tr *ngFor="let paymentHistoryEntryByPurchaser of paymentHistoryByPurchaser">
				<td style="padding: 3px;"> {{paymentHistoryEntryByPurchaser.itemName}} </td>
				<td style="padding: 3px;"> {{paymentHistoryEntryByPurchaser.itemType}} </td>
				<td style="padding: 3px;">
					{{ paymentHistoryEntryByPurchaser.currency }}
				</td>
				<td style="padding: 3px;"> {{paymentHistoryEntryByPurchaser.price}} </td>
				<td style="padding: 3px;"> {{paymentHistoryEntryByPurchaser.created}} </td>
			</tr>
		</table>
	</div>
	<p style="font-weight : 700; color : black;" class="col-xs-offset-1" (click)="toggleCreditHistory()"><a>Credit History</a></p>
	<div *ngIf="showCreditHistory">
		<table class="table-condensed" style="overflow: scroll; margin : 0 auto ;">
			<tr>
				<th style="padding: 3px;">amount owed</th>
				<th style="padding: 3px;">amount disbursed</th>
				<th style="padding: 3px;">time</th>
			</tr>
			<tr *ngFor="let creditHistoryEntry of creditHistoryEntries">
				<td style="padding: 3px;"> {{creditHistoryEntry.amountOwed}} </td>
				<td style="padding: 3px;"> {{creditHistoryEntry.amountDisbursed}} </td>
				<td style="padding: 3px;"> {{creditHistoryEntry.created}} </td>
			</tr>
		</table>
	</div>
</div>