import { Component , OnInit , Input } from '@angular/core'

import { IDream } from '../../index'

import { DataService } from '../../../core/index'

@Component({
	selector : 'profile-dreams' , 
	templateUrl : './profileDreams.html'
})

export class ProfileDreamsComponent {

	@Input() username : string

	dreams : IDream[] = []
	
	constructor( 
		private dataService : DataService 
	) {}

	ngOnInit() {
		this.getProfileDreams()
	}

	getProfileDreams( ) {

		let URI = 'dream/getRecentDreamsByUsername/' + this.username

		this.dataService.getObject( URI )
			.subscribe( ( response : { error : string , dreams : IDream[] } ) => {
				if ( response.error ) 
					return 
				return this.dreams = response.dreams
			})
	}

}