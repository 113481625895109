<!-- make height=1/2 view width -->
<div class="col-xs-offset-1 col-xs-10 col-lg-offset-2 col-lg-8" 
	style="
	    box-shadow: 0 0 0 1px rgba(0,0,0,.15), 0 2px 3px rgba(0,0,0,.2);
	    border-radius: 3px;
	    padding-left: 0px;
	    padding-right: 0px;
	    margin-top: 10px;
	    background-color: white;
	    margin-bottom: 10px;
	"
>
	<div class="featured-winner-container">
		<a routerLink="/competition/{{ winner.competitionId }}" style="color : white;">
			<div class="col-xs-12 noTopOrBottomPadding noTopOrBottomMargin" 
				style="
					width: 100% ; 
					height: 100% ; 
				    background-repeat: no-repeat;
				    background-size: cover;
				    background-position: center top;
				    padding: 0px;
				" 
				[style.background-image]="'url(' + this.winnerPhoto + ')'">
				<div class="featured-winner-information-section-desktop">
					<div style="
				    position: absolute;
				    -ms-transform: translateY(-30%);
				    -webkit-transform: translateY(-30%);
				    transform: translateY(-30%);
				    top: 30%;
				    width: 100%;
			    ">			
						<h2
							style="
								margin: 0px;
								padding: 10px;
							" 
							class="jumbo-text"
						>{{ winner.username }}</h2>
					</div>
			</div>
			<h2 
		    class="competition-name-jumbo-text-2"
				style="
					position: absolute;
					bottom: 5px ;
					right: 5px ;
					text-align: right;
		    " 
		  >{{ winner.competitionName }}</h2>
		</div>
		</a>
	</div>
	<div [innerHTML]="winner.body" style="margin : 10px;">
	</div>
</div>