<div *ngIf="createdGroups.length > 0">
	<div (click)="openModal()">
		<p style="margin: 0px;">Create Competition</p>
	</div>
	<share-modal *ngIf="showShareModal" [mediaObject]="contest" [mediaObjectType]="'contest'" (toggleClose)="closedShareModal($event)"></share-modal>
	<div id="uploadContestModal" class="cssModal" style="display: block;" *ngIf="showModal">
  		<div id="uploadContestModalContent" class="cssModalContent purple-gray-background modal-content row" style="display: block; 
    font-size: initial;
    text-align: center;
    line-height: initial;">
    		<div *ngIf="!userNotSignedIn">
            	<button type="button" class="btn btn-default top-left" (click)="closeModal()">Close</button>
			      <!-- <update-profile-modal 
			        *ngIf="showUpdateProfile"
			        [scene]="'createCompetition'"
			        (closeUpdateProfileModal)="closeUpdateProfileModal($event)"
			      ></update-profile-modal> -->
			    <!-- <div *ngIf="!showUpdateProfile">		     -->
			    <div>
	      			<div class="modal-header">
	      				<p>Create Competition <span *ngIf="group"> for {{ group.groupName }}</span></p>
	      			</div>
	      			<div *ngIf="pageShown === 'chooseGroup'">
	      				<choose-group (selectedGroup)="selectedGroup($event)"></choose-group>
	      			</div>
	      			<div *ngIf="pageShown === 'createCompetition'" >	
						<div class="col-xs-offset-1 " *ngIf="submittingContestToServer"><p>	submitting competition, one moment please. </p> <p *ngIf="!!uploadPercentage"> {{ uploadPercentage }} % </p> </div>
	    			<div class="col-xs-offset-1 " *ngIf="errorSubmittingContestToServer"><p> {{ err }} </p></div>
	    			<div class="col-xs-offset-1 " *ngIf="successSubmittingContestToServer"> success!</div>
						<div [hidden]="submittingContestToServer">	
							<div class=" " *ngIf="!submittedContestToServer">
								<form [formGroup]="createContestForm" autocomplete="off" novalidate (ngSubmit)="validateContest(createContestForm.value)" >
									<a (click)="displayChooseGroup()">
										<p style="color : white;">
											Choose a Different Group
										</p>
									</a>
									<div class="col-xs-12" >
										<input class="roundBorders dark-purple-background input-styling col-xs-12" formControlName="contestName" id="contestName" type="text" placeholder="Competition Name">
									</div>
									<div class="col-xs-12">
										<textarea class="roundBorders dark-purple-background input-styling col-xs-12" formControlName="description" id="description" placeholder="Description"></textarea>
									</div>
									<div class="col-xs-12">
										<input class="roundBorders dark-purple-background input-styling col-xs-12" [(ngModel)]="tagString" [ngModelOptions]="{standalone : true}" id="tags" type="text" placeholder="#hash #tags">
										<div *ngIf="tagsMustStartWithHash">
											<p>Tags must start with a '#'</p>
										</div>
									</div>
										<div class="col-xs-12">
											<label>Competition Link</label>
											<select 
												class="block roundBorders dark-purple-background input-styling" 
												formControlName="userContestHidden" 
												id="private" 
												type="text" 
												placeholder="Please Select One"
												style="margin: auto ; margin-bottom: 10px;" 
											>
		            				<option>Please Select One</option>
												<option value="private">Private</option>
												<option value="public">Public</option>
											</select>
										</div>

									<a (click)="toggleEntrySettings()">
										<p class="col-xs-12" style="text-align: center; color : white ; ">
											<span *ngIf="!showEntrySettings">+Show</span>
											<span *ngIf="showEntrySettings">-Hide</span>
											<span> Entry Settings</span>
										</p>
									</a>

									<div class="col-xs-12" *ngIf="showEntrySettings">
										<p class="col-xs-12">Entry Type</p>
										<div class="col-xs-12">
											<select class="roundBorders dark-purple-background input-styling" [(ngModel)]="contestType" [attr.ngModelOptions]="{standalone: true}" (change)="changeContestType(contestType)" formControlName="contestType" id="contestType" type="text" placeholder="Please Select One">
			                    				<option>Please Select One</option>
												<option value="picture">Picture</option>
												<option value="pictureGallery">Picture Gallery</option>
												<option value="video">Video</option>
												<option value="audio">Audio</option>
												<option value="pdf">Pdf</option>
												<option value="text">Text</option>
												<option value="code">Code</option>
											</select>
										<div [hidden]="contestType != 'pictureGallery'">
											<p>Max Pictures Per Entry</p>
											<div>
												<input class="roundBorders dark-purple-background input-styling col-xs-12" *ngIf="contestType == 'pictureGallery'" formControlName="maxSubmissionsPerEntry" id="max # of pictures" type="text" mask="00" placeholder="max # of pictures">
											</div>
										</div>
										</div>
										<div class="col-xs-12">
											<label>Entry Privacy</label>
											<select 
												class="block roundBorders dark-purple-background input-styling" 
												formControlName="entryPrivacy" 
												id="entryPrivacy" 
												type="text" 
												placeholder="Please Select One"
												style="margin: auto ; margin-bottom: 10px;" 
											>
		            				<option>Please Select One</option>
												<option value="private">Private</option>
												<option value="public">Public</option>
											</select>
										</div>

										<div class="col-xs-12">
											<input class="roundBorders dark-purple-background input-styling col-xs-12" formControlName="contestPrice" id="contestPrice" type="text"  mask="9*.99" placeholder="Entry Price ({{ currencySymbol }})">	
										</div>
										<div class="col-xs-12" style="margin-bottom: 30px;">
											<span>
												Entry goes to pot (You keep 20%)
											  <input 
											 		style="
												    height: 20px;
												    top: 3px;
												    width: 20px;
												    position: relative;
											    "
											  	type="checkbox"
											  	formControlName="entryGoesToPot"
											  >
											</span>
										</div>
										<div class="col-xs-12">
											<p class="col-centered col-xs-12">End of Competition</p>
											<div class="col-xs-12">
												<div style="margin: 0 auto;">
													<ngb-datepicker 
														(dateSelect)="setEndOfCompetitionDate($event)"
														class="lighter-background"
														style="
														    color: black;
														    margin: 0 auto;
														" 
														[minDate]="today"
													#d></ngb-datepicker>
												</div>
											</div>
										</div>
										<div class="col-xs-12">
											<div style="
											    width: 160px;
											    margin: 10px auto;
											    color : black; 
										    ">
												<ngb-timepicker formControlName="endOfContestTime" [meridian]="true" [spinners]="false"></ngb-timepicker>
											</div>
										</div>
										<div class="col-xs-12">
											<p class="col-centered col-xs-12">Date Winner is Announced</p>
											<div class="col-xs-12">
												<ngb-datepicker
													(dateSelect)="setWinnerSelectedDate($event)"
													class="lighter-background"
													style="
													    color: black;
													    margin: 0 auto;
													" 
													[minDate]="today"
												#d></ngb-datepicker>
											</div>
										</div>
										<div class="col-xs-12">
											<div
												style="
												    width: 160px;
												    margin: 10px auto;
												    color : black; 
												"
											>
												<ngb-timepicker formControlName="winnerSelectedTime" [meridian]="true" [spinners]="false"></ngb-timepicker>
											</div>
										</div>
									</div>

									<a class="col-xs-12" (click)="toggleVoteSettings()">
										<p style="text-align: center; color : white ; ">
											<span *ngIf="!showVoteSettings">+Show</span>
											<span *ngIf="showVoteSettings">-Hide</span>
											<span> Vote Settings (Voting is disabled for private entries)</span>
										</p>
									</a>

									<div class="col-xs-12" *ngIf="showVoteSettings">
										<div class="col-xs-12">
											<p>Voting Type</p>
											<select [attr.ngModelOptions]="{standalone: true}" (change)="changeVotingType(votingType)" class="roundBorders dark-purple-background input-styling" [(ngModel)]="votingType" formControlName="votingType">
												<option value="votesToScore">Votes Go To Score
												</option>
												<option value="crowdChoice">Crowd Choice</option>
												<option value="tieBreaker">Tie Breaker</option>
											</select>
										</div>
										<!-- <div class="col-xs-12">
											<p>Charge Voters</p>
											<select [attr.ngModelOptions]="{standalone: true}" (change)="changeChargeVoters(chargeVoters)" class="roundBorders dark-purple-background input-styling" [(ngModel)]="chargeVoters" formControlName="chargeVoters">
												<option value="true">Yes
												</option>
												<option value="false">No</option>
											</select>
										</div>
										<div class="col-xs-12">
											<p>Price Per Vote {{ currencySymbol }}</p>
											<div>
												<input class="roundBorders dark-purple-background input-styling col-xs-12"  formControlName="pricePerVote" id="pricePerVote" mask="9*.99" type="text" placeholder="Price Per Vote">
											</div>
										</div> -->
										<div class="col-xs-12">
											<p>Max # of <span *ngIf="chargeVoters === 'true'">Free </span>Votes Per Person</p>
											<div>
												<input class="roundBorders dark-purple-background input-styling col-xs-12"  formControlName="maxVotes" id="max # of votes" type="text" mask="9*" placeholder="(optional)">
											</div>
										</div>
										<div *ngIf="votingType === 'votesToScore'" class="col-xs-12">
											<p>Score Per Vote</p>
											<div>
												<input class="roundBorders dark-purple-background input-styling col-xs-12"  [attr.ngModelOptions]="{standalone: true}" [(ngModel)]="votingCriteria.scorePerVote" type="text" mask="9*" placeholder="score Per Vote" formControlName="scorePerVote">
											</div>
										</div>
										
									</div>


									<a class="col-xs-12" (click)="toggleJudgingSettings()">
										<p style="text-align: center; color : white ; ">
											<span *ngIf="!showJudgingSettings">+Show</span>
											<span *ngIf="showJudgingSettings">-Hide</span>
											<span> Judging Settings</span>
										</p>
									</a>

									<div class="col-xs-12" *ngIf="showJudgingSettings">
										<div class="col-xs-12">
											<label>Score Privacy</label>
											<select 
												class="block roundBorders dark-purple-background input-styling" 
												formControlName="scorePrivacy" 
												id="scorePrivacy" 
												type="text" 
												placeholder="Please Select One"
												style="margin: auto ; margin-bottom: 10px;" 
											>
		            				<option>Please Select One</option>
												<option value="private">Private</option>
												<option value="public">Public</option>
											</select>
										</div>
										<input class="roundBorders dark-purple-background input-styling col-xs-12"  [(ngModel)]="newJudgingCriteria.name" [ngModelOptions]="{standalone: true}" placeholder="Criteria Title (eg. Creativity, Skill, Performance)">
										<input class="roundBorders dark-purple-background input-styling col-xs-12"  [(ngModel)]="newJudgingCriteria.score" mask="9*" [ngModelOptions]="{standalone: true}" placeholder="Max Score (eg. 1000, 525, 10)">
										<div class="col-xs-12">
											<textarea class="roundBorders dark-purple-background input-styling col-xs-12" [(ngModel)]="newJudgingCriteria.description"  [ngModelOptions]="{standalone: true}" placeholder="Criteria Description (eg. Creativity is judged based on the uniqueness of the piece & the successful execution of a vision for the piece."></textarea>
										</div>
										<a (click)="addJudgingCriteria()"><p class="col-xs-12" style="text-align: center; color : #42f548 ; ">+Add Judging Criteria</p></a>
										<h3 *ngIf="judgingCriterion.length > 0" class="col-xs-12"  style="color: white ; text-align: center;">Judges Scorecard</h3>
										<div 
											*ngFor="let judgingCriteria of judgingCriterion"
											class="col-xs-12" 
											style="text-align: center; color: white;" 
										>
											<p>{{ judgingCriteria.name }} - Out of {{ judgingCriteria.score }} Points</p>
											<p>{{ judgingCriteria.description }}</p>
										</div>
										<a *ngIf="judgingCriterion.length > 0" (click)="removeJudgingCriteria()"><p class="col-xs-12" style="text-align: center; color : red ; ">-Remove Judging Criteria</p></a>
									</div>
									<a class="col-xs-12" (click)="toggleRewardSettings()">
										<p style="text-align: center; color : white ; ">
											<span *ngIf="!showRewardSettings">+Show</span>
											<span *ngIf="showRewardSettings">-Hide</span>
											<span> Reward Settings</span>
										</p>
									</a>

									<div class="col-xs-12" *ngIf="showRewardSettings">
										<div class="col-xs-12">
											<div *ngIf="rewardCashError"><p>Cash rewards must be 50 cents or greater</p></div>
											<p class="col-xs-12">First Place Cash Reward</p>
											<input class="roundBorders dark-purple-background input-styling col-xs-12" formControlName="rewardCash" id="rewardCash" type="text" mask="9*.99" placeholder="300, 125.50, etc.">	
										</div>
										<div class="col-xs-12">
											<p class="col-xs-12">First Place Prize Description</p>
											<textarea class="roundBorders dark-purple-background col-xs-12 input-styling" formControlName="rewardOther" id="rewardOther" type="text" placeholder="Describe first place prizes here."></textarea>
										</div>
										<br />
										<div *ngIf="positions > 1">
											<div *ngFor="let reward of rewards">
												<p class="col-xs-12">{{ ordinal( reward.position ) }} Place Cash Reward</p>
												<div class="col-xs-12">
													<div *ngIf="rewardCashError"><p>Cash rewards must be 50 cents or greater</p></div><input class="roundBorders dark-purple-background input-styling col-xs-12" 
													placeholder="300, 125.50, etc." 
													type="text" mask="9*.99" [(ngModel)]="rewards[reward.position - 2].rewardCash" [ngModelOptions]="{standalone: true}" >	
												</div>
												<div class="col-xs-12">
													<p class="col-xs-12">{{ ordinal( reward.position ) }} Place Cash Reward</p>
													<textarea class="roundBorders dark-purple-background input-styling col-xs-12" 
													placeholder="Describe #{{reward.position}} Prizes Here" 
													type="string" [(ngModel)]="rewards[reward.position - 2].rewardOther" [ngModelOptions]="{standalone: true}" ></textarea>	
												</div>
											</div>
										</div>
										<a *ngIf="positions > 1" (click)="removeReward()"><p class="col-xs-12" style="text-align: center; color : red ; ">-Remove Reward</p></a>
										<a (click)="addReward()"><p class="col-xs-12" style="text-align: center; color : #42f548 ; ">+Add Reward</p></a>
										
									</div>
									<div class="col-xs-12">
										<input class="btn btn-info btn-lg" type= "submit" value="Create Competition">
									</div>
								</form>
							</div>
						<div>
	      			</div>
	      		</div>
			    </div>
      		<div *ngIf="userNotSignedIn"><p>You must be signed in to create a competition!</p></div>
  		</div>
  		<div class="create-competition-bottom-margin"></div>
    </div>
</div>