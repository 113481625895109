<div style="height: 100vh" class="row no-padding no-margins text-center col-centered">  
  <div style="position: absolute; overflow: hidden; height: 100vh;">
      <img style=" height: 100vh; width : 100vw;" src="/app/assets/images/login-background.jpg">
  </div>
  <img class="col-centered" style="margin-top: 20px; " width="80" height="80" src="/app/assets/images/spotidolLogo-white.png">
  <div style="border-radius: 5px; border-width: 1px; border-color: white; border-style : solid ; padding: 15px; margin-top: 20px; background-color: #E2DFFF; width: 300px;" class="col-centered sans-font">
		<div *ngIf="loggedOut">
			<h3>You are successfully logged out. Come back soon!</h3>
		</div>
		<div *ngIf="!loggedOut">
			<h3>You must click the menu button on the top right of the screen and select "log out" to log out.</h3>
		</div>
  </div>
</div>