import { Component, OnInit , Input , ViewChild } from '@angular/core';

import { Observable, throwError, Subject } from 'rxjs';

import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'pdf-reader',
  templateUrl: './pdfReader.html'
})

export class PdfReaderComponent  {

  src : Blob

  @Input() pdfUrl : string

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    console.log( this.pdfUrl )
  	this.http.get(
      this.pdfUrl ,
      { responseType: 'blob' }
    ).subscribe((response) => {
    	this.src = response
    })
  }

}