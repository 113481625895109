import { Component , OnInit , Input } from '@angular/core'
import { FormControl , FormGroup } from '@angular/forms'
import { Router } from '@angular/router'
import { DataService , SessionService } from '../../core/index'

import { IContest , IFan , IGroup , IContestEntry } from '../../shared/interfaces'

import { IContestEntryComment } from '../index'

@Component({
  selector: 'contest-entry-comments', 
  templateUrl: "./contestEntryComments.html"
})

export class ContestEntryCommentsComponent {

	constructor( 
		private dataService : DataService ,
		private sessionService : SessionService
	) {}

	getContestEntryCommentsError : boolean

	session : any

	@Input() contestEntry : IContestEntry
	page : number = 1
	submittedContestEntryCommentToServer : boolean
	errorSubmittingContestEntryCommentToServer : boolean
	successSubmittingContestEntryCommentToServer : boolean
	pageLoaded : boolean
	err : string
	tempComment : string
	contestEntryComments : IContestEntryComment[] = []
	showComments : boolean = false 
	displayComments : IContestEntryComment[] = []
	contestEntryCommentActive : any = {}

	isCommenter : boolean = false

	ngOnInit() {
		this.getContestEntryComments(this.page)
		this.sessionService.session.subscribe( ( session : any ) => {
			this.session = session
			this.isCommenter = (!!this.contestEntry.groupAdmins.filter( ( groupAdmin ) => {
				return (groupAdmin.username === this.session.username)
			})[0] || session.username === this.contestEntry.contestantUsername)
			if ( !this.isCommenter )
				this.isCommenter = !!this.contestEntry.admins.filter( ( admin ) => {
					return (admin.username === this.session.username)
				})[0]
			if ( !this.isCommenter )
				this.isCommenter = !!this.contestEntry.commenters.filter( ( commenter ) => {
					return (commenter.username === this.session.username)
				})[0]
			console.log(this.isCommenter)
		})
	}

	toggleDisplayComments() {
		this.displayComments = this.contestEntryComments
	}

	getContestEntryComments( page : number ) {
		let URI = 'contestEntryComment/getContestEntryComments/' + this.contestEntry._id
		this.dataService.getObject(URI)
			.subscribe( (response) => {
				console.log(response)
				if (response.error)
					return this.getContestEntryCommentsError = true
				for(var i = 0 ; i < response.contestEntryComments.length ; i++) {
					response.contestEntryComments[i].reportedUserDetails = {
						userId : response.contestEntryComments[i].userId , 
						objectId : response.contestEntryComments[i]._id , 
						objectType : 'contestEntryComment'
					}
					response.contestEntryComments[i].isGroupAdmin = !!response.contestEntryComments[i].groupAdmins.filter( ( admin ) => { return ( admin.username == this.session.username )})[0]

					this.contestEntryCommentActive[response.contestEntryComments[i]._id] = response.contestEntryComments[i].active
					if (response.contestEntryComments[i].username === this.session.username )
						response.contestEntryComments[i].isMyComment = true
				}
				this.contestEntryComments.push(...response.contestEntryComments)
				this.displayComments = this.contestEntryComments.slice( 0 , 3 )
				console.log( this.contestEntryComments )
				console.log( this.displayComments )
				return this.page++
			})
	}

	submittedComment( newComment : any ) {
		console.log( 'submitted comment' , newComment )
		newComment.isMyComment = true
		this.contestEntryComments.push(newComment)
		this.displayComments = this.contestEntryComments
	}

	updateContestEntryComment( contestEntryComment : any ) {
		
		let URI = 'contestEntryComment/updateContestEntryComment/' + contestEntryComment._id ,
			formValues = {
				commentBody : this.tempComment , 
				active : contestEntryComment.active
			} 

		this.dataService.postObject(URI, formValues)
			.subscribe( (response) => {
        if (response.error !== null) {
          this.submittedContestEntryCommentToServer = false
          this.errorSubmittingContestEntryCommentToServer = true
          return this.err = response.error
        }
        this.errorSubmittingContestEntryCommentToServer = false
        this.err = null
        this.submittedContestEntryCommentToServer = true
        return this.successSubmittingContestEntryCommentToServer = true
      })

	}

	deleteContestEntryComment( contestEntryComment : any ) {
		let URI = 'contestEntryComment/deleteContestEntryComment/' + contestEntryComment._id
		this.dataService.postObject(URI, {})
			.subscribe( (response) => {
        if (response.error !== null) {
          this.submittedContestEntryCommentToServer = false
          this.errorSubmittingContestEntryCommentToServer = true
          return this.err = response.error
        }
        let commentIndex = this.contestEntryComments.indexOf( contestEntryComment )
        this.contestEntryComments.splice( commentIndex , 1 )
        this.displayComments.splice( commentIndex , 1 )
      })

	}

}