
<h1 class="col-xs-offset-1">Blog Posts</h1>
<div *ngIf="userIsBlogger">
	<h2>Write Blog Post</h2>
	<p *ngIf="successUploadingBlog">success!</p>
	<div class="col-xs-11 col-xs-offset-1">
		<label class="col-xs-4">Creator's Name: </label><input type="text" class="roundBorders lighter-background col-xs-4" placeholder="Creator's Name" [(ngModel)]="creatorName">
	</div>

	<div class="col-xs-11 col-xs-offset-1">
		<label class="col-xs-4">Title: </label><input type="text" class="roundBorders lighter-background col-xs-4" placeholder="Title" [(ngModel)]="title">
	</div>
	<div class="roundBorders lighter-background col-xs-10 col-xs-offset-1" [(ngModel)]="body" [froalaEditor]></div>
	<button type="submit" class="col-xs-offset-1 btn btn-info btn-lg" (click)="postBlogPost()">post blog</button>
</div>
<div infiniteScroll
    [infiniteScrollDistance]="3"
    [infiniteScrollThrottle]="300"
    (scrolled)="getBlogPosts(this.page)" 
>
	<div class="lighter-background roundBorders margin-between-objects" *ngFor="let blogPost of blogPosts">
		<div class="lighter-background col-xs-12 roundBorders margin-between-objects">
			<div class="col-xs-offset-1 col-xs-7">
				<h2> {{ blogPost.title }} </h2>
			</div>
			<div class="col-xs-3">
				<p> {{ blogPost.formattedCreated }} </p>
			</div>
			<div [innerHTML]="blogPost.body" class="col-xs-offset-1 col-xs-10 contestEntrySection roundBorders small-text">
			</div>
			<div class="lighter-background col-xs-3 col-xs-offset-1">
				<h3> {{ blogPost.creatorName }} </h3>
			</div>
		</div>
	</div>
</div>