<div>
	<span>Judge<span *ngIf="contest.admins.length > 1">s</span>: </span>
	<span *ngFor="let judge of judges"><img style = "height: 24px; width: 24px; border-radius: 12px;" src = "{{ judge.profilePicture }}" /> {{ judge.username }}<a *ngIf="isGroupAdmin" (click)="removejudge(judge)"><img src="/app/assets/images/cancel-btn.png" height="16px" width="16px" /></a>  </span>
	<a *ngIf="isGroupAdmin" (click)="openModal()"><span>  Add Judge</span><img src="/app/assets/images/plus-btn.png" height="16px" width="16px" /></a>
	<div id="addJudgeModal" class="cssModal">
		<div id="addJudgeModalContent" class="cssModalContent modal-content row">
  			<div class="modal-header ">
          		<button type="button" class="btn btn-default top-left" (click)="closeModal()">Close</button>
							<h2>Add Judge</h2>
  			</div>
			<p *ngIf="err">{{ err }}</p>
			<span>Username: </span>
			<input style="color: black;" type="text" [(ngModel)]="addJudgeUsername" />
			<div class="block">
				<input class="btn btn-lg dark-purple-background" type="button" value="Add Judge" (click)="addJudge(addJudgeUsername)">
			</div>
		</div>
	</div>
</div>