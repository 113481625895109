import { Injectable } from '@angular/core';
import {
  Router, Resolve , 
  ActivatedRouteSnapshot , 
  RouterStateSnapshot
} from '@angular/router';
import { DataService , SessionService , UserDataService } from '../../core/index'
import { IContest , IContestEntry , IFan } from '../../shared/interfaces'

@Injectable()
export class CompetitionEntryResolveService {

	contestEntriesData : any = {}
	usernameSearch : string = ''

  	constructor(
		private dataService: DataService, 
		private sessionService : SessionService ,  
		private userDataService : UserDataService ,   
 		private router: Router
 	) { }

 	resolve( route : any , state : RouterStateSnapshot) {
		return new Promise<void>( ( resolve , reject ) => {
			Promise.all([
				this.getSession() , 
				this.getContestEntry(route.params.contestEntryId)
			]).then(() => {
				console.log('contestEntriesData' , this.contestEntriesData)
				if ( !this.contestEntriesData.contestEntry ) 
					return resolve( this.contestEntriesData )
				Promise.all([
			  		this.getContest( this.contestEntriesData.contestEntry.contestId ), 
					this.getGroup( this.contestEntriesData.contestEntry.contestId ) , 
					this.getOtherEntries( this.contestEntriesData.contestEntry.contestId )
				]).then(() => {
					return resolve( this.contestEntriesData )
				})
			})
		})
	}

	getContestEntry( id : string ) {
		return new Promise<void>( ( resolve ) => {
			
			let URI = 'contestEntry/getContestEntryById/' + id 

			this.dataService.getObject( URI ) 
				.subscribe( ( response : { status : boolean , error : string , contestEntry : IContestEntry } ) => {
					console.log(response)
					this.contestEntriesData.contestEntry = response.contestEntry
					return resolve()
				})
		}) 
	}

	getOtherEntries( contestId : string ) {
		return new Promise<void>( ( resolve , reject ) => {
			let URI = 'contestEntry/getContestEntriesByContestId/?page=1&contestId=' + contestId
	    	this.dataService.getObject(URI)
		        .subscribe((response : any) => {
		        	console.log( 'contestEntries:' , response )
		        	for(var i = 0; i < response.contestEntries.length ; i++ ) {

					  	response.contestEntries[i].reportedUserDetails = {}
					  	response.contestEntries[i].reportedUserDetails.userId = response.contestEntries[i].contestantId
					  	response.contestEntries[i].reportedUserDetails.objectId = response.contestEntries[i]._id
					  	response.contestEntries[i].reportedUserDetails.objectType = 'contestEntry'


							var created = response.contestEntries[i].created
							var dateAndTime = created.split('T')
							var time = dateAndTime[1].split(':')
							var date = dateAndTime[0].split('-')
							var month = date[1]
							var day = date[2]
							if (day.charAt(0) === '0')
								day = day.charAt(1)

							var hour = time[0]
							var minute = time[1]
							if (hour.charAt(0) === '0')
								hour = hour.charAt(1)
							var hourNumber = parseInt(hour)
							if (hourNumber > 12) {
								hourNumber = hourNumber - 12
								hour = hourNumber + ''
								var timeString = hour + ':' + minute + ' PM UT'
							} else {
								var timeString = hour + ':' + minute + ' AM UT'
							}
							var stringifiedMonth = ''
							switch (month) {
								case '01': {
									stringifiedMonth = 'Jan'
									break
								}
								case '02': {
									stringifiedMonth = 'Feb'
									break
								}
								case '03': {
									stringifiedMonth = 'Mar'
									break
								}
								case '04': {
									stringifiedMonth = 'Apr'
									break
								}
								case '05': {
									stringifiedMonth = 'May'
									break
								}
								case '06': {
									stringifiedMonth = 'Jun'
									break
								}
								case '07': {
									stringifiedMonth = 'Jul'
									break
								}
								case '08': {
									stringifiedMonth = 'Aug'
									break
								}
								case '09': {
									stringifiedMonth = 'Sep'
									break
								}
								case '10': {
									stringifiedMonth = 'Oct'
									break
								}
								case '11': {
									stringifiedMonth = 'Nov'
									break
								}
								case '12': {
									stringifiedMonth = 'Dec'
									break
								}
							}
					    response.contestEntries[i].formattedCreationDate = stringifiedMonth + ' ' + day + ' ' + timeString
		        	}
		        	this.contestEntriesData.totalPages = response.pages
          		this.contestEntriesData.contestEntries = response.contestEntries;
          		return resolve()
		        },
		        (err: any) => console.log(err),
		        () => console.log("contestEntries"));
			})
	
	}

 	getSession() {
 		return new Promise<void>( ( resolve , reject ) => {
	 		let URI = 'user/checkSession'
			this.dataService.getObject(URI).subscribe( (response:any) => {
				this.contestEntriesData.userInstance = response.sessionStatus
				return resolve()
			})
 		})
 	}

	getGroup( groupUrl : string ) {
		return new Promise<void>( ( resolve , reject ) => {
			let URI = 'group/url/' + groupUrl
	    	this.dataService.getObject(URI)
		        .subscribe((response) => {
		          this.contestEntriesData.group = response.group;
		          return resolve()
		        },
	        (err: any) => console.log(err),
	        () => console.log("group"));
		})
	}
	
	getContest( contestId : string ) {
		return new Promise<void>( ( resolve , reject ) => {
			let URI = 'contest/getContestById/' + contestId
	    	this.dataService.getObject(URI)
	        .subscribe((response : any) => {

						var endOfContest = response.contest.endOfContest
						var dateAndTime = endOfContest.split('T')
						var date = dateAndTime[0].split('-')
						var year = date[0]
						var month = date[1]
						var day = date[2]

						if (day.charAt(0) === '0')
							day = day.charAt(1)

						var stringifiedMonth = ''
						switch (month) {
							case '01': {
								stringifiedMonth = 'Jan'
								break
							}
							case '02': {
								stringifiedMonth = 'Feb'
								break
							}
							case '03': {
								stringifiedMonth = 'Mar'
								break
							}
							case '04': {
								stringifiedMonth = 'Apr'
								break
							}
							case '05': {
								stringifiedMonth = 'May'
								break
							}
							case '06': {
								stringifiedMonth = 'Jun'
								break
							}
							case '07': {
								stringifiedMonth = 'Jul'
								break
							}
							case '08': {
								stringifiedMonth = 'Aug'
								break
							}
							case '09': {
								stringifiedMonth = 'Sep'
								break
							}
							case '10': {
								stringifiedMonth = 'Oct'
								break
							}
							case '11': {
								stringifiedMonth = 'Nov'
								break
							}
							case '12': {
								stringifiedMonth = 'Dec'
								break
							}
						}
				    response.contest.formattedEndOfContest = stringifiedMonth + ' ' + day + ' ' + year
		        	this.contestEntriesData.contest = response.contest
		        return resolve()
	        },
		    (err: any) => console.log(err),
		    () => console.log("contests"));
	  })
	}
}