<div class="row">
	<div class="col-xs-12">
		<h2 class="col-xs-offset-1">Open Competitions</h2>
		<div class="col-md-offset-1 col-md-10">
			<div>
				<contest-thumbnail *ngFor = "let contest of openContests" [contest]="contest"></contest-thumbnail>
			</div>
		</div>
	</div>
	<div class="col-xs-12">	
		<h2 *ngIf="pageLoaded" class="col-xs-offset-1">Recent Competitions</h2>
		<div 
			infiniteScroll
      [infiniteScrollDistance]="3"
      [infiniteScrollThrottle]="300"
      (scrolled)="getNextPageOfContests()" 
      class="col-md-offset-1 col-md-10 inline" 
    >
			<div>
				<contest-thumbnail *ngFor = "let contest of contests" [contest]="contest"></contest-thumbnail>
			</div>
		</div>
	</div>
</div>