
<div style="
    display: flex;
    width: 100%;
    align-items: center;
    color: white;
    justify-content: space-between; 
    padding: 0px 20px;
">
	<a routerLink="/competition/{{ contestEntry.contestId }}" style="font-weight: 700; color: white;">
		{{ contestEntry.contestantUsername }}
	</a>
	<span style="display: flex; align-items: center ;">
		<span>
			{{ durationString }}
		</span>
		<span style="display: inline-block; vertical-align: text-top; margin: 5px;">
			<a *ngIf="!audioPaused" (click)="pauseAudio()">
				<img src="https://storage.googleapis.com/spotidol/pause.png" style="width: 25px; height: 25px; margin : 0px ;" />
			</a>
			<a *ngIf="audioPaused" (click)="playAudio()">
				<img src="https://storage.googleapis.com/spotidol/play.png" style="width: 25px; height: 25px; margin : 0px ;" />
			</a>
		</span>
	</span>
</div>