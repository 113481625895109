import { Component , OnInit , Input } from '@angular/core'
import { FormControl , FormGroup , Validators } from '@angular/forms'
import { DataService } from '../core/data.service'

@Component({
	selector: 'support', 
	templateUrl: "./support.html"
})

export class SupportComponent implements OnInit {

	contactSupportForm : FormGroup

	ngOnInit() {
		let supportMessage = new FormControl('' , Validators.required)
		let email = new FormControl('' , Validators.required)
		this.contactSupportForm = new FormGroup({
			supportMessage : supportMessage , 
			email : email
		})
	}

	err : string
	postedMessage : boolean
	showRewards : boolean = false
	showCompetitions : boolean = false
	showPayments : boolean = false
	
	constructor( private dataService : DataService) {}

	postSupportMessage( formValues : any ) {
		var URI = 'support'
    	this.dataService.postObject(URI , formValues)
	        .subscribe((response : any) => {
	        	if (response.error)
	        		return this.err = response.error
	        	return this.postedMessage = true
			})
	}

	toggleCompetitions(){
		if (this.showCompetitions === true)
			return this.showCompetitions = false
		return this.showCompetitions = true
	}

	toggleRewards(){
		if (this.showRewards === true)
			return this.showRewards = false
		return this.showRewards = true
	}

	togglePayments(){
		if (this.showPayments === true)
			return this.showPayments = false
		return this.showPayments = true
	}

}