<div 
	style="padding: 0px; margin-bottom: 10px;" 
	class="col-xs-12"
>
	<div class="col-xs-12 text-align purple-gray-background white-text">
		<div class="col-xs-12">
			<h1 class="hide-on-phones" style="font-size: 560% ; color: white ;">Winners</h1>
			<h1 class="show-on-phones" style="font-size: 280%  ; color: white ;">Winners</h1>
		</div>
	</div>
</div>
<div *ngIf="winners.length > 0">
	<winner *ngFor="let winner of winners"  [winner]="winner"></winner>
</div>
<div style="margin-top: 10px;">
	<footer></footer>
</div>