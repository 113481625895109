import { Component , OnInit , NgZone } from '@angular/core'
import { Meta } from '@angular/platform-browser'
import { Router , RouterEvent , NavigationCancel , NavigationEnd , NavigationError , RoutesRecognized , NavigationStart , ActivatedRoute } from '@angular/router'
import { DataService , SessionService } from './core/index'

@Component({
  selector: 'spotidol',
  templateUrl : './spotidol-app.html'

})

export class SpotidolAppComponent {

  public showOverlay = true;

	constructor( 
		private meta : Meta , 
		private router : Router
	) {	
    router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event)
    })
	}


  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
    	console.log('navigation start')
      this.showOverlay = true;
    }
    if (event instanceof NavigationEnd) {
    	console.log('navigation end')
      this.showOverlay = false;
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.showOverlay = false;
    }
    if (event instanceof NavigationError) {
      this.showOverlay = false;
    }
  }

}