<div style="width: 100%; ">
	<h2 style="text-align: center;">{{ headerText }}</h2>
	<h3 style="margin : 0 auto ;">Please Add One</h3>
	<div style="margin : 0 auto ;">
		<span>Instagram Handle @</span>
		<input 
			[(ngModel)]="instagramHandle" 
			(change)="setUpdateInstagramTimer()"
			style="
		    background-color: #251133;
		    border: none;
		    border-bottom: white 1px solid;
		    position: relative;
		    top: -3px;
	    " 	
		/>
		<img *ngIf="successUpdatingInstagramProfileLink" class="inline" src="/app/assets/images/checkmark.png" height="15px" width="15px"/>
	</div>
	<div style="margin : 0 auto ;">
		<span>Facebook Handle @</span>
		<input 
			[(ngModel)]="facebookHandle" 
			(change)="setUpdateFacebookTimer()"
			style="
			    background-color: #251133;
			    border: none;
			    border-bottom: white 1px solid;
			    position: relative;
			    top: -3px;
		    " 	
		/>
		<img *ngIf="successUpdatingFacebookProfileLink" class="inline" src="/app/assets/images/checkmark.png" height="15px" width="15px"/>
	</div>
	<div>
		<span>Twitter Handle @</span>
		<input 
			[(ngModel)]="twitterHandle" 
			(change)="setUpdateTwitterTimer()"
			style="
			    background-color: #251133;
			    border: none;
			    border-bottom: white 1px solid;
			    position: relative;
			    top: -3px;
		    " 	
		/>
		<img *ngIf="successUpdatingTwitterProfileLink" class="inline" src="/app/assets/images/checkmark.png" height="15px" width="15px"/>
	</div>
	<div style="margin : 0 auto ;">
		<create-dream></create-dream>
	</div>
	<a (click)="closeUpdateProfile()" style="position: absolute;bottom: 10px ; left : 10px;"><span>Skip</span></a>
	<button 
		style="position: absolute; bottom: 10px; right: 10px; border: none;" 
		(click)="closeUpdateProfile()"
    class="white-text btn btn-info btn-lg purple-gray-background" 
	>Continue</button>
</div>