
<div style="max-width: 300px; text-align: center ;" class="col-centered sans-font">
	<h1 class="margin-bottom">Welcome Back</h1>
  <p *ngIf="!err" class="bold">Log In to Enter</p>
  <p>{{err}}</p>
  <div class="col-centered" style="width: 80px; border-bottom-color: rgb(0 , 0 , 0) ; border-bottom-width: 1px; border-bottom-style: solid; margin-bottom: 5px; height: 2px;"><br /></div>
	<div class="margin-bottom col-centered" *ngIf="loginFailed">
		<p>Login failed, please try again or <a routerLink="/resetPassword">reset your password</a></p>
		<p> {{ err }} </p>
	</div>
    <div *ngIf="submittingLogInToServer" style=" margin : 80px auto;" class="loader"></div>
	<form clsas="margin-bottom login-input col-centered" *ngIf="!submittingLogInToServer && !loggedIn" [formGroup]="loginForm" autocomplete="off" novalidate (ngSubmit)="login(loginForm.value)">
		<div class="margin-bottom">
			<input class="margin-bottom col-centered white-border input-styling block" style="border: 1px solid white;" formControlName="username" id="username" type="text" placeholder="username, email or phone">
		</div>
		<div class="margin-bottom">
			<input class="margin-bottom col-centered white-border input-styling block" style="border: 1px solid white;" formControlName="password" id="password" type="password" placeholder="password">
		</div>
		<div class="margin-bottom">
            <button type="submit" class="margin-bottom col-centered btn btn-md block no-padding no-margins medium-small-text white-text" style="padding: 4px; border: 1px solid white; background-color: #251133;"><b>Log In</b></button>
		</div>
	</form>
</div>