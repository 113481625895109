<div>
	<div class="free-section">
		<div class="image-shadow">
			<h1 class="free-text">
				All features on Spotidol are free to use.
			</h1>
		</div>
	</div>
	<div class="data-share-section" style="text-align: center;">
		<p>
			We do not share or sell your data with governments or third party organizations, unless explicitly stated in individual competitions.
		</p>
		<p>Competition hosts get access to entrant's email addresses.</p>
	</div>
	<div class="profit-section">
		<p style="text-align: center;">
			Spotidol profits by collecting 20% of payouts & 5% surcharge on payments.
		</p>
	</div>
	<div class="pricing-comparison-container">	
		<div class="pricing-comparison-section">
			<div class="col-sm-12 col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2 hide-on-phones">		
				<h3 style="    
					text-align: center;
				    font-size: 60px;
			    ">See How Spotidol Compares to Others</h3>
				<div class="col-xs-12 select-competitor-section">
					<div class="col-md-4 col-sm-12">
						<p style="
						    position: absolute;
						    top: 50%;
						    transform: translateY(-50%);
						    font-size: 32px;
					    ">Select a Competitor</p>
					    <p> <br>
					    </p>
					</div>
					<div class="col-md-8 col-sm-12">
						<span *ngFor="let platform of competitorFeatures" class="col-xs-6">
							<a class="col-xs-6" *ngIf="platform.name !== selectedCompetitor.name" (click)="selectCompetitor( platform.name )">
								<img src="{{ platform.logo }}" style="width: 180px; height: auto; ">
							</a>
							<a class="col-xs-6" *ngIf="platform.name === selectedCompetitor.name" (click)="selectCompetitor( platform.name )">
								<img src="{{ platform.logo }}" style="width: 180px; height: auto; border: white 2px solid; border-radius: 3px; padding: 5px;">
							</a>
						</span>
					</div>
				</div>
				<div>
					<div class="col-xs-12" style="
					    display: flex;
					    align-items: center;
					">
						<div class="col-xs-4"> </div>
						<div class="col-xs-4">
							<img src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fspotidolcom%20logo.png?alt=media&token=9505b6fe-193f-41c2-8a9e-6aed4eff9ff6" style="width: 140px; height: auto; ">
						</div>
						<div class="col-xs-4">
							<img src="{{ selectedCompetitor.logo }}" style="width: 180px; height: auto; ">
						</div>
					</div>
					<div class="col-xs-12" style="
					    display: flex;
					    align-items: center;
					">
						<div class="col-xs-4"> </div>
						<div class="col-xs-4">
							<p class="pricing-text">Free</p>
						</div>
						<div class="col-xs-4">
							
							<p class="pricing-text">${{ selectedCompetitor.price}} per {{ selectedCompetitor.rate }}</p>
						</div>
					</div>
					<div class="col-xs-12">
						<div style="margin: 5px; overflow: auto ;">
							<div class="col-xs-4 comparison-text"><p>Charge in 125 Currencies</p></div>
							<div class="col-xs-4"><img src="/app/assets/images/checkmark.png" style="width: 32px ; height: 32px ; "></div>
							<div class="col-xs-4"><img src="/app/assets/images/cancel-btn.png" style="width: 32px ; height: 32px ; "></div>
						</div>
					</div>
					<div class="col-xs-12">
						<div style="margin: 5px; overflow: auto ;">
							<div class="col-xs-4 comparison-text"><p>Web & Mobile Apps</p></div>
							<div class="col-xs-4"><img src="/app/assets/images/checkmark.png" style="width: 32px ; height: 32px ; "></div>
							<div class="col-xs-4"><img src="/app/assets/images/cancel-btn.png" style="width: 32px ; height: 32px ; "></div>
						</div>
					</div>
					<p class="col-xs-12 feature-text">Entry</p>
					<div class="col-xs-12">
						<div style="margin: 5px; overflow: auto ;" *ngFor="let entryFeature of entryFeatures">
							<div class="col-xs-4 comparison-text"><p>{{ entryFeature }}</p></div>
							<div class="col-xs-4"><img src="/app/assets/images/checkmark.png" style="width: 32px ; height: 32px ; "></div>
							<div class="col-xs-4"><img *ngIf="selectedCompetitor.features.indexOf( entryFeature ) === -1" src="/app/assets/images/cancel-btn.png" style="width: 32px ; height: 32px ; "><img *ngIf="selectedCompetitor.features.indexOf( entryFeature ) > -1" src="/app/assets/images/checkmark.png" style="width: 32px ; height: 32px ; "></div>
						</div>
					</div>
					<p class="col-xs-12 feature-text">Voting</p>
					<div class="col-xs-12">
						<div style="margin: 5px; overflow: auto ;" *ngFor="let votingFeature of votingFeatures">
							<div class="col-xs-4 comparison-text"><p>{{ votingFeature }}</p></div>
							<div class="col-xs-4"><img src="/app/assets/images/checkmark.png" style="width: 32px ; height: 32px ; "></div>
							<div class="col-xs-4"><img *ngIf="selectedCompetitor.features.indexOf( votingFeature ) === -1" src="/app/assets/images/cancel-btn.png" style="width: 32px ; height: 32px ; "><img *ngIf="selectedCompetitor.features.indexOf( votingFeature ) > -1" src="/app/assets/images/checkmark.png" style="width: 32px ; height: 32px ; "></div>
						</div>
					</div>
					<p class="col-xs-12 feature-text">Judging</p>
					<div class="col-xs-12">
						<div style="margin: 5px; overflow: auto ;" *ngFor="let judgingFeature of judgingFeatures">
							<div class="col-xs-4 comparison-text"><p>{{ judgingFeature }}</p></div>
							<div class="col-xs-4"><img src="/app/assets/images/checkmark.png" style="width: 32px ; height: 32px ; "></div>
							<div class="col-xs-4"><img *ngIf="selectedCompetitor.features.indexOf( judgingFeature ) === -1" src="/app/assets/images/cancel-btn.png" style="width: 32px ; height: 32px ; "><img *ngIf="selectedCompetitor.features.indexOf( judgingFeature ) > -1" src="/app/assets/images/checkmark.png" style="width: 32px ; height: 32px ; "></div>
						</div>
					</div>
					<p class="col-xs-12 feature-text">Profiles</p>
					<div class="col-xs-12">
						<div style="margin: 5px; overflow: auto ;" *ngFor="let profileFeature of profileFeatures">
							<div class="col-xs-4 comparison-text"><p>{{ profileFeature }}</p></div>
							<div class="col-xs-4"><img src="/app/assets/images/checkmark.png" style="width: 32px ; height: 32px ; "></div>
							<div class="col-xs-4"><img *ngIf="selectedCompetitor.features.indexOf( profileFeature ) === -1" src="/app/assets/images/cancel-btn.png" style="width: 32px ; height: 32px ; "><img *ngIf="selectedCompetitor.features.indexOf( profileFeature ) > -1" src="/app/assets/images/checkmark.png" style="width: 32px ; height: 32px ; "></div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-sm-12 col-md-10 col-md-offset-1 col-lg-8 col-lg-offset-2 show-on-phones">		
				<h3 style="    
					text-align: center;
				    font-size: 60px;
			    ">Explore Spotidol's Features</h3>
				<div>
					<div class="col-xs-12" style="
					    display: flex;
					    align-items: center;
					">
						<div class="col-xs-6"> </div>
						<div class="col-xs-6">
							<img src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fspotidolcom%20logo.png?alt=media&token=9505b6fe-193f-41c2-8a9e-6aed4eff9ff6" style="width: 140px; height: auto; ">
						</div>
					</div>
					<div class="col-xs-12">
						<div style="margin: 5px; overflow: auto ;">
							<div class="col-xs-6 comparison-text"><p>Charge in 125 Currencies</p></div>
							<div class="col-xs-6"><img src="/app/assets/images/checkmark.png" style="width: 32px ; height: 32px ; "></div>
						</div>
					</div>
					<div class="col-xs-12">
						<div style="margin: 5px; overflow: auto ;">
							<div class="col-xs-6 comparison-text"><p>Web & Mobile Apps</p></div>
							<div class="col-xs-6"><img src="/app/assets/images/checkmark.png" style="width: 32px ; height: 32px ; "></div>
						</div>
					</div>
					<p class="col-xs-12 feature-text">Entry</p>
					<div class="col-xs-12">
						<div style="margin: 5px; overflow: auto ;" *ngFor="let entryFeature of entryFeatures">
							<div class="col-xs-6 comparison-text"><p>{{ entryFeature }}</p></div>
							<div class="col-xs-6"><img src="/app/assets/images/checkmark.png" style="width: 32px ; height: 32px ; "></div>
						</div>
					</div>
					<p class="col-xs-12 feature-text">Voting</p>
					<div class="col-xs-12">
						<div style="margin: 5px; overflow: auto ;" *ngFor="let votingFeature of votingFeatures">
							<div class="col-xs-6 comparison-text"><p>{{ votingFeature }}</p></div>
							<div class="col-xs-6"><img src="/app/assets/images/checkmark.png" style="width: 32px ; height: 32px ; "></div>
						</div>
					</div>
					<p class="col-xs-12 feature-text">Judging</p>
					<div class="col-xs-12">
						<div style="margin: 5px; overflow: auto ;" *ngFor="let judgingFeature of judgingFeatures">
							<div class="col-xs-6 comparison-text"><p>{{ judgingFeature }}</p></div>
							<div class="col-xs-6"><img src="/app/assets/images/checkmark.png" style="width: 32px ; height: 32px ; "></div>
						</div>
					</div>
					<p class="col-xs-12 feature-text">Profiles</p>
					<div class="col-xs-12">
						<div style="margin: 5px; overflow: auto ;" *ngFor="let profileFeature of profileFeatures">
							<div class="col-xs-6 comparison-text"><p>{{ profileFeature }}</p></div>
							<div class="col-xs-6"><img src="/app/assets/images/checkmark.png" style="width: 32px ; height: 32px ; "></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<footer></footer>
</div>