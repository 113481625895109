<div class="relative no-overflow">
    <div>    
	  	<navbar class="display-on-top"></navbar>
	  	<div>
	  		<router-outlet></router-outlet>
	    </div>
    </div>
    <div
      *ngIf="showOverlay"
      style="
        background-color: rgba( 156 , 156 , 156 , 0.5 );
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        width: 100vw; 
        height: 100vh;
        position: fixed;
        z-index : 100;
        top : 0px;
        left: 0px;
      "
    >
        <div style=" margin : 0 auto; margin-top : 30vh ;" class="loader"></div>
    </div>
</div>