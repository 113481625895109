<button type="button" style="width: 100%; height: 36px; font-size: 18px; background-color: #121014; color: white; border: none;" data-toggle="modal" attr.data-target="#updateContest{{contest._id}}" >Upload Description Video</button>
<div id="updateContest{{contest._id}}" class="modal fade" role="dialog">
	<div class="modal-dialog">
		<div class="modal-content">
  		<button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
			<div class="modal-header ">
				upload description video
			</div>
			<div *ngIf="!submittedToServer" [hidden]="submittingFile">	
	    <div class="drop-container" ngFileDrop (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput" [ngClass]="{ 'is-drop-over': dragOver }">
	      <h1>Drag and Drop</h1>
	    </div>

	    <label class="upload-button">
	      or choose file
	      <input type="file" class="btn btn-info btn-lg" ngFileSelect (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput">
	    </label>

	    <button type="button" class="start-upload-btn btn btn-info btn-lg" (click)="startUpload()">
	      Start Upload
	    </button>
	  </div>
	  <div class=" medium-small-text" *ngIf="submittingFile">One moment while we submit your video. <p *ngIf="!!uploadPercentage"> {{ uploadPercentage }} % </p>  </div>
	  <div class=" medium-small-text" *ngIf="!!err && !submittingFile"><p> {{ err }} </p></div>
	  <div class=" medium-small-text" *ngIf="submittedToServer"> success! Please revisit this page to view your new video. </div>
	  </div>
  </div>
</div>