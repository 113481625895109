<div style="color: white;">
  <div class="row"
    style="    
      background: url(app/assets/images/scienceFair/tvsf-header.jpg);
      height: 67vh;
      background-origin: padding-box;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      width: 100%;
      padding: 0px;
      margin: 0px;
    "
  >
    <div class="col-xs-12"
      style="
        background: rgba(40,55,119 , 0.5);
        color: white;
        display: flex;
        width : 100%;
        justify-content: space-around;
        flex-direction: column;
      " 
    >
      <h1 class="col-xl-offset-1 col-xl-1 title-text header-font">The Virtual Science Fair</h1>
      <h2 class="col-xl-offset-1 col-xl-1 title-text-2">Providing a platform for anyone to create &amp; enter STEM competitions</h2>
    </div>
  </div>
  <div class="row">
      <h2 style="text-align: center; width: 100vw; margin: 40px">Because of COVID, Science Fairs across America were cancelled.</h2>
  </div>
  <div class="row">
    <div class="col-lg-10 col-lg-offset-1 col-md-10 col-md-offset-1 col-xl-offset-1 col-xxl-10 col-xl-10 col-xs-12" >
      <div class="row" style="align-items: center; display: flex;">   
        <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-sm-12">
          <p
            style="
              font-size: 28px;
              text-align: center;
            "
          >We want to motivate young people to be involved in science.</p>
        </div>
        <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-sm-12">
          <div
          class="home-detail"
          style="    
            background: url( app/assets/images/scienceFair/school.jpg );
            background-origin: padding-box;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          "
        >
          <div
            style="
              background-image: linear-gradient(rgba(0 , 0 , 0 , 1) , rgba( 0 , 0 , 0 , 0 ) ) ; 
              padding: 10px;
            "
          >
            <p style="font-size: 22px;">For Students of All Ages</p>
          </div>
          <div
            style="
              background-image: linear-gradient(rgba(0 , 0 , 0 , 0) , rgba( 0 , 0 , 0 , 1 ) ) ; 
              padding: 10px;
            "
          >
            <p style="font-size: 22px;">Enter a Virtual Science Fair</p>
          </div>
        </div>
      </div>

    </div>
  </div>
  <!--   <div *ngFor="let homeDetail of homeDetails" class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12 home-detail-container">
      <div
        class="home-detail"
        style="    
          background: url( {{ homeDetail.image }});
          background-origin: padding-box;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        "
      >
        <div>
          <p>{{ homeDetail.title }}</p>
        </div>
        <div>
          <p>{{ homeDetail.details }}</p>
        </div>
      </div>
    </div> -->
  </div>
  <div class="row">
      <h2 style="text-align: center; width: 100vw; margin: 40px;">On Spotidol, it's free &amp; easy to create &amp; enter virtual science fairs.</h2>
  </div>
  <div class="row">    
    <div class="col-lg-10 col-lg-offset-1 col-md-10 col-md-offset-1 col-xl-offset-1 col-xxl-10 col-xl-10 col-xs-12" >
      <div class="row" style="align-items: center; display: flex;">  
        <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-sm-12">
          <div
            class="home-detail"
            style="    
              background: url( app/assets/images/scienceFair/museum.jpg );
              background-origin: padding-box;
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            "
          >
            <div
              style="
                background-image: linear-gradient(rgba(0 , 0 , 0 , 1) , rgba( 0 , 0 , 0 , 0 ) ) ; 
                padding: 10px;
              "
            >
              <p style="font-size: 22px;">For Schools, Libraries, &amp; Museums</p>
            </div>
            <div
              style="
                background-image: linear-gradient(rgba(0 , 0 , 0 , 0) , rgba( 0 , 0 , 0 , 1 ) ) ; 
                padding: 10px;
              "
            >
              <p style="font-size: 22px;">Host a Virtual Science Fair</p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-sm-12">
          <p style="text-align: center; font-size: 22px;">Want to Host a Virtual Science Fair?</p>
          <div style="margin: 0 auto;">
            <button
              class="btn-lg enter-competition-button " 
              routerLink="/signUp"
              style="
                border-color: #6D507F;
                border-style: solid;
                border-width: 1px;
                margin: auto auto;
                display: block;
              "
            >
              Create Competition
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" style="padding: 40px;">    
    <div class="col-lg-10 col-lg-offset-1 col-md-10 col-md-offset-1 col-xl-offset-1 col-xxl-10 col-xl-10 col-xs-12" >
      <div class="row" style="align-items: center; display: flex;">  
        <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-sm-12">
          <p style="font-size: 22px; text-align: center;">Have Questions? Contact us any time or visit our <a routerLink="/about">about page</a>, <a routerLink="/pricing">pricing page</a>, or <a routerLink="/faq">FAQ page</a>.</p>
        </div>
        <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-sm-12" style="text-align: center ; font-size: 18px">

          <div
            class="home-detail"
            style="    
              background-origin: padding-box;
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
              display: flex;
              flex-direction: column;
              justify-content: center;
              background-color: #38244C;
              align-items: center; display: flex;
            "
          >            
            <p>Call at +1 (510) 712-6380</p>
            <p>Email support@spotidol.com</p>
            <p>Visit spotidol.com/support</p>
          </div>
        </div>
      </div>
    </div>
    <footer></footer>
</div>