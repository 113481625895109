import { Component , Input , OnInit } from '@angular/core'

import { IContest } from '../../shared/interfaces'

var moment = require('moment') 
import 'moment-timezone'

@Component({
	selector : 'contest-header' ,
	templateUrl : './contestHeader.html'
})

export class ContestHeaderComponent {

	@Input() contest : IContest

	@Input() countdownText : string 
	@Input() countdownStart : string | Date
	competitionImage : string 

	ngOnInit() {
		this.competitionImage = this.contest.groupProfilePicture ? 
			'url(' + this.contest.groupProfilePicture + ')'
			:
			'url(https://storage.googleapis.com/spotidol/spotidolBannerPicture.png)'

	}

}