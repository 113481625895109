import { Component } from '@angular/core'

import { Router } from '@angular/router'

import { SearchService } from '../search.service'

@Component({
	selector : 'welcome-search' , 
	templateUrl : './welcomeSearch.html' ,
	styleUrls : [ './welcomeSearch.css' ]
})

export class WelcomeSearchComponent {

	constructor(
		private searchService : SearchService ,
		private router : Router
	) {}

	searchInput : string

	searchCompetitions( ) {
		this.searchService.changeSearchInput(this.searchInput + '&searchBy=competitions')
		this.router.navigateByUrl( '/search/' + this.searchInput )
	}

}