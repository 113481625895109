import { Component , OnInit , Input} from '@angular/core'

import { SessionService , DataService } from '../../core/index'

import { IDream } from '../index'

@Component({
	selector : 'join-dream' ,
 	templateUrl : './joinDream.html'
})

export class JoinDreamComponent {

	constructor(
		private sessionService : SessionService , 
		private dataService : DataService
	) {}


	username : string
	dreamJoiners : [{
		username : string 
		profilePicture : string
	}]
	@Input () dream : IDream
	loggedIn : boolean 
	myDreamJoiner : {
		username : string 
		profilePicture : string
	} | null
	joinedDream : boolean

	ngOnInit() {
		this.sessionService.session.subscribe( ( session : any ) => {
			this.loggedIn = session.loggedIn
			this.username = session.username
		})
		this.dreamJoiners = this.dream.joiners
		this.myDreamJoiner = this.dreamJoiners.filter(( dreamJoiner : {
			username : string
			profilePicture : string
		}) => { return ( dreamJoiner.username === this.username ) })[0]
	}

	joinDream() {
		if ( this.joinedDream )
			return
		if ( !this.loggedIn )
			return this.openModal()
		let URI = 'dream/joinDream'
		this.dataService.postObject( URI , {
			dreamId : this.dream._id
		})
			.subscribe( ( response : {
				status : boolean
				error : string
				dream : IDream
			}) => {
				if ( response.error )
					return console.log( response.error )
				this.joinedDream = true 
				return this.dreamJoiners = response.dream.joiners
			})
	}

	openModal() {
		document.getElementById('joinDreamModal' + this.dream._id).style.display = 'block'
		document.getElementById('joinDreamModalContent' + this.dream._id).style.display = 'block'
	}

	closeModal() {
		document.getElementById('joinDreamModal' + this.dream._id).style.display = 'none'
		document.getElementById('joinDreamModalContent' + this.dream._id).style.display = 'none'
	}

}
