import { Component , Input , OnInit } from '@angular/core'

import { IContest } from '../../../../shared/interfaces'
import { SessionService } from '../../../../core/index'
import { InternationalService } from '../../../../shared/international.service'

import moment from 'moment'
import 'moment-timezone'

const  ordinal = require('ordinal')

@Component({
	selector : 'review-contest' , 
	templateUrl : './reviewContest.html'
})

export class ReviewContestComponent {

	@Input() contest : IContest

	showDetails : boolean
	showTimeline : boolean
	showOtherRewards : boolean

	noRewards : boolean
	
	currencySymbol : string = '$'

	formattedEndOfContest : string
	formattedWinnerSelectedDate : string

	ordinal : any


	constructor(
		private sessionService : SessionService , 
		private internationalService : InternationalService
	) {}

	ngOnInit() {
		this.ordinal = ordinal

		this.sessionService.session.subscribe( (session : any ) => {
			this.currencySymbol = this.internationalService.convertCurrencySymbol(session.country)
		})

	    this.formattedEndOfContest =  moment(this.contest.endOfContest).tz(moment.tz.guess()).format("MMM Do YY, h:mm a")
	    this.formattedWinnerSelectedDate =  moment(this.contest.winnerSelectedDate).tz(moment.tz.guess()).format("MMM Do YY, h:mm a")
	}

	toggleDetails() {
		this.showDetails = !this.showDetails
		this.showTimeline = false
		this.showOtherRewards = false
	}

	toggleTimeline() {
		this.showTimeline = !this.showTimeline
		this.showDetails = false
		this.showOtherRewards = false
	}

	toggleOtherRewards() {
		this.showOtherRewards = !this.showOtherRewards
		this.showDetails = false
		this.showTimeline = false
		this.showAllRewardsModal()
	}
  
  showAllRewardsModal() {    
    document.getElementById('contestRewardsModal').style.display = "block"
    document.getElementById('contestRewardsModalContent').style.display = "block"
  }

  hideAllRewardsModal() {    
    document.getElementById('contestRewardsModal').style.display = "none"
    document.getElementById('contestRewardsModalContent').style.display = "none"
	this.showOtherRewards = false
  }
  

}