import { Component , Input } from '@angular/core'
import { DataService } from '../../../core/index'

@Component({
	selector : 'update-profile-twitter-link' , 
	templateUrl : './updateProfileTwitterLink.html'
})
export class UpdateProfileTwitterLinkComponent {

	@Input() username : string
	twitterLink : string 
	err : string
	successUpdatingTwitterProfileLink : boolean

	constructor(
		private dataService : DataService
	) {}

	updateTwitterLink() {
		this.err = null
		let URI = "user/updateTwitterProfileLink"
		let params = {
			username : this.username , 
			twitterProfileLink : this.twitterLink 
		}
		this.dataService.postObject( URI , params )
			.subscribe( ( response : any ) => {
				if (response.error)
					return this.err = response.error
				return this.successUpdatingTwitterProfileLink = true
			})
	}	

	openModal() {
		document.getElementById('uploadProfileTwitterLinkModal').style.display = "block"
		document.getElementById('uploadProfileTwitterLinkModalContent').style.display = "block"
	}

	closeModal() {
		document.getElementById('uploadProfileTwitterLinkModal').style.display = "none"
		document.getElementById('uploadProfileTwitterLinkModalContent').style.display = "none"
		this.successUpdatingTwitterProfileLink = false 
	}

}