
  <a class="col-xs-12" style="background-color: #121014; color: white; text-align: center; height: 45px; font-size: 16px;"><div data-toggle="modal" data-target="#uploadGroupPicture"><p style="background-color: #121014;">Upload Picture</p></div></a>
	<div id="uploadGroupPicture" class="modal fade" role="dialog">
		<div class="modal-dialog">
  		<div class="modal-content">
      <button 
        type="button" 
        class="btn btn-default" 
        data-dismiss="modal"
        style="
          position: absolute;
          top: 0px;
          left: 0px; 
        " 
      >Close</button>
			<div class="modal-header">
        <h2>upload Group Picture</h2>
			</div>
      <upload [uploadType]="'picture'" [competitionEntryOrGroupPicture]="'groupPicture'" [objectId]="group._id" [maxFiles]="maxUploads" ></upload>
      <div class=" medium-small-text" *ngIf="submittingFile">One moment while we submit your picture. </div>
      <div class=" medium-small-text" *ngIf="!!err && !submittingFile"><p> {{ err }} </p></div>
      <div class=" medium-small-text" *ngIf="submittedPictureToServer"> Success! Please revisit this page to view your new picture. </div>
      </div>
      </div>
  </div>
