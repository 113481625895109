<div style="height: 100vh" class="row no-padding no-margins text-center col-centered">  
 <!--    <div style="position: absolute; overflow: hidden; height: 100vh;">
        <img style=" height: 100vh; width : 100vw;" src="/app/assets/images/login-background.jpg">
    </div> -->
    <img class="col-centered" style="margin-top: 20px; " width="80" height="80" src="/app/assets/images/spotidolLogo-white.png">
    <div style="border-radius: 5px; border-width: 1px; border-color: white; border-style : solid ; padding: 15px; margin-top: 20px; background-color: #E2DFFF; width: 300px;" class="col-centered sans-font">
			<h1 class="margin-bottom">Log In</h1>
      <p *ngIf="!err" class="bold">Welcome Back</p>
      <p>{{err}}</p>
      <div class="col-centered" style="width: 80px; border-bottom-color: rgb(0 , 0 , 0) ; border-bottom-width: 1px; border-bottom-style: solid; margin-bottom: 5px; height: 2px;"><br /></div>
			<div class="margin-bottom col-centered" *ngIf="loginFailed">
				<p> {{ err }} </p>
			</div>
			<form clsas="margin-bottom login-input col-centered" *ngIf="!loggedIn" [formGroup]="loginForm" autocomplete="off" novalidate (ngSubmit)="login(loginForm.value)">
				<div class="margin-bottom">
					<input class="margin-bottom col-centered black-border input-styling block" formControlName="username" id="username" type="text" placeholder="username, email or phone">
				</div>
				<div class="margin-bottom">
					<input class="margin-bottom col-centered black-border input-styling block" formControlName="password" id="password" type="password" placeholder="password">
				</div>
				<div class="margin-bottom">
					<button type="submit" class="margin-bottom col-centered btn btn-md block no-padding no-margins purple-background medium-small-text white-text" style="padding : 6px;"><b>Log In</b></button>
				</div>
			</form>
    </div>
	<p><a routerLink="/resetPassword" style="color : white ; margin-top : 16px;">Forgot your password? Reset It.</a></p>
    <div style="border-radius: 5px; border-width: 1px; border-color: white; border-style : solid ; padding: 15px; margin-top: 20px; background-color: #E2DFFF; width: 300px;" class="col-centered bold">
        <span>Not a Member? </span>
        <a routerLink="/signUp"><span>Sign Up</span></a>
    </div>
    <div class="col-centered white-text" style="margin-top: 8px;"><a class="white-text" routerLink="/termsOfUse">Terms</a> - <a class="white-text" routerLink="/privacyPolicy">Privacy</a> - <a class="white-text">2023  &copy; Spotidol, Inc.</a></div>
</div>