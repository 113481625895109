import { Component , OnInit , NgZone , Input } from '@angular/core'
import { Router , NavigationEnd , RoutesRecognized , NavigationStart , ActivatedRoute } from '@angular/router'
import { DataService , SessionService } from '../core/index'
import { SearchService } from '../search/search.service'
import { InternationalService } from '../shared/international.service'
import { trigger, state, style, animate, transition } from '@angular/animations';

import * as $ from 'jquery'

@Component({
	selector: 'navbar', 
	templateUrl: "./navbar.html" , 
	styleUrls : ["./navbar.css"] , 
	animations : [
		trigger('showSearchTrigger', [
			state('show' , style({
				'transform': 'translateY(60px)'
			})), 
			state('hide' , style({
				'transform': 'translateY(-250%)'
			})), 
			transition('show <=> hide' , animate('250ms ease-out'))
		]) 
	]
})


export class NavbarComponent implements OnInit {

	//display vars
	showSearchState : string = 'hide'
	searchBy : string = 'competitions'
	competitionsButtonBackground = '#5E297F'
	usersButtonBackground = '#6D507F'
	groupsButtonBackground = '#6D507F'
	searchTimer : any



	showSearchInput() {
		$('#navbar-search').focus()
		console.log(this.showSearchState)
		this.showSearchState = (this.showSearchState === 'hide' ? 'show' : 'hide')
	}
 
	//others
	navbarSearch : string = ''
	loggedIn : boolean
	username : string = null
	uncheckedAlerts : number = 0
	timerSet : boolean
	isPhone : boolean = false
	lastStep : boolean
	firstTime : boolean
	alertImage : string
	referralCodeActive : boolean

	showMenu : boolean = false

	countryCodes : any[]
	country : string  = 'US'

	constructor(private router : Router , 
				private route : ActivatedRoute ,
				private dataService : DataService ,
				private searchService : SearchService ,
				private sessionService : SessionService , 
				private internationalService : InternationalService , 
				private zone : NgZone
	) {
		router.events.subscribe((event) => {
  			console.log(this.router.url.split('/')[this.router.url.split('/').length -1 ])
      		if (event instanceof NavigationEnd) {
      			console.log(this.router.url.split('/')[this.router.url.split('/').length - 1 ])
				if (this.router.url.split('/')[this.router.url.split('/').length - 1] === 'firstTime') {
					this.openHelp()
				}
			}
		})
	}

	ngOnInit() {
		this.sessionService.session.subscribe( (session:any) => {
			this.referralCodeActive = session.referralCodeActive
			this.loggedIn = session.loggedIn
			this.username = session.username || ''
			this.uncheckedAlerts = session.uncheckedAlerts || 0
			this.alertImage = 'alert' + this.uncheckedAlerts + '.png'
			if ( this.uncheckedAlerts > 5 )
				this.alertImage = 'alert5+.png'
			if ( this.uncheckedAlerts === 0)
				this.alertImage = 'alert.png'
			this.country = session.country
		})
		this.getCountryCodes()
	}

	openHelp() {
		console.log('open help' , document.getElementById('helpModal'))
	    document.getElementById('helpModal').style.display = "block"
	    document.getElementById('helpModalContent').style.display = "block"
		this.toggleMenu()
	}

	closeHelp() {
	    document.getElementById('helpModal').style.display = "none"
	    document.getElementById('helpModalContent').style.display = "none"
	}

	endOnboarding() {
		this.lastStep = false
		this.firstTime = false
	}


	toggleMenu() {
		console.log('toggling menu')
		this.showMenu = this.showMenu ? false : true
	}

	logOut() {
		let URI = 'user/logOut'
		this.dataService.postObject(URI, '')
			.subscribe((response : any) => {
				let session = {
				 	loggedIn : false , 
					username : '' ,
					isAdmin : false , 
					blogger : false , 
					redirectTo: null , 
					country : 'US', 
					exchangeRate  : 1 ,
					uncheckedAlerts : 0
				}
				this.sessionService.renewSession(session)
				this.username = null
				this.loggedIn = false		
				window.location.replace('/logout')
			})
	}

	getCountryCodes() {
		this.countryCodes = this.internationalService.getCountryCodes()
		this.countryCodes.sort()
	}

	changeCountry( countryCode : string ) {
		console.log(countryCode)
		let URI = 'user/setCountry/' + countryCode
		this.dataService.postObject(URI , '')
			.subscribe((response : any) => {
				if (response.error)
					return console.log(response.error)
				window.location.replace(this.router.url)
			})
	}

	setSearchTimer() {
		clearTimeout(this.searchTimer)
		this.searchTimer = setTimeout(()=> { this.searchSpotidol() } , 1500 )
	}

	searchSpotidol() {
		if (this.navbarSearch === null)
			return
		this.searchService.changeSearchInput(this.navbarSearch + '&searchBy=' + this.searchBy )
		this.router.navigateByUrl( '/search/' + this.navbarSearch )
	}

	changeSearchBy( searchBy : string ) {
		switch (searchBy) {
			case "competitions":
				this.competitionsButtonBackground = '#5E297F'
				this.usersButtonBackground = '#6D507F'
				this.groupsButtonBackground = '#6D507F'
				break;
			case "groups":
				this.competitionsButtonBackground = '#6D507F'
				this.usersButtonBackground = '#6D507F'
				this.groupsButtonBackground = '#5E297F'
				break;
			case "users":
				this.competitionsButtonBackground = '#6D507F' 
				this.usersButtonBackground = '#5E297F'
				this.groupsButtonBackground = '#6D507F'
				break;
			
			default:
				// code...
				break;
		}
		this.searchBy = searchBy
		this.searchService.changeSearchInput( this.navbarSearch + '&searchBy=' + searchBy )
	}

	navigateHome() {
		if ( this.firstTime === true )
			return window.location.replace('/home') 
		if ( this.loggedIn === true ) 
			return this.router.navigateByUrl('/home')
		return this.router.navigateByUrl('/welcome')
	}

	clearUncheckedAlerts() {
		let URI = 'user/clearUncheckedAlerts'
		this.dataService.getObject(URI)
			.subscribe((response : any) => {
				this.alertImage = 'alert.png'
				this.uncheckedAlerts = 0
			})
	}
}