import { Component, Input, OnInit } from '@angular/core'
import { DataService, SessionService, UserDataService } from '../../core/index'
import { IContest, IUser } from '../../shared/interfaces'


@Component({
	selector : 'judge' ,
	templateUrl : './judge.html'
})

export class JudgeComponent implements OnInit {

	@Input() contest : IContest 
	@Input() user : IUser
	err : string
	judges : [{
		username : string,
		profilePicture? : string
	}]
	username : string
	profilePicture : string
	isGroupAdmin : boolean = false
	addJudgeUsername : string

	constructor(
		private dataService : DataService ,
		private userDataService : UserDataService ,
		private sessionService : SessionService
	) {}

	ngOnInit() {
		this.sessionService.session
			.subscribe( (session : any) => {
				if (session.username && this.contest.groupAdmins.filter( (adminObj) => { return adminObj.username === session.username })[0] )
					this.isGroupAdmin = true
				for (var i = 0 ; i < this.contest.admins.length; i++) {
					this.contest.admins[i].profilePicture = this.contest.admins[i].profilePicture ? this.contest.admins[i].profilePicture : '/app/assets/images/idol-icon-white.png'
				}
				this.judges = this.contest.admins
			})
	}

	addJudge(username : string) {
		let URI =  'contest/addJudge'
		let body = {
			contestId : this.contest._id ,
			username : username
		}
		this.dataService.postObject(URI, body)
			.subscribe( (response : any) => {
				if (response.error) {
					return this.err = response.error
				}
				let tempUserData
				this.userDataService.userData
					.subscribe( (userData : any) => {
						tempUserData = userData
						tempUserData.contests = tempUserData.contests.filter( (contest : IContest) => {
							return (contest._id === this.contest._id)
						})
						tempUserData.contests.push(response.contest)
						this.userDataService.renewUserData(tempUserData)
					})
				this.err = null
				this.closeModal()
				let adminDetails = {
					username : username ,
					profilePicture : '/app/assets/images/idol-icon-white.png'
				}
				this.judges.push(adminDetails)
				return this.contest.admins.push(adminDetails)
			})
	}


	//removeJudge() {}


	closeModal() {
		document.getElementById('addJudgeModal').style.display = "none"
		document.getElementById('addJudgeModalContent').style.display = "none"
	}

	openModal() {
		document.getElementById('addJudgeModal').style.display = "block"
		document.getElementById('addJudgeModalContent').style.display = "block"
	}
}