import { Component , OnInit } from '@angular/core'
import { ActivatedRoute , Router } from '@angular/router'

import { DataService } from '../../core/data.service'

import { IUser } from '../../shared/interfaces'

@Component({
  selector: 'emailVerification', 
  template: "<h2>Welcome to Spotidol!</h2><p>Please wait one moment before you play as we verify your url...</p>"
})

export class EmailVerificationComponent {

	user : IUser

	constructor(
		private dataService : DataService, 
		private route : ActivatedRoute , 
		private router : Router
	) {}

  ngOnInit() {
    this.verifyEmail(this.route.snapshot.params['emailVerificationCode'])
  }

	verifyEmail( emailVerificationCode : string ) {
		let URI = 'user/emailVerification/' + emailVerificationCode
		this.dataService.getObject(URI)
	        .subscribe((response) => {
				return window.location.replace('/updateUser/firstTime')
	        },
	    (err: any) => console.log(err),
	    () => console.log("user"));
	}
}