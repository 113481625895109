<a *ngIf="!joinedDream" (click)="joinDream()"><p style="color: white;">Join Dream</p></a>
<div id="joinDreamModal{(dream._id)}" class="cssModal" role="dialog">
	<div id="joinDreamModalContent{(dream._id)}" class="cssModalContent-modal-content-row">
		<div>
			<button type="button" class="btn btn-default" (click)="closeModal()" style="position: absolute; top: 0px; left: 0px;">Close</button>
			<upload-contest-entry-sign-up></upload-contest-entry-sign-up>
		</div>
	</div>
</div>
<!-- For people who have joined the dream -->
<p *ngIf="joinedDream">
	<img style="height: 32px; width: 32px; border-radius: 16px;" src="{{ myDreamJoiner.profilePicture }}">
	Joined Dream
</p>