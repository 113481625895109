<div>
	<span style="margin: 4px;">Admin: </span>
	<span *ngFor="let admin of group.admins" style="margin: 4px; white-space: pre-line;">
		<img src="{{ admin.profilePicture }}" style="height:24px;width:24px;border-radius: 12px; margin : 3px;">
		{{ admin.username }}
		<a *ngIf="isGroupCreator" (click)="removeAdmin(admin)"><img *ngIf="admin !== username && admin !== group.username" src="/app/assets/images/cancel-btn.png" height="16px" width="16px" /></a>  </span>
	<a *ngIf="isGroupCreator" (click)="openModal()"><span>Add Admin</span><img src="/app/assets/images/plus-btn.png" height="16px" width="16px" /></a>
	<div id="addAdminModal" class="cssModal">
		<div id="addAdminModalContent" class="cssModalContent modal-content row">
  			<div class="modal-header ">
          		<button type="button" class="btn btn-default top-left" (click)="closeModal()">Close</button>
							<h2>Add Admin</h2>
  			</div>
			<p *ngIf="err">{{ err }}</p>
			<span>Username: </span>
			<input style="color: black;" type="text" [(ngModel)]="addAdminUsername" />
			<div class="block">
				<input class="btn btn-lg dark-purple-background" type="button" value="Add Admin" (click)="addAdmin(addAdminUsername)">
			</div>
		</div>
	</div>
</div>