import { Component , OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import { DataService } from '../../core/data.service'

import { IContest } from '../../shared/interfaces'

@Component({
	selector: 'myContests', 
	templateUrl: "./myContests.html"
})

export class MyContestsComponent implements OnInit {

	contests : IContest[]
	page : number = 1 

	constructor(
		private route : ActivatedRoute ,  
		private dataService : DataService
	) {}

	ngOnInit () {
		this.getMyContests( this.page )
	}

	getMyContests( page : number ) {
		let URI = 'contest/getContestsByCreatorId/?page=' + page
    	this.dataService.getObject(URI)
	        .subscribe((response) => {
	          for (var i = 0 ; i < response.contests.length ; i++) {
				var endOfContest = response.contests[i].endOfContest
				var dateAndTime = endOfContest.split('T')
				var date = dateAndTime[0].split('-')
				var year = date[0]
				var month = date[1]
				var day = date[2]
				if (day.charAt(0) === '0')
					day = day.charAt(1)

				var stringifiedMonth = ''
				switch (month) {
					case '01': {
						stringifiedMonth = 'Jan'
						break
					}
					case '02': {
						stringifiedMonth = 'Feb'
						break
					}
					case '03': {
						stringifiedMonth = 'Mar'
						break
					}
					case '04': {
						stringifiedMonth = 'Apr'
						break
					}
					case '05': {
						stringifiedMonth = 'May'
						break
					}
					case '06': {
						stringifiedMonth = 'Jun'
						break
					}
					case '07': {
						stringifiedMonth = 'Jul'
						break
					}
					case '08': {
						stringifiedMonth = 'Aug'
						break
					}
					case '09': {
						stringifiedMonth = 'Sep'
						break
					}
					case '10': {
						stringifiedMonth = 'Oct'
						break
					}
					case '11': {
						stringifiedMonth = 'Nov'
						break
					}
					case '12': {
						stringifiedMonth = 'Dec'
						break
					}
				}
			    response.contests[i].formattedEndOfContest = stringifiedMonth + ' ' + day + ' ' + year
	          }
	        	if (this.page === 1)
	          		this.contests = response.contests;
	          	else
	          		this.contests.push(...response.contests)
	        },
        (err: any) => console.log(err),
        () => console.log("contests"));
	}

	getNextContestsPage() {
		this.page ++
		this.getMyContests( this.page )
	}
}