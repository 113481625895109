import { Injectable } from '@angular/core';
import { currencySymbols } from './international/currencySymbols'
import { countries } from './international/countries'
import { stripeCurrencies } from "./international/stripeCurrencies"

@Injectable()

export class InternationalService {

	convertCurrencySymbol( myCountry : string ) {
		let myCurrencyCode = (<any>countries).filter(( country : any) => { 
			return (country.cca2 === myCountry)
		})[0].currency[0]

		let myCurrency = (<any>currencySymbols).filter(( currencySymbol : any ) => {
			return ( currencySymbol.cc === myCurrencyCode )
		})[0]

		return myCurrency.symbol
	}

	convertCurrencySymbolFromCurrencyCode( currencyCode : string ) {

		let myCurrency = (<any>currencySymbols).filter(( currencySymbol : any ) => {
			return ( currencySymbol.cc === currencyCode )
		})[0]

		if (!myCurrency) {
			myCurrency = {
				symbol: '$'
			}
		}

		return myCurrency.symbol
	}

	getCountryCodes() {
		let countriesWithCurrencySymbols = [] , 
			countryCodes = []

		console.log(stripeCurrencies)

		for ( var i = 0 ; i < (<any>currencySymbols).length ; i++ ) {
			countriesWithCurrencySymbols.push(...(<any>countries).filter(( country : any ) => {
				return ( country.currency[0] === currencySymbols[i].cc )
			}))
		}

		countriesWithCurrencySymbols = countriesWithCurrencySymbols.filter( ( country : any ) => {
			return ( (<any>stripeCurrencies).indexOf(country.currency[0]) > -1 )
		})

		for ( var i = 0 ; i < countriesWithCurrencySymbols.length ; i++ ) {
				if (countriesWithCurrencySymbols[i]) 
					countryCodes.push(countriesWithCurrencySymbols[i].cca2)
		}
		return countryCodes
	}

	getCurrencyCode( myCountry : string ) {
		return (<any>countries).filter(( country : any) => { 
			return (country.cca2 === myCountry)
		})[0].currency[0]
	}

	convertCurrencyAmount( amount : number , exchangeRate : number ) {
      let numericAmount = Math.ceil(amount * exchangeRate * 100 ) / 100
      let textAmount = parseFloat(numericAmount.toFixed(2))
      let amounts = { 
      	numericAmount : numericAmount , 
      	textAmount : textAmount
      }
      return amounts 
	}

}