import { Injectable } from '@angular/core'
import { IAudioPlayer } from './index'


 

import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class AudioPlayerSubject {

	audioObject : IAudioPlayer = {
		username : '' ,
		competitionName : '' ,
		audioPaused : false , 
		audioMuted : false ,
		competitionId : '' ,
		objectId : '' , 
		skipTo : 0 , 
		url : ''
	}

	private audioSubject = new BehaviorSubject<any>(this.audioObject)
	public audioPlayer : Observable<any> = this.audioSubject.asObservable()

	updateAudioPlayer( newAudioObject : IAudioPlayer ) {
		this.audioSubject.next(newAudioObject)
	}

}