import { NgModule } from '@angular/core'

import {
} from './index'

@NgModule({
	imports: [],
    declarations: [ 
    ], 
	providers: []
})

export class ContestEntryModule{}