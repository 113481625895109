import { Component , OnInit , Output , Input , EventEmitter } from '@angular/core'

import { IContestEntryComment } from '../../index'

import { UserDataService } from '../../../core/index'

@Component({
	selector : 'new-comment-section' ,
	templateUrl : './newCommentSection.html'
})

export class NewCommentSectionComponent {

	@Input() contestEntryId : string

	commentText : string = 'Type a Comment...'
	commentSectionHeight : '90px' | '180px'
	profilePicture : string 

	commentWidth : string 

	@Output() madeNewComment : EventEmitter<IContestEntryComment>  = new EventEmitter()

	constructor(
		private userDataService : UserDataService
	) { }

	ngOnInit() {
		this.userDataService.userData.subscribe( ( userData ) => {
			this.profilePicture = userData.user.profilePicture || 'https://storage.googleapis.com/spotidol/spotidolEclipse.png'
		})
	}

	toggleCommentSection( ) {
		this.commentSectionHeight = ( this.commentSectionHeight === '90px' ) ?
			'90px' 
			:
			'180px'
	}

	submittedComment( newComment : IContestEntryComment ) {
		console.log( 'made new comment' , newComment )
		this.madeNewComment.emit( newComment )
		this.commentText = ''
	}

}