<div *ngIf="
  (
    contestEntry 
    &&
    (
      (
        contest.contestType === 'pictureGallery' 
        &&
        contestEntry.entryAttachments.length < maxUploads
      )
      ||
      (!contestEntry.entryAttachment && contestStarted)
    )
  )
  ||
  !contestEntry"
>
  <p *ngIf="err">{{ err }}</p>
  <div (click)="uploadContestEntryHandler()">
    <button 
      type="button" 
      class="white-text btn btn-info btn-lg purple-gray-background" 
      style="
        margin: auto;
        border-width: 0px;
        display: block;
      "
    >{{ registrationText }}</button>
  </div>
</div>
<share-modal *ngIf="showShareModal" [mediaObject]="contestEntry" [mediaObjectType]="'contestEntry'" (toggleClose)="closedShareModal($event)"></share-modal>
<div id="uploadContestEntriesMobileModal" class="cssModal" role="dialog">
  <div id="uploadContestEntriesMobileModalContent" class="cssModalContent modal-content row" style="max-width: 700px;">
    <div>
      <button type="button" class="btn btn-default" (click)="closeModal()" style="position: absolute; top: 0px; left: 0px;">Close</button>
      <upload-contest-entries-mobile-modal></upload-contest-entries-mobile-modal>
    </div>
  </div>
</div>
<div id="uploadContestEntryModal{{contest._id}}" class="cssModal" role="dialog">
    <div id="uploadContestEntryModalContent{{contest._id}}" class="cssModalContent modal-content row" style="max-width: 700px;">
      <div>
          <button type="button" class="btn btn-default" (click)="closeModal()" style="position: absolute; top: 0px; left: 0px;">Close</button>
          <div class="black-text  medium-text" *ngIf="!!err && !submitting"><p> {{ err }} </p></div>
          <div class="black-text  medium-text" *ngIf="successSubmittingContestEntryToServer"> success! please click "close" to close.</div>
          <div *ngIf="submitting">Submitting entry. One Moment please. {{ percentUploaded }} % 
            <div *ngIf="finishedUploading"><p>Almost done...</p></div>
          </div>
          <div class="medium-text" *ngIf="userNotSignedIn">
            <div *ngIf="showSignup">
              <upload-contest-entry-sign-up (signedUserIn)="submittedSignUp($event)"></upload-contest-entry-sign-up>
          
              <div style="border-radius: 5px; border-width: 1px; border-color: white; border-style : solid ; padding: 15px; margin-top: 20px; max-width: 300px; color : white; text-align: center;" class="col-centered bold">
                  <span>Already a Member?</span>
                  <a (click)="toggleNotSignedInPage()" style="color: white;"><p>Log In</p></a>
              </div>
            </div>

            <div *ngIf="showSignin">
              <upload-contest-entry-login (signedUserIn)="submittedSignUp($event)"></upload-contest-entry-login>

              <div style="border-radius: 5px; border-width: 1px; border-color: white; border-style : solid ; padding: 15px; margin-top: 20px; max-width: 300px; color : white; text-align: center;" class="col-centered bold">
                  <span>Not a Member?</span>
                  <a (click)="toggleNotSignedInPage()"><p>Sign Up</p></a>
              </div>
            </div>
          </div>
          <update-profile-modal 
            *ngIf="showUpdateProfile"
            [scene]="'uploadCompetitionEntry'"
            (closeUpdateProfileModal)="closeUpdateProfileModal($event)"
          ></update-profile-modal>
          <div 
            id="enterContestFormDetails" 
            *ngIf="
              !showUpdateProfile && 
              contestStarted && !successSubmittingContestEntryToServer && 
              ( !group || myFan ) && 
              !userNotSignedIn && 
              (
                contestEntry 
                &&
                (
                  (
                    contest.contestType === 'pictureGallery' 
                    &&
                    contestEntry.entryAttachments.length < maxUploads
                  )
                  ||
                  (!contestEntry.entryAttachment && contestStarted)
                )
              )
              ||
              !contestEntry
            " 
            [hidden]="submitting"
          >
            <div *ngIf="( !group || myFan ) && !userNotSignedIn && !alreadyEnteredContest" id="fileUploadSection">
              <upload [competitionEntryOrGroupPicture]="'competitionEntry'" [objectId]="contest._id" [maxFiles]="maxUploads" (uploadFinished)="onUploadFinished($event)" ></upload>
            </div>
            <p class="black-text  medium-text" *ngIf="group && fanLoaded && !myFan">You must be a {{ contest.kindOfFan }} to enter into competitions by this group!</p>
            <p class="black-text  medium-text" *ngIf="contestEntryLoaded && alreadyEnteredContest">You have already entered this competition!</p>
          </div>
          <div *ngIf="showCommentOnEntry">
            <h3>Add a Comment on Your Entry</h3>
            <div style="color: black; ">
              <new-comment-section (madeNewComment)="madeNewComment($event)" [contestEntryId]="contestEntry._id"></new-comment-section>
            </div>
          </div>
          <div *ngIf="!userNotSignedIn && !contestStarted && ( !group || myFan )">
            <h2>This competition hasn't started yet. Register to secure your spot!</h2>
            <div>
              <button 
                type="button" 
                class="white-text btn btn-info btn-lg purple-gray-background" 
                style="
                  margin: auto;
                  border-width: 0px;
                  display: block;
                "
              >{{ registrationText }}</button>
            </div>
          </div>
        </div>
    </div>
</div>
<div *ngIf="contestEntry && contestEntry.paid && !contestEntry.entryAttachment"><p class="col-xs-offset-1 col-xs-10 col-lg-offset-3 col-lg-6" style="text-align: center;">You Are Registered to Enter <span *ngIf="!contestStarted">But the Competition Hasn't Started Yet.</span></p></div>