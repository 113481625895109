import { Component , OnInit , OnDestroy , AfterViewInit , NgZone , Input , Sanitizer } from '@angular/core'
import { trigger, state , style , transition, animate , keyframes } from '@angular/animations'

import { ActivatedRoute , Router , Resolve } from '@angular/router'

import { DataService , SessionService } from '../core/index'
import { FormControl , FormGroup , Validators } from '@angular/forms'

import { DateService } from '../shared/date.service'
import { IContest , IFan , IGroup , IUser , IContestEntry } from '../shared/interfaces'
import { DomSanitizer } from '@angular/platform-browser';

@Component({
	selector: 'welcome', 
	templateUrl: "./welcome.html" , 
	styleUrls : ["./welcome.css"] 
})

export class WelcomeComponent implements OnInit {

	welcomeData : any
	recentCompetitions : IContest[]
	recentCompetitionsPhone : IContest[]
	topCashPrizeContests : IContest[]
	recentGroups : IGroup[] = []
	recentGroupsPhone : IGroup[] = []
	sessionSubscription : any
	group : any = {}
	width : number
	topCashRewardCompetitions : IContest[]
	trendingContestEntries : IContestEntry[]
	topCashRewardCompetitionsPhone : IContest[]
	featuredContests : IContest[]

	constructor(
		private dataService : DataService , 
		private dateService : DateService , 
		private sessionService : SessionService , 
		private route : ActivatedRoute , 
		private sanitizer : DomSanitizer , 
		private router : Router 
	) {}


	ngOnInit() {
		this.width = window.innerWidth
		this.sessionSubscription = this.sessionService.session
			.subscribe( ( session : any )=> {
				if (session.loggedIn === true)
					this.router.navigateByUrl('/home')
				else {
					this.getMostRecentContests(1) 
					this.getFeaturedCompetitions() 
					this.getTopCashPrizeCompetitions()
				}
			})
	}

	getTopCashPrizeCompetitions() {
		let URI = 'contest/getTopCashPrizeCompetitions'
		this.dataService.getObject(URI)
      .subscribe((response) => {
    		this.topCashPrizeContests = response.contests
      },
    (err: any) => console.log(err),
    () => console.log("featuredContests"));
	}


	getFeaturedCompetitions() { 
		let URI = 'contest/getFeaturedContests'
		this.dataService.getObject(URI)
	        .subscribe((response) => {
          		this.featuredContests = response.contests
	        },
        (err: any) => console.log(err),
        () => console.log("featuredContests"));
	}	

	getMostRecentContests( page : number ) {
		let URI = 'contest/getWelcomeContests'
    	this.dataService.getObject(URI)
	        .subscribe((response : any) => {
          		this.recentCompetitions = response.contests;
	        },
        (err: any) => console.log(err),
        () => console.log("contests"));
	}

	linkToDownload() {
		var isIOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
		var isAndroid = !!navigator.platform && /android/.test(navigator.platform)
		if (isIOS) {
			window.location.href = "https://itunes.apple.com/us/app/spotidol-competitions/id1441184363?mt=8"
		}
		if (isAndroid) {
			window.location.href = "http://play.google.com/store/apps/details?id=com.spotidol.spotidol"
		} else 
			window.location.href = "https://itunes.apple.com/us/app/spotidol-competitions/id1441184363?mt=8"
	}

	ngOnDestroy() {
		this.sessionSubscription.unsubscribe()
	}
}