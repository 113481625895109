<div class="col-xs-12">
	<!-- <div class="row"> -->
		<div class="col-xs-12 col-sm-4 group-details-container" style="padding: 0px; margin: 0px;">		
			<div class="group-details-section">			
				<div class="col-xs-12 fill-screen large-content-height" *ngIf="group.profilePicture == null"  style="background-image: url(https://storage.googleapis.com/spotidol/spotidolBannerPicture.png)">
					<div style="position: absolute; bottom: 20px; right: 25px;">
						<media-menu [reportedUserDetails]="reportedUserDetails" [mediaObject]="group" [mediaObjectType]="'group'" [whiteOrBlack]="'white'"></media-menu>
					</div>
					<div class="block white-text">
						<a class="noTopOrBottomMargin group-name"> 
							<div>
								<h2 class="inline group-name bold-font white-text">
									{{ group.groupName }}
								</h2>
								<p class="inline no-margins word-break white-text ">@{{ group?.groupUrl }}</p>
							</div>
						</a>
					</div>
					<div class="bottom col-xs-7" style="
						bottom: 10px
					line-height: 30px;
				"  *ngIf="group.tags">
						<div 
							*ngFor="let tag of group.tags" 
							style="
						    margin: 5px;
						    padding: 5px;
						    background-color: rgba(152, 140, 229, 0.6);
						    color: white;
						    word-break: keep-all;
						    border-radius: 10px;
						    text-align: center;
	    					display: inline;
						  "
				    >
							<a routerLink="/search/{{ tag }}"><span class="white-text">{{ tag }}</span></a>
						</div>
					</div> 
				</div>

				<div class="col-xs-12 fill-screen large-content-height noTopOrBottomPadding noTopOrBottomMargin" *ngIf="!!group.profilePicture"  [ngStyle]="{'background-image': 'url(' + group.profilePicture
				+ ')'}">
					<div class="block">				
						<a routerLink="/group/{{ group?.groupUrl }}" class="noTopOrBottomMargin group-name noTopOrBottomPadding"> 
							<div>
								<h2 class="inline group-name bold-font white-text">
									{{ group.groupName }}
								</h2>
								<p class="word-break inline no-margins white-text">@{{ group?.groupUrl }}</p>
							</div>
						</a>
					</div>
					<div class="bottom col-xs-12" style="bottom: 10px; 
				line-height: 30px;" *ngIf="group.tags">
						<div 
							*ngFor="let tag of group.tags"
							style="
						    margin: 5px;
						    padding: 5px;
						    background-color: rgba(152, 140, 229, 0.6);
						    color: white;
						    border-radius: 10px;
						    word-break: keep-all;
						    text-align: center;
	    					display: inline;
						  "
						>
							<a routerLink="/search/{{ tag }}"><span class="white-text">{{ tag }}</span></a> 
						</div>
					</div> 
				</div>
				<div class="col-xs-12 purple-background white-text">
					<admin [group]="group"></admin>
				</div>
			</div>
		</div>
		<div class="col-md-8 col-xs-12" style="margin: 0px; padding: 0px;">
			<div
				infiniteScroll	
			    [infiniteScrollDistance]="0.4"
			    [infiniteScrollThrottle]="25"
				(scrolled)="setGetFans()" 
			>
				<h1 style="color: white ;">Scores</h1>
				<div *ngIf="myFan" class="fan-score-container">
					<span>
						<img 
							*ngIf="myFan && myFan.profilePicture"src="{{ myFan.profilePicture }}" 
							style="height : 36px ; width : 36px ; border-radius : 18px;"
						/>
						<img 
							*ngIf="myFan && !myFan.profilePicture"src="https://storage.googleapis.com/spotidol/spotidolEclipse.png" 
							style="height : 36px ; width : 36px ; border-radius : 18px;"
						/>
						{{ myFan?.username }}
					</span>
					<span>{{ myFan?.score }}</span>
				</div>
				<div *ngFor="let fan of fans" class="fan-score-container">
					<div>
						<a routerLink="/profile/{{ fan.username }}"><span style="color: white;">
							{{ fan.position }}
							<img 
								*ngIf="fan.profilePicture"src="{{ fan.profilePicture }}" 
								style="height : 36px ; width : 36px ; border-radius : 18px;"
							/>
							<img 
								*ngIf="!fan.profilePicture"src="https://storage.googleapis.com/spotidol/spotidolEclipse.png" 
								style="height : 36px ; width : 36px ; border-radius : 18px;"
							/>
							{{ fan.username }}
						</span></a>
						<span *ngIf="isMyGroup" style="margin-left : 8px; color: gray;">{{ fan.email }}</span>
					</div>
					<span>{{ fan.score }}</span>
				</div>
				<div>
			</div>
		</div>
	<!-- </div> -->
</div>