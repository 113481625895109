import { Component , Input , OnInit } from '@angular/core'

import { IGroup , IFan } from '../../shared/interfaces'

import { DataService , SessionService , UserDataService } from '../../core/index'

@Component({
  selector: 'group-details', 
  templateUrl: "./groupDetails.html" ,
  styleUrls: ["./groupDetails.css"]
})

export class GroupDetailsComponent implements OnInit {

	@Input() group : IGroup
	myFan : IFan
	isMyGroup : boolean
	loadedFan : boolean
	price : number = null

	constructor(
		private dataService : DataService , 
		private sessionService : SessionService ,
		private userDataService : UserDataService
	) {}

	ngOnInit() {
		this.checkIfGroupOrFan()
		this.getPrice()
	}

	getPrice() {
		if (this.group.defaultKindOfFan == 'fan')
			this.price = this.group.priceToBecomeFan
		else
			this.price = this.group.kindsOfFans.filter(( f : any) => { return ( f.fanTitle === this.group.defaultKindOfFan )})[0].priceToBecomeKindOfFan
		console.log(this.price)
	}

	checkIfGroupOrFan() {
		this.getMyFan( this.group.groupUrl )
		this.sessionService.session.subscribe(( session : any ) => {
			this.checkIfGroup(session)
		})
	}
	
	getMyFan( url : string ) {
	    this.userDataService.userData
	    .subscribe( ( userData : any ) => {
	    if (userData.myFans !== null)
		    this.myFan = userData.myFans.filter( ( fan : IFan ) => {return (fan.groupUrl === url && fan.kindOfFan === this.group.defaultKindOfFan)})[0]
	    this.loadedFan = true
	    })
	}

	checkIfGroup(userInstance : any ) {
	    if (userInstance.username === this.group.username) {
	    	this.isMyGroup = true
	    }
	}
}