<div class="row">
	<h2 class="col-xs-offset-1">Recent Groups</h2>
	<div>
		<div *ngIf="!isPhone" infiniteScroll
	        [infiniteScrollDistance]="3"
	        [infiniteScrollThrottle]="300"
	        (scrolled)="getNextPageOfGroups(page)"
	        class="inline-block floatLeft group-thumbnail-bottom-margin" style="margin-left: 40px; width: 100%;">
			<group-details [group]="group" *ngFor = "let group of groups"></group-details>
		</div>
		<div *ngIf="isPhone" infiniteScroll
	        [infiniteScrollDistance]="3"
	        [infiniteScrollThrottle]="300"
	        (scrolled)="getNextPageOfGroups(page)"
	        class="inline-block col-xs-12 group-thumbnail-bottom-margin">
	        <div class="group-thumbnail-bottom-margin">
				<groupThumbnail [group]="group" *ngFor = "let group of groups"></groupThumbnail>
	        </div>
		</div>
	</div>
</div>