import { Component } from '@angular/core'
import { DataService } from '../../core/index'

@Component({
	templateUrl : './affiliates.html' , 
	styleUrls: [ './affiliates.css' ]
})

export class AffiliatesComponent {

	email : string
	error : string 
	submittedEmail : boolean

	constructor( 
		private dataService : DataService
	) {}

	submitEmail() {
		this.error = null
		let URI = 'affiliate/'
		this.dataService.postObject( URI , { email : this.email })
			.subscribe( ( response ) => {
				if ( response.error )
					return this.error = response.error
				return this.submittedEmail = true
			})
	}

}