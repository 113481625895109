<div>
	<span>Viewer<span *ngIf="contest.viewers.length > 1">s</span>: </span>
	<span *ngFor="let viewer of viewers"><img style = "height: 24px; width: 24px; border-radius: 12px;" src = "{{ viewer.profilePicture }}" /> {{ viewer.username }}<a *ngIf="isGroupAdmin" (click)="removeviewer(viewer)"><img src="/app/assets/images/cancel-btn.png" height="16px" width="16px" /></a>  </span>
	<a *ngIf="isGroupAdmin" (click)="openModal()"><span>  Add Viewer</span><img src="/app/assets/images/plus-btn.png" height="16px" width="16px" /></a>
	<div id="addViewerModal" class="cssModal">
		<div id="addViewerModalContent" class="cssModalContent modal-content row">
  			<div class="modal-header ">
          		<button type="button" class="btn btn-default top-left" (click)="closeModal()">Close</button>
							<h2>Add Viewer</h2>
  			</div>
			<p *ngIf="err">{{ err }}</p>
			<span>Username: </span>
			<input style="color: black;" type="text" [(ngModel)]="addViewerUsername" />
			<div class="block">
				<input class="btn btn-lg dark-purple-background" type="button" value="Add Viewer" (click)="addViewer(addViewerUsername)">
			</div>
		</div>
	</div>
</div>