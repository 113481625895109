import { Component , OnInit } from '@angular/core'

import { UserDataService } from '../../core/index'

import { IContest } from '../../shared/interfaces'

import moment from 'moment' 

@Component({
	selector : 'your-competitions' , 
	templateUrl : './yourCompetitions.html' ,
	styleUrls : ['./yourCompetitions.css']
})

export class YourCompetitionsComponent {

	contests : IContest[] = []

	constructor(
		private userDataService : UserDataService
	) {}

	ngOnInit() {

		this.userDataService.userData.subscribe( ( userData : any ) => {
			console.log( userData )
			this.contests = userData.myContests.filter(( contest : IContest ) => {
				return moment().isBefore( contest.winnerSelectedDate )
			})
		})
	}

}