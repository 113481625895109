<div style="margin-top : 80px; ">
    <div *ngIf="
        (contests && contests.length > 0) ||
        (groups && groups.length > 0) ||
        (users && users.length > 0 )
    ">
        <div *ngIf="showContests">
            <h2 class="col-xs-offset-1 hide-on-phones">Competition Search Results for <span *ngIf="hashtagSearch">#</span>{{ searchInput }}</h2>
            <div class="col-xs-12">
                <div class="col-md-offset-1 col-md-10">
                    <div class="recent-contests-margin col-centered">
                        <div class="col-centered">
                            <contest-thumbnail *ngFor = "let contest of contests" [contest]="contest"></contest-thumbnail>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="showGroups">
            <h2 class="col-xs-offset-1 hide-on-phones">Group Search Results for {{ searchInput }}</h2>
            <div class="col-xs-12">
                <div class="col-md-offset-1 col-md-10">
                    <div class="recent-contests-margin col-centered">
                        <div class="col-centered">
                            <groupThumbnail class="inline col-md-4 contest-thumbnail-height xl-breakpoint" *ngFor = "let group of groups" [group]="group"></groupThumbnail>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="showUsers">
            <h2 class="col-xs-offset-1 hide-on-phones">User Search Results for {{ searchInput }}</h2>
            <div class="col-xs-12">
                <div class="col-md-offset-1 col-md-10">
                    <div class="recent-contests-margin col-centered">
                        <div class="col-centered">
                            <user-thumbnail class="inline col-md-4 contest-thumbnail-height xl-breakpoint" *ngFor = "let user of users" [user]="user"></user-thumbnail>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="
        searching === false && !(
            (contests && contests.length > 0) ||
            (groups && groups.length > 0) ||
            (users && users.length > 0 )
        )"
    >
        <div class="col-xs-offset-1 col-xs-11">
            <h2>No results match {{ searchInput }}</h2>
        </div>
    </div>
</div>