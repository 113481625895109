<div class="group-thumbnail-container" style="margin:4px; display: inline-block;">
	<div class="inline group-thumbnail no-padding no-margins">
	  <div 
	  	style="
				cursor: pointer; 
	  		margin: auto;
		    width: 100%;
		    height: 100%;
		    position: relative;
		    background-repeat: no-repeat;
		    background-position: center;
		    background-size: cover;
			  text-shadow: 0 0 0.25em #000, 0 0 0.25em #000, 0 0 0.25em #000;
	  	"
			[ngStyle] = "groupThumbnailStyles"
	  >

			<div 
				style="
					background: linear-gradient(rgba(0 , 0 , 0 , 0.5), rgba( 0 , 0 ,0 ,0));
					width: 100%; 
			  " 

				class="contest-thumbnail no-padding no-margins"
			>
				<p class="no-margins no-padding" style="
					text-align: center;
				  white-space: nowrap;
				  overflow: hidden;
				  text-overflow: ellipsis;
				  color: white;
			  "> {{ group?.groupName }} </p>
			</div>
			<div
				style="
					background: linear-gradient(rgba( 0 , 0 ,0 ,0) , rgba(0 , 0 , 0 , 0.5) );
					width: 100%; 
					min-height: 70px;
					
					position: absolute;
					bottom: 0px;
					left: 0px;
				"
			>
				<div style="
					position: absolute;
					bottom: 0px;
					width: 100%;
				">
					<div
						style="
						  z-index: 2;
						  margin: 0px auto;
						  text-align: center;
						"
					>
						<p style="text-align: center; color: white;">{{ group?.totalFans }} Players</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>