<div class="col-xs-12">
	<h2 style="text-align: center; color: white;">Invite People to Participate!</h2>
	<div class="col-xs-offset-1 col-xs-10 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3">
		<p><span style="font-weight: 900 ; ">Entry Invite Link:</span> https://spotidol.com/competition/{{ this.contest._id }}</p>
		<p><span style="font-weight: 900 ; ">Judges Invite Link:</span> https://spotidol.com/competition/{{ this.contest._id }}/judge/{{ this.contest.judgeInviteUrl }}</p>
		<p><span style="font-weight: 900 ; ">Commenters Invite Link:</span> https://spotidol.com/competition/{{ this.contest._id }}/commenter/{{ this.contest.commenterInviteUrl }}</p>
		<p><span style="font-weight: 900 ; ">Viewers Invite Link:</span> https://spotidol.com/competition/{{ this.contest._id }}/viewer/{{ this.contest.viewerInviteUrl }}</p>
	</div>
	<div class="col-xs-12">
		<h3 style="text-align: center;">Follow Spotidol</h3>
	</div>
	<div class="col-xs-offset-3 col-xs-6">
		<a href="https://www.facebook.com/spotidol">
			<p class="col-xs-4" style="text-align: center; color : white;">
				<img src="/app/assets/images/facebook-logo-gray.png" style="display: inline-block;" width="18" height="18">
				<span>Facebook</span>
			</p>
		</a>
		<a href="https://www.twitter.com/spotidolo">
			<p class="col-xs-4" style="text-align: center; color : white;">
				<img src="/app/assets/images/twitter-logo-gray.png" style="display: inline-block;" width="18" height="18">
				<span>Twitter</span>
			</p>
		</a>
		<a href="https://www.instagram.com/spotidol">
			<p class="col-xs-4" style="text-align: center; color : white;">
				<img src="/app/assets/images/instagram-logo-gray.png" style="display: inline-block; width : 18px ; height : 18px; ">
				<span>Instagram</span>
			</p>
		</a>
	</div>
	<div class="col-xs-12">
		<a href="https://www.surveymonkey.com/r/QYT2XJP"><p style="color: white; text-align: center;">Take Our Survey!</p></a>
	</div>
</div>