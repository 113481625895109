import { Component , OnInit } from '@angular/core'

import { CreateContestSubjectService } from '../../index'
import { SessionService } from '../../../core/index'
import { InternationalService } from '../../../shared/international.service'

import moment from 'moment'
import 'moment-timezone'

@Component({
	selector : 'create-contest-entry-details' ,
	templateUrl : './entry.html' , 
	styleUrls : ['./entry.css']
})

export class CreateContestEntryDetailsComponent {

	createContestObject : any

	currencySymbol : string = '$'
	contestType : string

  date : any = new Date()

  today : any = {
	year: this.date.getFullYear(),
	month: this.date.getMonth() + 1,
	day: this.date.getDate()
  }

  tomorrow : { year : number , month : number , day : number } = {
	year: this.date.getFullYear(),
	month: this.date.getMonth() + 1,
	day: this.date.getDate() + 1
  }


	modal : any = document.getElementById('uploadContestModal')

  myDatePickerOptions : any = {
	// other options...
		dateFormat: 'mm.dd.yyyy',
		inline: false, 
		editableDateField: false, 
		openSelectorOnInputClick: true, 
		disableUntil: {
			year: this.today.year, 
			month: this.today.month, 
			day: this.today.day
		}
  };

  endOfContestDate : any = {
	year: this.date.getFullYear(),
	month: this.date.getMonth() + 1,
	day: this.date.getDate() + 1
  }
  endOfContestTime : any 

  winnerSelectedDate : any = {
	year: this.date.getFullYear(),
	month: this.date.getMonth() + 1,
	day: this.date.getDate() + 1
  }
  winnerSelectedTime : any 

	constructor( 
		private createContestSubjectService : CreateContestSubjectService ,
		private sessionService : SessionService , 
		private internationalService : InternationalService
	) { }

	ngOnInit() {
		this.createContestSubjectService.createContestObject.subscribe( ( createContestObject ) => {
			this.createContestObject = createContestObject
		})
		this.sessionService.session.subscribe( (session : any ) => {
			this.currencySymbol = this.internationalService.convertCurrencySymbol(session.country)
		})
	}

	submitDetails() {
		this.formatWinnerSelected()
		this.formatEntryEnds()
		this.createContestSubjectService.renewCreateContestObject( this.createContestObject )
	}

	formatWinnerSelected() {
		this.winnerSelectedDate = this.winnerSelectedDate
		this.winnerSelectedTime = this.winnerSelectedTime ? this.winnerSelectedTime : {} 
		this.winnerSelectedTime.hour = this.winnerSelectedTime ? (this.winnerSelectedTime.hour ? this.winnerSelectedTime.hour : 13) : 13 
		this.winnerSelectedTime.minute = this.winnerSelectedTime ? (this.winnerSelectedTime.minute ? this.winnerSelectedTime.minute : 30) : 30 
		let winnerSelectedTimeString = this.winnerSelectedTime.hour + ':' + this.winnerSelectedTime.minute
		let winnerSelectedDateString = this.winnerSelectedDate.year + '-' + this.winnerSelectedDate.month + '-' + this.winnerSelectedDate.day 
		let winnerSelectedString = winnerSelectedDateString + ' ' + winnerSelectedTimeString
		this.createContestObject.winnerSelectedDate = moment(winnerSelectedString , "YYYY-MM-DD HH:mm").utc().format()
	}

	formatEntryEnds() {
		this.endOfContestTime = this.endOfContestTime ? this.endOfContestTime : {} 
		this.endOfContestTime.hour = this.endOfContestTime ? (this.endOfContestTime.hour ? this.endOfContestTime.hour : 12) : 12 
		this.endOfContestTime.minute = this.endOfContestTime ? (this.endOfContestTime.minute ? this.endOfContestTime.minute : 30) : 30 
		let endOfContestTimeString = this.endOfContestTime.hour + ':' + this.endOfContestTime.minute
		let endOfContestDateString = this.endOfContestDate.year + '-' + this.endOfContestDate.month + '-' + this.endOfContestDate.day 
		let endOfContestString = endOfContestDateString + ' ' + endOfContestTimeString
		this.createContestObject.endOfContest = moment(endOfContestString , "YYYY-MM-DD HH:mm").utc().format()
	}

	setEndOfCompetitionDate( event : any ) {
		this.endOfContestDate = event
	}

	setWinnerSelectedDate( event : any ) {
		this.winnerSelectedDate = event
	}

}