import { Component , OnInit } from '@angular/core'
import { Router , RouterEvent , NavigationCancel , NavigationError , RoutesRecognized , NavigationEnd , NavigationStart , ActivatedRoute } from '@angular/router'
import { DataService , SessionService , UserDataService , OpenAppService } from '../core/index'
import { IContest , IUser , IContestEntry , IFan , IGroup  } from '../shared/interfaces'

@Component({
  templateUrl: './root.html'
})

export class RootComponent {

	session : any
	userData : any = {}
	contests : IContest[] = []
	createdGroups : IGroup[] = []
	contestEntries : IContestEntry[] = []
	loggedIn : boolean = false
	myFans : IFan[] = []
	myFanRequests : IFan[] = []
	myGroups : IGroup[] = []
	user : IUser 
	isMobile : boolean
	showOverlay : boolean

	constructor(
		private router : Router , 
		private route : ActivatedRoute ,
		private dataService : DataService ,
		private openAppService : OpenAppService , 
		private userDataService : UserDataService , 
		private sessionService : SessionService 
	) {
		router.events.subscribe((event : RouterEvent ) => {
			this.navigationInterceptor( event )
		    if(event instanceof NavigationStart) {
				this.postNavigation(this.router.url)
		    }
		    if ( event instanceof NavigationEnd )
		      window.scrollTo(0, 0)
		})
	}

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
    	console.log('navigation start')
      this.showOverlay = true;
    }
    if (event instanceof NavigationEnd) {
    	console.log('navigation end')
      this.showOverlay = false;
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.showOverlay = false;
    }
    if (event instanceof NavigationError) {
      this.showOverlay = false;
    }
  }
	
	openInApp() {
		let groupUrl = null , competitionId = null
		let urlArray = this.router.url.split('/')
		groupUrl = urlArray[2]
		let url
		if (this.router.url.indexOf('/competition/') > -1)
			competitionId = urlArray[4]
		if (competitionId && groupUrl) 
			url = "spotidol://competitionEntries?competitionId=" + competitionId 
		else if (groupUrl)
			url = "spotidol://group?groupUrl=" + groupUrl
		else
			url = "spotidol://"
		window.location.replace(url)
	}

	navigateHome() {
		if ( this.loggedIn === true ) 
			return this.router.navigateByUrl('/home')
		return this.router.navigateByUrl('/welcome')
	}
 
	getSession( cb ) {
		let URI = 'user/checkSession'
		this.dataService.getObject(URI)
			.subscribe((response : any) => {
				this.sessionService.renewSession( response.sessionStatus )
				if (response.sessionStatus.loggedIn) {
					this.loggedIn = true
					if ( response.sessionStatus.redirectTo !== null ) {
						this.router.navigateByUrl( '/' + response.sessionStatus.redirectTo )
					}
				}
				cb()
		})
	}

	linkToDownload() {
		var isIOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
		var isAndroid = !!navigator.platform && /android/.test(navigator.platform)
		if (isIOS) {
			window.location.href = "https://itunes.apple.com/us/app/spotidol-competitions/id1441184363?mt=8"
		}
		if (isAndroid) {
			window.location.href = "http://play.google.com/store/apps/details?id=com.spotidol.spotidol"
		}
	}

	getUserData() {
		return new Promise<void>( ( resolve , reject ) => {
			if ( this.loggedIn !== true )
				return resolve() 
			Promise.all([
				this.getCreatedGroups(),
				this.getMyGroups(),
				this.getMyContests(),
				this.getMyUser() ,  
				this.getMyFans(), 
				this.getMyContestEntries()
			]).then(() => {
				this.userData = {
					myContests : this.contests ,
					myContestEntries : this.contestEntries , 
					myFans : this.myFans ,
					user : this.user ,
					createdGroups : this.createdGroups , 
					myGroups : this.myGroups  
				}
				this.userDataService.renewUserData(this.userData)
				return resolve()
			})
		})
	}

	postNavigation( uri : string ) {
		return new Promise<void>( ( resolve , reject ) => {
			let postNavigationBody = {
				uri : uri
			}
			let URI = 'userEngagement/postNavigation' 
			this.dataService.postObject( URI , postNavigationBody )
				.subscribe( ( response : any ) => {
					console.log(response)
					return resolve()
				})
		})
	}

	getCreatedGroups(){
		return new Promise<void>( ( resolve , reject ) => {
			let URI = 'group/getManagedGroups'
	    	this.dataService.getObject(URI)
		        .subscribe((response) => {
		          	this.createdGroups = response.groups;
					return resolve()
		        },
	        (err: any) => console.log(err),
	        () => console.log("groupsICreated"));
		})
	}

	getMyContests(){
		return new Promise<void>( ( resolve , reject ) => {
			let URI = 'contest/getContestsByUserId'
			this.dataService.getObject(URI)
				.subscribe( ( response ) => {
					this.contests = response.contests
					return resolve()
				},
	        (err: any) => console.log(err),
	        () => console.log("groupsImAFanOf"));
		})
	}

	getMyContestEntries(){
		return new Promise<void>( ( resolve , reject ) => {
			let URI = 'contestEntry/getContestEntriesByContestantId'
			this.dataService.getObject(URI)
				.subscribe( ( response ) => {
					this.contestEntries = response.contestEntries
					return resolve()
				},
	        (err: any) => console.log(err),
	        () => console.log("groupsImAFanOf"));
		})
	}

	getMyGroups(){
		return new Promise<void>( ( resolve , reject ) => {
			let URI = 'group/getGroupsByFanId'
    	this.dataService.getObject(URI)
        .subscribe((response) => {
        	this.myGroups = response.groups;
					return resolve()
        },
        (err: any) => console.log(err),
        () => console.log("groupsImAFanOf"));
    })
	}

	getMyFans(){
		return new Promise<void>( ( resolve , reject ) => {
			let URI = 'fan/getFansBySessionId'
	    	this.dataService.getObject(URI)
		        .subscribe((response) => {
		        	for ( var i = 0 ; i < response.fans.length ; i++ ) {
		        		if (response.fans[i].approved === false )
		        			this.myFanRequests.push(response.fans[i])
		        		else 
		        			this.myFans.push(response.fans[i])
		        	}
							return resolve()
		        },
	        (err: any) => console.log(err),
	        () => console.log("groupsImAFanOf"));
	    })
	}


	getMyUser() {
		return new Promise<void>( ( resolve , reject ) => {
			let URI = 'user/getUserBySession' 
			this.dataService.getObject( URI )
				.subscribe( ( response ) => {
					if ( response.error )
						return console.log( response.error )
					this.user = response.user
					return resolve()
				})
		})
	}

}