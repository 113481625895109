<style>
	input {
		display: block;
		width : 100%;
	}
</style>
<div 
	class="col-xs-12 col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3"
	style="margin-top: 20px;" 
>
	<form 
		style="
			border: solid;
		    background-color: #e2dfff;
		    display: flex;
		    flex-direction: column;
		    border-radius: 10px; 
		    padding: 20px;
	    " 
		[formGroup]="updateUserForm" 
		autocomplete="off" 
		novalidate 
		(ngSubmit)="checkToCreateACard(updateUserForm.value)"
	>
		<h2 style="text-align: center;">Account Settings</h2>
		<p style="margin-bottom: 20px; text-align: center;">To enable payouts to your bank account, you must completely fill out this form.</p>
		<div  *ngIf="updatingUser"><h3>One moment while we update your user.</h3></div>
		<div  *ngIf="updateUserError && !updatingUser"><h3>{{ err }}</h3></div>
		<div  *ngIf="updateUserSuccess"><h3>Success!</h3></div>
		<div 
			class="col-xs-12 col-sm-10 col-sm-offset-1"
			style="border : solid 2px white; border-radius: 5; padding : 5px; margin-bottom: 5px;"
		>
			<p style="font-weight: 600; text-align: center;">Basic Account Info</p>
			<p style="font-weight: 600;">{{ user?.username }}</p>
			<div *ngIf="!dontDisplayEmail">
				<label>Email (click to change): </label><input class=" transparent-input" formControlName="email" id="email" type="email" placeholder="{{ user?.email }}">
			</div>
			<div>
				<label>New Password</label><input class=" roundBorders darker-background input-styling" formControlName="password" id="password" type="password" placeholder="password">
			</div>
			<div>
				<label>Confirm Your New Password</label><input class=" roundBorders darker-background input-styling" formControlName="confirmPassword" id="confirmPassword" type="password" placeholder="Confirm Your Password">
			</div>
			<button type="submit" class="btn btn-info btn-lg purple-background" style="width : 100%;">Update</button>
		</div>
		<div 
			class="col-xs-12 col-sm-10 col-sm-offset-1"
			style="border : solid 2px white; border-radius: 5; padding : 5px; margin-bottom: 5px;"
		>
			<p style="font-weight: 600; text-align: center;">Personal Info</p>
			<div>
				<label>First Name</label><input class=" roundBorders darker-background input-styling" formControlName="firstName" id="firstName" type="text" placeholder="{{ user?.firstName }}">
			</div>
			<div>
				<label>Last Name</label><input class=" roundBorders darker-background input-styling" formControlName="lastName" id="lastName" type="text" placeholder="{{ user?.lastName }}">
			</div>
			<p style="font-weight: 600; text-align: center;">Birthdate</p>
			<div [ngClass]="{ 'error' : updateUserForm.controls.dobMonth.invalid && updateUserForm.controls.dobMonth.touched }">
				<label>Month</label><input class=" roundBorders darker-background input-styling" mask="00" formControlName="dobMonth" id="dobMonth" type="text" placeholder="{{ user?.dobMonth }}">
			</div>
			<div [ngClass]="{ 'error' : updateUserForm.controls.dobDay.invalid && updateUserForm.controls.dobDay.touched }">
				<label>Day</label>
				<input class=" roundBorders darker-background input-styling" formControlName="dobDay" id="dobDay" mask="00" placeholder="{{ user?.dobDay }}">	
			</div>
			<div [ngClass]="{ 'error' : updateUserForm.controls.dobYear.invalid && updateUserForm.controls.dobYear.touched }">
				<label>Year</label><input class=" roundBorders darker-background input-styling" formControlName="dobYear" id="dobYear" mask="0000"  type="text" placeholder="{{ user?.dobYear }}">
			</div>
			<button type="submit" class="btn btn-info btn-lg purple-background" style="width : 100%;">Update</button>
		</div>
		<div 
			class="col-xs-12 col-sm-10 col-sm-offset-1"
			style="border : solid 2px white; border-radius: 5; padding : 5px; margin-bottom: 5px;"
		>
			<p style="text-align: center; font-weight: 600;">Address</p>
			<div>
				<label>Address</label><input class=" roundBorders darker-background input-styling" formControlName="address" id="address" type="text" placeholder="{{ user?.address }}">
			</div>
			<div>
				<label>City</label><input class=" roundBorders darker-background input-styling" formControlName="city" id="city" type="text" placeholder="{{ user?.city }}">
			</div>
			<div>
				<label>State</label><input class=" roundBorders darker-background input-styling" formControlName="state" id="state" type="text" placeholder="{{ user?.state }}">
			</div>
			<div>
				<label>Country</label>
				<select class=" roundBorders darker-background input-styling" formControlName="country" id="country" name="country">
					<option *ngFor = " let countryItem of countries " value="{{ countryItem.code }}">{{ countryItem.name }}</option>
				</select>
			</div>
			<div>
				<label>ZipCode</label><input class="roundBorders darker-background input-styling" formControlName="zipCode" id="zipCode"  mask="00000" placeholder="{{ user?.zipCode }}">
			</div>
			<button type="submit" class="btn btn-info btn-lg purple-background" style="width : 100% ; ">Update</button>
		</div>
		<div 
			class="col-xs-12 col-sm-10 col-sm-offset-1"
			style="border : solid 2px white; border-radius: 5; padding : 5px; margin-bottom: 5px;"
		>
			<p style="text-align: center; font-weight: 600;">Payout Info</p>
			<div [ngClass]="{ 'error' : updateUserForm.controls.cardNumber.invalid && updateUserForm.controls.cardNumber.touched }">
				<label>Card Number</label><input class=" roundBorders darker-background input-styling" formControlName="cardNumber" id="cardNumber" type="text" mask="0000000000000000" placeholder="4242424242424242">
			</div>
			<div [ngClass]="{ 'error' : updateUserForm.controls.expMonth.invalid && updateUserForm.controls.expMonth.touched }">
				<label>Expiration Month</label><input class=" roundBorders darker-background input-styling" formControlName="expMonth" id="expMonth" mask="00"  type="text" placeholder="12">
			</div>
			<div [ngClass]="{ 'error' : updateUserForm.controls.expYear.invalid && updateUserForm.controls.expYear.touched }">
				<label>Expiration Year</label><input class=" roundBorders darker-background input-styling" formControlName="expYear" id="expYear" type="text" mask="0000" placeholder="2024">
			</div>
			<div [ngClass]="{ 'error' : updateUserForm.controls.cvc.invalid && updateUserForm.controls.cvc.touched }">
				<label>CVC (security code)</label><input class=" roundBorders darker-background input-styling" formControlName="cvc" id="cvc" type="text" mask="000" placeholder="424">
			</div>
			<div [ngClass]="{ 'error' : updateUserForm.controls.ssnLast4.invalid && updateUserForm.controls.ssnLast4.touched }">
				<label>Last 4 Ssn</label><input class=" roundBorders darker-background input-styling" formControlName="ssnLast4" id="ssnLast4" type="text" mask="0000" placeholder="{{ user?.ssnLast4 }}">
			</div>
			<button type="submit" class="btn btn-info btn-lg purple-background" style="width : 100% ; ">Update</button>
		</div>
		<div 
			class="col-xs-12 col-sm-10 col-sm-offset-1"
			style="border : solid 2px white; border-radius: 5; padding : 5px; margin-bottom: 5px;"
		>
			<p style="text-align: center; font-weight: 600;">For Businesses</p>
			<div>
				<label>Business Name</label><input class="roundBorders darker-background input-styling" formControlName="businessName" id="businessName" type="text" placeholder="{{ user?.businessName }}">
			</div>
			<div>
				<label>Business Tax Id</label><input class="roundBorders darker-background input-styling" formControlName="businessTaxId" id="businessTaxId" type="text" placeholder="business tax id">
			</div>
			<button type="submit" class="btn btn-info btn-lg purple-background" style="width : 100%;">Update</button>
		</div>
		<button type="submit" class="btn btn-info btn-lg purple-background" style="width : 100%; background-color: red;">De-Activate Account</button>
	</form>
	<div class="col-xs-offset-1">
		<h2>Payout Cards</h2>
		<div class="darker-background roundBorders margin-between-objects white-text width-75" *ngFor="let card of user?.metaData.cards">
			<p *ngIf="card.default">Default</p>
			<p class="inline col-xs-offset-1">{{ card.brand }}</p>
			<p class="inline col-xs-offset-3">{{ card.last4 }}</p>
			<p class="inline col-xs-offset-5">{{ card.exp_month }}</p>/<p class="inline">{{ card.exp_year }}</p>
		</div>
	</div>
</div>