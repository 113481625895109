<!-- make height=1/2 view width -->
<div class="featured-winner-container text-shadow">
	<a routerLink="/winners" style="color : white;">
		<div [@fade]="isDisplayed ? 'fadeIn' : 'fadeOut'" class="col-xs-12 noTopOrBottomPadding noTopOrBottomMargin" 
			style="
				width: 100% ; 
				height: 100% ; 
			    background-repeat: no-repeat;
			    background-size: cover;
			    background-position: center top;
			    padding: 0px;
			" 
			[ngStyle]="{'background-image': 'url(' + winner.winnerPhoto + ')'}">

			<div class="featured-winner-information-section-desktop">
				<h2
					style="
						margin: 0px;
						padding: 10px;
						position: absolute;
						top: 5px;
						left : 5px;
					" 
					class="jumbo-text"
				>{{ winner.competitionName }}</h2>
					<div style="
				    position: absolute;
				    -ms-transform: translateY(-50%);
				    -webkit-transform: translateY(-50%);
				    transform: translateY(-50%);
				    top: 50%;
				    width: 100%;
			    ">			
						<h2
							style="
								margin: 0px;
								padding: 10px;
							" 
							class="jumbo-text"
						>{{ winner.username }}</h2>
					</div>
			</div>
			<h2 
		    class="competition-name-jumbo-text-2"
				style="
					position: absolute;
					bottom: 5px ;
					right: 5px ;
					text-align: right;
		    " 
		  >Read Their Story</h2>
		</div>
	</a>
</div>