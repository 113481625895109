import { Injectable } from '@angular/core';

@Injectable()

export class DateService {

	stringifyDate( dateToStringify : any) {
		var dateAndTime = dateToStringify.split('T')
		var date = dateAndTime[0].split('-')
		var year = date[0]
		var currentYear : any = new Date();
		currentYear = currentYear.getFullYear()
		if (year === currentYear)
			year = ''
		var month = date[1]
		var day = date[2]
		if (day.charAt(0) === '0')
			day = day.charAt(1)

		var stringifiedMonth = ''
		switch (month) {
			case '01': {
				stringifiedMonth = 'Jan'
				break
			}
			case '02': {
				stringifiedMonth = 'Feb'
				break
			}
			case '03': {
				stringifiedMonth = 'Mar'
				break
			}
			case '04': {
				stringifiedMonth = 'Apr'
				break
			}
			case '05': {
				stringifiedMonth = 'May'
				break
			}
			case '06': {
				stringifiedMonth = 'Jun'
				break
			}
			case '07': {
				stringifiedMonth = 'Jul'
				break
			}
			case '08': {
				stringifiedMonth = 'Aug'
				break
			}
			case '09': {
				stringifiedMonth = 'Sep'
				break
			}
			case '10': {
				stringifiedMonth = 'Oct'
				break
			}
			case '11': {
				stringifiedMonth = 'Nov'
				break
			}
			case '12': {
				stringifiedMonth = 'Dec'
				break
			}
		}
	    return stringifiedMonth + ' ' + day + ' ' + year

	}

	stringifyTime( timeToStringify : any )	{
		var dateAndTime = timeToStringify.split('T')
		var time = dateAndTime[1].split(':')
		var date = dateAndTime[0].split('-')
		var month = date[1]
		var day = date[2]
		if (day.charAt(0) === '0')
			day = day.charAt(1)

		var hour = time[0]
		var minute = time[1]
		if (hour.charAt(0) === '0')
			hour = hour.charAt(1)
		var hourNumber = parseInt(hour)
		if (hourNumber > 12) {
			hourNumber = hourNumber - 12
			hour = hourNumber + ''
			var timeString = hour + ':' + minute + ' PM UT'
		} else {
			var timeString = hour + ':' + minute + ' AM UT'
		}
		var stringifiedMonth = ''
		switch (month) {
			case '01': {
				stringifiedMonth = 'Jan'
				break
			}
			case '02': {
				stringifiedMonth = 'Feb'
				break
			}
			case '03': {
				stringifiedMonth = 'Mar'
				break
			}
			case '04': {
				stringifiedMonth = 'Apr'
				break
			}
			case '05': {
				stringifiedMonth = 'May'
				break
			}
			case '06': {
				stringifiedMonth = 'Jun'
				break
			}
			case '07': {
				stringifiedMonth = 'Jul'
				break
			}
			case '08': {
				stringifiedMonth = 'Aug'
				break
			}
			case '09': {
				stringifiedMonth = 'Sep'
				break
			}
			case '10': {
				stringifiedMonth = 'Oct'
				break
			}
			case '11': {
				stringifiedMonth = 'Nov'
				break
			}
			case '12': {
				stringifiedMonth = 'Dec'
				break
			}
		}
	    return stringifiedMonth + ' ' + day + ' ' + timeString
	}	
} 