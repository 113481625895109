import { Component , OnInit , Output , EventEmitter } from '@angular/core'
import { Resolve , Router } from '@angular/router'
import { FormControl , FormGroup , Validators } from '@angular/forms'
import { DataService , SessionService , UserDataService } from '../../../core/index'
import { IContest , IContestEntry , IFan , IGroup } from '../../../shared/interfaces'

@Component({
  selector: 'sign-up-redirect', 
    templateUrl: "./signUpRedirect.html"
})

export class SignUpRedirectComponent {

    submittingSignUpToServer : boolean 
    err : string
    signUpForm : FormGroup
    userData : any = {}
    contests : IContest[]
    createdGroups : IGroup[]
    contestEntries : IContestEntry[]
    loggedIn : boolean = false
    session : any = null
    myFans : IFan[]
    myGroups : IGroup[]
    showReferralInput : boolean = false

    constructor(
        private dataService : DataService , 
        private userDataService : UserDataService , 
        private sessionService : SessionService ,
        private router : Router
    ) {}   

    toggleReferralInput() {
        this.showReferralInput = !this.showReferralInput
    }

    ngOnInit() {
        this.getSession().then( () => {})
        let username = new FormControl('' , Validators.required)
        let email = new FormControl('' , Validators.required)
        let password = new FormControl('' , Validators.required)
          let confirmPassword = new FormControl('' , Validators.required)
        let referralCode = new FormControl('')
        this.signUpForm = new FormGroup({
            username : username , 
            email : email , 
            password : password , 
            referralCode : referralCode , 
            confirmPassword : confirmPassword
        })
    }


    signUp( formValues : any ) {
        if (this.session === null)
            return
        console.log(this.session)
        if (!formValues.username)
            return this.err = 'Please provide a username.'
        if (!formValues.email)
            return this.err = 'Please provide an email.'
        if (!formValues.password)
            return this.err = 'Please provide password.'
        if (formValues.password !== formValues.confirmPassword)
            return this.err = 'Passwords do not match. Please try again.'
        formValues.country = this.session.country
        let URI = 'user/signUpViaEmail'
        this.dataService.postObject(URI, formValues)
            .subscribe((response : any) => {
                if (response.error) {
                    return this.err = response.error
                }
                if (response.status === false )
                    return
                Promise.all([
                    this.getSession() ,
                    this.getUserData()
                ]).then(() => {
                    if (this.session.loggedIn === true)
                        this.router.navigateByUrl('/home')
                })
            })
    }
 
    getSession() {
        return new Promise<void>( ( resolve , reject ) => {        
            let URI = 'user/checkSession'
            this.dataService.getObject(URI)
                .subscribe((response : any) => {
                    console.log(response)
                    this.session = response.sessionStatus
                    this.sessionService.renewSession( response.sessionStatus )
                    return resolve()
                })
        })
    }

    getUserData() {
        return new Promise<void>( ( resolve , reject ) => {
            if ( this.loggedIn !== true )
                return resolve() 
            Promise.all([
                this.getCreatedGroups(),
                this.getMyGroups(),
                this.getMyContests(),
                this.getMyFans(), 
                this.getMyContestEntries()
            ]).then(() => {
                this.userData = {
                    myContests : this.contests ,
                    myContestEntries : this.contestEntries , 
                    myFans : this.myFans ,
                    createdGroups : this.createdGroups , 
                    myGroups : this.myGroups  
                }
                this.userDataService.renewUserData(this.userData)
                return resolve()
            })
        })
    }

    getCreatedGroups(){
        return new Promise<void>( ( resolve , reject ) => {
            let URI = 'group/getGroupsByUserId'
            this.dataService.getObject(URI)
                .subscribe((response) => {
                      this.createdGroups = response.groups;
                    return resolve()
                },
            (err: any) => console.log(err),
            () => console.log("groupsICreated"));
        })
    }

    getMyContests(){
        return new Promise<void>( ( resolve , reject ) => {
            let URI = 'contest/getContestsByUserId'
            this.dataService.getObject(URI)
                .subscribe( ( response ) => {
                    this.contests = response.contests
                    return resolve()
                },
            (err: any) => console.log(err),
            () => console.log("groupsImAFanOf"));
        })
    }

    getMyContestEntries(){
        return new Promise<void>( ( resolve , reject ) => {
            let URI = 'contestEntry/getContestEntriesByContestantId'
            this.dataService.getObject(URI)
                .subscribe( ( response ) => {
                    this.contestEntries = response.contestEntries
                    return resolve()
                },
            (err: any) => console.log(err),
            () => console.log("groupsImAFanOf"));
        })
    }

    getMyGroups(){
        return new Promise<void>( ( resolve , reject ) => {
            let URI = 'group/getGroupsByFanId'
            this.dataService.getObject(URI)
                .subscribe((response) => {
                      this.myGroups = response.groups;
                    return resolve()
                },
            (err: any) => console.log(err),
            () => console.log("groupsImAFanOf"));
        })
    }

    getMyFans(){
        return new Promise<void>( ( resolve , reject ) => {
            let URI = 'fan/getFansBySessionId'
            this.dataService.getObject(URI)
                .subscribe((response) => {
                      this.myFans = response.fans;
                    return resolve()
                },
            (err: any) => console.log(err),
            () => console.log("groupsImAFanOf"));
        })
    }


}