import { Component , OnInit , Input } from '@angular/core'
import { FormControl , FormGroup } from '@angular/forms'

import { DataService , SessionService } from '../../core/index'

@Component({
	selector: 'reset-password' , 
	templateUrl: "./resetPassword.html"
})

export class ResetPasswordComponent implements OnInit {

	err : string
	passwordReset : boolean
	loggedIn : boolean
	user : string

	constructor(private dataService : DataService , 
				private sessionService : SessionService ) {}

	ngOnInit() {
		this.sessionService.session.subscribe( (session:any) => {
			this.loggedIn = session.loggedIn
		})
	}

	resetPassword( user : string ) {
		console.log(user)
		var form = {
			user : user
		}
		let URI = 'user/resetPassword'
		this.dataService.postObject(URI , form)
			.subscribe((response : any) => {
				console.log(response)
				if (response.error)
					return this.err = response.error
				this.err = null
				return this.passwordReset = true
			})
	}

}