import { Component } from '@angular/core'

@Component({
	selector: 'cmo',
	templateUrl: './cmo.html' ,
	styleUrls : ['./cmo.css']
})

export class CMOComponent {

}
