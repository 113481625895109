import { Injectable } from '@angular/core'
import { IContest, IRewardCurrency, ICurrency } from '../shared/interfaces'
import { InternationalService } from '../shared/international.service'

@Injectable()
export class ContestService {

	constructor(
		private internationalService : InternationalService
	) {}

	checkIfThereAreNoRewards(contest : IContest) {
    	return !(contest.rewardFanpoints > 0) && !(contest.rewardCash > 0) && !contest.rewardOther
  	}

  	//
  	setCompetitionNameLength(contest : IContest) {
		if (contest.contestName.length > 45)
			contest.contestName = contest.contestName.substring(0, 56) + '...'
		if (contest.rewardOther && contest.rewardOther.length > 55)
			contest.rewardOther = contest.rewardOther.substring(0, 56) + '...'
		return contest
	}

	setCurrency(currency : ICurrency) {
      if (currency.currencyWasSet)
        return currency
      currency.currencyWasSet = true 
      if (this.internationalService.getCurrencyCode(currency.session.country) !== currency.contest.currency) {
        let exchangeRate = currency.session.exchangeRate / currency.contest.exchangeRate
        currency.contest.contestPrice = Math.ceil(currency.contest.contestPrice * exchangeRate * 100 ) / 100
        currency.contest.displayPrice = parseFloat(currency.contest.contestPrice.toFixed(2))
        currency.entryCurrencySymbol = this.internationalService.convertCurrencySymbol(currency.session.country)
      } else {
        currency.contest.displayPrice = currency.contest.contestPrice
        currency.entryCurrencySymbol = this.internationalService.convertCurrencySymbol(currency.session.country)
      }
      //this.setRewardCurrency()
      var rewardsCurrency : IRewardCurrency = {
        session: currency.session,
        contest: currency.contest,
        rewardCurrencySymbol: currency.rewardCurrencySymbol,
        displayPotSize: currency.displayPotSize
      }

      var newRewardsCurrency = this.setRewardCurrency(rewardsCurrency)
      currency.session = newRewardsCurrency.session
      currency.contest = newRewardsCurrency.contest
      currency.rewardCurrencySymbol = newRewardsCurrency.rewardCurrencySymbol
      currency.displayPotSize = newRewardsCurrency.displayPotSize
      return currency
   }

	setRewardCurrency(rewardsCurrency : IRewardCurrency) {
		let exchangeRate = rewardsCurrency.session.exchangeRate / rewardsCurrency.contest.exchangeRate
		if (rewardsCurrency.contest.rewardCash > 0 || rewardsCurrency.contest.totalPotSize > 0) {
		  if (this.internationalService.getCurrencyCode(rewardsCurrency.session.country) !== rewardsCurrency.contest.currency) {
		    rewardsCurrency.rewardCurrencySymbol = this.internationalService.convertCurrencySymbol(rewardsCurrency.session.country)
		    rewardsCurrency.contest.rewardCash = Math.ceil(rewardsCurrency.contest.rewardCash * exchangeRate * 100 ) / 100
		    rewardsCurrency.contest.displayRewardCash = parseFloat(rewardsCurrency.contest.rewardCash.toFixed(2))
		    rewardsCurrency.contest.totalPotSize = Math.ceil(rewardsCurrency.contest.totalPotSize * exchangeRate * 100 ) / 100
		    rewardsCurrency.displayPotSize = parseFloat(rewardsCurrency.contest.totalPotSize.toFixed(2))
		  } else {
		    rewardsCurrency.rewardCurrencySymbol = this.internationalService.convertCurrencySymbol(rewardsCurrency.session.country)
		    rewardsCurrency.contest.rewardCash = Math.ceil(rewardsCurrency.contest.rewardCash * 100 ) / 100
		    rewardsCurrency.contest.displayRewardCash = parseFloat(rewardsCurrency.contest.rewardCash.toFixed(2))
		    rewardsCurrency.contest.totalPotSize = Math.ceil(rewardsCurrency.contest.totalPotSize * 100 ) / 100
		    rewardsCurrency.displayPotSize = parseFloat(rewardsCurrency.contest.totalPotSize.toFixed(2))
		  }
		}
		if ( rewardsCurrency.contest.positions > 1 ) {
		  for (var i = 0 ; i < rewardsCurrency.contest.rewards.length ; i++ ) {
		    if (rewardsCurrency.contest.rewards[i].rewardCash > 0) {
		      if (this.internationalService.getCurrencyCode(rewardsCurrency.session.country) !== rewardsCurrency.contest.currency) {
		        let exchangeRate = rewardsCurrency.session.exchangeRate / rewardsCurrency.contest.exchangeRate
		        rewardsCurrency.rewardCurrencySymbol = this.internationalService.convertCurrencySymbol(rewardsCurrency.session.country)
		        rewardsCurrency.contest.rewards[i].rewardCash = Math.ceil(rewardsCurrency.contest.rewards[i].rewardCash * exchangeRate * 100 ) / 100
		        rewardsCurrency.contest.rewards[i].displayRewardCash = parseFloat(rewardsCurrency.contest.rewards[i].rewardCash.toFixed(2))
		      } else {
		        rewardsCurrency.rewardCurrencySymbol = this.internationalService.convertCurrencySymbol(rewardsCurrency.session.country)
		        rewardsCurrency.contest.rewards[i].rewardCash = Math.ceil(rewardsCurrency.contest.rewards[i].rewardCash * 100 ) / 100
		        rewardsCurrency.contest.rewards[i].displayRewardCash = parseFloat(rewardsCurrency.contest.rewards[i].rewardCash.toFixed(2))
		      }
		    }
		  }
		}

		return rewardsCurrency
	}
	
}