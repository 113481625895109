<a (click)="toggleCreateContentMenu()" style="display: inline-block; color: white;">
  <img height='30px' width='30px' src="/app/assets/images/create-competition-logo-white.png">
  <h4 
    class="navbar-icon-text" 
  >Create</h4>
</a>
<div 
	[hidden]="!showCreateContentMenu" 
	class="menus"
	style="
	    text-align: center;
	    padding: 0px;
		background-color: #AEB7D0 ;
		border: solid 1px gray;
		border-top: none;
	"
>
	 <a  
    	class="dark-purple-2 menu-text" 
    	routerLink="/createCompetition/group"
    	style="
        	color: white;
        	padding: 5px; 
        	display: block;
        	font-size: 12px;
			text-align: right;
			line-height: 1;
		"
	>
		<p style="margin: 0px;">Create Competition</p>
	</a>
	<a  
    	class="dark-purple-2 menu-text" 
    	style="
        	color: white;
        	padding: 5px; 
        	display: block;
        	font-size: 12px;
			text-align: right;
			line-height: 1;
		"
	>
		<create-group></create-group>
	</a>
</div>