export const zeroDecimalCurrencies = [
	"BIF" , 
	"CLP" , 
	"DJF" , 
	"GNF" , 
	"JPY" ,
	"KMF" , 
	"KRW" , 
	"MGA" , 
	"PYG" , 
	"RWF" , 
	"UGX" , 
	"VND" , 
	"VUV" , 
	"XAF" , 
	"XOF" , 
	"XPF" 
]