<div style="width: 100%">
	<div 
		style="
			width: 100%;
		    background-color: #38244C;
		    height: 30px;
		    color: white;
		    padding-left: 30px;
		    padding-right: 30px;
		    vertical-align: center;
		"
	>
		<span style="float: left;">{{ dream.username }}'s Dream</span>
		<span style="float: right;">{{ dream.created }}</span>
	</div>
	<div 
		style="
	    display: block;
	    min-height: 40px;
	    width: 100%;
	    background-color: white;
	    color: black;
	    margin: 0px;
		"
	>
		<p>{{ dream.description }}</p>
	</div>
	<div 
		style="
			width: 100%;
		    background-color: #38244C;
		    height: 30px;
		    color: white;
		    padding-left: 30px;
		    padding-right: 30px;
		    vertical-align: center;
		"
	>
		<span style="float: left;"><join-dream [dream]="dream"></join-dream></span>
		<span style="float: right;"><dream-joiners [dream]="dream"></dream-joiners></span>
	</div>
</div>