import { Component , OnInit } from '@angular/core'

import { CreateContestSubjectService } from '../../index'

@Component({
	selector : 'create-contest-details' ,
	templateUrl : './details.html' , 
	styleUrls : ['./details.css']
})

export class CreateContestDetailsComponent {

	createContestObject : any
	tagString : string
	err : string

	constructor( private createContestSubjectService : CreateContestSubjectService ) { }

	ngOnInit() {
		this.createContestSubjectService.createContestObject.subscribe( ( createContestObject ) => {
			this.createContestObject = createContestObject
		})
	}

	submitDetails() {
		if (this.tagString)
			var tags = this.makeTagsArray(this.tagString)
		if (tags === false) 
			return this.err = 'Your tags must start with "#".'
		this.createContestObject.tags = tags
		this.createContestSubjectService.renewCreateContestObject( this.createContestObject )
		this.err = null
	}

	makeTagsArray (tagString : string) {	
		if (tagString && !tagString.match('[#].*')) 
			return false
		let tags = tagString.split(" #")
		for ( var i = 1 ; i < tags.length ; i++ ) {
			tags[i] = '#' + tags[i]
		}
		return tags
	}


}