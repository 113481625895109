import { Component , Input , AfterContentInit } from '@angular/core'

import { IWinner } from '../index'

import { FadeAnimation } from '../../shared/animations/index'

@Component({
	selector : 'featured-winner' , 
	templateUrl : './featuredWinner.html' , 
	styleUrls : [ './featuredWinner.css' ] ,
  animations: [
  	FadeAnimation 
  ]
})

export class FeaturedWinnerComponent {

	@Input( ) winner : IWinner

  isDisplayed = false;

  toggleAnimation() {
    this.isDisplayed = !this.isDisplayed;
  }

	constructor() {	}

	ngAfterContentInit() {
		this.toggleAnimationTimeouts()
		setInterval(()=> { this.toggleAnimationTimeouts() } , 18000 )
	}

	toggleAnimationTimeouts() {
		setTimeout(()=>{this.toggleAnimation()} , 1000 )	
		setTimeout(()=>{this.toggleAnimation()} , 17900 )			
	}


}