import { Component , Input , Output , EventEmitter } from '@angular/core'


@Component({
	selector : 'share-modal' ,
	templateUrl : './shareModal.html'
})

export class ShareModalComponent {

	constructor() {}

	@Input() mediaObject : any
	@Input() showModal : boolean
	@Input() mediaObjectType : string
	@Output() toggleClose = new EventEmitter<boolean>()
	link : string
	shareText : 'Competition' | 'Entry' | 'Group'

	ngOnInit() {
		this.setSocialShareLinks()
	}

	setSocialShareLinks() {
		switch (this.mediaObjectType) {
			case "contestEntry": {
				this.link = 'competitionEntry/' + this.mediaObject._id
				this.shareText = 'Entry'
				break;
			}
			case "contest": {
				this.link = 'competition/' + this.mediaObject._id
				this.shareText = 'Competition'
				break;
			}
			case "group": {
				this.link = 'group/' + this.mediaObject.groupUrl 
				this.shareText = 'Group'
				break;
			}
		}
	}

	closeModal() {
		this.toggleClose.emit(false)
	}


}