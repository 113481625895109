<a 
	type="button" 
	style="
		color: white;
		font-weight: 800;
	" 
	[ngStyle]="{
		'color' : contestEntry.score > 0 ? 'white' : 'red' 
	}"
	(click)="openScoreEntryModal()"
><span>Score Entry {{ score }}</span></a>
<div id="scoreEntryModal{{contestEntry._id}}" class="cssModal">
	<div id="scoreEntryModalContent{{contestEntry._id}}" class="cssModalContent modal-content row">
	<button style="position: absolute ; top: 0px ; left : 0px;" type="button" class="btn btn-default" (click)="closeScoreEntryModal()">Close</button>
	<h3 style="color: white; text-align: center;">Score {{ contestEntry.contestantUsername }}'s Entry</h3>
	<div *ngIf="scoreCard && scoreCard.judgingCriteria">	
		<div class="col-xs-12" *ngFor="let criteria of scoreCard.judgingCriteria; let i = index" [attr.data-index]="i">
			<div style="margin: 0 auto ;">
				<span>{{ criteria.name }}: </span>
				<input *ngIf="entryScoreCard && entryScoreCard.judgingCriteria[i]"
					style="display: inline;" 	
					type="text" 
					placeholder="eg. 2400, 485, 2, 10" 
					numberOnly="true" 
					class="roundBorders darker-background input-styling" 
					[(ngModel)]="entryScoreCard.judgingCriteria[i].points" 
				/>
				<span> Out of {{ criteria.score }} Points</span>
			</div>
		</div>
	</div>
	<h3 style="color: white; text-align: center;">Provide {{ contestEntry.contestantUsername }} Feedback</h3>
		<textarea
			[(ngModel)]="commentText"
			placeholder="Type your feedback here..."
			style="
		    height: 100px;
		    padding: 0px;
		    border-radius: 4px;
				display: block;
			"
			class="col-xs-10 col-xs-offset-1 darker-background"
		></textarea>
		<div class="col-xs-12">	
			<button 
				style="
					
					display: block;
					margin: 10px auto;
					border-width: 0px;
				" 	
				type="button" 
	      class="white-text btn btn-info btn-lg purple-gray-background"
				(click)="submitScoring()"
			>
				submit
			</button>
		</div>
		<p style="color: white ; text-align: center;" *ngIf="err">{{ err }}</p>
	</div>
</div>