import { Component , OnInit , Input , Output , EventEmitter } from '@angular/core'

import { DataService } from '../../../core/index'

import { IContestEntryComment } from '../../index'

@Component({
	templateUrl : './newCommentButton.html' , 
	selector : 'new-comment-button'
})

export class NewCommentButtonComponent {

	@Input() commentText : string 
	@Input() contestEntryId : string

	@Output() madeNewComment : EventEmitter<IContestEntryComment>  = new EventEmitter()

	constructor( 
		private dataService : DataService
	) {}

	submitComment() {

		let URI = 'contestEntryComment/' ,
			postCommentBody = {
				contestEntryId : this.contestEntryId , 
				commentBody : this.commentText
			}

			console.log(postCommentBody)

		this.dataService
			.postObject( URI , postCommentBody ) 
			.subscribe( ( response : {
				error : boolean | string , 
				contestEntryComment : IContestEntryComment
			}) => {
				this.madeNewComment.emit( response.contestEntryComment )
			})
	}
}