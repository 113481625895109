import { Component , Input , OnInit } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser';

import { IWinner } from '../index'

@Component({
	selector : 'winner' ,
	styleUrls : [ './winner.css'] , 
	templateUrl : './winner.html'
})
export class WinnerComponent {

	@Input() winner : IWinner
	winnerPhoto : string
	style : any 

	constructor(
		private sanitizer : DomSanitizer  
	) {}

	ngOnInit() {
		console.log(this.winner)
		this.winnerPhoto = this.winner.winnerPhoto
		this.style = {'background-image': 'url(' + this.winnerPhoto + ')'}
		this.winner.body = this.sanitizer.bypassSecurityTrustHtml(this.winner.body)
	}
	
}	