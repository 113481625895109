import { Component , OnInit , Input } from '@angular/core'
import { ActivatedRoute , Router , NavigationEnd } from '@angular/router'

import { SessionService , DataService , UserDataService } from '../../core/index'
import { InternationalService } from '../../shared/international.service'
import { IContestEntry , IGroup , IContest , IFan } from '../../shared/interfaces'
declare var Fingerprint2 : any

@Component({
	selector: 'contest-entry-picture-gallery', 
	templateUrl: "./contestEntryPictureGallery.html", 
	styleUrls : ["./contestEntryPictureGallery.css"]
})

export class ContestEntryPictureGalleryComponent implements OnInit {

	group : IGroup
	fan : IFan
	contests : IContest[] = []
	contestEntry : IContestEntry
	selectedContestEntry : IContestEntry
	userInstance : any
	err : string
	page : number = 1
	totalPages : number
	searchValue : string
	score : number
	//view variables
	submittedContestWinnerToServer : boolean  = false
	errorSubmittingContestWinnerToServer : boolean = false
	isMyGroup : boolean = false
	currencySymbol : string = '$'
	winnerSelected : boolean
	pictureSubmissionType : boolean = false
	videoSubmissionType : boolean = false
	audioSubmissionType : boolean = false
	textSubmissionType : boolean = false
	showEnterContestButton : boolean = true
	showScoreContestEntryButton : boolean = false
	noFoundContestEntries : boolean = false
	fanLoaded : boolean = false
	session : any 

	votes : number = 0


	constructor(
		private route : ActivatedRoute ,  
		private router : Router , 
		private dataService : DataService , 
		private sessionService : SessionService , 
		private userDataService : UserDataService , 
		private internationalService : InternationalService
	) {}

	ngOnInit () {
	    this.route.data
	      .subscribe(( data : any ) => {
	      	let contestEntriesData = data.contestEntriesData
	      	console.log(contestEntriesData)
	      	this.group = contestEntriesData.group
	      	this.contests = contestEntriesData.contests
	      	if (this.contests[0].winnerSelected === true) this.winnerSelected = true
	      	this.contestEntry = contestEntriesData.contestEntry
	      	this.err = contestEntriesData.err
	      	this.totalPages = contestEntriesData.totalPages
	      	this.searchValue = contestEntriesData.usernameSearch
	      	if (contestEntriesData.isMyGroup) this.isMyGroup = contestEntriesData.isMyGroup
	      	this.getMyFan()
	      	this.pictureSubmissionType = contestEntriesData.pictureSubmissionType
	      	this.audioSubmissionType = contestEntriesData.audioSubmissionType
	      	this.videoSubmissionType = contestEntriesData.videoSubmissionType
	      	this.textSubmissionType = contestEntriesData.textSubmissionType
	      	this.showEnterContestButton = contestEntriesData.showEnterContestButton
    	})
	    this.router.events.subscribe((event) => {
      		if (event instanceof NavigationEnd) {
      			console.log(window.location.pathname.split('/').length)
				if (window.location.pathname.split('/').length === 5 ) {
					this.contestEntry = null
					console.log('wtf')
					this.totalPages = 2
					this.searchValue = null
				}
				else 
					this.searchContestEntries(this.searchValue)
			}
	    })
	    this.sessionService.session.subscribe(( session : any ) => {
	      	this.session = session
	      })
	}

	selectContestEntry( contestEntry : IContestEntry ) {
		this.selectedContestEntry = contestEntry
	}

	getGroup( groupUrl : string ) {
		let URI = 'group/url/' + groupUrl
    	this.dataService.getObject(URI)
	        .subscribe((response) => {
	          this.group = response.group;
			  this.getContest(this.route.snapshot.params['contestId'])
	        },
        (err: any) => console.log(err),
        () => console.log("group"));
	}

	getContest( contestId : string ) {
		let URI = 'contest/getContestById/' + contestId
    	this.dataService.getObject(URI)
	        .subscribe((response : any) => {
				var endOfContest = response.contest.endOfContest
				var dateAndTime = endOfContest.split('T')
				var date = dateAndTime[0].split('-')
				var year = date[0]
				var month = date[1]
				var day = date[2]
				if (day.charAt(0) === '0')
					day = day.charAt(1)

				var stringifiedMonth = ''
				switch (month) {
					case '01': {
						stringifiedMonth = 'Jan'
						break
					}
					case '02': {
						stringifiedMonth = 'Feb'
						break
					}
					case '03': {
						stringifiedMonth = 'Mar'
						break
					}
					case '04': {
						stringifiedMonth = 'Apr'
						break
					}
					case '05': {
						stringifiedMonth = 'May'
						break
					}
					case '06': {
						stringifiedMonth = 'Jun'
						break
					}
					case '07': {
						stringifiedMonth = 'Jul'
						break
					}
					case '08': {
						stringifiedMonth = 'Aug'
						break
					}
					case '09': {
						stringifiedMonth = 'Sep'
						break
					}
					case '10': {
						stringifiedMonth = 'Oct'
						break
					}
					case '11': {
						stringifiedMonth = 'Nov'
						break
					}
					case '12': {
						stringifiedMonth = 'Dec'
						break
					}
				}
			    response.contest.formattedEndOfContest = stringifiedMonth + ' ' + day + ' ' + year
	          this.contests[0] = response.contest
			  this.checkIfGroup(this.group)
	        },
        (err: any) => console.log(err),
        () => console.log("contests"));
	}

	getMyFan( ) {
		this.sessionService.session.subscribe( (session:any) => {
			this.userInstance = session
	      	if ((session === undefined) || (session.username === '') || (session.username === undefined)) 
				return 
		})
		this.userDataService.userData.subscribe( ( userData : any ) => {
			if (!userData.myFan)
				return
			this.fan = userData.myFans.filter(( fan : IFan ) => { return ( fan.kindOfFan === this.contests[0].kindOfFan && fan.groupId === this.group._id)})
		})
	}

	searchContestEntries( username : string ) {
		let URI = 'contestEntry/getContestEntryByContestantUsername/?username=' + username + '&contestId=' + this.contests[0]._id
    	this.dataService.getObject(URI)
	        .subscribe((response : any) => {
	        	if (response.contestEntry !== null) {
	        		this.noFoundContestEntries = false
		        	var contestEntries = [response.contestEntry]
		        	for(var i = 0; i < contestEntries.length ; i++ ) {
					  	contestEntries[i].reportedUserDetails = {}
					  	contestEntries[i].reportedUserDetails.userId = contestEntries[i].contestantId
					  	contestEntries[i].reportedUserDetails.objectId = contestEntries[i]._id
					  	contestEntries[i].reportedUserDetails.objectType = 'contestEntry'


						var created = contestEntries[i].created
						var dateAndTime = created.split('T')
						var time = dateAndTime[1].split(':')
						var date = dateAndTime[0].split('-')
						var month = date[1]
						var day = date[2]
						if (day.charAt(0) === '0')
							day = day.charAt(1)

						var hour = time[0]
						var minute = time[1]
						if (hour.charAt(0) === '0')
							hour = hour.charAt(1)
						var hourNumber = parseInt(hour)
						if (hourNumber > 12) {
							hourNumber = hourNumber - 12
							hour = hourNumber + ''
							var timeString = hour + ':' + minute + ' PM UT'
						} else {
							var timeString = hour + ':' + minute + ' AM UT'
						}
						var stringifiedMonth = ''
						switch (month) {
							case '01': {
								stringifiedMonth = 'Jan'
								break
							}
							case '02': {
								stringifiedMonth = 'Feb'
								break
							}
							case '03': {
								stringifiedMonth = 'Mar'
								break
							}
							case '04': {
								stringifiedMonth = 'Apr'
								break
							}
							case '05': {
								stringifiedMonth = 'May'
								break
							}
							case '06': {
								stringifiedMonth = 'Jun'
								break
							}
							case '07': {
								stringifiedMonth = 'Jul'
								break
							}
							case '08': {
								stringifiedMonth = 'Aug'
								break
							}
							case '09': {
								stringifiedMonth = 'Sep'
								break
							}
							case '10': {
								stringifiedMonth = 'Oct'
								break
							}
							case '11': {
								stringifiedMonth = 'Nov'
								break
							}
							case '12': {
								stringifiedMonth = 'Dec'
								break
							}
						}
					    contestEntries[i].formattedCreationDate = stringifiedMonth + ' ' + day + ' ' + timeString
		        	}
	          		this.contestEntry = contestEntries[0]
	        	} else {
	        		this.contestEntry = null
	        		this.noFoundContestEntries = true
	        	}
	        },
        (err: any) => console.log(err),
        () => console.log("contestEntries"));
	}

	setEnterContestButtonViewType( ended : boolean ) {
		if (ended === true) {
			return this.showEnterContestButton = false 
		} 
		return this.showEnterContestButton = true 
	}

	checkIfGroup( group : any ) {
		this.sessionService.session.subscribe( (session:any) => {	
	      if ((session !== undefined) && (session.username !== '') && (session.username === group.username)) {
	      	this.isMyGroup = true
			this.showEnterContestButton = false
		  } else {
			this.showScoreContestEntryButton = false
			this.setEnterContestButtonViewType(this.contests[0].ended)
		  }
		})
	}

	voteOnEntry( contestEntry : IContestEntry ) {
		this.votes++
		let fingerprint
		new Fingerprint2().get( ( result : any , components : any )=>{
			console.log('fingerprint' , result)
			fingerprint = result

			let URI = 'contestVote' , 
				contestVoteBody = {
					fingerprint : fingerprint , 
					groupId : this.contestEntry.groupId ,
					contestId : contestEntry.contestId , 
					contestEntryId : contestEntry._id ,
					contestName : contestEntry.contestName ,
					contestantUsername : contestEntry.contestantUsername , 
					groupName : contestEntry.groupName ,
					groupUrl : contestEntry.groupUrl
				}

	    	this.dataService.postObject(URI , contestVoteBody)
		        .subscribe((response : any) => {})
			
		})
	}

	scoreEntry( contestEntry : IContestEntry , score : number ) {
		var scoreBody = {
			groupUserId : this.contests[0].creatorId , 
			contestWinnerSelected : this.contests[0].winnerSelected , 
			score : score
		}
		let URI = 'contestEntry/scoreContestEntry/' + contestEntry._id
		this.dataService.postObject(URI, scoreBody)
	        .subscribe((response : any) => {
	        	if (response.error !== null) 
	        		return this.err = response.error
	        	this.contestEntry.score = score
			  	this.submittedContestWinnerToServer = true
	        },
        (err: any) => {
			this.submittedContestWinnerToServer = true
        	this.err = 'internal server error, please try again'
        },
        () => console.log("contests[0]"));
	}

	openScoreEntryModal() {
		document.getElementById('scoreEntryModal' + this.contestEntry._id).style.display = "block"
		document.getElementById('scoreEntryModalContent' + this.contestEntry._id).style.display = "block"
	}

	closeScoreEntryModal() {
		document.getElementById('scoreEntryModal' + this.contestEntry._id).style.display = "none"
		document.getElementById('scoreEntryModalContent' + this.contestEntry._id).style.display = "none"
	}

	closeVoteModal() {
		document.getElementById('voteModal').style.display = "none"
		document.getElementById('voteModalContent').style.display = "none"
	}
}