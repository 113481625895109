<div style="color : white ; ">
	<div class="col-xs-12 text-shadow" style="overflow: hidden; position: relative; margin: 0px ; padding: 0px ; background-image: url(/app/assets/images/spotidolHomeBanner.png) ; background-repeat: no-repeat; background-size: cover; background-position: center; display: flex;">
		<div style="text-align: center; margin: 0px ; padding: 0px ; background-image: url(/app/assets/images/spotidolHomeBanner.png) ; background-repeat: no-repeat; background-size: cover; background-position: center;">
			<p [hidden]="hideVideo" style="text-align: center; margin: 0px ; padding: 0px ;">
		 		 <video style="position: absolute; object-fit: cover; object-position: center; min-width: 120vw; min-height: 95vh ; position: absolute;" #hipHopVideoTarget id="hipHopVideoTarget" class="video-js vjs-fill" muted playsinline loop controls="false"></video>
			</p>
		</div>
		<div 
		class="col-xs-12 text-shadow" 
		style="
			min-height: 75vh; 
			overflow: overlay; 
			position: relative; 
			width: 100%;
			color : white ;
	    background-color: rgba(37,17,51 , 0.68);
	    margin: 0px;
	    text-align: center;
		">
			<div class="col-sm-6 col-xs-12">		
				<h1 class="hip-hop-title header-font">Enter Hip-Hop Competitions With the Biggest Artists. Create Hip-Hop Competitions for Free.</h1>
			</div>
			<div class="col-sm-6 col-xs-12">
				<upload-contest-entry-sign-up></upload-contest-entry-sign-up>
			</div>
		</div>
	</div>
	<div class="row">
    <div class="col-xs-12">
      <div class="col-md-offset-1 col-md-10 col-xs-12" style="margin-top: 5px;">
        <div class="col-centered">
          <contest-thumbnail *ngFor = "let contest of contests" [contest]="contest"></contest-thumbnail>
        </div>
      </div>
    </div>
	</div>
	
	<div 
		style="margin: 0px ; padding: 0px ; background-image: url(https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fhiphoppic.jpg?alt=media&token=5bc50196-a22d-4d35-a1db-9407bbf62d9b) ; background-repeat: no-repeat; background-size: cover; background-position: center; height: 75vh; width: 100vw;
		color : white;
		display: flex;
		" 
		class="row"
	>
	  <div
	    style="
	      text-shadow: 0 0 0.25em #000, 0 0 0.25em #000, 0 0 0.25em #000;
	      background-color: rgba(37,17,51 , 0.68);
	      width: 100%;
	      margin: 0px;
	    "
	  >  
	    <div class="col-xs-12" style="
		    transform: translateY(-30%);
		    top: 30%;
	    ">
	      <div
	        class="col-md-10 col-md-offset-1 col-sm-12"
	      >
					<div
				    class="col-xs-12 col-sm-6" 
					>
						<h2 
							style="
						    font-size: 32px;
					    "
					    class="header-font" 
				    >
				  		Live Your Dreams, Build Your Following, &amp; Profit With Hip-Hop Competitions.
				  	</h2>
				  	<p>Host rap battles, rap cyphers, beat contests, &amp; dance battles. Charge for entry &amp; voting to profit with your fans. Build your following with voters &amp; viewers. Create the moments people remember forever.</p>
				  	<p>Enter hip-hop competitions to win cash &amp; other prizes. Connect with artists &amp; winners. Live your dreams of working with top artists.</p>
					</div>
			    <div
				    class="col-xs-12 col-sm-6" 
				    style="text-align: center;"
			    >
				    <div class="col-xs-12 create-competition-section">
				    	<p
								style="
							    font-size: 26px;
						    "
						  >
						  	Want to host a competition but not sure how? Read our <a routerLink="/faq">FAQ</a>.
						  </p>
				      <button
				        class="btn-lg enter-competition-button " 
				        routerLink="/signUp"
				        style="
				          border-color: #6D507F;
				          border-style: solid;
				          border-width: 1px;
				          margin: auto auto;
				          display: block;
				        "
				      >
				        Create Competition
				      </button>
				    </div>
			    </div>
			  </div>
			</div>
		</div>
	</div>
	<div class="col-xs-12">
		<footer></footer>
	</div>
</div>