import { Component , OnInit , Input , Output, EventEmitter } from '@angular/core'
import { Router } from '@angular/router'
import { FormControl , FormGroup, Validators} from '@angular/forms'
import { IGroup , IKindOfFan } from '../../../shared/interfaces'

import { DataService , SessionService , UserDataService } from '../../../core/index'
import { InternationalService } from '../../../shared/international.service'

@Component({
	selector: 'create-group-create-contest', 
	templateUrl: "./createGroupCreateContest.html" , 
	styleUrls: [ "./createGroupCreateContest.css" ]
})

export class CreateGroupCreateContestComponent implements OnInit {

    submittedGroupToServer : boolean
    submittingGroupToServer : boolean
    successSubmittingGroupToServer : boolean
    errorSubmittingGroupToServer : boolean 
    err : string 
    showGroupLink : boolean
    optionalShown : boolean
    tagString : string
    tagsMustStartWithHash : boolean

    currencySymbol : string = '$'
    session : any

    showModal : boolean
    showShareModal : boolean
    showUploadModal : boolean
    
	group : IGroup
	kindsOfFans : IKindOfFan[] = []

	newKindOfFanFieldsState : boolean
	kindsOfFansState : boolean
	fanTitle : string 
	roleRequiresApproval : boolean = false 
	primaryKindOfFan : boolean
	is321DealActiveForKindOfFan : string | boolean
	priceToBecomeKindOfFan : number
	priceFor321DealForKindOFFan : number
	createGroupForm : FormGroup
	userData : any 

	@Output() createdGroup = new EventEmitter<IGroup>();

	constructor(
		private dataService : DataService , 
		private router : Router , 
		private internationalService : InternationalService , 
		private sessionService : SessionService , 
		private userDataService : UserDataService
	) {}

	ngOnInit () {

    this.userDataService.userData.subscribe( ( userData : any ) => {
      this.userData = userData
    })
		let groupName = new FormControl('' , [
			Validators.required , 
		 	Validators.maxLength(55)
		])
		let groupUrl = new FormControl('' , [
			Validators.required , 
		 	Validators.maxLength(55)
		])
		let priceToBecomeFan = new FormControl()
		let is321DealActive = new FormControl()
		let priceFor321Deal = new FormControl()
		let fanTitle = new FormControl()
		let primaryKindOfFan = new FormControl()
		let is321DealActiveForKindOfFan = new FormControl()
		let priceToBecomeKindOfFan = new FormControl()
		let priceFor321DealForKindOfFan = new FormControl()
		let fanRequiresApproval = new FormControl()
		this.createGroupForm = new FormGroup({
			groupName : groupName ,
			groupUrl : groupUrl ,
			priceToBecomeFan : priceToBecomeFan ,
			is321DealActive : is321DealActive ,
			priceFor321Deal : priceFor321Deal , 
			fanRequiresApproval : fanRequiresApproval , 
			fanTitle : fanTitle , 
			primaryKindOfFan : primaryKindOfFan , 
			is321DealActiveForKindOfFan : is321DealActiveForKindOfFan , 
			priceToBecomeKindOfFan : priceToBecomeKindOfFan , 
			priceFor321DealForKindOfFan : priceFor321DealForKindOfFan
		})
		this.sessionService.session.subscribe((session : any) => {
			this.session = session
			this.currencySymbol = this.internationalService.convertCurrencySymbol(session.country)
		})
	}

	toggleOptional() {
		this.optionalShown = this.optionalShown ? false : true
	}

	openModal() {
		this.showModal = true 
	}

	closeModal() {
		this.showModal = false 
	}

	showNewKindOfFanFields() {
		this.newKindOfFanFieldsState = true
	}

	addKindOfFan() {
		if (this.fanTitle === undefined) 
			this.fanTitle = 'player'
		if (this.is321DealActiveForKindOfFan === undefined || this.is321DealActiveForKindOfFan === "true") 
			this.is321DealActiveForKindOfFan = true
		else 
			this.is321DealActiveForKindOfFan = false
		if (this.priceToBecomeKindOfFan === undefined || this.priceToBecomeKindOfFan < 0) 
			this.priceToBecomeKindOfFan = 0
		if (this.priceFor321DealForKindOFFan === undefined || this.priceFor321DealForKindOFFan < 0) 
			this.priceFor321DealForKindOFFan = 0
		console.log(this.roleRequiresApproval)
		let kindOfFan = {
			fanTitle : this.fanTitle ,
			is321DealActiveForKindOfFan : this.is321DealActiveForKindOfFan ,
			created : new Date() , 
			fanRequiresApproval : this.roleRequiresApproval , 
			priceToBecomeKindOfFan : this.priceToBecomeKindOfFan * 1 || 0, 
			priceFor321DealForKindOFFan : this.priceFor321DealForKindOFFan || 0
		}
		console.log(kindOfFan)
		this.kindsOfFans.push(kindOfFan)
		this.fanTitle = undefined
		this.kindsOfFansState = true
		this.is321DealActiveForKindOfFan = undefined
		this.priceToBecomeKindOfFan = undefined
		this.priceFor321DealForKindOFFan = undefined
		this.newKindOfFanFieldsState = false
	}

	makeTagsArray (tagString : string) {
			this.tagsMustStartWithHash = false
		if (tagString && !tagString.match('[#].*')) 
			return this.tagsMustStartWithHash = true
		let tags = tagString.split(" #")
		for ( var i = 1 ; i < tags.length ; i++ ) {
			tags[i] = '#' + tags[i]
		}
		return tags
	}

	setRoleRequiresApproval() {
		this.roleRequiresApproval = !this.roleRequiresApproval
	}

	createGroup( formValues : any ) {
		if ( this.submittingGroupToServer ) 
			return
		for (let inner in this.createGroupForm.controls) {
            this.createGroupForm.get(inner).markAsTouched();
            this.createGroupForm.get(inner).updateValueAndValidity();
        }
		if (this.createGroupForm.valid){
			if (formValues.priceToBecomeFan > 0 && formValues.priceToBecomeFan < 0.5) {
              this.submittedGroupToServer = false
              this.errorSubmittingGroupToServer = true
              return this.err = 'the price to become a fan must be at least 50 cents'
			}
			if (formValues.priceFor321Deal > 0 && formValues.priceFor321Deal < 0.5) {
              this.submittedGroupToServer = false
              this.errorSubmittingGroupToServer = true
              return this.err = 'the price to get the 321Deal must be at least 50 cents'
			}
			if (this.tagString)
				formValues.tags = this.makeTagsArray(this.tagString)
			if (this.tagsMustStartWithHash === true) {
              this.submittedGroupToServer = false
              this.errorSubmittingGroupToServer = true
				return this.err = 'your tags must start with "#"'
			}
			for (var i = 0 ; i < this.kindsOfFans.length ; i++ ) {
				if (this.kindsOfFans[i].priceToBecomeKindOfFan > 0 && this.kindsOfFans[i].priceToBecomeKindOfFan < 0.5) {
	              this.submittedGroupToServer = false
	              this.errorSubmittingGroupToServer = true
	              return this.err = 'the price to become a fan must be at least 50 cents'
				}
				if (this.kindsOfFans[i].priceFor321DealForKindOfFan > 0 && this.kindsOfFans[i].priceFor321DealForKindOfFan < 0.5) {
	              this.submittedGroupToServer = false
	              this.errorSubmittingGroupToServer = true
	              return this.err = 'the price to get the 321Deal must be at least 50 cents'
				}
				this.kindsOfFans[i].priceToBecomeKindOfFan = this.kindsOfFans[i].priceToBecomeKindOfFan
				this.kindsOfFans[i].priceFor321DealForKindOfFan = this.kindsOfFans[i].priceFor321DealForKindOfFan
			}  
			formValues.kindsOfFans = this.kindsOfFans
			formValues.priceToBecomeFan = formValues.priceToBecomeFan
			formValues.priceFor321Deal = formValues.priceFor321Deal
			formValues.exchangeRate = this.session.exchangeRate
			formValues.currency = this.internationalService.getCurrencyCode(this.session.country)
			this.submittingGroupToServer = true
			let URI = 'group/create'
			this.dataService.postObject(URI, formValues)
				.subscribe((response) => {
					this.submittingGroupToServer = false
		            if (response.error !== null) {
		              this.submittedGroupToServer = false
		              this.errorSubmittingGroupToServer = true
		              return this.err = response.error
		            }
		            if (response.newGroup) {
      						this.group = response.newGroup
		              this.submittedGroupToServer = true
		              this.errorSubmittingGroupToServer = false
		              this.successSubmittingGroupToServer = true
		              this.showGroupLink = true
		              this.createGroupForm.reset()
		              let tempUserData
		              this.showUploadModal = true 
		              // this.showShareModal = true 
		            }
		          });
		}
	}

	onUploadFinished( uploadUri : string ) {
		this.group.profilePicture = uploadUri 
  	this.userData.createdGroups.push(this.group)
	  document.getElementById('uploadGroupModal').style.display = "none"
	  document.getElementById('uploadGroupModalContent').style.display = "none"
  	this.userDataService.renewUserData( this.userData )
  	this.createdGroup.emit( this.group )
	}

	skipUpload() {
  	this.userData.createdGroups.push(this.group)
	  document.getElementById('uploadGroupModal').style.display = "none"
	  document.getElementById('uploadGroupModalContent').style.display = "none"
  	this.userDataService.renewUserData( this.userData )
  	this.createdGroup.emit( this.group )
	}

	closedShareModal( showModal : boolean ) {
		this.showShareModal = false
	}

}