<div style="width: 100%; margin : 0; ">
	<h3 style="color: white;">What is the next dream you will experience?</h3>
	<textarea 
		style="
			width : 80%;
			height: 60px;
			color: black;
		"
		[(ngModel)]="description"
		placeholder="Enter your dreams" 
	>
	</textarea>
	<div 
		style="
			width: 80% ; 
			margin-top:  10px ;
		    display: block;
		    height: 40px;
		"
    >
		<button
		      type="button" 
		      class="white-text btn btn-info btn-lg purple-gray-background" 
		      style="
		        border-width: 0px;
		        display: block;
		        float: right;
		      "
			(click)="createDream()"
		>Create Dream</button>
	</div>
	<div style="width: 80% ; margin-top: 10px;">
		<dream *ngFor="let dream of newDreams" [dream]="dream"></dream>
	</div>
</div>