import { Component , OnInit , Input } from '@angular/core'

import { ActivatedRoute , Router }  from '@angular/router'

import { DataService , SessionService } from '../../core/index'

import { IContest } from '../../shared/interfaces'
import { InternationalService } from '../../shared/international.service'

@Component({
	selector: 'recentContests', 
	templateUrl: "./recentContests.html"
})

export class RecentContestsComponent implements OnInit { 

	contests : IContest[]
	topCashRewardContests : IContest[]
	openContests : IContest[]
	session : any
	page : number = 1
	pages : number
	pageLoaded : boolean = false
	currencySymbol : string = '$'
	firstTime : boolean
	stageOneOfOnboarding : boolean
	stageTwoOfOnboarding : boolean
	stageThreeOfOnboarding : boolean
	stageFourOfOnboarding : boolean

	constructor(
		private dataService : DataService , 
		private sessionService : SessionService , 
		private route : ActivatedRoute , 
		private internationalService : InternationalService ,  
		private router : Router
	) {}

	ngOnInit () {
		if (this.route.snapshot.params['firstTime'] === 'firstTime') {
			this.firstTime = true
			this.stageOneOfOnboarding = true
		}
	    this.route.data
	        .subscribe((data ) => {
				this.contests = data.recentContestsData.contests
				this.pages = data.recentContestsData.pages
				this.topCashRewardContests = data.recentContestsData.topCashRewardContests
				this.openContests = data.recentContestsData.openContests
				this.pageLoaded = true
			})

	}

	getMostRecentContests( page : number ) {
		let URI = 'contest/getRecentContests/' + page
    	this.dataService.getObject(URI)
	        .subscribe((response : any) => {
	          for (var i = 0 ; i < response.contests.length ; i++) {
				var endOfContest = response.contests[i].endOfContest
				var dateAndTime = endOfContest.split('T')
				var date = dateAndTime[0].split('-')
				var year = date[0]
				var month = date[1]
				var day = date[2]
				if (day.charAt(0) === '0')
					day = day.charAt(1)

				var stringifiedMonth = ''
				switch (month) {
					case '01': {
						stringifiedMonth = 'Jan'
						break
					}
					case '02': {
						stringifiedMonth = 'Feb'
						break
					}
					case '03': {
						stringifiedMonth = 'Mar'
						break
					}
					case '04': {
						stringifiedMonth = 'Apr'
						break
					}
					case '05': {
						stringifiedMonth = 'May'
						break
					}
					case '06': {
						stringifiedMonth = 'Jun'
						break
					}
					case '07': {
						stringifiedMonth = 'Jul'
						break
					}
					case '08': {
						stringifiedMonth = 'Aug'
						break
					}
					case '09': {
						stringifiedMonth = 'Sep'
						break
					}
					case '10': {
						stringifiedMonth = 'Oct'
						break
					}
					case '11': {
						stringifiedMonth = 'Nov'
						break
					}
					case '12': {
						stringifiedMonth = 'Dec'
						break
					}
				}
			    response.contests[i].formattedEndOfContest = stringifiedMonth + ' ' + day + ' ' + year
	          }
	        	
	        	if (this.page === 1 )
	          		this.contests = response.contests;
	          	else 
	          		this.contests.push(...response.contests)
	        },
        (err: any) => console.log(err),
        () => console.log("contests"));
	}

	getNextPageOfContests() {
		this.page ++
		if ( this.page > this.pages ) return
		this.getMostRecentContests(this.page)
	}

	goToNextStageOfOnboarding() {		
		if (this.stageThreeOfOnboarding === true) {
			this.stageThreeOfOnboarding = false
			this.stageFourOfOnboarding = true
			this.router.navigateByUrl('/contests/lastStep')
		}		
		if (this.stageTwoOfOnboarding === true) {
			this.stageTwoOfOnboarding = false
			this.stageThreeOfOnboarding = true
		}
		if (this.stageOneOfOnboarding === true) {
			this.stageOneOfOnboarding = false
			this.stageTwoOfOnboarding = true
		}
	}
}