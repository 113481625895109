import { Component , OnInit } from '@angular/core'

import { CreateContestSubjectService } from '../../index'

@Component({
	selector : 'create-contest-review' ,
	templateUrl : './review.html' ,
	styleUrls : ['./review.css']
})

export class CreateContestReviewComponent {

	createContestObject : any

	constructor( private createContestSubjectService : CreateContestSubjectService ) { }

	ngOnInit() {
		this.createContestSubjectService.createContestObject.subscribe( ( createContestObject ) => {
			this.createContestObject = createContestObject
			this.createContestObject.groupProfilePicture = this.createContestObject.group.profilePicture
		})
	}

}