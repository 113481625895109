<div class="roundBorders darker-background margin-between-objects extra-padding">
	<p>{{ supportMessage.created }}</p>
	<p>{{ supportMessage.body }}</p>
	<p>responded: {{ supportMessage.responded }}</p>
	<p>resolved: {{ supportMessage.resolved }}</p>
	<p>responses</p>
	<p *ngFor="let supportMessageResponse of supportMessage.responses"> {{  supportMessageResponse }} </p>
	<form *ngIf="!postedResponse" [formGroup]="supportResponseForm" novalidate 
	(ngSubmit)="respondToMessage(supportResponseForm.value)">
		<label class="col-xs-offset-1">reponse form</label>
		<br>
		<textarea class="response-form-height roundBorders lighter-background col-xs-10 col-xs-offset-1" formControlName="message"></textarea>
		<button type="submit" class="btn btn-info btn-lg block">respond to message</button>
	</form>
	<p *ngIf="postedResponse">The response has been sent to the user.</p>
	<p *ngIf="err">{{ err }}</p>
	<button *ngIf="!postedResponse" type="button" (click)="resolveMessage()">resolve message</button>
</div>