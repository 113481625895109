import { Component , OnInit , Input } from '@angular/core'
import { FormControl , FormGroup , Validators } from '@angular/forms'
import { DataService } from '../../core/data.service'
import { ISupportMessage } from '../../shared/interfaces'


@Component({
	selector: 'support-message', 
	templateUrl: "./supportMessage.html"
})

export class SupportMessageComponent implements OnInit {

	@Input() supportMessage : any
	supportResponseForm : FormGroup
	postedResponse : boolean
	err : string

	ngOnInit() {
		let message = new FormControl()
		this.supportResponseForm = new FormGroup({
			message : message 
		})
	}


	constructor( private dataService : DataService) {}

	respondToMessage( formValues : any ) {
		var URI = 'support/respondToMessage/' + this.supportMessage._id 
    	this.dataService.postObject(URI , formValues)
	        .subscribe((response : any) => {
	        	if (response.error)
	        		return this.err = response.error
	        	return this.postedResponse = true
			})
	}

	resolveMessage( ) {
		var URI = 'support/resolveMessage/' + this.supportMessage._id 
    	this.dataService.postObject(URI , '')
	        .subscribe((response : any) => {
	        	if (response.error)
	        		return this.err = response.error
	        	return this.postedResponse = true
			})
	}

}