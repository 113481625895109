<div style="color : white;">
	<h2 *ngIf="!chosenGroup"
		style="
			text-align: center; 
	    "
    >Choose a Group or Create one</h2>
    <div *ngIf="chosenGroup" style="display: block;">
		<h2>Chosen Group</h2>
		<div style="color : white ; text-align: center; background-color: gray; width : 300px ; margin: 10px ; border-radius: 10px; float: left; overflow: hidden; white-space: nowrap;">
			<h4>{{ chosenGroup.groupName }}</h4>
			<div
				[ngStyle]="{'background-image' : 'url(' + chosenGroup.profilePicture + ')' }"
				style="
					width : 300px ; 
					height : 300px ;
			    background-position: center;
			    background-repeat: no-repeat;
			    background-size: cover;

				"
			>
				<p style="font-size: 0px;">
					a
				</p>	
			</div>
		</div>
	    	
    </div>
    <div>
	    <create-group-create-contest (createdGroup)="chooseGroup($event)"></create-group-create-contest>
	    <a *ngFor="let group of groups" (click)="chooseGroup(group)">
	    	<choose-group-thumbnail [group]="group"></choose-group-thumbnail>
	    </a>
    </div>
<!-- 	<div
		style="
		    white-space: nowrap;
		    overflow: hidden;
		    height: 400px;
		    width : 100000px;
		    position: relative;
		    display: flex;
		    align-items: center;
		"
		[ngStyle]="{
			'left' : translateDistance
		}"
	>
		<a href="">	
			<div style="display: inline ; color : white ; text-align: center; background-color: gray; width : 300px ; margin: 10px ; border-radius: 10px; float: left; overflow: hidden; white-space: nowrap;">
				<h4>Create a Group</h4>
				<div 
					style="
						width : 300px ; 
						height : 300px ;
						display: flex;
						align-items: center;
						justify-content: center;
						background-color: black;
					"
				>
					<p style="font-size: 250px; margin: 0px; padding: 0px;">
						+
					</p>	
				</div>
			</div>
		</a>
		<a *ngFor="let group of groups" (click)="selectGroup( group )">
			<div style="display: inline ; color : white ; text-align: center; background-color: gray; width : 300px ; margin: 10px ; border-radius: 10px; float: left; overflow: hidden; white-space: nowrap;">
				<h4>{{ group.groupName }}</h4>
				<div
					[ngStyle]="{'background-image' : 'url(' + group.profilePicture + ')' }"
					style="
						width : 300px ; 
						height : 300px ;
				    background-position: center;
				    background-repeat: no-repeat;
				    background-size: cover;

					"
				>
					<p style="font-size: 0px;">
						a
					</p>	
				</div>
			</div>
		</a>
		<div style="width: 300px; text-align: center;">
			<p 
				*ngIf="noMoreRightGroups"
				class="no-groups-text" 
			>
				You don't have any other groups. Click here to create a group.
			</p>
		</div>
	</div>
	<div style="text-align: center;">
		<a 
			(click)="navigateToPreviousGroup()"
			*ngIf="!noMoreLeftGroups"
		>
			<img style="height: 60px; width : 60px; margin : 10px ; transform: rotate(180deg);" src="https://storage.googleapis.com/spotidol/forward-arrow-white.png">
		</a>
		<img *ngIf="noMoreLeftGroups" style="height: 60px; width : 60px; margin : 10px ; transform: rotate(180deg);" src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fforward-arrow-gray.png?alt=media&token=b729674a-7021-4ea2-a3ae-cfc7a8f94a39">
		<a 
			(click)="navigateToNextGroup()"
			*ngIf="!noMoreRightGroups"
		>
			<img style="height: 60px; width : 60px; margin : 10px ;" src="https://storage.googleapis.com/spotidol/forward-arrow-white.png">
		</a>
		<img *ngIf="noMoreRightGroups" style="height: 60px; width : 60px; margin : 10px ;" src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fforward-arrow-gray.png?alt=media&token=b729674a-7021-4ea2-a3ae-cfc7a8f94a39">
	</div> -->
</div>