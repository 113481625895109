import { Component , OnInit , Input } from '@angular/core'
import { Router } from '@angular/router'

import { SessionService } from '../../core/index'

@Component({
	templateUrl : "./logout.html"
}) 

export class LogoutComponent implements OnInit {
	constructor( private sessionService : SessionService ,
				 private router : Router ) {}

	loggedOut : boolean

	ngOnInit() {
		this.checkIfLoggedOut()
	}

	checkIfLoggedOut() {
		setTimeout(() => {
			this.router.navigateByUrl('/welcome')
		} , 1600 )
		this.sessionService.session.subscribe( (session:any) => {
			if (session.loggedIn !== true) {
				this.loggedOut = true
			} else {
				this.loggedOut = false
			}
		})
	}
}