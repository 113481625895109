export function setImageProps( screenWidth : number , url : string ) {
	let width : number, height : number, heightOrWidthIsGreater : string, maxWidth : number, img = new Image()
	maxWidth = ( screenWidth > 767 ) ? (screenWidth * 5 / 12 - 3) : (screenWidth * 11 / 12 - 3)
	img.onload = function( e ) {
		width = img.width
		height = img.height
		heightOrWidthIsGreater = (width > height) ? 'width' : 'height'
		if (heightOrWidthIsGreater === 'width') {
			img.height = maxWidth * height / width
			img.width = maxWidth
		} else {
			img.width = maxWidth * width / height
			img.height = maxWidth
		}
	}
	img.src = url
	img.id = url
	return img
}

export function setThumbnailImage( screenWidth : number , url : string ) {
	let width : number, height : number, heightOrWidthIsGreater : string, minWidth : number, minHeight : number , img = new Image()
	if ( screenWidth > 1000 ) {
		minWidth = 300
		minHeight = 200
	} else {	
		minWidth = screenWidth
		minHeight = screenWidth * 2 / 3
	}
	img.onload = function( e ) {
		width = img.width
		height = img.height
		heightOrWidthIsGreater = ( width > height ) ? 'width' : 'height'
		if (heightOrWidthIsGreater === 'width') {
				img.height = minWidth * height / width
				img.width = minWidth
				if (img.height < minHeight){
					img.height = minHeight
					img.width = img.height * width / height 
				}
		} else {
			img.width = minHeight * width / height
			img.height = minHeight
			if ( img.width < minWidth ) {
				img.width = minWidth
				img.height = img.width * height / width 
			}
		}
	}
	img.src = url
	img.id = url
	return img
}

export function setGroupThumbnailImage( screenWidth : number , url : string ) {
	let width : number, height : number, heightOrWidthIsGreater : string, minWidth : number, minHeight : number , img = new Image()
		minWidth = 120
		minHeight = 120
		img.onload = function( e ) {
		width = img.width
		height = img.height
		heightOrWidthIsGreater = ( width > height ) ? 'width' : 'height'
		if (heightOrWidthIsGreater === 'width') {
				img.height = minWidth * height / width
				img.width = minWidth
				if (img.height < minHeight){
					img.height = minHeight
					img.width = img.height * width / height 
				}
		} else {
			img.width = minHeight * width / height
			img.height = minHeight
			if ( img.width < minWidth ) {
				img.width = minWidth
				img.height = img.width * height / width 
			}
		}
	}
	img.src = url
	img.id = url
	return img
}