import { Component, Input, OnInit } from '@angular/core'
import { DataService, SessionService, UserDataService } from '../../core/index'
import { IContest, IUser } from '../../shared/interfaces'


@Component({
	selector : 'commenter' ,
	templateUrl : './commenter.html'
})

export class CommenterComponent implements OnInit {

	@Input() contest : IContest 
	@Input() user : IUser
	err : string
	commenters : [{
		username : string,
		profilePicture? : string
	}]
	username : string
	profilePicture : string
	isGroupAdmin : boolean = false
	addCommenterUsername : string

	constructor(
		private dataService : DataService ,
		private userDataService : UserDataService ,
		private sessionService : SessionService
	) {}

	ngOnInit() {
		this.sessionService.session
			.subscribe( (session : any) => {
				if (session.username && this.contest.groupAdmins.filter( (commenterObj) => { return commenterObj.username === session.username })[0] )
					this.isGroupAdmin = true
				for (var i = 0 ; i < this.contest.commenters.length; i++) {
					this.contest.commenters[i].profilePicture = this.contest.commenters[i].profilePicture ? this.contest.commenters[i].profilePicture : '/app/assets/images/idol-icon-white.png'
				}
				this.commenters = this.contest.commenters
			})
	}

	addCommenter(username : string) {
		let URI =  'contest/addCommenter'
		let body = {
			contestId : this.contest._id ,
			username : username
		}
		this.dataService.postObject(URI, body)
			.subscribe( (response : any) => {
				if (response.error) {
					return this.err = response.error
				}
				let tempUserData
				this.userDataService.userData
					.subscribe( (userData : any) => {
						tempUserData = userData
						tempUserData.contests = tempUserData.contests.filter( (contest : IContest) => {
							return (contest._id === this.contest._id)
						})
						tempUserData.contests.push(response.contest)
						this.userDataService.renewUserData(tempUserData)
					})
				this.err = null
				this.closeModal()
				let commenterDetails = {
					username : username ,
					profilePicture : '/app/assets/images/idol-icon-white.png'
				}
				this.commenters.push(commenterDetails)
				return this.contest.commenters.push(commenterDetails)
			})
	}


	//removeCommenter() {}


	closeModal() {
		document.getElementById('addCommenterModal').style.display = "none"
		document.getElementById('addCommenterModalContent').style.display = "none"
	}

	openModal() {
		document.getElementById('addCommenterModal').style.display = "block"
		document.getElementById('addCommenterModalContent').style.display = "block"
	}
}