<div>
	<div class="col-xs-12">	
		<h2 class="col-xs-offset-1">Groups I Created</h2>
		<div>	
	        <div class="col-md-offset-1 col-md-10">
	            <div class="recent-contests-margin hide-on-sm inline">
	                <groupThumbnail class="inline col-md-3 contest-thumbnail-height recent-contests-margin" [group]="group" *ngFor = "let group of groupsICreated"></groupThumbnail>
	            </div>
	            <div class="inline">
	                <groupThumbnail class="inline col-md-3 contest-thumbnail-height recent-contests-margin" [group]="group" *ngFor = "let group of groupsICreated"></groupThumbnail>
	            </div>
	        </div>
		</div>
	</div>
	<br />
	<div class="col-xs-12">	
		<h2 class="col-xs-offset-1">Groups I'm Following</h2>
		<div>
	        <div class="col-md-offset-1 col-md-10">
	            <div class="recent-contests-margin hide-on-sm inline">
	                <groupThumbnail class="inline col-md-3 contest-thumbnail-height recent-contests-margin" [group]="group" *ngFor = "let group of groupsImAFanOf"></groupThumbnail>
	            </div>
	            <div class="inline">
	                <groupThumbnail class="inline col-md-3 contest-thumbnail-height recent-contests-margin" [group]="group" *ngFor = "let group of groupsImAFanOf"></groupThumbnail>
	            </div>
	        </div>
	    </div>
	</div>
</div>