import { Component , Input , OnInit , ElementRef , ViewChild , AfterContentInit } from '@angular/core'

import { IGroup , IFan } from '../../../../shared/interfaces'
import { setGroupThumbnailImage } from '../../../../shared/functions/setImageProps.function'

import { DataService , SessionService , UserDataService } from '../../../../core/index'

@Component({
  selector: 'choose-group-thumbnail', 
  templateUrl: "./chooseGroupThumbnail.html" ,
  styleUrls: ["./chooseGroupThumbnail.css"]
})

export class ChooseGroupThumbnailComponent implements OnInit {

	@Input() group : IGroup

  @ViewChild("groupPicture") pictureElement : ElementRef
  imgElement : any

  groupThumbnailStyles

	myFan : IFan
	isMyGroup : boolean
	loadedFan : boolean

	constructor(
		private dataService : DataService , 
		private sessionService : SessionService , 
		private userDataService : UserDataService
	) {}

	ngOnInit() {
		if (!this.group)
			return    
		let pictureString = ((!!this.group.profilePicture) ? this.group.profilePicture : "https://storage.googleapis.com/spotidol/spotidolBannerPicture.png")
		this.groupThumbnailStyles = {
	      'background-image' : 'url("' + pictureString + '")'
	    }
	}

  ngAfterContentInit() {
    let entryString = ((!!this.group.profilePicture) ? this.group.profilePicture : "https://storage.googleapis.com/spotidol/spotidolBannerPicture.png")
    this.imgElement = setGroupThumbnailImage( window.innerWidth , entryString )
    this.imgElement.className = "center-pic"
    console.log( this.pictureElement )

    this.pictureElement.nativeElement.append(this.imgElement)

  }

	checkIfGroupOrFan() {
		this.getMyFan( this.group.groupUrl )
		this.sessionService.session.subscribe(( session : any ) => {
			this.checkIfGroup(session)
		})
	}

	getMyFan( url : string ) {
	    this.userDataService.userData
	    .subscribe( ( userData : any ) => {
	    if (userData.myFans !== null)
		    this.myFan = userData.myFans.filter( ( fan : IFan ) => {return (fan.groupUrl === url && fan.kindOfFan === this.group.defaultKindOfFan)})[0]
	    this.loadedFan = true
	    })
	}

	checkIfGroup(userInstance : any ) {
		if (!this.group)
			return
	    if (userInstance.username === this.group.username) {
	    	this.isMyGroup = true
	    }
	}
}