<!-- make height=1/2 view width -->
<div class="featured-contest-container">
	<!-- make 50% and push over 50% on desktop, 100% on phones-->
	<div class="featured-contest-background">
		<br>
	</div>
	<img [@fade]="isDisplayed ? 'fadeIn' : 'fadeOut'" class="featured-contest-group-picture" src="{{ contest?.groupProfilePicture }}" />
	<!-- make black opacity & 50% -->
	<div class="featured-contest-information-section">
		<div [@fade]="isDisplayed ? 'fadeIn' : 'fadeOut'" class="competition-title col-xs-12">
			<h2>Featured Competition</h2>
			<h3>{{ contest.contestName }}</h3>
			<a routerLink="/group/{{ contest.groupUrl }}"><p class="white-text">@{{ contest.groupUrl }}</p></a>
			<p>{{ contest.description }}</p>
			<featured-contest-rewards [contest]="contest"></featured-contest-rewards>
			<button class="block enter-competition-button btn-lg" routerLink="/competition/{{ contest._id }}">Enter Competition</button>
		</div>
	</div>
</div>