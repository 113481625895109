import { Component } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { IContest , IContestEntry , IFan , IGroup } from '../shared/interfaces'

@Component({
	templateUrl : './profile.html' , 
	styleUrls : ['./profile.css']
})

export class ProfileComponent {
	
	competitions : IContest[] = []
	competitionEntries : IContestEntry[] = [] 
	fans : IFan[] = []
	groups : IGroup[] = []
	username : string 
	isUser : boolean 
	userData : any
	profilePicture : string 

	constructor(
		private route : ActivatedRoute
	) {}

	ngOnInit() {
	    this.route.data
	        .subscribe((data ) => {
	        	this.competitions = data.profileData.competitions
	        	this.competitionEntries = data.profileData.competitionEntries
	        	this.fans = data.profileData.fans
	        	this.groups = data.profileData.groups
	        	this.isUser = data.profileData.isUser
	        	this.username = data.profileData.username
	        	this.userData = data.profileData.userData

						this.profilePicture = this.userData.profilePicture ? 
							'url(' + this.userData.profilePicture + ')'
						: 
							'url(/app/assets/images/spotidolBannerPicture.png)'
	        })
	}
}