import { Component } from '@angular/core'

@Component({
	templateUrl : './about.html' , 
	styleUrls : [ './about.css']
})

export class AboutComponent {

	constructor(){}

}