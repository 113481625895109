<div style="color: white ;">
    <button type="button" class="btn btn-default" (click)="closeOnboarding()" style="position: absolute; top: 0px; left: 0px;">Close</button>
	<div>
		<div *ngIf="page === 'menu' ">
			<h2 style="text-align: center;">Tutorial</h2>
			<p style="text-align: center;">Welcome To Spotidol! On it, you can do all of these things! What would you like to do? (Click below to learn more)</p>
			<div class="col-sx-12 col-sm-6 onboarding-button-container">
				<button class="onboarding-button">
					<p class="onboarding-text" (click)="goToCreateCompetitions()">
						Create Competitions
					</p>
				</button>
			</div>
			<div class="col-sx-12 col-sm-6 onboarding-button-container">
				<button class="onboarding-button">
					<p class="onboarding-text" (click)="goToEnterCompetitions()">
						Enter Competitions
					</p>
				</button>
			</div>
			<div class="col-sx-12 col-sm-6 onboarding-button-container">
				<button class="onboarding-button">
					<p class="onboarding-text" (click)="goToShareDreams()">
						Share My Dreams
					</p>
				</button>
			</div>
			<div class="col-sx-12 col-sm-6 onboarding-button-container">
				<button class="onboarding-button">
					<p class="onboarding-text" (click)="goToJoinOtherDreams()">
						Join Other's Dreams
					</p>
				</button>
			</div>
			<div class="col-xs-12 close-button-container" (click)="closeOnboarding()">
				<button class="close-button">
					<p class="close-text">
						Close this Tutorial (You can find it in the menu)
					</p>
				</button>
			</div>
		</div>
		<div *ngIf="page === 'createCompetitions'">
			<a (click)="goToMenu()"><h3>Back</h3></a>
			<p style="margin-top: 30px;">1. Click on the “create” button on the top left side of the screen.</p>
			<img style="width : 100%; height: auto;" src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2FcreateCompetitionDemo%2Fsite%2FcreateCompetition1.png?alt=media&token=eefe90f3-b3b8-43c0-95ee-88e05181c480&_gl=1*c61tvt*_ga*MzY1ODIwMzE1LjE2ODU0ODQ0MDA.*_ga_CW55HF8NVT*MTY4NTg5NjI5Ni4zLjEuMTY4NTg5NzA1NS4wLjAuMA..">
			<p style="margin-top: 30px;">2. In the drop-down menu, select “create competition”.</p>
			<img style="width : 100%; height: auto;" src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2FcreateCompetitionDemo%2Fsite%2FcreateCompetition2.png?alt=media&token=7eb4fe83-b8c3-49a5-ab4f-aaf06c0fbb9f&_gl=1*mpqe12*_ga*MzY1ODIwMzE1LjE2ODU0ODQ0MDA.*_ga_CW55HF8NVT*MTY4NTg5OTMyNC40LjEuMTY4NTg5OTY4MS4wLjAuMA..">
			<p style="margin-top: 30px;">3. Groups allow you to contact players &amp; keep track of their progress over time. By having a group, past players get alerts to new competitions you create. Click on an image to select a group. You can also choose to have no group. Your competition will still be visible on your profile page.</p>
			<img style="width : 100%; height: auto;" src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2FcreateCompetitionDemo%2Fsite%2FcreateCompetition3.png?alt=media&token=03167652-8207-4a94-8cd4-b44caec3425a&_gl=1*d2e7hp*_ga*MzY1ODIwMzE1LjE2ODU0ODQ0MDA.*_ga_CW55HF8NVT*MTY4NTg5NjI5Ni4zLjEuMTY4NTg5NzEwNy4wLjAuMA..">
			<p style="margin-top: 30px;">4. Click on the details button on the left panel.</p>
			<img style="width : 100%; height: auto;" src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2FcreateCompetitionDemo%2Fsite%2FcreateCompetition4.png?alt=media&token=e03e6dad-e5c6-455c-afa9-13944991e599&_gl=1*2dy3br*_ga*MzY1ODIwMzE1LjE2ODU0ODQ0MDA.*_ga_CW55HF8NVT*MTY4NTg5OTMyNC40LjEuMTY4NTg5OTM1My4wLjAuMA..">
			<p style="margin-top: 30px;">5. You can then provide a name, description, hashtags, &amp;  privacy settings for your competition. Each competition has entry, voting, judging, &amp; prizes. Private makes the competition only viewable if the user has the private link to the competition. Once done, click "submit".</p>
			<img style="width : 100%; height: auto;" src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2FcreateCompetitionDemo%2Fsite%2FcreateCompetition5.png?alt=media&token=efc2633b-49a2-4c06-8290-e233f324920e&_gl=1*1mekqs1*_ga*MzY1ODIwMzE1LjE2ODU0ODQ0MDA.*_ga_CW55HF8NVT*MTY4NTg5NjI5Ni4zLjEuMTY4NTg5NzE4OC4wLjAuMA..">
			<p style="margin-top: 30px;">6. Click on the entry button on the left panel. </p>
			<img style="width : 100%; height: auto;" src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2FcreateCompetitionDemo%2Fsite%2FcreateCompetition7.png?alt=media&token=25d11b7a-96ad-4c29-bbc1-a141c7cbe9c7&_gl=1*614kb1*_ga*MzY1ODIwMzE1LjE2ODU0ODQ0MDA.*_ga_CW55HF8NVT*MTY4NTg5OTMyNC40LjEuMTY4NTg5OTM4MS4wLjAuMA..">
			<p style="margin-top: 30px;">7. You can then provide the entry type (picture, text, audio, video, pdf, or code). You can then set an entry price, entry privacy (private is where entries are only viewable by judges), entry price, whether there is a pot system for the prize, when the entry ends, &amp; when the winner is announced. Once done, click "submit".</p>
			<img style="width : 100%; height: auto;" src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2FcreateCompetitionDemo%2Fsite%2FcreateCompetition8.png?alt=media&token=5c81ce32-4ead-4002-afa2-8fd593196a8c&_gl=1*1mtmiws*_ga*MzY1ODIwMzE1LjE2ODU0ODQ0MDA.*_ga_CW55HF8NVT*MTY4NTg5NjI5Ni4zLjEuMTY4NTg5NzI0Mi4wLjAuMA..">
			<p style="margin-top: 30px;">8. Click on the voting button on the left panel.</p>
			<img style="width : 100%; height: auto;" src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2FcreateCompetitionDemo%2Fsite%2FcreateCompetition10.png?alt=media&token=417b1b21-0118-4c88-a239-4c4e7457486e&_gl=1*14x3nn5*_ga*MzY1ODIwMzE1LjE2ODU0ODQ0MDA.*_ga_CW55HF8NVT*MTY4NTg5OTMyNC40LjEuMTY4NTg5OTQwMi4wLjAuMA..">
			<p style="margin-top: 30px;">9. Select the voting type: crowd choice, votes go to score (votes are added to the judge's given score on each entry), or tie breaker. Select if you'd like to charge for voting (ex. $1 per vote). Select the number of votes allowed to each person. Once done, click "submit".</p>
			<img style="width : 100%; height: auto;" src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2FcreateCompetitionDemo%2Fsite%2FcreateCompetition11.png?alt=media&token=2dd1adb6-fee7-4d41-99dc-37fd5ae103ef&_gl=1*1r37k82*_ga*MzY1ODIwMzE1LjE2ODU0ODQ0MDA.*_ga_CW55HF8NVT*MTY4NTg5NjI5Ni4zLjEuMTY4NTg5NzMyMC4wLjAuMA..">
			<p style="margin-top: 30px;">10. Click on the judging button on the left panel.</p>
			<img style="width : 100%; height: auto;" src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2FcreateCompetitionDemo%2Fsite%2FcreateCompetition13.png?alt=media&token=0321b973-9a57-45ce-85b3-554522c292a7&_gl=1*1jbuyve*_ga*MzY1ODIwMzE1LjE2ODU0ODQ0MDA.*_ga_CW55HF8NVT*MTY4NTg5OTMyNC40LjEuMTY4NTg5OTQyMy4wLjAuMA..">
			<p style="margin-top: 30px;">11. Select the score privacy, where only the entrant, the competition judges, &amp; group admin can see the scores given to each entry. Write in the judging criteria and the amount of points it’s worth, then click the “add judging criteria” button in green to add the criteria. Once done, click "submit".</p>
			<img style="width : 100%; height: auto;" src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2FcreateCompetitionDemo%2Fsite%2FcreateCompetition14.png?alt=media&token=8bd74003-61e1-446d-8d35-cdd801c90be3&_gl=1*1tockiy*_ga*MzY1ODIwMzE1LjE2ODU0ODQ0MDA.*_ga_CW55HF8NVT*MTY4NTg5NjI5Ni4zLjEuMTY4NTg5NzM3OC4wLjAuMA..">
			<p style="margin-top: 30px;">12. Click on the prizes button on the left panel.</p>
			<img style="width : 100%; height: auto;" src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2FcreateCompetitionDemo%2Fsite%2FcreateCompetition16.png?alt=media&token=68cfc524-5973-4bba-91d7-f4cb4f49ba01&_gl=1*1alyova*_ga*MzY1ODIwMzE1LjE2ODU0ODQ0MDA.*_ga_CW55HF8NVT*MTY4NTg5OTMyNC40LjEuMTY4NTg5OTQ0NC4wLjAuMA..">
			<p style="margin-top: 30px;">13. Select if you want to add a cash prize or other non-cash prizes. To ensure cash prizes are disbursed, competition creators provide the cash prize at the creation of the competition. Once done, click "submit".</p>
			<img style="width : 100%; height: auto;" src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2FcreateCompetitionDemo%2Fsite%2FcreateCompetition17.png?alt=media&token=08c0dad0-0612-4c8a-8728-889d4730983f&_gl=1*1uqehqu*_ga*MzY1ODIwMzE1LjE2ODU0ODQ0MDA.*_ga_CW55HF8NVT*MTY4NTg5NjI5Ni4zLjEuMTY4NTg5NzQyMS4wLjAuMA..">
			<p style="margin-top: 30px;">14. Review your competition with the "Review" button on the left panel. Once done, create click "Create" to create the competition!</p>
			<img style="width : 100%; height: auto;" src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2FcreateCompetitionDemo%2Fsite%2FcreateCompetition19.png?alt=media&token=d86c1deb-7ef5-4081-ab9c-ed197ffc8165&_gl=1*1d0v69n*_ga*MzY1ODIwMzE1LjE2ODU0ODQ0MDA.*_ga_CW55HF8NVT*MTY4NTg5OTMyNC40LjEuMTY4NTg5OTQ1Ny4wLjAuMA..">
			<div class="col-xs-12 close-button-container" (click)="goToMenu()">
				<button class="close-button">
					<p class="close-text">
						Back to Tutorials
					</p>
				</button>
			</div>
		</div>
		<div *ngIf="page === 'enterCompetitions'">
			<a (click)="goToMenu()"><h3>Back</h3></a>
			<p style="margin-top: 30px;">1. Select a competition you want to join! You can select a competition from the home page or search!</p>
			<img style="width : 100%; height: auto;" src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2FenterCompetitions1.JPG?alt=media&token=a03ffb63-58e8-4cc2-b95b-2b5eba81b1c4">
			<img style="width : 100%; height: auto;" src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2FenterCompetitions2.JPG?alt=media&token=8d5a10f0-e6c5-4487-9b85-9bcbf9835fb7">
			<p style="margin-top: 30px;">2. Once at the competition page, review the details for the competition. The entry button is below the details.</p>
			<img style="width : 100%; height: auto;" src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2FenterCompetitions3.JPG?alt=media&token=c8f2bc3f-72b6-4d48-a97a-d6db1a6d6df0">
			<p style="margin-top: 30px;">3. Click the "Enter" button &amp; select a file for upload or enter your text entry if it's a text competition.</p>
			<img style="width : 100%; height: auto;" src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2FenterCompetitions4.JPG?alt=media&token=d766b0fa-8c5f-426b-bf28-9e588f9d4cec">
			<div class="col-xs-12 close-button-container" (click)="goToMenu()">
				<button class="close-button">
					<p class="close-text">
						Back to Tutorials
					</p>
				</button>
			</div>
		</div>
		<div *ngIf="page === 'createDreams'">
			<a (click)="goToMenu()"><h3>Back</h3></a>
			<p style="margin-top: 30px;">1. Navigate to your profile by clicking "profile"!</p>
			<img style="width : 100%; height: auto;" src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2FcreateDreams1.JPG?alt=media&token=92246cc1-bc83-43ef-a70e-90af8cec5a94">
			<p style="margin-top: 30px;">2. Once on your profile, input the next dream you want to experience.</p>
			<img style="width : 100%; height: auto;" src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2FcreateDreams2.JPG?alt=media&token=82040747-8c2d-4a1a-a1a9-156f2fc3ca9e">
			<div class="col-xs-12 close-button-container" (click)="goToMenu()">
				<button class="close-button">
					<p class="close-text">
						Back to Tutorials
					</p>
				</button>
			</div>
		</div>
		<div *ngIf="page === 'joinDreams'">
			<a (click)="goToMenu()"><h3>Back</h3></a>
			<p style="margin-top: 30px;">1. Search for a profile for a person who's dreams you want to join!</p>
			<img style="width : 100%; height: auto;" src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2FcreateDreams1.JPG?alt=media&token=92246cc1-bc83-43ef-a70e-90af8cec5a94">
			<p style="margin-top: 30px;">2. Once on their profile, click "join dream" for the dream you want to join.</p>
			<img style="width : 100%; height: auto;" src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2FcreateDreams2.JPG?alt=media&token=82040747-8c2d-4a1a-a1a9-156f2fc3ca9e">
			<div class="col-xs-12 close-button-container" (click)="goToMenu()">
				<button class="close-button">
					<p class="close-text">
						Back to Tutorials
					</p>
				</button>
			</div>
		</div>
	</div>
</div>