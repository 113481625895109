import { Component , OnInit } from '@angular/core'

import { IContestEntry } from '../../shared/interfaces'
import { DataService } from '../../core/index'

@Component({
	selector : 'featured-contest-entry', 
	templateUrl : './FeaturedContestEntry.html', 
	styleUrls : ['./featuredContestEntry.css']
})

export class FeaturedContestEntryComponent {

	error : string
	contestEntry : IContestEntry | any = {}
	featuredContestEntryContainerStyles : {
		'background-image' : string
	}

	constructor(
		private dataService : DataService
	){}

	ngOnInit() {
		this.getFeaturedContestEntry()
	}

	getFeaturedContestEntry(){
		let URI = 'contestEntry/getFeaturedContestEntry'
		this.dataService.getObject( URI )
			.subscribe( ( response : {
				status : boolean 
				error : string | null 
				contestEntry : IContestEntry | undefined
			}) => {
				if( response.error ) {
					this.error = response.error
					return console.log( 'getFeaturedContestEntry' , this.error )
				}
				this.contestEntry = response.contestEntry
				this.featuredContestEntryContainerStyles = {
					'background-image' : 'url("' + this.contestEntry.entryAttachment + '")'
				}
				return console.log( 'getFeaturedContestEntry' , this.contestEntry )
			})
	}

}