<div style="height: 30vh; max-height: 900px;" >
	<div 
		style="
			width: 100% ; 
			height: 100% ; 
	    background-repeat: no-repeat;
	    background-size: cover;
	    background-position: center center;
	    padding: 0px;
		" 
		class="col-xs-12 text-shadow "
		[ngStyle]="{ 'background-image' : competitionImage }"
	>
		<div
			style="
				background: linear-gradient(rgba(0 , 0 , 0 , 0.5), rgba( 0 , 0 ,0 ,0));
				width: 100%; 
				min-height: 70px;
				position: absolute;
				top: 0px;
				left: 0px;
			"
		>
			<div style="margin-left: 5px; margin-top: 3px ; z-index: 2;">
				<h3 routerLink="/competition/{{ contest?._id }}" style="
					margin : 0px;
					margin-right: 40px;
			    color : white ;
				">
					{{ contest?.contestName }}
				</h3>
				<h4 routerLink="/group/{{ contest?.groupUrl }}" *ngIf="contest.groupUrl" style="color: white ; font-size: 14px ; margin: 0px;">@{{ contest?.groupUrl }}</h4>
			</div>
		</div>
		<div
			style="
				background: linear-gradient(rgba( 0 , 0 ,0 ,0) , rgba(0 , 0 , 0 , 0.5) );
				width: 100%; 
				min-height: 70px;
				
				position: absolute;
				bottom: 0px;
				left: 0px;
			"
		>
			<div style="
				position: absolute;
				bottom: 0px;
				width: 100%;
			">
				<div
					style="
					  z-index: 2;
					  margin: 0px auto;
					  text-align: center;
					"
				>
					<h2 style="margin: 0px ; padding: 0px ; width: 100% ; color: red; text-align: center; font-weight: 1000; letter-spacing: 0.03em;">
		 				<span style="font-size: 24px ; font-family: digital-7, sans-serif;">
							{{ countdownText }} 
						</span>
						<countdown *ngIf="countdownStart" [startTime]="countdownStart"></countdown>
					</h2>
				</div>
			</div>
		</div>
	</div>
</div>