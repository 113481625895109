	<div 
		class="col-xs-12 featured-contest-entry-container"
		style="color : white; display: flex; padding: 0px;" 
		[ngStyle]="featuredContestEntryContainerStyles"
	>
  <div
    style="
      text-shadow: 0 0 0.25em #000, 0 0 0.25em #000, 0 0 0.25em #000;
	    background-color: rgba(37,17,51 , 0.68);
	    width: 100%;
	    margin: 0px;
    "
  >
  	<div 
		class="col-xs-12"
		style="
		    transform: translateY(-30%);
		    top: 30%;
		" 
	>
	    <div class="col-xs-12">
	      <div
	        class="col-md-10 col-md-offset-1 col-sm-12"
	      >
	        <h2 class="jumbo-text header-font" style="color: white;">We are on a mission</h2>
	      </div>
	    </div>
	    <div class="col-xs-12">
	      <div
	        class="col-md-10 col-md-offset-1 col-sm-12"
	      >
	        <div class="col-sm-6 col-xs-12">
	        	<div
					class="home-description-text"
				>	
		          <h2
		            style="
		              color: white;
		            "
		          >
		            To help everyone live the life of their dreams
		          </h2>
		          <p
		            style="
		              color: white;
		            "
		          >
		            Unforgettable moments arise that positively change our lives forever. Moments that define who we are. Our mission is to make these moments happen for everyone.
		          </p>
	        	</div>
	        </div>
	        <div class="col-sm-6 col-xs-12">
	          <div 
	            class="col-xs-10 col-xs-offset-1"
	          > 
	            <div 
	              class="col-sm-12"
	            > 
	              <div
	                style="
	                  max-width: 560px;
	                  padding: 10px;
	                  margin: 5px auto;
	                  min-height: 140px;
	                "
	              >
	                <img src="/app/assets/images/sheila.jpg"
	                  style="
	                    width: 120px;
	                    height: 120px;
	                    border-radius: 60px;
	                  float: left;
	                  margin-right: 10px;
	                  " 
	                />
	                <span style="
	                top: 3px;
	                position: relative;
	                color: white;
	                ">
	                "All my life, I've dreamed about getting my music in America. They all say it's impossible to achieve so I should forget it. Now look where I'm at."
	                <br />
	                <a routerLink="/profile/sheila">Sheila</a> - Ghana
	              </span>
	              </div>
	            </div>

	          </div>
	        </div>
	      </div>
	    </div>
	    <div class="col-xs-12">
	      <div
	        class="col-md-10 col-md-offset-1 col-sm-12"
	      >
	        <div class="col-sm-6 col-xs-12">
	        	<div
		            class="home-description-text"
	        	>
		          <h2 style="color: white;">
		            To share &amp; join dreams with others
		          </h2>
		          <p style="color: white;">
		            The greatest relationships are forged around a common purpose. Relationships that form communities &amp; shape our civilzation. On Spotidol, you can share &amp; join others to live your dreams together.
		          </p>
		          <a routerLink="/about"><p style="color: white; font-weight: 700;">Learn more about Spotidol.</p></a>
	        	</div>
	        </div>
	        <div class="col-sm-6 col-xs-12">
	          <div 
	            class="col-xs-10 col-xs-offset-1"
	          > 
	            <div 
	              class="col-sm-12"
	            > 
	              <div
	                style="
	                  max-width: 560px;
	                  padding: 10px;
	                  margin: 5px auto;
	                  min-height: 140px;
	                "
	              >
	                <img src="https://storage.googleapis.com/spotidol-169915.appspot.com/userUploads%2Fkim%20yonjan%2FNEq5VXUae.PNG?GoogleAccessId=raja-92%40spotidol-169915.iam.gserviceaccount.com&Expires=4673203200&Signature=feB62kQ6YD82bftLN5M5uGZ3DOH2CKd61qf95mLTDFoLoSAN1MmoiYlPy8IdKSeNdhr%2FQJRZuCWWPYm4vRl%2BJo%2FGMgLU6c4%2FQbkoxPVUmucenjKUxv%2BhlIWHbxaTet31vG5SyzKVxYOCVrotbURQY%2FxydEP6kl8IYr75N%2FXKOZBHbIrHnx0H29WIiTuk6hBp7ZtBfSYGM96LtjwnQg8bNuqXQfsbSEH4HylKWCGedA3OSZ1MrXmcZ2KeKCr%2FYrPDIyE9WXDxKgXNWDVaXx2nuHJDRB1qPJZf9RyqYUSdr9VBKKbvQguFrcmkM1NaHZfjKsWkLwYrUDqxQHyQ7I5vEA%3D%3D"
	                  style="
	                    width: 120px;
	                    height: 120px;
	                    border-radius: 60px;
	                  float: left;
	                  margin-right: 10px;
	                  " 
	                />
	                <span style="color : white;">
	                  <p>"My dream is to be an idol with other girls"
	                  <br /><a routerLink="/profile/kim yonjan" ><span>Kim Yonjan</span></a>
	                  <span> - Lebanon</span>
	                  </p>
	                </span>
	                <div >
	                  <button
	                  	routerLink="/profile/kim yonjan"
	                    class="enter-competition-button btn-lg" 
	                    style="
	                      border-color: #6D507F;
	                      border-style: solid;
	                      border-width: 1px;
	                      margin: auto auto;
	                      display: block;
	                    "
	                  >
	                    Join Kim's Dream
	                  </button>
	                </div>
	              </div>
	            </div>
	          </div>
	        </div>
	      </div>
	    </div>
  	</div>
  </div>
	</div>