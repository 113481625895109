<div class="inline no-margins no-padding">		
	<div class="dropdown inline no-margins no-padding navbar-height">
	    <button (click)="toggleMenu()" class="inline no-margins menu-button btn btn-lg menu-height navbar-button" style="padding: 10px; border: none; background: none;">
				<div>
					<img class="inline navbarMenu no-margins no-padding" height="30" width="30" src="https://storage.googleapis.com/spotidol/menu-white.png" />
					<h4 class="navbar-icon-text">Menu</h4>
				</div>
    	</button>
	    <ul 
	    	style="
			    position: absolute;
			    top: 40px;
			    text-align: center;
			    left: -10px;
			    padding: 0px;
				background-color: #AEB7D0 ;
				border: solid 1px gray;
				border-top: none;
	    	"
	    	*ngIf="showMenu"
	    >
        <span 
        	class="dark-purple-2 menu-text" 
        	style="
	        	color: white;
	        	padding: 3px; 
	        	display: block;
				
				text-align: right;
				line-height: 1;
			"
		>
			<a (click)="toggleMenu()" style="color: white; padding : 2px; font-size: 12px;" routerLink="/updateUser">Update User</a>
		</span>
        <span 
        	class="dark-purple-2 menu-text" 
        	style="
        		color: white;
        		padding: 3px; 
        		display: block;
				
				text-align: right;
				line-height: 1;
			"
		>
			<a (click)="toggleMenu()" style="color: white; padding : 2px; font-size: 12px;" routerLink="/cashOut">
				Cash Out
			</a>
		</span>
        <span 
        	class="dark-purple-2 menu-text" 
        	style="
        		color: white;
        		padding: 3px; 
        		display: block;
				
				text-align: right;
				line-height: 1;
			"
		><a style="color: white; padding : 2px; font-size: 12px;" (click)="logOut()">Log Out</a></span>
        <span 
        	class="dark-purple-2 menu-text" 
        	style="
        		color: white;
        		padding: 3px; 
        		display: block;
				
				text-align: right;
				line-height: 1;
			"
		>
		<a (click)="toggleMenu()" style="color: white; padding : 2px; font-size: 12px;" routerLink="/support">Support</a></span>
        <span 
        	class="dark-purple-2 menu-text" 
        	style="
        		color: white;
        		padding: 3px; 
        		display: block;
				
				text-align: right;
				line-height: 1;
			"
		 (click)="openHelp()">
		 <a style="color: white; padding : 2px; font-size: 12px;">Help</a></span>
	    </ul>
	</div>
    <button  routerLink="/profile/{{ username }}" class="inline no-margins menu-button btn btn-lg menu-height navbar-button" style="padding: 10px; border: none; background: none;">
		<div>
			<img class="inline navbarMenu no-margins no-padding" height="30" width="30" src="/app/assets/images/user.png" />
			<h4 class="navbar-icon-text">Profile</h4>
		</div>
	</button>
	<create-content-button class="inline no-margins menu-button btn btn-lg menu-height navbar-button" style="padding: 10px; border: none; background: none;"></create-content-button>
</div>