import { Component } from '@angular/core'

@Component({
	selector : 'pricing' , 
	templateUrl : './pricing.html' ,
	styleUrls : [ './pricing.css' ]
})

export class PricingComponent {

	features : string[] = [
		"Charge in 125 Currencies" ,
		"Web & Mobile Apps" , 
		"Competitions" ,
		"Entry" ,

	]

	entryFeatures : string[] = [
		"Any Media/File Type" ,
		"Unlimited Entry" ,
		"Control Entry Privacy" ,
		"Charge For Entry" ,
		"Control Who Can Enter"
	]

	votingFeatures : string[] = [
		"Multiple Voting Systems" ,
		"Simple Up/Down Vote" ,
		"Voter Scorecards" ,
		"Charge For Voting" ,
		"Multiple Votes Per Voter" ,
		"Control How Votes Are Used" ,
		"Tie Breaker" ,
		"Crowd Choice Award" ,
		"Elimination" ,
		"Votes Determine Winner" ,
	]

	judgingFeatures : string[] = [
		"Unlimited Judges" ,
		"Create Judging Scorecards" , 
		"Randomly Assign Entries" , 
		"Assign All Entries To All Judges" , 
		"Public/Private Scoring" ,
		"Judging Feedback On Entries"
	]

	profileFeatures : string[] = [
		"View Past Entries" ,
		"View Past Competitions" ,
		"View Social Medias" ,
		"View Dreams"
	]

	selectedCompetitor : {
		name : string 
		features : string[] 
		price : number 
		rate : string 
		logo : string
	} = {
	    name : "submittable" ,
	    features : [
	      'Simple Up/Down Vote' , 
	      'Randomly Assign Entries'
	    ] , 
	    price : 999 ,
	    rate : 'Year' ,
	    logo : "https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fsubmittable.png?alt=media&token=29effe94-0534-45d0-9dd0-e651cd5df9b7"
	}

	competitorFeatures : any[] = [
	  {
	    name : "submittable" ,
	    features : [
	      'Simple Up/Down Vote' , 
	      'Randomly Assign Entries'
	    ] , 
	    price : 999 ,
	    rate : 'Year' ,
	    logo : "https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fsubmittable.png?alt=media&token=29effe94-0534-45d0-9dd0-e651cd5df9b7"
	  } , {
	    name : "woobox" , 
	    features : [
	      "Any Media/File Type" , 
	    ] , 
	    price : 37 , 
	    rate : "Month" ,
	    logo : "https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fwoobox.svg?alt=media&token=3b44314b-4b07-4a16-9dbf-6e5326280c93"
	  } , {
	    name : "shortstack" , 
	    features : [

	    ] , 
	    price : 199 , 
	    rate : 'Month' , 
	    logo : "https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fshortstack.png?alt=media&token=aa263317-9109-401f-ba76-8ea5592cffb4"
	  } , {
	    name : "viralsweep" , 
	    features : [
	      "Unlimited Entry"
	    ] , 
	    price : 49 , 
	    rate : 'Month' , 
	    logo : "https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fviralsweep.webp?alt=media&token=d9e307b2-2593-40d0-acbf-f1d56379998b"
	  }
	]

	selectCompetitor( name : string ) {
		this.selectedCompetitor = this.competitorFeatures.filter( ( competitor : any ) => { return competitor.name === name })[0]
	}

}