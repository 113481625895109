import { Component , OnInit } from '@angular/core'

import { CreateContestSubjectService } from '../../index'

@Component({
	selector : 'create-contest-judging-details' ,
	templateUrl : './judging.html' 
})

export class CreateContestJudgingDetailsComponent {

	createContestObject : any
	judgingCriterion : any[] = [] 
	newJudgingCriteria : any = {}

	showAddCashPrize : boolean
	showAddOtherPrize : boolean

	constructor( private createContestSubjectService : CreateContestSubjectService ) { }

	ngOnInit() {
		this.createContestSubjectService.createContestObject.subscribe( ( createContestObject ) => {
			this.createContestObject = createContestObject
			if ( this.createContestObject.scoreCard && this.createContestObject.scoreCard.judgingCriteria )
				this.judgingCriterion = this.createContestObject.scoreCard.judgingCriteria
		})
	}

	submitDetails() {
		if ( this.judgingCriterion.length === 0 )
			return
		this.createContestObject.scoreCard.judgingCriteria = this.judgingCriterion
		this.createContestSubjectService.renewCreateContestObject( this.createContestObject )
	}
	
	addJudgingCriteria() {
		this.judgingCriterion.push(this.newJudgingCriteria)
		this.newJudgingCriteria = {}
	}

	removeJudgingCriteria() {
		this.judgingCriterion.splice( this.judgingCriterion.length - 1, 1 )
	}

}