<a><img (click)="openModal()" src="/app/assets/images/pencil-logo.png" height="25px" width="25px"></a>
<div id="uploadProfileFacebookLinkModal" class="cssModal">
	<div id="uploadProfileFacebookLinkModalContent" class="cssModalContent modal-content row">
  		<div class="col-xs-12">
  			<div class="modal-header">
          		<button type="button" class="btn btn-default top-left" (click)="closeModal()">Close</button>
          		<h2>Add/Edit Facebook Link</h2>
  			</div>
  			<div *ngIf="!successUpdatingFacebookProfileLink" class="col-centered">
  				<p *ngIf="err">{{ err }}</p>
  				<span class="update-social-link-input-section">
  					<p style="display: inline-block;">https://www.facebook.com/</p><input style="display: inline; position: relative; border-width: 0px; " type="text" placeholder="type here" [(ngModel)]="facebookLink" />
  				</span>
  				<button (click)="updateFacebookLink()" class="btn btn-info btn-lg">Update Link</button>
  			</div>
  			<div *ngIf="successUpdatingFacebookProfileLink">
  				<p>You have just updated your Facebook profile link to be <a href="https://www.facebook.com/{{facebookLink}}">https://www.facebook.com/{{facebookLink}}</a>.</p>
  			</div>
  		</div>
  	</div>
</div>