<!-- make height=1/2 view width -->
<div class="featured-contest-container text-shadow">
	<a routerLink="/competition/{{ contest._id }}" style="color : white;">
		<div [@fade]="isDisplayed ? 'fadeIn' : 'fadeOut'" class="col-xs-12 noTopOrBottomPadding noTopOrBottomMargin" 
			style="
				width: 100% ; 
				height: 100% ; 
		    background-repeat: no-repeat;
		    background-size: cover;
		    background-position: center center;
		    padding: 0px;
			" 
			[ngStyle]="{'background-image': 'url(' + contest.groupProfilePicture + ')'}">
			<div class="featured-contest-information-section-desktop">
				<div style="
			    position: absolute;
			    -ms-transform: translateY(-30%);
			    -webkit-transform: translateY(-30%);
			    transform: translateY(-30%);
			    top: 30%;
			    width: 100%;
		    ">			
					<h2
						style="
							margin: 0px;
							padding: 10px;
						" 
						class="jumbo-text"
					>{{ contest.contestName }}</h2>
					<a routerLink="/group/{{ contest.groupUrl }}"><h3 class="white-text" style="
				    margin-top: 0px;
				    margin-bottom: 0px;
				  ">@{{ contest.groupUrl }}</h3></a>
					<featured-contest-rewards [contest]="contest"></featured-contest-rewards>
<!-- 			
					<p class="featured-contest-description" style="
				    max-height: 109px;
				    overflow: hidden;
				    text-overflow: ellipsis;
			    ">{{ contest.description }}</p>
 -->
 					<button 
						style="
					    border-color: #6D507F;
					    border-style: solid;
					    border-width: 1px;
					    margin: auto auto;
				    " 
				    class="block enter-competition-button btn-lg" 
				    routerLink="/competition/{{ contest._id }}"
				  >Enter</button>		
				</div>
			</div>
		</div>
	</a>
	<div 
		style="
			position: absolute; 
			bottom: 20%;
	    -ms-transform: translateY(-20%);
	    -webkit-transform: translateY(-20%);
			transform: translateY(-20%);
	    width : 100%;
    "
  >
		<welcome-search></welcome-search>
	</div>
</div>