<div class="relative"> 
  <div class="footer-fix row">
    <welcome-video></welcome-video>
    <div *ngIf="featuredContests" class="col-xs-12">
        <featured-contests [contests]="featuredContests"></featured-contests>
    </div>
    <!-- <div class="col-xs-12">
      <div class="col-xs-12 center-text">
        <h2>Top Cash Reward Competitions</h2>
      </div>
      <div class="col-md-offset-1 col-md-10 col-xs-12" style="padding: 0px;">
        <div class="col-centered">
          <contest-thumbnail *ngFor = "let contest of topCashRewardCompetitions" [contest]="contest"></contest-thumbnail>
        </div>
      </div>
    </div> -->
<!--     <div class="col-xs-12">
        <div class="col-xs-12 center-text">
          <h2>Recent Groups</h2>
        </div>
        <div>
          <div class="col-centered">
            <groupThumbnail [group]="group" *ngFor = "let group of recentGroups"></groupThumbnail>
          </div>
        </div>
    </div> -->
    <div class="col-xs-12">
      <div class="col-md-offset-1 col-md-10 col-xs-12" style="margin-top: 5px;">
        <h2 style="color : white;">Competitions For You</h2>
        <div class="col-centered">
          <contest-thumbnail *ngFor = "let contest of recentCompetitions" [contest]="contest"></contest-thumbnail>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div 
      class="col-xs-12 col-md-offset-1 col-md-10 mock-up-section"
    >      
      <div class="mock-up-background">
        
      </div>
      <div 
        class="col-sm-6 col-xs-12"
        style="
          display: flex; 
          flex-direction: column;
        " 
      >
        <h2 class="jumbo-text header-font">
          Create &amp; Enter Competitions For Free
        </h2>
        <h2 style="margin-top: 0px;">
          Win Cash &amp; Other Prizes
        </h2>
        <p>Spotidol is a free platform to create &amp; enter competitions. Build networks of champions. Profit with sponsors. Connect winners from around the world. Provide &amp; gain feedback on your work.</p>
        <div 
          style="    
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
          "
        >        
          <button
            class="enter-competition-button btn-lg dark-purple-background" 
            routerLink="/signUp"
            style="
              border-color: #6D507F;
              background-color: #38194C;
              border-style: solid;
              border-width: 1px;
              display: block;
            "
          >
            Create Competition
          </button>
          <a 
            (click)="linkToDownload()"
          >   
            <img 
              src="/app/assets/images/download-app.png" 
              style="
                height: 100px;
              " 
            />
          </a>
        </div>
      </div>
      <div class="col-sm-6 col-xs-12">
        <img 
          src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fspotidol-renders.png?alt=media&token=3d9808fe-6f46-4ac4-ada1-bb8586b811af" 
          style="width: 100%; height: auto;" 
        />
      </div>
    </div>
  </div>
  <div class="pricing-comparison-container">
    <div class="pricing-comparison-background"></div>
    <div 
      class="col-xs-12 col-sm-6 feature-header"
      style="
        display: flex;
        align-items: center;
        justify-content: center;
      "   
    >
      <div style="margin-bottom: 40px;">
        <h2>Explore our wide range of features.</h2>
        <a routerLink="/pricing"><p style="color: black; font-weight: 600;">View All Features</p></a>
      </div>
    </div>
    <div 
      class="col-xs-12 col-sm-6 feature-details"
      style="
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
      "
    >
      <div class="row">
        <div class="col-xs-6 comparison-text"><p>Unlimited Entry</p></div>
        <div class="col-xs-6"><img src="/app/assets/images/checkmark.png" style="width: 32px ; height: 32px ; "></div>
      </div>
      <div class="row">
        <div class="col-xs-6 comparison-text"><p>Any Media/File Type</p></div>
        <div class="col-xs-6"><img src="/app/assets/images/checkmark.png" style="width: 32px ; height: 32px ; "></div>
      </div>
      <div class="row">
        <div class="col-xs-6 comparison-text"><p>Multiple Voting Systems</p></div>
        <div class="col-xs-6"><img src="/app/assets/images/checkmark.png" style="width: 32px ; height: 32px ; "></div>
      </div>
      <div class="row">
        <div class="col-xs-6 comparison-text"><p>Unlimited Judges</p></div>
        <div class="col-xs-6"><img src="/app/assets/images/checkmark.png" style="width: 32px ; height: 32px ; "></div>
      </div>
      <div class="row">
        <div class="col-xs-6 comparison-text"><p>User Profiles</p></div>
        <div class="col-xs-6"><img src="/app/assets/images/checkmark.png" style="width: 32px ; height: 32px ; "></div>
      </div>
      
    </div>
  </div>
  <welcome-playlist></welcome-playlist>
  <featured-contest-entry></featured-contest-entry>
  <div>
   <!--  <featured-winners></featured-winners> -->
  </div>
  <footer></footer>
</div>
