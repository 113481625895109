import { Component , OnInit , AfterContentInit , EventEmitter , Input} from '@angular/core'
import { FormControl , FormGroup , Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { IContest , IGroup } from '../../shared/interfaces'
import { zeroDecimalCurrencies } from '../../shared/international/zeroDecimalCurrencies'

import { stripePublishableKey } from '../../shared/keys/keys'


import { DataService , SessionService , UserDataService } from '../../core/index'
import { InternationalService } from '../../shared/international.service'
import { UploadOutput, UploadInput, UploadFile, humanizeBytes } from 'ngx-uploader';
import moment from 'moment'
import 'moment-timezone'

const  ordinal = require('ordinal')

@Component({
	selector: 'select-group-create-contest', 
	templateUrl: "./selectGroupCreateContest.html", 
	styleUrls: ['./selectGroupCreateContest.css']

	
})

export class SelectGroupCreateContestComponent implements OnInit { 

  date : any = new Date()

  today : any = {
	year: this.date.getFullYear(),
	month: this.date.getMonth() + 1,
	day: this.date.getDate()
  }

  tomorrow : { year : number , month : number , day : number } = {
	year: this.date.getFullYear(),
	month: this.date.getMonth() + 1,
	day: this.date.getDate() + 1
  }


	modal : any = document.getElementById('uploadContestModal')

  myDatePickerOptions : any = {
	// other options...
		dateFormat: 'mm.dd.yyyy',
		inline: false, 
		editableDateField: false, 
		openSelectorOnInputClick: true, 
		disableUntil: {
			year: this.today.year, 
			month: this.today.month, 
			day: this.today.day
		}
  };

  endOfContestDate : any = {
	year: this.date.getFullYear(),
	month: this.date.getMonth() + 1,
	day: this.date.getDate() + 1
  }
  winnerSelectedDate : any = {
	year: this.date.getFullYear(),
	month: this.date.getMonth() + 1,
	day: this.date.getDate() + 1
  }

  files: UploadFile[]
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;
  uploadPercentage : number
  submittedContestToServer : boolean
  submittingContestToServer : boolean
  successSubmittingContestToServer : boolean
  errorSubmittingContestToServer : boolean 
  rewardCashError : boolean
  userNotSignedIn : boolean
  showGroupPicker : boolean
  err : string 
  session : any
  rewardCash : any
  submittingFile : boolean
  contestHasFile : boolean
  showModal : boolean
  currencySymbol : string 

	positions : number = 1 
	rewards : any[] = []
	showLengthInput : Boolean = false 
	contest : IContest
	tagString : string
	contestType : string
	createContestForm : FormGroup
	userHasDefaultCard : boolean = false
	stripeCharge : number
	showShareModal : boolean = false

	tagsMustStartWithHash : boolean

	showText: boolean
	showUpdateProfile : boolean 

	userData : any

	createdGroups : IGroup[]
	group : IGroup
	groupUrl : string

	pageShown : 'chooseGroup' | 'createCompetition' = 'chooseGroup'

	chargeVoters : 'true' | 'false' = 'false'
	showEntrySettings : boolean = false 
	showVoteSettings : boolean = false 
	showScoreSettings : boolean = false
	showRewardSettings : boolean = false
	showJudgingSettings : boolean = false

	votingType : string 
	votingCriteria : {
		votingType : string 
		scorePerVote : number
	} = {
		votingType : 'crowdChoice' ,
		scorePerVote : 0
	}

	judgingCriterion : any[] = [] 
	newJudgingCriteria : {} | {
		name : string ,
		score : number , 
		description : string 
	} = {}

	ordinal : any

	constructor(
		private dataService : DataService , 
		private router : Router , 
		private sessionService : SessionService ,
		private internationalService : InternationalService , 
		private userDataService : UserDataService
	) {
		this.files = []; // local uploading files array
		this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
		this.humanizeBytes = humanizeBytes;
		this.ordinal = ordinal
	}

	ngOnInit () {

		this.userDataService.userData.subscribe( ( userData : any) => {
			this.createdGroups = userData.createdGroups
			// this.getMyUser()
			this.userData = userData

		})


		this.sessionService.session.subscribe( (session : any ) => {
			this.session = session
			this.currencySymbol = this.internationalService.convertCurrencySymbol(this.session.country)
		})

		var scrollPos = 0;

		let contestName = new FormControl('')
		let contestPrice = new FormControl('')
		let rewardFanpoints = new FormControl('')
		let rewardCash = new FormControl('')
		let rewardOther = new FormControl('')
		let contestType = new FormControl('')
		let minutes = new FormControl('')
		let seconds = new FormControl('')
		let endOfContest = new FormControl()
		let entryGoesToPot = new FormControl()
		let entryPrivacy = new FormControl()
		let scorePrivacy = new FormControl()
		let endOfContestTime = new FormControl()
		let scorePerVote = new FormControl()
		let votingType = new FormControl()
		let startDate = new FormControl()
		let startTime = new FormControl()
		let userContestHidden = new FormControl()
		let winnerSelectedDate = new FormControl()
		let winnerSelectedTime = new FormControl()
		let description = new FormControl('')
		let positions = new FormControl(1)
		let dummyRewards = new FormControl()
		let kindOfFan = new FormControl()
		let group = new FormControl()
		let chargeVoters = new FormControl()
		let maxSubmissionsPerEntry = new FormControl()
		let maxVotes = new FormControl()
		let pricePerVote = new FormControl()
		this.createContestForm = new FormGroup({
			contestName : contestName ,
			contestType : contestType , 
			minutes : minutes , 
			seconds : seconds , 
			entryPrivacy : entryPrivacy , 
			scorePrivacy : scorePrivacy , 
			contestPrice : contestPrice , 
			rewardFanpoints : rewardFanpoints ,
			rewardCash : rewardCash , 
			group : group , 
			pricePerVote : pricePerVote , 
			rewardOther : rewardOther ,
			votingType : votingType ,
			userContestHidden : userContestHidden , 
			scorePerVote : scorePerVote ,
			entryGoesToPot : entryGoesToPot ,  
			maxSubmissionsPerEntry : maxSubmissionsPerEntry , 
			maxVotes : maxVotes , 
			positions : positions ,
			chargeVoters : chargeVoters , 
			startDate : startDate , 
			startTime : startTime , 
			endOfContest : endOfContest ,
			endOfContestTime : endOfContestTime , 
			winnerSelectedDate : winnerSelectedDate , 
			winnerSelectedTime : winnerSelectedTime , 
			kindOfFan : kindOfFan , 
			dummyRewards : dummyRewards , 
			description : description 
		})
	}


  getMyUser() {
    let URI = 'user/getUserBySession'
    this.dataService.getObject( URI )
      .subscribe( ( response : any ) => {
        if ( response.error || !response.user ) 
          return 
        let user = response.user
        // this.showUpdateProfile = ( !user.twitterLink && !user.facebookLink && !user.instagramLink )
      })
  }

	getGroup() {
		let URI = 'group/getGroupsByUserId'
		this.dataService.getObject(URI)
			.subscribe((response) => {
				this.createdGroups = response.groups;
				this.group = this.createdGroups[0]
			},
		(err: any) => console.log(err),
		() => console.log("groupsICreated"));
	}

	displayChooseGroup( ) {
		this.pageShown = 'chooseGroup'
	}

	selectedGroup( group : IGroup ) {
		this.group = group 
		this.pageShown = 'createCompetition'
	}

	changeGroup( groupUrl : string ) {
		this.group = this.createdGroups.filter( ( i : IGroup ) => { return (i.groupUrl === groupUrl )})[0]
	}


	openModal() {
		console.log('open')
		this.showModal = true
	}

	toggleEntrySettings() {
		this.showEntrySettings = !this.showEntrySettings
	}

	toggleVoteSettings() {
		this.showVoteSettings = !this.showVoteSettings
	}

	toggleRewardSettings() {
		this.showRewardSettings = !this.showRewardSettings
	}

	toggleJudgingSettings() {
		this.showJudgingSettings = !this.showJudgingSettings
	}

	changeVotingType( votingType : 'votesToScore' | 'crowdChoice' | 'tieBreaker' ) {
		this.votingCriteria.votingType = votingType
	}

	addReward() {
		this.positions ++ 
		let emptyReward : any
		emptyReward = {
			rewardCash : null ,
			rewardFanpoints : null , 
			rewardOther : '' , 
			position : this.positions
		}
		this.rewards.push(emptyReward)
	}

	addJudgingCriteria() {
		this.judgingCriterion.push(this.newJudgingCriteria)
		this.newJudgingCriteria = {}
	}

	removeJudgingCriteria() {
		this.judgingCriterion.splice( this.judgingCriterion.length - 1, 1 )
	}

	removeReward() {
		this.rewards.splice( this.rewards.length - 1 , 1 )
		this.positions --
	}

	toggleGroupPicker() {
		this.showGroupPicker = !this.showGroupPicker
		if ( !this.showGroupPicker ) {
			this.groupUrl = null
			this.group = null
		}
	}

	makeTagsArray (tagString : string) {
			this.tagsMustStartWithHash = false
		if (tagString && !tagString.match('[#].*')) 
			return this.tagsMustStartWithHash = true
		let tags = tagString.split(" #")
		for ( var i = 1 ; i < tags.length ; i++ ) {
			tags[i] = '#' + tags[i]
		}
		return tags
	}

	changeContestType (contestType : any ) {
		if (contestType === 'video' || contestType === 'audio') {
			this.showLengthInput = true
		} else {
			this.showLengthInput = false
		}
	}

	setEndOfCompetitionDate( event : any ) {
		this.endOfContestDate = event
	}

	setWinnerSelectedDate( event : any ) {
		this.winnerSelectedDate = event
		console.log(this.winnerSelectedDate)
	}

	validateContest( formValues  : any ) {

		for (let inner in this.createContestForm.controls) {
		  this.createContestForm.get(inner).markAsTouched();
		  this.createContestForm.get(inner).updateValueAndValidity();
		}
		this.submittingContestToServer = true 
		this.sessionService.session.subscribe( (session:any) => {		
			if (session.loggedIn === false) 
				return this.userNotSignedIn = true
		})
		if (this.createContestForm.valid){
			if (this.tagString)
				formValues.tags = this.makeTagsArray(this.tagString)
			if (this.tagsMustStartWithHash === true) {
				this.submittingContestToServer = false 
				this.errorSubmittingContestToServer = true
				this.submittedContestToServer = false
				return this.err = 'your tags must start with "#"'
			}
			if (formValues.minutes || formValues.seconds) {
				if (isNaN(formValues.minutes) || isNaN(formValues.seconds)) {
					this.submittingContestToServer = false 
					this.errorSubmittingContestToServer = true
					this.submittedContestToServer = false
					return this.err = 'Price and rewards must be numbers'
				}
						
				formValues.length = formValues.minutes * 60 + formValues.seconds
			}  
			formValues.endOfContest = this.endOfContestDate
			formValues.contestType = formValues.contestType || 'picture'
			formValues.endOfContestTime = formValues.endOfContestTime ? formValues.endOfContestTime : {} 
			formValues.endOfContestTime.hour = formValues.endOfContestTime ? (formValues.endOfContestTime.hour ? formValues.endOfContestTime.hour : 12) : 12 
			formValues.endOfContestTime.minute = formValues.endOfContestTime ? (formValues.endOfContestTime.minute ? formValues.endOfContestTime.minute : 30) : 30 
			let endOfContestTimeString = formValues.endOfContestTime.hour + ':' + formValues.endOfContestTime.minute
			let endOfContestDateString = formValues.endOfContest.year + '-' + formValues.endOfContest.month + '-' + formValues.endOfContest.day 
			console.log( endOfContestDateString , endOfContestTimeString )
			let endOfContestString = endOfContestDateString + ' ' + endOfContestTimeString
			formValues.endOfContest = moment(endOfContestString , "YYYY-MM-DD HH:mm").utc().format()

			formValues.winnerSelectedDate = this.winnerSelectedDate
			console.log( this.winnerSelectedDate )
			formValues.winnerSelectedTime = formValues.winnerSelectedTime ? formValues.winnerSelectedTime : {} 
			formValues.winnerSelectedTime.hour = formValues.winnerSelectedTime ? (formValues.winnerSelectedTime.hour ? formValues.winnerSelectedTime.hour : 13) : 13 
			formValues.winnerSelectedTime.minute = formValues.winnerSelectedTime ? (formValues.winnerSelectedTime.minute ? formValues.winnerSelectedTime.minute : 30) : 30 
			let winnerSelectedTimeString = formValues.winnerSelectedTime.hour + ':' + formValues.winnerSelectedTime.minute
			let winnerSelectedDateString = formValues.winnerSelectedDate.year + '-' + formValues.winnerSelectedDate.month + '-' + formValues.winnerSelectedDate.day 
			console.log( winnerSelectedDateString , winnerSelectedTimeString )
			let winnerSelectedString = winnerSelectedDateString + ' ' + winnerSelectedTimeString
			formValues.winnerSelectedDate = moment(winnerSelectedString , "YYYY-MM-DD HH:mm").utc().format()
			console.log( formValues.winnerSelectedDate )

			formValues.startDate = formValues.startDate ? formValues.startDate : {
				date : this.tomorrow
			} 
			formValues.startTime = formValues.startTime ? formValues.startTime : {} 
			formValues.startTime.hour = formValues.startTime ? (formValues.startTime.hour ? formValues.startTime.hour : 13) : 13 
			formValues.startTime.minute = formValues.startTime ? (formValues.startTime.minute ? formValues.startTime.minute : 30) : 30 
			let startTimeString = formValues.startTime.hour + ':' + formValues.startTime.minute
			let startDateString = formValues.startDate.year + '-' + formValues.startDate.month + '-' + formValues.startDate.day 
			let startString = startDateString + ' ' + startTimeString
			formValues.startTime = moment(startString , "YYYY-MM-DD HH:mm" ).utc().format()

			formValues.exchangeRate = this.session.exchangeRate

			if (isNaN(formValues.contestPrice) || isNaN(formValues.rewardCash) || isNaN(formValues.rewardFanpoints)) {
				this.submittingContestToServer = false 
				this.errorSubmittingContestToServer = true
				this.submittedContestToServer = false
				return this.err = 'Price and rewards must be numbers'
			}
			if (this.positions > 1) {
				formValues.rewards = this.rewards
			}
			formValues.positions = this.positions
			formValues.group = this.group

			formValues.scoreCard = {} 
			formValues.scoreCard.judgingCriteria = this.judgingCriterion
			formValues.scoreCard.votingCriteria = this.votingCriteria
			console.log(formValues)
	 		return this.postContest(formValues)
		} else {
			this.submittingContestToServer = false
		}
	}


	postContest( formValues : any ) {
		let URI = 'contest'
		this.rewardCash = formValues.rewardCash

		let chargeAmount : number = 0	
		for (let i = 0 ; i < this.rewards.length ; i++) {
			if ((this.rewards[i].rewardCash < 0.5 && this.rewards[i].rewardCash > 0)
			  || this.rewards[i].rewardCash < 0) {
				this.submittingContestToServer = false 
				this.submittedContestToServer = false
				this.errorSubmittingContestToServer = true
				return this.err = 'the reward must be at least 50 cents or free'
			}    		
			if (this.rewards[i].rewardCash > 0)
				chargeAmount += parseFloat(this.rewards[i].rewardCash)
		}
		if ((formValues.rewardCash > 0 && formValues.rewardCash < 0.5) || formValues.rewardCash < 0) {
		  this.submittingContestToServer = false 
		  this.submittedContestToServer = false
		  this.errorSubmittingContestToServer = true
		  return this.err = 'the reward must be at least 50 cents or free'
		}
		if ((formValues.contestPrice > 0 && formValues.contestPrice < 0.5) || formValues.contestPrice < 0) {
		  this.submittingContestToServer = false 
	  this.submittedContestToServer = false
	  this.errorSubmittingContestToServer = true
	  return this.err = 'the contest price must be at least 50 cents or free'
		}
		if ((formValues.rewardCash > 0 && formValues.rewardCash < 1))
		this.stripeCharge = formValues.rewardCash * 100 + 3 + chargeAmount * 100 * 1.03
		else 
		this.stripeCharge = formValues.rewardCash * 100 * 1.03 + chargeAmount * 100 * 1.03
		if ((<any>zeroDecimalCurrencies).indexOf(this.internationalService.getCurrencyCode(this.session.country)) > -1)
		  this.stripeCharge = Math.ceil(this.stripeCharge / 100)
		formValues.exchangeRate = this.session.exchangeRate
		if (!formValues.maxSubmissionsPerEntry) formValues.maxSubmissionsPerEntry = 1
		if (!formValues.positions) formValues.positions = 1 
		if (this.group && !this.group.profilePicture) this.group.profilePicture = ''
		if (this.contestHasFile === true) {
			this.submittingFile = true
			formValues.rewards = JSON.stringify(formValues.rewards)
			const event: UploadInput = {
			  type: 'uploadAll',
			  fieldName: 'upload', 
			  url: 'api/' + URI,
			  method: 'POST',
			  data: { 
						contestName : formValues.contestName ,
						contestType : formValues.contestType ,
						groupUserId : this.group.userId , 
						entryPrivacy  : formValues.entryPrivacy , 
						groupName : this.group.groupName , 
						groupUrl : this.group.groupUrl , 
						groupId : this.group._id , 
						exchangeRate : formValues.exchangeRate , 
						groupProfilePicture : this.group.profilePicture , 
						minutes : formValues.minutes , 
						seconds : formValues.seconds , 
						maxSubmissionsPerEntry : formValues.maxSubmissionsPerEntry , 
						contestPrice : formValues.contestPrice , 
						rewardFanpoints : formValues.rewardFanpoints ,
						rewardCash : formValues.rewardCash , 
						rewardOther : formValues.rewardOther , 
						currency : this.internationalService.getCurrencyCode(this.session.country) , 
						positions : formValues.positions , 
						rewards : formValues.rewards ,
						endOfContest : formValues.endOfContest ,
						description : formValues.description , 
						tags: formValues.tags
				  }
			}

				this.uploadInput.emit(event);

		} else {	
			formValues.noGroupVideo = true 
			if ( this.group ) {
					formValues.groupUserId = this.group.userId  
					formValues.groupName = this.group.groupName  
					formValues.groupUrl = this.group.groupUrl  
					formValues.groupId = this.group._id  			
					formValues.groupProfilePicture = this.group.profilePicture
			}
				formValues.currency = this.internationalService.getCurrencyCode(this.session.country) 
				this.dataService.postObject(URI, formValues)
					.subscribe((response : any) => {
						if (response.error !== null) {
										this.submittingContestToServer = false 
						  this.submittedContestToServer = false
						  this.errorSubmittingContestToServer = true
						  return this.err = response.error
						}
						this.contest = response.newContest
						if (this.stripeCharge > 0) {
							return this.chargeCard( this.contest )
						} else {
							  this.submittingContestToServer = false 
				  this.submittedContestToServer = true
				  this.successSubmittingContestToServer = true
				  this.userData.myContests.push(response.newContest)
				  this.userDataService.renewUserData(this.userData)
          this.showShareModal = true 
					document.getElementById('uploadContestModal').style.display = "none"
					document.getElementById('uploadContestModalContent').style.display = "none"
					this.router.navigateByUrl( '/competition/' + response.newContest._id )

					setTimeout(() => {
						this.createContestForm.reset()
						this.submittedContestToServer = false 
						this.successSubmittingContestToServer = false
					}, 5000)
					return this.err = null
						}
					  },
					(err: any) => {
							this.submittingContestToServer = false 
					  this.submittedContestToServer = false
					  this.errorSubmittingContestToServer = true
					  this.err = 'internal server error, please try again'
					} , () => {this.submittingContestToServer = false});
				}
	}


 
  onUploadOutput(output: UploadOutput) {

	if (output.file && output.file !== undefined)
		this.uploadPercentage = output.file.progress.data.percentage
	if (output.type === 'allAddedToQueue') { // when all files added in queue
	  // uncomment this if you want to auto upload files when added
	  // const event: UploadInput = {
	  //   type: 'uploadAll',
	  //   url: '/upload',
	  //   method: 'POST',
	  //   data: { foo: 'bar' },
	  //   concurrency: 0
	  // };
	  // this.uploadInput.emit(event);
	} else if (output.type === 'addedToQueue') {

	  this.contestHasFile = true
	  this.files.push(output.file); // add file to array when added
	} else if (output.type === 'uploading') {
	  // update current data in files array for uploading file
	  const index = this.files.findIndex(file => file.id === output.file.id);
	  this.files[index] = output.file;
	} else if (output.type === 'removed') {
	  // remove file from array when removed
	  this.files = this.files.filter((file: UploadFile) => file !== output.file);
	} else if (output.type === 'dragOver') { // drag over event
	  this.dragOver = true;
	} else if (output.type === 'dragOut') { // drag out event
	  this.dragOver = false;
	} else if (output.type === 'drop') { // on drop event
	  this.dragOver = false;
	}
	if (output.type === 'done') {
	  this.submittingFile = false
	  if ( this.files[0].response.error ) {
			this.submittingContestToServer = false 
		  this.submittedContestToServer = false
		  this.errorSubmittingContestToServer = true
		return this.err =  this.files[0].response.error
	  }
		this.contest = this.files[0].response.newContest
		if (this.rewardCash > 0) 
			return this.chargeCard( this.contest )
		this.showShareModal = true 
		this.createContestForm.reset()
		this.submittingContestToServer = false 
		document.getElementById('uploadContestModal').style.display = "none"
		document.getElementById('uploadContestModalContent').style.display = "none"
		this.router.navigateByUrl('/competition/' + this.files[0].response.newContest._id)
		return this.err = null
	}
  }

	cancelUpload(id: string): void {
		this.uploadInput.emit({ type: 'cancel', id: id });
	}

	chargeCard( contest : any ) {
		var handler = (<any>window).StripeCheckout.configure({
		  key: stripePublishableKey,
		  locale: 'auto',
		  currency: this.internationalService.getCurrencyCode(this.session.country) , 
		  token: (token: any) => {
			contest.token = token
			this.updateContestPaid(contest)
		  }
		})

		handler.open({
		  name: 'create contest' ,
		  description: contest.contestName , 
		  amount: this.stripeCharge , 
		});
	}

	updateContestPaid(contest : any) {
			let URI = 'contest/updateContestPaid'
			this.dataService.postObject(URI, contest)
				.subscribe((response : any) => {
					if (response.error !== null) {
									this.submittingContestToServer = false 
					  this.submittedContestToServer = false
					  this.errorSubmittingContestToServer = true
					  return this.err = response.error
					}
					if (response.newContest) {
									this.submittingContestToServer = false 
					  this.submittedContestToServer = true
					  this.successSubmittingContestToServer = true
					  this.contest = response.newContest	
					  this.err = null
					}
					this.showShareModal = true
					this.createContestForm.reset()
					  this.userData.myContests.push(response.newContest)
					  this.userDataService.renewUserData(this.userData)
								document.getElementById('uploadContestModal').style.display = "none"
								document.getElementById('uploadContestModalContent').style.display = "none"
								this.router.navigateByUrl('/competition/' + response.newContest._id)
				  },
				(err: any) => {
							this.submittingContestToServer = false 
				  this.submittedContestToServer = false
				  this.errorSubmittingContestToServer = true
				  this.err = 'internal server error, please try again'
				} , () => {this.submittingContestToServer = false});

	}

	closeModal() {
		this.showModal = false
	}

	closedShareModal( showModal : boolean ) {
		this.showShareModal = false
	}

	setTextDisplay() {
		this.showText = this.showText ? false : true
	}

  closeUpdateProfileModal( closed : any ) {
    this.showUpdateProfile = false 
  }

}