import { Injectable } from '@angular/core'
import { Resolve , Router , RouterStateSnapshot } from '@angular/router'
import { DataService , SessionService , UserDataService } from '../core/index'
import { IContest , IUser , IContestEntry , IFan , IGroup } from '../shared/interfaces'

@Injectable()
export class RootResolveService {


	userData : any = {}
	contests : IContest[]
	createdGroups : IGroup[]
	contestEntries : IContestEntry[]
	loggedIn : boolean = false
	myFans : IFan[] = []
	user : IUser
	myFanRequests : IFan[] = []
	myGroups : IGroup[] = []
	navUrl : string


	constructor(
		private dataService : DataService , 
		private router : Router , 
		private sessionService : SessionService , 
		private userDataService : UserDataService 
	) {}

	resolve( route : any , state : RouterStateSnapshot ) {
		console.log('resolving root' )
		return new Promise<any>( ( resolve , reject ) => {
			Promise.all([
				this.getSession() ,
				this.postNavigation( window.location.href.split('.com')[1] ) 
			])
				.then(()=>{
					this.getUserData()
						.then(() => {
							console.log('got user data')
							return resolve({
								loggedIn : this.loggedIn
							})
						})
				})
		})
	}
 
	getSession() {
		return new Promise<void>( ( resolve , reject ) => {
			console.log('getting session...')
			let URI = 'user/checkSession'
			this.dataService.getObject(URI)
				.subscribe((response : any) => {
					this.loggedIn = response.sessionStatus.loggedIn
					console.log('got session', response.sessionStatus)
					this.sessionService.renewSession( response.sessionStatus )
					return resolve()
				})
		})
	}

	getUserData() {
		console.log('getting user data')
		return new Promise<void>( ( resolve , reject ) => {
			if ( this.loggedIn !== true ) {
				return resolve() 
			} else {
				Promise.all([
					this.getCreatedGroups(),
					this.getMyGroups(),
					this.getMyContests()
				]).then(() => {
					Promise.all([
						this.getMyUser() ,
						this.getMyFans(), 
						this.getMyContestEntries()
					]).then( () => {
						this.userData = {
							myContests : this.contests ,
							myContestEntries : this.contestEntries , 
							myFans : this.myFans ,
							createdGroups : this.createdGroups , 
							myFanRequests : this.myFanRequests , 
							user : this.user ,
							myGroups : this.myGroups
						}
						this.userDataService.renewUserData(this.userData)
						console.log(this.userData)
						return resolve()
					})
				})
			}
		})
	}

	postNavigation( uri : string ) {
		return new Promise<void>( ( resolve , reject ) => {
			resolve()
			let postNavigationBody = {
				uri : uri
			}
			console.log( 'post nav body' , postNavigationBody )
			let URI = 'userEngagement/postNavigation' 
			this.dataService.postObject( URI , postNavigationBody )
				.subscribe( ( response : any ) => {
					console.log(response)
				})
		})
	}

	getCreatedGroups(){
		return new Promise<void>( ( resolve , reject ) => {
			let URI = 'group/getManagedGroups'
	    	this.dataService.getObject(URI)
		        .subscribe((response) => {
		        	console.log('got created groups')
		          	this.createdGroups = response.groups;
					return resolve()
		        },
	        (err: any) => console.log(err),
	        () => console.log("groupsICreated"));
		})
	}

	getMyContests(){
		return new Promise<void>( ( resolve , reject ) => {
			let URI = 'contest/getContestsByUserId'
			this.dataService.getObject(URI)
				.subscribe( ( response ) => {
					console.log('my contests' , response )
					this.contests = response.contests
					return resolve()
				},
	        (err: any) => console.log(err),
	        () => console.log("groupsImAFanOf"));
		})
	}

	getMyContestEntries(){
		return new Promise<void>( ( resolve , reject ) => {
			let URI = 'contestEntry/getContestEntriesByContestantId'
			this.dataService.getObject(URI)
				.subscribe( ( response ) => {
		        	console.log('got my ces')
					this.contestEntries = response.contestEntries
					return resolve()
				},
	        (err: any) => console.log(err),
	        () => console.log("groupsImAFanOf"));
		})
	}

	getMyGroups(){
		return new Promise<void>( ( resolve , reject ) => {
			let URI = 'group/getGroupsByFanId'
	    	this.dataService.getObject(URI)
		        .subscribe((response) => {
		        	console.log('got my groups')
		        	if (response.groups)
			          	this.myGroups = response.groups;
					return resolve()
		        },
	        (err: any) => console.log(err),
	        () => console.log("groupsImAFanOf"));
	    })
	}

	getMyFans(){
		return new Promise<void>( ( resolve , reject ) => {
			let URI = 'fan/getFansBySessionId'
	    	this.dataService.getObject(URI)
		        .subscribe((response) => {
		        	console.log('got my fans')
		        	for ( var i = 0 ; i < response.fans.length ; i++ ) {
		        		if (response.fans[i].approved === false )
		        			this.myFanRequests.push(response.fans[i])
		        		else 
		        			this.myFans.push(response.fans[i])
		        	}
							return resolve()
		        },
	        (err: any) => console.log(err),
	        () => console.log("groupsImAFanOf"));
	    })
	}

	getMyUser() {
		return new Promise<void>( ( resolve , reject ) => {
			let URI = 'user/getUserBySession' 
			this.dataService.getObject( URI )
				.subscribe( ( response ) => {
		        	console.log('got my user')
					if ( response.error )
						console.log( response.error )
					this.user = response.user
					return resolve()
				})
		})
	}


}