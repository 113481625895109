import { Component } from '@angular/core'

@Component({
	selector: 'privacy-policy',
	template: `
<div class="col-xs-10  white-text">
	<h1>Spotidol Privacy Policy</h1>
	 
	<h2>Personal Information You Provide to Us:</h2>
		<p>We receive and store any information you enter on our Service or provide to us in any other way except those explicitly stated in this Privacy Policy or in the Terms of Use. The types of Personal Information collected may include your name, username, password, email address, videos, photograph or other likeness, purchase history, IP address, browser information, credit card information and any other information you provide in connection with your user account. The credit card information that we store includes the last four digits of your card number, the card brand, and the expiration date of your cards for issuing payouts. You can choose not to provide us with certain information, but then you may not be able to take advantage of many of our special features. The Personal Information you provide is used for such purposes as allowing you to interact with other users, improving the content of the Service, customizing the advertising and content you see, and communicating with you about specials and new features. When you make payments through the Service, you may need to provide financial account information, such as your credit card number, to our third-party service providers, such as <a href="https://stripe.com/us/legal">Stripe</a>, and such information may be stored and used for both billing and payment services by such third-party service providers.</p> 
	<h2>Information Collected Automatically:</h2>
		<p>We and our third-party partners, such as usage analytics providers, receive and store certain types of information whenever you interact with our Service or services. Spotidol automatically receives and records information on our server logs from your browser including your IP address, cookie information, and the page you requested. These third-party partners also may collect information about your online usage activities over time and on other websites or mobile applications.</p>
	 
		<p>Generally, our service automatically collects usage information, such as the numbers and frequency of visitors to our site and its components, similar to TV ratings that indicate how many people watched a particular show. Spotidol only uses this data in aggregate form, that is, as a statistical measure. This type of aggregate data enables us to figure out how often customers use parts of the Service or services so that we can make the Service appealing to as many customers as possible, and improve those services. As part of this use of information, we may provide aggregate information to our partners about how our customers, collectively, use our site. We share this type of statistical data so that our partners also understand how often people use their services and our Service, so that they, too, may provide you with an optimal online experience.</p>
	<h2>E-mail Communications:</h2>
		<p>We may receive a confirmation when you open an email from Spotidol if your computer supports this type of program. Spotidol uses this confirmation to help us make emails more interesting and helpful and improve our service. If you do not want to receive email or other mail from us, please send your request to <a routerLink="/support"> www.spotidol.com/support.</a></p>
	<h2>Cookies</h2>
		<p>Cookies are alphanumeric identifiers that we transfer to your computer’s hard drive through your browser to enable our systems to recognize your browser and tell us how and when pages in our site are visited and by how many people. Spotidol cookies do not collect Personal Information, and we do not combine the general information collected through cookies with other Personal Information to tell us who you are or what your screen name or email address is.</p>
		<p>Most browsers have an option for turning off the cookie feature, which will prevent your browser from accepting new cookies, as well as (depending on the sophistication of your browser software) allowing you to decide on acceptance of each new cookie in a variety of ways. We strongly recommend that you leave the cookies activated, however, because cookies enable you to take advantage of some of our Service’s most attractive features.</p>
	<h2>Sharing Personal Information</h2>
		<p><b>Affiliated Businesses We Do Not Control:</b> We are affiliated with a variety of businesses and work closely with them. In certain situations, these businesses sell items to you through Spotidol's Service. In other situations, Spotidol provides services, or sells products jointly with affiliated businesses. You can easily recognize when an affiliated business is associated with your transaction, and we will share your Personal Information that is related to such transactions with that affiliated business.</p>
		<p><b>Agents:</b> We employ other companies and people to perform tasks on our behalf and need to share your information with them to provide products or services to you. Examples may include sending postal mail and e-mail, analyzing data, providing marketing assistance, providing search results and links (including paid listings and links), processing credit card payments, and providing customer service. Unless we tell you differently, Spotidol’s agents do not have any right to use Personal Information we share with them beyond what is necessary to assist us. You hereby consent to our sharing of Personal Information for the above purposes.</p>
		<p><b>Creators of Groups Pages:</b> When you use certain functionality or services, we may provide certain personal information to users, such as your e-mail address, country of residence and zip/postal code ("Fan Information") to add you to the relevant band's mailing list. Users are only authorized by us to use the Fan Information to send email to you and may not share, rent, or sell the information with others for any other purpose.</p>
		<p><b>User profiles:</b> User profile information including users’ name, email address, purchase history and other information you enter (“User Submissions”) may be displayed to other users to facilitate user interaction within the Service. Email addresses are used to add new User Submissions to user profiles and to communicate through User Submissions. Users’ email addresses will not be directly revealed to other users by us, except, when the user is "connected" to another user via a shared group membership, or an invitation, or by becoming a fan of others’ respective group pages. You may designate certain User Submissions, including individual items in your purchase history, as private, in which case they will not be displayed to other users.</p>
		 
		<p><b>Communication in response to User Submissions:</b> As part of the Service and Services, you will receive from Company email and other communication relating to your User Submissions. You acknowledge and agree that by posting such User Submissions, Company may send you email and other communication that it determines in its sole discretion relate to your User Submissions.</p>
		 
		<p><b>Business Transfers:</b> In these types of transactions, customer information is typically one of the business assets that is transferred. Moreover, if Spotidol, or substantially all of its assets were acquired, or in the unlikely event that Spotidol goes out of business or enters bankruptcy, customer information would be one of the assets that is transferred or acquired by a third party. You acknowledge that such transfers may occur, and that any acquirer of Spotidol may continue to use your Personal Information as set forth in this policy.</p>
		 
		<p><b>Protection of Spotidol and Others:</b> We may access, preserve, and disclose collected information, if we believe doing so is required or appropriate to: comply with law enforcement requests and legal process, such as a court order or subpoena; enforce or apply our conditions of use and other agreements; or protect the rights, property, or safety of Spotidol, our employees, our users, or others. This includes exchanging information with other companies and organizations for fraud protection and credit risk reduction.</p>
		<p><b>With Your Consent:</b> Except as set forth above, you will be notified when your Personal Information may be shared with third parties, and will be able to prevent the sharing of this information.</p>
</div>
`
})

export class PrivacyPolicyComponent {

}
