<div 
	class="row"
>
	<your-competitions></your-competitions>
	<div class="col-xs-12">
		<h3 class="col-xs-offset-1" style="color: white;">Competitions for You</h3>
		<div *ngIf="contests.length === 0" style=" margin : 5vh auto;" class="loader"></div>
		<div class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1">
			<contest-thumbnail *ngFor="let contest of contests"  [contest]="contest" ></contest-thumbnail>
		</div>
	</div>
	<div class="col-xs-12">	
		<h3 class="col-xs-offset-1" style="color: white;">Competition Entries</h3>
	  <div *ngIf="displayedEntries.length === 0" style=" margin : 5vh auto;" class="loader"></div>
		<div 
			class="col-md-offset-1"
			infiniteScroll	
	    [infiniteScrollDistance]="1"
	    [infiniteScrollThrottle]="1000"
			(scrolled)="setLoadedEntries()" 
			style="overflow: hidden;" 
	   >
			<contest-entry *ngFor = "let contestEntry of displayedEntries"  [contestEntry]="contestEntry" ></contest-entry>
		</div>
	</div>
</div>