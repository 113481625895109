import { Component , OnInit } from '@angular/core'

import { DataService , SessionService } from '../core/index'
import { DateService } from '../shared/date.service'
import { InternationalService } from '../shared/international.service'


import moment from 'moment'

import 'moment-timezone'

import { IPaymentHistory , IUserCreditHistory } from '../shared/interfaces'

@Component({
	selector: 'payment-history', 
	templateUrl: "./paymentHistory.html"
})

export class PaymentHistoryComponent implements OnInit {

	paymentHistoryBySeller : IPaymentHistory[]
	paymentHistoryByPurchaser : IPaymentHistory[]
	creditHistoryEntries : IUserCreditHistory[]
	showSales : boolean
	session : any
	showPurchases : boolean
	showCreditHistory : boolean


	constructor(
		private dataService : DataService ,
		private dateService : DateService , 
		private sessionService : SessionService ,
		private internationalService : InternationalService 
	) {}

	ngOnInit () {
		this.sessionService.session.subscribe(( session : any ) => {
			this.session = session
		})
		this.getPaymentHistoryEntriesBySeller(1)
		this.getPaymentHistoryEntriesByPurchaser(1)
		this.getUserCreditHistory(1)
	}

	getPaymentHistoryEntriesBySeller( page : number ) {
		let URI = 'paymentHistory/getPaymentHistoryEntriesBySeller/?page=' + page
    	this.dataService.getObject(URI)
	        .subscribe((response) => {
	          this.paymentHistoryBySeller = response.paymentHistoryEntries
	          for (var i = 0 ; i < this.paymentHistoryBySeller.length ; i++ ) {
	          	this.paymentHistoryBySeller[i].created = moment(this.paymentHistoryBySeller[i].created).tz(moment.tz.guess()).format("MMM Do YY, h:mm a")
	          	this.paymentHistoryBySeller[i].price = Math.ceil( this.paymentHistoryBySeller[i].price * 100 ) / 100
	          }

	        },
        (err: any) => console.log(err),
        () => console.log("paymentHistoryBySeller"));
	}

	getPaymentHistoryEntriesByPurchaser( page : number ) {
		let URI = 'paymentHistory/getPaymentHistoryEntriesByPurchaser/?page=' + page
    	this.dataService.getObject(URI)
	        .subscribe((response) => {
	          this.paymentHistoryByPurchaser = response.paymentHistoryEntries
	          console.log(this.paymentHistoryByPurchaser)
	          for (var i = 0 ; i < this.paymentHistoryByPurchaser.length ; i++ ) {
	          	console.log(this.paymentHistoryByPurchaser[i])
	          	this.paymentHistoryByPurchaser[i].created = moment(this.paymentHistoryByPurchaser[i].created).tz(moment.tz.guess()).format("MMM Do YY, h:mm a")
	          	this.paymentHistoryByPurchaser[i].price = Math.ceil( this.paymentHistoryByPurchaser[i].price * 100 ) / 100
	          }
	        },
        (err: any) => console.log(err),
        () => console.log("paymentHistoryByPurchaser"));
	}

	getUserCreditHistory( page : number ) {
		let URI = 'userCreditHistory/getUserCreditHistory/?page=' + page
    	this.dataService.getObject(URI)
	        .subscribe((response) => {
	          this.creditHistoryEntries = response.creditHistoryEntries
	          for (var i = 0 ; i < this.creditHistoryEntries.length ; i++ ) {
	          	this.creditHistoryEntries[i].created = moment(this.creditHistoryEntries[i].created).tz(moment.tz.guess()).format("MMM Do YY, h:mm a")
	          	if (this.creditHistoryEntries[i].amountOwed)
	          		this.creditHistoryEntries[i].amountOwed = Math.ceil(this.creditHistoryEntries[i].amountOwed * 100) / 100 
	          	else
	          		this.creditHistoryEntries[i].amountDisbursed = Math.ceil(this.creditHistoryEntries[i].amountDisbursed * 100) / 100 
	          }
	        },
        (err: any) => console.log(err),
        () => console.log("creditHistoryEntries"));	
	}

	toggleSales() {
		if (this.showSales === true)
			return this.showSales = false
		return this.showSales = true
	}

	togglePurchases() {
		if (this.showPurchases === true)
			return this.showPurchases = false
		return this.showPurchases = true
	}

	toggleCreditHistory() {
		if (this.showCreditHistory === true)
			return this.showCreditHistory = false
		return this.showCreditHistory = true
	}
}