import { Component , OnInit , Input } from '@angular/core'

import { DataService , SessionService } from '../../core/index'

import { IReportedUser } from '../interfaces'

@Component({
	selector : 'report-user' , 
	templateUrl : "./reportUser.html"
})

export class ReportUserComponent implements OnInit {

	loggedIn : boolean = false
	details : string = ''

	@Input() reportedUserDetails : any 

	submittedReportToServer : boolean = false
	successSubmittingReportToServer : boolean = false
	err : string

	constructor( private dataService : DataService , 
				 private sessionService : SessionService ) {}

	ngOnInit() {
		this.checkIfLoggedIn()
	}



	reportUser( reason : string ) {

		document.getElementById('reportUserModal').style.display = "block"
		document.getElementById('reportUserModalContent').style.display = "block"

		if (this.details === '') {
			this.submittedReportToServer = true
			return this.err = 'you must give details about the report'
		}
		this.err = null
		let URI = 'reportUser'
		let reportUserBody : IReportedUser = {
			reportedUserId : this.reportedUserDetails.userId , 
			reason 	  	   : reason ,
			objectId 	   : this.reportedUserDetails.objectId , 
			objectType 	   : this.reportedUserDetails.objectType , 
			details 	   : this.details
		}
		this.dataService.postObject( URI , reportUserBody)
		 	.subscribe((response : any) => {
		 		this.submittedReportToServer = true
		 		if (response.error)
		 			return this.err = response.error
		 		return this.successSubmittingReportToServer = true 
		 	})
	}

	checkIfLoggedIn() {
		this.sessionService.session.subscribe( (session:any) => {
			this.loggedIn = session.loggedIn
		})
	}

	closeModal() {
		document.getElementById('reportUserModal').style.display = "none"
		document.getElementById('reportUserModalContent').style.display = "none"
	}

}