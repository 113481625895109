import { Component , OnInit , Input} from '@angular/core'

import moment from 'moment' 
import 'moment-timezone'

import { IDream } from '../../index'

import { SessionService } from '../../../core/index'

@Component({
	selector : 'dream' , 
	templateUrl : './dream.html'
})

export class DreamComponent {
	
	constructor( 
		private sessionSerivce : SessionService 
	) {}

	session : any
	@Input() dream : IDream

	ngOnInit() {
		this.sessionSerivce.session.subscribe( ( session : any ) => {
			this.session = session
		})
		this.dream.created = moment(this.dream.created).tz(moment.tz.guess()).format("MMM Do, h:mm a")
	}

}