
<div class="col-xs-12">
	<div class="row">
		<div 
			class="col-md-4"
			style="
		    padding: 0px;
		    margin: 0px;
		    position: relative;
		    top: -2px;
	    " 
		>		
			<div 
				class="competition-details-section" 
			>
				<contest-header [contest]="createContestObject" ></contest-header>
				<div>
					<review-contest [contest]="createContestObject"></review-contest>
				</div>
			</div>
		</div>
		<div 
			class="col-xs-12 col-md-8 desktop-groups hide-on-phones" 
			style="
		    margin-top: 10px;
		    border-radius: 5px;
		    height: 200px;
		    z-index : -1 ;
	    "
    >	
			<div 
				*ngIf="createContestObject.entryPrivacy != 'private'"
				style="width: 100% ; height: 100% ; padding: 20px; text-align: center;background-color: gray; border-radius : 5px;" 
	    >
		  	<p
			  	style="
				    color: white;
				    font-size: 48px;
				    font-weight: 900;
			    "
		    >No Entries</p>
	  	</div>
			<div 
				*ngIf="createContestObject.entryPrivacy == 'private'"
				style="width: 100% ; height: 100% ; padding: 20px; text-align: center;background-color: gray; border-radius : 5px;" 
	    >
		  	<p style="
			    color: white;
			    font-size: 48px;
			    font-weight: 900;
		    ">Entries Are Private</p>
	  	</div>
		</div>	
	</div>
</div>