<div class="under-construction-container">
	<div class="under-construction-content">
			<div class="col-xs-3 inline">
				<img class="under-construction-spotidol-logo" src="./app/assets/images/spotidolLogo.png">
			</div>
			<div class="inline under-construction-text col-xs-8">
				<h1>This site is getting a remodeling</h1>
				<h2>Look out for our app in the app stores!</h2>
			</div>
		<div class="block col-xs-12">
			<support></support>
		</div>
	</div>
</div>