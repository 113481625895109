import { Injectable } from '@angular/core'
import {
    Router, Resolve , 
    ActivatedRouteSnapshot , 
    RouterStateSnapshot
} from '@angular/router';
import { DataService } from '../../core/data.service'
import { SessionService } from '../../core/session.service'
import { IContestEntry , IContestVote } from '../../shared/interfaces'

@Injectable()
export class ContestVoteResolveService {
	
	contestVoteData : {
		contestVotes : IContestVote[]
		myVote : IContestVote 
		session : any , 
		error : string , 
		totalVotes : number ,
		myVotes : number 
	} = {
		contestVotes : [] , 
		myVote : null , 
		session : null ,
		error : null , 
		totalVotes : 0 , 
		myVotes : 0
	}

	contestEntry : IContestEntry

	constructor(
  	private dataService: DataService, 
  	private sessionService : SessionService ,   
 		private router: Router
 	) {}

	getContestVoteData( contestEntry : IContestEntry ) {
		return new Promise<any>(( resolve , reject ) => {		
			this.contestEntry = contestEntry
			console.log(contestEntry)
	    	Promise.all([
				this.getContestEntryVotes(contestEntry._id) ,  
				this.getSession()
			])
			.then(() => {			
				console.log('resolved!!')
				this.getVotesInformation( this.contestVoteData.contestVotes).then(() => {
					return resolve(this.contestVoteData)
				})
		    })
		})
	}
			
	getSession() {
		return new Promise<void>( ( resolve , reject ) => {
			this.sessionService.session.subscribe( ( session : any) => {
				this.contestVoteData.session = session
				return resolve()
			})
		})
	}

	getContestEntryVotes( contestEntryId ) {
		return new Promise<void>( ( resolve , reject ) => {
			let URI = 'contestVote/' + contestEntryId
			this.dataService.getObject(URI)
				.subscribe( ( response : {
				 	status : boolean , 
				 	error : string | null , 
				 	contestEntryVotes : IContestVote[] 
				}) => {
					console.log(response)	
					if ( response.error ) 
						this.contestVoteData.error = response.error
					else 
						this.contestVoteData.contestVotes = response.contestEntryVotes
					return resolve()
				})
		})
	}

	getVotesInformation( contestVotes : IContestVote[] ) {
		return new Promise<void>( ( resolve , reject ) => {
			if ( this.contestVoteData.error )
				return resolve()
			for ( var i = 0 ; i < contestVotes.length ; i++ ) {
				this.contestVoteData.totalVotes += contestVotes[i].votes.filter((vote : string )=> {
					return ( vote === this.contestEntry._id)
				}).length
			} 
			let myVote = contestVotes.filter( ( cv : IContestVote ) => {
				return ( cv.username === this.contestVoteData.session.username )
			})[0]
			this.contestVoteData.myVote = myVote
			this.contestVoteData.myVotes = (myVote) ? myVote.votes.length : 0
			return resolve()
		})
	}

}