
<div 
	class="hide-on-phones no-margins no-padding" 
	style="
		display: flex-inline;
		align-items: center;
	"
>
	<a routerLink="/signUp" class="navbar-text">Sign Up</a>
	<a routerLink="/login" class="navbar-text">Log In</a>
	<a 
		routerLink="/competitions" 
		class="navbar-text"
		style="
			display: inline-flex;
			align-items: center;
		" 
	>
		Competitions
	</a>
	<a routerLink="/support" class="navbar-text">Support</a>
	<a routerLink="/about" class="navbar-text">
		About
<!-- 		<img 
			src="/app/assets/images/dropdown-button.png"
			style="
				width: 10px; 
				height: 10px;
			    margin: 5px;
			" 
		> -->
	</a>
</div>
<div class="show-on-phones dropdown inline no-margins no-padding navbar-height" style="margin-left: 4px;">
	<button (click)="toggleMenu()" class="inline no-padding no-margins menu-button btn btn-lg menu-height" style="background: none;">
		<img class="inline navbarMenu no-margins no-padding" height="30" width="30" src="https://storage.googleapis.com/spotidol/menu-white.png" />
	</button>
	<ul 
		style="
		    position: absolute;
		    top: 40px;
		    text-align: right;
		    padding: 0px;
			background-color: #AEB7D0 ;
			border: solid 1px gray;
			border-top: none;
		"
		*ngIf="showMenu"
	>
	    <span class="dark-purple-2 menu-text " style="padding: 3px; display: block;"><a (click)="toggleMenu()" style="color: white; padding : 2px; font-size: 12px;" routerLink="/login">Log In</a></span>
	    <span class="dark-purple-2 menu-text " 
        	style="
	        	color: white;
	        	padding: 3px; 
	        	display: block;
				
				text-align: right;
				line-height: 1;
			"
		><a (click)="toggleMenu()" style="color: white; padding : 2px; font-size: 12px;" routerLink="/signUp">Sign Up</a></span>
	    <span class="dark-purple-2 menu-text " 
        	style="
	        	color: white;
	        	padding: 3px; 
	        	display: block;
				
				text-align: right;
				line-height: 1;
			"
		><a (click)="toggleMenu()" style="color: white; padding : 2px; font-size: 12px;" routerLink="/about">About</a></span>
	    <span class="dark-purple-2 menu-text " 
        	style="
	        	color: white;
	        	padding: 3px; 
	        	display: block;
				
				text-align: right;
				line-height: 1;
			"
		><a (click)="toggleMenu()" style="color: white; padding : 2px; font-size: 12px;" routerLink="/support">Support</a></span>
	</ul>
</div>