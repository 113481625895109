<div style="overflow: hidden;">
	<div 
		*ngFor="let comment of displayComments"
		class="col-xs-12" 
		style="margin-bottom: 5px;" 
	>	
		<div
			style="
				width: 320px;
				background-color: white;
		    box-shadow: 0 0 0 1px rgba(0,0,0,.15), 0 2px 3px rgba(0,0,0,.2);
				border-radius: 4px;
		    overflow: hidden;
			"
		>
			<a
				routerLink="/profile/{{ comment.username }}"
			>
				<img 
					*ngIf="comment.profilePicture" 
					src="{{ comment.profilePicture }}"
					style="
						width : 70px ; 
						height : 70px ;
						margin : 5px ;
						border-radius: 40px;
				    display: inline;
				    float : left ;
				    vertical-align: top;
					" 
				>
				<img 
					*ngIf="!comment.profilePicture" 
					src="https://storage.googleapis.com/spotidol/spotidolEclipse.png"
					style="
						width : 70px ; 
						height : 70px ;
						margin : 5px ;
						border-radius: 40px;
				    float : left ;
				    display: inline;
				    vertical-align: top;
					" 
				>
			</a>
			<span style="width : 300px; margin-top : 10px;">
				<a
					routerLink="/profile/{{ comment.username }}"
				>
					<p style="margin-bottom: 5px; font-weight: 600;">{{ comment.username }}</p>
				</a>
				<a *ngIf="comment.isMyComment || comment.isGroupAdmin" (click)="deleteContestEntryComment(comment)">delete comment</a>
				<p>{{ comment.commentBody }}</p>
			</span>
		</div>
	</div>
</div>
<a 
	*ngIf=" 
		(contestEntryComments.length - 3) > 0 && contestEntryComments.length !== displayComments.length
	" 
	(click)="toggleDisplayComments()"
>
	<p>Show {{ contestEntryComments.length - 3 }} more...</p>
</a>
<new-comment-section 
	*ngIf="isCommenter"
	style="position: relative;"
	(madeNewComment)="submittedComment($event)"
	[contestEntryId]="contestEntry._id" 
></new-comment-section>