import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation , AfterViewInit } from '@angular/core';
import videojs from 'video.js';

@Component({
  selector: 'welcome-video',
  templateUrl: './welcomeVideo.html' ,
  styleUrls: [
    './welcomeVideo.css'
  ],
  encapsulation: ViewEncapsulation.None,
})

export class WelcomeVideoComponent implements OnDestroy {
  @ViewChild('target' ) target: ElementRef;
  // see options: https://github.com/videojs/video.js/blob/mastertutorial-options.html
  options: any
  player: videojs.Player;
  hideVideo : boolean = true

  constructor(
    private elementRef: ElementRef,
  ) { }

  ngAfterViewInit() {
  	this.options = {
  		autoplay : true , 
  		muted : true , 
  		controls : false ,
  		lopp : true , 
		  sources : [{
        src : "https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fhiphopvid.mp4?alt=media&token=60428ed1-4ad8-4a4c-98cb-b90bfe282b4f" , 
  			type : 'video/mp4'
  		}]
  	}
    // instantiate Video.js
    this.player = videojs(this.target.nativeElement, this.options, () => {
      setTimeout(()=>{this.hideVideo = false}) 
    });
  }

  ngOnDestroy() {
    // destroy player
    if (this.player) {
      this.player.dispose();
    }
  }
}