
<div>
  <div (click)="openModal()">
    <p>Create Group</p>
  </div>
</div>
<share-modal *ngIf="showShareModal" [mediaObject]="group" [mediaObjectType]="'group'" (toggleClose)="closedShareModal($event)"></share-modal>
<div id="uploadGroupModal" class="cssModal" *ngIf="showModal" style="display: block;">
		<div id="uploadGroupModalContent" class="cssModalContent modal-content row" style="display: block;
    font-size: initial;
    text-align: center;
    line-height: initial;">
  		<div>
  			<div class="modal-header ">
          <button type="button" class="btn btn-default top-left" (click)="closeModal()">Close</button>
          <h2>Create Group</h2>
  			</div>
        <div *ngIf="!submittingGroupToServer">
  				<div>
  					<form [formGroup]="createGroupForm" autocomplete="off" novalidate (ngSubmit)="createGroup(createGroupForm.value)">
              <div class="col-xs-12" [ngClass]="{ 'error' : createGroupForm.controls.groupName.invalid && createGroupForm.controls.groupName.touched }">
    						<label>Group Name</label>
    						<input class="roundBorders dark-purple-background input-styling col-xs-12" formControlName="groupName" id="groupName" type="text" placeholder="group name">
                <p class="error-msg" *ngIf="createGroupForm.controls.groupName.invalid && createGroupForm.controls.groupName.errors.required && createGroupForm.controls.groupName.touched">required</p>
              </div>
              <div class="col-xs-12" [ngClass]="{ 'error' : createGroupForm.controls.groupUrl.invalid && createGroupForm.controls.groupUrl.touched }">
    						<label>Group Handle</label>
    						<div class="col-xs-12">
                  <span>@</span><input class="roundBorders dark-purple-background input-styling" formControlName="groupUrl" id="groupUrl" type="text" placeholder="where people find you">
                </div>
                <p class="error-msg" *ngIf="createGroupForm.controls.groupUrl.invalid && createGroupForm.controls.groupUrl.errors.required && createGroupForm.controls.groupUrl.touched" >Required</p>
              </div>
              <div class="col-xs-12">
                <label>Hashtags (separated by a space)</label><input class="roundBorders dark-purple-background input-styling col-xs-12" [(ngModel)]="tagString" [ngModelOptions]="{standalone : true}" id="tags" type="text" placeholder="#sports #science">
                <div *ngIf="tagsMustStartWithHash">
                  <p>Tags must start with a '#'</p>
                </div>
              </div>
              <div *ngIf="optionalShown">
                <div class="col-xs-12">
      						<label>Fan Requires Approval</label>
                  <select class="roundBorders dark-purple-background input-styling" formControlName="fanRequiresApproval">
                    <option value="{{ true }}">Yes</option>
                    <option value="{{ false }}">No</option>
                  </select>
                </div>
                <div class="col-xs-12">              
                  <a *ngIf="!newKindOfFanFieldsState" (click)="showNewKindOfFanFields()"><label>Add another role (eg. Student)</label></a>
                  <div *ngIf="newKindOfFanFieldsState">
                    <h3>Add Another Role</h3>
                    <div class="col-xs-12">
                      <label>Title: </label>
                      <input class="roundBorders dark-purple-background input-styling col-xs-12" [(ngModel)]="fanTitle" formControlName="fanTitle" [attr.ngModelOptions]="{standalone: true}" id="fanTitle" type="text" placeholder="eg. Actor, Musician, Writer">
                    </div>
                    <div class="col-xs-12">
                      <label>Requires Approval</label>
                      <select class="roundBorders dark-purple-background input-styling" [(ngModel)]="roleRequiresApproval" [attr.ngModelOptions]="{standalone: true}" (change)="setRoleRequiresApproval()" >
                        <option value="{{ true }}">Yes</option>
                        <option value="{{ false }}">No</option>
                      </select>
                    </div>
                    <div class="col-xs-12">
                      <input (click)="addKindOfFan()" class="btn btn-info btn-lg" value="Add Role">
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="kindsOfFansState" class="col-xs-12">
                <label>Roles</label>
                <div *ngFor="let kindOfFan of kindsOfFans">
                  <label>Title : {{kindOfFan.fanTitle}}</label> <br>
                  <label *ngIf="kindOfFan.fanRequiresApproval">Requires Approval</label> <br>
               <!--    <label>Offering 321Deal : {{kindOfFan.is321DealActiveForKindOfFan}}</label> <br>
                  <label>Price For 321Deal : {{ currencySymbol }}{{kindOfFan.priceFor321DealForKindOFFan}}</label> <br> <br> -->
                </div>
              </div>
              <div class="col-xs-12">
  						  <input class="btn btn-info btn-lg" type="submit" value="Create Group">
              </div>
  					</form>					
  				</div>
        </div>

        <div class="col-xs-12">
          <div class="col-xs-offset-1 medium-text" *ngIf="submittingGroupToServer"><p>Submitting group, one moment please.</p></div>
    			<div class="col-xs-offset-1 medium-text" *ngIf="errorSubmittingGroupToServer"><p> {{ err }} </p></div>
        </div>
    		</div>
		</div>
</div>