<div class="col-xs-12">
	<div *ngIf="contestEntry" class="row">
		<div 
			class="col-md-4 col-xs-12"
			style="
			    padding: 0px;
			    margin: 0px;
			    position: relative;
			    top: -2px;
		    " 
		>
			<div 
				class="competition-details-section" 
			>
				<contest-header [contest]="contest" ></contest-header>
				<div>
					<contest [group]="group" [contest]="contest" [viewType]="'contestEntries'" [showEnterContestButton]="showEnterContestButton"></contest>
				</div>
	    	<button *ngIf="userInstance && userInstance.username" type="button" class="btn btn-default col-xs-offset-1" (click)="openInviteModal()">Share</button>
			</div>	
		</div>
		<div class="col-xs-12 col-md-8" style="padding: 0px;">
			<div>
				<contest-entries-page-contest-entry 
					[contestEntry]="contestEntry" 
					[contest]="contest" 
					[isMyGroup]="isMyGroup" 
					[fan]="fan" 
					[group]="group" 
					[userInstance]="userInstance" 
				></contest-entries-page-contest-entry>
			</div>
			<h2 class="col-xs-12">All Entries</h2>
			<div 
				*ngIf="contest.entryPrivacy != 'private' || (contest.entryPrivacy == 'private' && isMyGroup)"
	    >
	    	<div
	    		*ngFor="let otherContestEntry of contestEntries"  
	    		style="
			    	margin-top: 5px;
			    "
	    	>
		    	<contest-entries-page-contest-entry 
		    		*ngIf="contestEntry !== otherContestEntry"
		    		[contestEntry]="otherContestEntry" 
		    		[contest]="contest" 
		    		[isMyGroup]="isMyGroup" 
		    		[fan]="fan" 
		    		[group]="group" 
		    		[userInstance]="userInstance" 
		    	></contest-entries-page-contest-entry>
	    	</div>
			</div>
		</div>

	</div>
	<div *ngIf="!contestEntry" class="col-xs-offset-1 col-xs-10 col-md-6 col-md-offset-3 col-lg-4 col-lg-offset-4">
		<h3 style="text-align: center">
			This is not the entry that you're looking for
		</h3>
	</div>
</div>