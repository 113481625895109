<div
	style="
	    width: 100%;
	    overflow: hidden;
	    color : white ;
	    padding-bottom: 100px;
	"
>
	<div class="col-md-10 col-md-offset-1">
		<h1 class="jumbo-text">SPOTIDOL'S MISSION</h1>
		<h1>In the future, everyone will be living their dreams.</h1>
		<p>This future is not up for debate. It’s the only future that matters. The life of everyone’s dreams is a human right. You deserve the life of your dreams. It’s not what most people think it is. It's a simple formula, maximize peak experiences &amp; fully eliminate trauma. The best life is full of great experiences with no bad experiences. The sole purpose of technology is to enable this future.</p>
		<h1>Spotidol’s mission is to build up the value of as many idols as high & as fast as possible.</h1>
		<p>Idols are perfect embodiments of people’s highest values. Values are sets of similar preferred experiences generalized into concepts. Our highest values are sets of peak experiences generalized into concepts. Idols are perfect embodiments of the life of people’s dreams.</p>
		<h1>All people are born equal, as idols.</h1>
		<p>People are created immediately into what should be a peak experience - childbirth. All lives have the potential to be filled with peak experiences. Everyone who lives in society has a responsibility to provide peak experiences to one another. Because of this, part of Spotidol's mission is to build up the value of everyone.</p>
		<h1>Spotidol orders it’s mission with intent.</h1>
		<p>Build up - All activity that we do must be in the upward direction.</p>
		<p>The value - All activity must be to create peak experiences that become generalized into measurable concepts (people’s highest values) within our communities.</p>
		<p>As many Idols - The total amount of idols we are building value with comes before the total value per idol.</p>
		<p>As high - How much value that we can bring to each idol comes after being able to build up value for all idols.</p>
		<p>As fast as possible - The rate of growth of the total idols & the value we bring to each idol only comes after we have developed products &amp; methods to build the value of all idols.</p>
		<h1>Spotidol measures it’s value in three dimensions</h1>
		<div style="color: black;">
			<div class="col-xs-12 col-sm-4 value-section">
				<div class="value-container" >
					<p class="value-header-text">Monetization</p>			
					<div
						style="
					    height: 100px; 
					    background-image: url(https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fmonetization.jpg?alt=media&token=82fe75b1-26ab-43c8-87c2-9e21d0e84154); background-repeat: no-repeat; background-size: cover; background-position: center ;
				    "
					>
					</div>
					<p class="value-details-text">All people deserve a way of living.</p>
					<a routerLink="/values">
						<div class="values-link">
							<p>Read More...</p>
						</div>
					</a>
				</div>
			</div>
			<div class="col-xs-12 col-sm-4 value-section">
				<div class="value-container">
					<p class="value-header-text">Curation</p>			
					<div
						style="
					    background: red;
					    height: 100px;
					    background-image: url(https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fcuration.jpg?alt=media&token=5740b711-0a2b-436a-a8d9-53da4eac5f12); background-repeat: no-repeat; background-size: cover; background-position: center ;
				    "
			    >
					</div>
					<p class="value-details-text">All people deserve to share their living with others.</p>
					<a routerLink="/values">
						<div class="values-link">
							<p>Read More...</p>
						</div>
					</a>
				</div>
			</div>
			<div class="col-xs-12 col-sm-4 value-section">
				<div class="value-container">
					<p class="value-header-text">Purpose</p>			
					<div
						style="
					    height: 100px;
					    background-image: url(https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fpurpose.jpg?alt=media&token=a5b34b13-75d1-4604-ae5e-3e0a78ae7d8d); background-repeat: no-repeat; background-size: cover; background-position: center ;
				    "
					>
					</div>
					<p class="value-details-text">All people deserve to live their dreams.</p>
					<a routerLink="/values">
						<div class="values-link">
							<p>Read More...</p>
						</div>
					</a>
				</div>
			</div>
		</div>
	</div>
	<div class="col-xs-12">
		<sign-up-redirect></sign-up-redirect>
	</div>
	<footer></footer>
</div>