import { Component , OnInit , Input } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

import { DataService } from '../../core/data.service'

import { IContestEntry } from '../../shared/interfaces'

@Component({
	selector: 'my-contest-entries', 
	templateUrl: "./myContestEntries.html"
})

export class MyContestEntriesComponent implements OnInit {

	contestEntries : IContestEntry[]

	constructor(
		private route : ActivatedRoute ,  
		private dataService : DataService
	) {}

	ngOnInit () {
		this.getMyContestEntries()
	}

	getMyContestEntries() {
		let URI = 'contestEntry/getContestEntriesByContestantId'
    	this.dataService.getObject(URI)
	        .subscribe((response) => {
	        	for(var i = 0; i < response.contestEntries.length ; i++ ) {
					var created = response.contestEntries[i].created
					var dateAndTime = created.split('T')
					var time = dateAndTime[1].split(':')
					var date = dateAndTime[0].split('-')
					var year = date[0]
					var month = date[1]
					var day = date[2]
					if (day.charAt(0) === '0')
						day = day.charAt(1)

					var hour = time[0]
					var minute = time[1]
					if (hour.charAt(0) === '0')
						hour = hour.charAt(1)
					var hourNumber = parseInt(hour)
					if (hourNumber > 12) {
						hourNumber = hourNumber - 12
						hour = hourNumber + ''
						var timeString = hour + ':' + minute + ' PM UT'
					} else {
						var timeString = hour + ':' + minute + ' AM UT'
					}
					var stringifiedMonth = ''
					switch (month) {
						case '01': {
							stringifiedMonth = 'Jan'
							break
						}
						case '02': {
							stringifiedMonth = 'Feb'
							break
						}
						case '03': {
							stringifiedMonth = 'Mar'
							break
						}
						case '04': {
							stringifiedMonth = 'Apr'
							break
						}
						case '05': {
							stringifiedMonth = 'May'
							break
						}
						case '06': {
							stringifiedMonth = 'Jun'
							break
						}
						case '07': {
							stringifiedMonth = 'Jul'
							break
						}
						case '08': {
							stringifiedMonth = 'Aug'
							break
						}
						case '09': {
							stringifiedMonth = 'Sep'
							break
						}
						case '10': {
							stringifiedMonth = 'Oct'
							break
						}
						case '11': {
							stringifiedMonth = 'Nov'
							break
						}
						case '12': {
							stringifiedMonth = 'Dec'
							break
						}
					}
				    response.contestEntries[i].formattedCreationDate = timeString + ' ' + stringifiedMonth + ' ' + day + ' ' + year
	        	}
	          this.contestEntries = response.contestEntries;
	        },
        (err: any) => console.log(err),
        () => console.log("contestEntries"));
	}
}