import { Component , OnInit , NgModule , Input , EventEmitter } from '@angular/core'
import { DataService } from '../../core/data.service'
import { IGroup } from '../../shared/interfaces'

// const URL = '/api/';

@Component({
  selector : 'group-upload' , 
  templateUrl: "./groupUpload.html"
})

export class GroupUploadComponent {

  setProfilePicture : any = true
  submittingFile : boolean

  URL : string = 'api/groupPicture/'

  errorSubmittingPictureToServer : boolean
  submittedPictureToServer : boolean
  err : string
  maxUploads : number = 1

  humanizeBytes: Function;
  dragOver: boolean;

  @Input() group : IGroup 

	constructor( private dataService : DataService ) {
  }

}
