<div style="display: inline;">
	<a>
		<img *ngIf="whiteOrBlack == 'black'" (click)="toggleMediaMenu()" src="./app/assets/images/report-btn.png" style="width: 18px; height: 18px;">
		<img *ngIf="whiteOrBlack == 'white'" (click)="toggleMediaMenu()" src="./app/assets/images/report-btn-white.png" style="width: 18px; height: 18px;">
	</a>
</div>
<div *ngIf="showMediaMenu" style="position: absolute; top: 20px; left: -60px; z-index: 5">
	<share-on-social *ngIf="showShareButtons" [mediaObject]="mediaObject" [mediaObjectType]="mediaObjectType"></share-on-social>
	<report-user [reportedUserDetails]="reportedUserDetails"></report-user>
</div>
