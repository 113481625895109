import { Injectable } from '@angular/core'

import { DataService } from '../../../core/data.service'
import { SessionService } from '../../../core/session.service'
import { IContestEntry , IContestVote } from '../../../shared/interfaces'

@Injectable()
export class ScoreCardContestVoteResolveService {
	
	contestVoteData : {
		contestVotes : IContestVote[]
		myVote : IContestVote 
		session : any , 
		error : string , 
		totalVotes : number ,
		myVotes : number 
	} = {
		contestVotes : [] , 
		myVote : null , 
		session : null ,
		error : null , 
		totalVotes : 0 , 
		myVotes : 0
	}

	contestEntry : IContestEntry

	constructor(
	  	private dataService: DataService, 
	  	private sessionService : SessionService
 	) {}

	getContestVoteData( contestEntry : IContestEntry ) {
		console.log( 'loading votes data ')
		return new Promise<any>(( resolve , reject ) => {		
			this.contestEntry = contestEntry
			this.getSession().then(() => {			
				this.getMyContestEntryVote().then(() => {
					console.log('resolved contest votes!!')
						return resolve(this.contestVoteData)
				})  
	    })
		})
	}
			
	getSession() {
		return new Promise<void>( ( resolve , reject ) => {
			this.sessionService.session.subscribe( ( session : any) => {
				this.contestVoteData.session = session
				return resolve()
			})
		})
	}

	getMyContestEntryVote() {
		return new Promise<void>( ( resolve , reject ) => {
			let URI = 'contestVote/getMyVotes/' + this.contestEntry.contestId
			this.dataService.getObject(URI)
				.subscribe( ( response : {
				 	status : boolean , 
				 	error : string | null , 
				 	contestVote : IContestVote 
				}) => {
					if ( response.error ) 
						this.contestVoteData.error = response.error
					else 
						this.contestVoteData.myVote = response.contestVote
					return resolve()
				})
		})

	}

}