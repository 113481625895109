import { Component , Input , OnInit} from '@angular/core'

import { IGroup } from '../../shared/interfaces'

@Component({
  selector: 'group-header', 
	templateUrl: "./groupHeader.html"
})

export class GroupHeaderComponent {

	@Input() group : IGroup

	profilePictureUrl : string

	ngOnInit() {
		if ( this.group.profilePicture )
			this.profilePictureUrl = 'url(' + this.group.profilePicture + ')'
		else 
			this.profilePictureUrl = 'url(https://storage.googleapis.com/spotidol/spotidolBannerPicture.png)'
	}

}