<div style="color: white;">
	<h2 style="text-align: center;">Voting Details</h2>
	<div class="col-xs-12">
		<span>Voting Type </span>
		<select class="roundBorders dark-purple-background input-styling" [(ngModel)]="votingCriteria.votingType">
			<option value="votesToScore">Votes Go To Score
			</option>
			<option value="crowdChoice">Crowd Choice</option>
			<option value="tieBreaker">Tie Breaker</option>
		</select>
	</div>
	<div class="col-xs-12">
		<span>Charge Voters </span>
		<select class="roundBorders dark-purple-background input-styling" [(ngModel)]="createContestObject.chargeVoters">
			<option value="true">Yes
			</option>
			<option value="false">No</option>
		</select>
	</div>
	<div *ngIf="createContestObject.chargeVoters === 'true'" class="col-xs-12">
		<span>Price Per Vote {{ currencySymbol }}</span>
		<input class="roundBorders dark-purple-background input-styling"  [(ngModel)]="createContestObject.pricePerVote" id="pricePerVote" mask="9*.99" type="text" placeholder="Price Per Vote">
	</div>
	<div class="col-xs-12">
		<span>Max # of <span *ngIf="createContestObject.chargeVoters === 'true'">Free </span>Votes Per Person </span>
		<input class="roundBorders dark-purple-background input-styling"  [(ngModel)]="createContestObject.maxVotes" id="max # of votes" type="text" mask="9*" placeholder="(optional)">
	</div>
	<div *ngIf="votingCriteria.votingType === 'votesToScore'" class="col-xs-12">
		<span>Score Per Vote </span>
		<input class="roundBorders dark-purple-background input-styling" [(ngModel)]="votingCriteria.scorePerVote" type="text" mask="9*" placeholder="score Per Vote">
	</div>
	<input (click)="submitDetails()" class="btn btn-info btn-lg" type= "submit" value="Submit">
</div>