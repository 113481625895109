import { Component , OnInit , Output , EventEmitter } from '@angular/core'
import { FormControl , FormGroup , Validators } from '@angular/forms'
import { ActivatedRoute , Router } from '@angular/router' 

import { DataService , SessionService , UserDataService } from '../../../core/index'
import { IContest , IContestEntry , IFan , IGroup } from '../../../shared/interfaces'

@Component({
	templateUrl : 'uploadContestEntryLogin.html' , 
	selector : 'upload-contest-entry-login'
})

export class UploadContestEntryLoginComponent {
	
	loginForm : FormGroup

	username : string 
	err : string 
	contests : IContest[]
	contestEntries : IContestEntry[]
	myFans : IFan[]
	createdGroups : IGroup[]
	myGroups : IGroup[]

	session : any
	userData : any

	submittingLogInToServer : boolean = false

	submittedSignUp : any = new EventEmitter<boolean>()

	usernameNotDefined : boolean
	loggedIn : boolean
	loginFailed : boolean 

	constructor(
		private dataService : DataService , 
		private sessionService : SessionService , 
		private userDataService : UserDataService , 
		private router : ActivatedRoute ,
		private route : Router 
	) {}

	ngOnInit() {
		if (this.username === null) {
			this.usernameNotDefined = true
		}
		if (this.router.snapshot.params['failed'] === 'failed') {
				this.loggedIn = false
				this.loginFailed = true
		}
		if (this.router.snapshot.params['failed'] === 'success') {
				window.location.replace('/home')
		}
		let username = new FormControl()
		let password = new FormControl()
		this.loginForm = new FormGroup({
			username: username ,
			password: password
		})
	}

	login( formValues : any ) {
		this.submittingLogInToServer = true
		let URI = 'user/login'
		this.dataService.postObject(URI, formValues)
			.subscribe((response : any) => {
				this.submittingLogInToServer = false
				if (response.error) 
					return this.err = response.error
				if (response.username === undefined)
					return this.usernameNotDefined = true
				else {
					this.username = response.username
					this.loggedIn = true
				}
				window.location.reload()
				Promise.all([
					this.getSession() ,
					this.getUserData()
				]).then(() => {
					this.submittedSignUp.emit(true)
				})
			})
	}

	getSession() {
		return new Promise<void>( ( resolve , reject ) => {		
			let URI = 'user/checkSession'
			this.dataService.getObject(URI)
				.subscribe((response : any) => {
					console.log(response)
					this.session = response.sessionStatus
					this.sessionService.renewSession( response.sessionStatus )
					return resolve()
				})
		})
	}

	getUserData() {
		return new Promise<void>( ( resolve , reject ) => {
			if ( this.loggedIn !== true )
				return resolve() 
			Promise.all([
				this.getCreatedGroups(),
				this.getMyGroups(),
				this.getMyContests(),
				this.getMyFans(), 
				this.getMyContestEntries()
			]).then(() => {
				this.userData = {
					myContests : this.contests ,
					myContestEntries : this.contestEntries , 
					myFans : this.myFans ,
					createdGroups : this.createdGroups , 
					myGroups : this.myGroups  
				}
				this.userDataService.renewUserData(this.userData)
				return resolve()
			})
		})
	}


	getCreatedGroups(){
		return new Promise<void>( ( resolve , reject ) => {
			let URI = 'group/getGroupsByUserId'
	    	this.dataService.getObject(URI)
		        .subscribe((response) => {
		          	this.createdGroups = response.groups;
					return resolve()
		        },
	        (err: any) => console.log(err),
	        () => console.log("groupsICreated"));
		})
	}

	getMyContests(){
		return new Promise<void>( ( resolve , reject ) => {
			let URI = 'contest/getContestsByUserId'
			this.dataService.getObject(URI)
				.subscribe( ( response ) => {
					this.contests = response.contests
					return resolve()
				},
	        (err: any) => console.log(err),
	        () => console.log("groupsImAFanOf"));
		})
	}

	getMyContestEntries(){
		return new Promise<void>( ( resolve , reject ) => {
			let URI = 'contestEntry/getContestEntriesByContestantId'
			this.dataService.getObject(URI)
				.subscribe( ( response ) => {
					this.contestEntries = response.contestEntries
					return resolve()
				},
	        (err: any) => console.log(err),
	        () => console.log("groupsImAFanOf"));
		})
	}

	getMyGroups(){
		return new Promise<void>( ( resolve , reject ) => {
			let URI = 'group/getGroupsByFanId'
	    	this.dataService.getObject(URI)
		        .subscribe((response) => {
		          	this.myGroups = response.groups;
					return resolve()
		        },
	        (err: any) => console.log(err),
	        () => console.log("groupsImAFanOf"));
	    })
	}

	getMyFans(){
		return new Promise<void>( ( resolve , reject ) => {
			let URI = 'fan/getFansBySessionId'
	    	this.dataService.getObject(URI)
		        .subscribe((response) => {
		          	this.myFans = response.fans;
					return resolve()
		        },
	        (err: any) => console.log(err),
	        () => console.log("groupsImAFanOf"));
	    })
	}
}