import { Component , OnInit , Input , EventEmitter } from '@angular/core'
import { FormControl , FormGroup } from '@angular/forms'
import { Router } from '@angular/router'
import { DataService , SessionService , UserDataService } from '../../core/index'

import { IContest , IFan , IGroup , IContestEntry } from '../../shared/interfaces'
import { InternationalService } from '../../shared/international.service'
import { stripePublishableKey } from '../../shared/keys/keys'
import { zeroDecimalCurrencies } from '../../shared/international/zeroDecimalCurrencies'

import moment from 'moment' 
import 'moment-timezone'

@Component({
  selector: 'upload-code-contest-entry', 
  templateUrl: "./uploadCodeContestEntry.html"
})

export class UploadCodeContestEntryComponent {

  submittedContestEntryToServer : boolean = false
  successSubmittingContestEntryToServer : boolean
  errorSubmittingContestEntryToServer : boolean 
  alreadyEnteredContest : boolean
  userNotSignedIn : boolean
  userData : any
  userLoaded : boolean = false
  err : string 
  @Input() myFan : IFan | boolean
  modalId : string
  textEntryBody : string
  priceToBecomeKindOfFan : number = 0

  showSignin : boolean = false 
  showSignup : boolean = true

  @Input() contest : IContest
  @Input() group : IGroup

  contestEntry : IContestEntry
  session : any
  tip : number = 0
  entryPrice : number 

  ngOnInit() {
    this.modalId = 'uploadCodeEntryModal' + this.contest._id
    this.sessionService.session.subscribe( ( session : any ) => {
      this.session = session
      this.setEntryPrice()
    })
    this.userDataService.userData.subscribe( ( userData : any ) => {
      this.userData = userData
    })

  }


  setEntryPrice( ) {
    this.entryPrice = this.contest.earlyRegistration && moment().isBefore(this.contest.earlyRegistration.registrationTime) ?
      this.contest.earlyRegistration.registrationPrice 
      :
      this.contest.registration && moment().isBefore(this.contest.registration.registrationTime) ?
        this.contest.registration.registrationPrice
        :
        this.contest.lateRegistration && moment().isBefore(this.contest.lateRegistration.registrationTime) ?
          this.contest.lateRegistration.registrationPrice
          :
          this.contest.contestPrice
    if (this.internationalService.getCurrencyCode( this.session.country ) !== this.contest.currency ) {
      this.entryPrice = this.entryPrice * this.session.exchangeRate / this.contest.exchangeRate 
    }
  }
	constructor( 
    private dataService : DataService ,
    private userDataService : UserDataService , 
    private sessionService : SessionService , 
    private internationalService : InternationalService , 
    private router : Router 
  ) {}


  getFanAndContest() {
    this.userLoaded = true
    if ( !this.contest.groupUrl )
      this.myFan = true
    this.sessionService.session.subscribe( (session:any) => { 
      if (session.loggedIn !== true) 
        return this.userNotSignedIn = true
    })
    this.getMyFan(this.contest.groupUrl)
    this.openModal()
    this.selectContest()
    this.getPriceToBecomeAKindOfFan()
  }

  toggleNotSignedInPage() {
    this.showSignup = !this.showSignup
    this.showSignin = !this.showSignin
  }

  openModal() {
    document.getElementById('uploadContestEntryModal' + this.contest._id ).style.display = "block"
    document.getElementById('uploadContestEntryModalContent' + this.contest._id ).style.display = "block"
  }

  closeModal() {
    document.getElementById('uploadContestEntryModal' + this.contest._id ).style.display = "none"
    document.getElementById('uploadContestEntryModalContent' + this.contest._id ).style.display = "none"
  }


  signedUserIn( notSignedUp : boolean ) {
    console.log( 'signedIN' , notSignedUp )
    this.userNotSignedIn = notSignedUp 
  }

  becameAFan( newFan : IFan ) {
    this.myFan = newFan
  }

  getPriceToBecomeAKindOfFan() {
    if ( this.contest.groupId ) {
      if ( this.contest.kindOfFan !== 'fan') {
        this.priceToBecomeKindOfFan = this.group.kindsOfFans.filter(( kindOfFan : any ) => {
          return (kindOfFan.fanTitle === this.contest.kindOfFan)
        })[0].priceToBecomeKindOfFan
      } else
        return this.priceToBecomeKindOfFan = this.group.priceToBecomeFan
    }
  }

  getMyFan( url : string ) {
    let URI = 'fan/getMyFanByGroupUrlAndKind/?groupUrl=' + url + '&kindOfFan=' + this.contest.kindOfFan 
      this.dataService.getObject(URI)
          .subscribe((response : any) => {
            this.myFan = response.fan;
          },
          (err: any) => console.log(err),
          () => console.log("myFan"));
  }

  selectContest() {
    let URI = 'contest/getContestById/' + this.contest._id
    this.dataService.getObject(URI)
      .subscribe((response : any) => {
        this.selectContestEntry()
      })
  }

  selectContestEntry() {
    let URI = 'contestEntry/getMyContestEntryByContestantUsername'
    this.dataService.getObject(URI)
      .subscribe((response : any) => {
        if (response.contestEntry !== null && response.contestEntry.paid == true) {
          this.errorSubmittingContestEntryToServer = true
          this.err = 'you have already entered into this contest'
          return this.alreadyEnteredContest = true
        }
        this.alreadyEnteredContest = false
      })
  }

  uploadTextEntry( formValues : any ) {
    let URI = 'contestEntry/createTextContestEntry'
    if (this.tip > 0 && this.tip < 0.75) {
      this.successSubmittingContestEntryToServer = false 
      return this.err = 'The tip must be at least 75 cents'
    }
    if (this.entryPrice < 1 && this.entryPrice > 0)
      var stripeCharge = this.internationalService.convertCurrencyAmount(this.entryPrice * 100 + 3 + this.tip * 100 , this.session.exchangeRate)
    else 
      var stripeCharge =  this.internationalService.convertCurrencyAmount(this.entryPrice * 100 * 1.05 + this.tip * 100 , this.session.exchangeRate)

    if ((<any>zeroDecimalCurrencies).indexOf(this.internationalService.getCurrencyCode(this.session.country) ) > -1 )
      (<any>stripeCharge) = Math.ceil(<any>stripeCharge / 100)
    formValues = {
      textEntry : formValues , 
      contestId : this.contest._id
    }

    this.dataService.postObject( URI , formValues )
      .subscribe((response) => { 
        if (response.error !== null) {
          this.submittedContestEntryToServer = false
          this.errorSubmittingContestEntryToServer = true
          return this.err = response.error
        }
        if (this.entryPrice == 0 && this.tip == 0) {
          this.errorSubmittingContestEntryToServer = false
          this.err = null
          this.submittedContestEntryToServer = true
          this.userData.myContestEntries.push(response.contestEntry)
          this.userDataService.renewUserData(this.userData)
          this.closeModal()
          this.contestEntry = response.contestEntry
          return this.successSubmittingContestEntryToServer = true
        }
        var handler = (<any>window).StripeCheckout.configure({
          key: stripePublishableKey,
          locale: 'auto',
          currency : this.internationalService.getCurrencyCode(this.session.country) , 
          token: (token: any) => {
            var URL = 'contestEntry/updateContestEntryPaid/' + this.contest._id
            response.contestEntry.token = token
            response.contestEntry.contestPrice = this.entryPrice
            response.contestEntry.token = token
            response.contestEntry.group = this.group
            response.contestEntry.currency = this.internationalService.getCurrencyCode(this.session.country)
            this.dataService.postObject(URL, response.contestEntry)
              .subscribe(response => {
                if (response.error){
                  this.submittedContestEntryToServer = false
                  this.errorSubmittingContestEntryToServer = true
                  return this.err = response.error
                }
                this.userData.myContestEntries.push(response.contestEntry)
                this.userDataService.renewUserData(this.userData)
                this.errorSubmittingContestEntryToServer = false
                this.err = null
                this.submittedContestEntryToServer = true
                this.contestEntry = response.contestEntry
                return this.successSubmittingContestEntryToServer = true
              })
          }
        })
        handler.open({
          name: this.contest.contestName ,
          description: 'Entry' , 
          amount: stripeCharge
        });
      },
      (err: any) => {
        this.submittedContestEntryToServer = false
        this.errorSubmittingContestEntryToServer = true
        this.err = 'internal server error, please try again'
      }
    )
  }
}
