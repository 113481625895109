import { Component , OnInit } from '@angular/core'
import { Router } from '@angular/router';

import { CreateContestSubjectService } from '../../index'
import { zeroDecimalCurrencies } from '../../../shared/international/zeroDecimalCurrencies'

import { stripePublishableKey } from '../../../shared/keys/keys'


import { DataService , SessionService , UserDataService } from '../../../core/index'
import { InternationalService } from '../../../shared/international.service'

@Component({
	selector : 'create-contest-navbar' ,
	templateUrl : './createContest.navbar.html' ,
	styleUrls : ['./createContest.navbar.css']
})

export class CreateContestNavbarComponent {

	createContestObject : any
	session : any
	userData : any
	stripeCharge : number
	err : string
	creatingContest : boolean

	buttons : {
		name : string
		url : string
		style? : any
		completed? : boolean
	}[] = [{
		name : 'Group' , 
		url : '/createCompetition/group'
	} , {
		name : 'Details' , 
		url : '/createCompetition/details'
	} , {
		name : 'Entry' , 
		url : '/createCompetition/entry'
	} , {
		name : 'Voting' , 
		url : '/createCompetition/voting'
	} , {
		name : 'Judging' , 
		url : '/createCompetition/judging'
	} , {
		name : 'Prizes' , 
		url : '/createCompetition/prizes'
	} , {
		name : 'Review' , 
		url : '/createCompetition/review'
	} ]

	constructor(
		private router: Router , 
		private dataService : DataService , 
		private sessionService : SessionService , 
		private userDataService : UserDataService , 
		private internationalService : InternationalService , 
		private createContestSubjectService : CreateContestSubjectService
	) {}

	ngOnInit() {

		this.selectNavigation({ name : window.location.pathname.split('/')[2]})          

		this.userDataService.userData.subscribe( ( userData : any ) => {
			this.userData = userData
		})

		this.createContestSubjectService.createContestObject.subscribe( ( createContestObject ) => {
			this.buttons[0].completed = (!!createContestObject.group)
			this.buttons[1].completed = ( createContestObject.contestName && createContestObject.description && createContestObject.userContestHidden	)
			this.buttons[2].completed = ( createContestObject.contestType && createContestObject.entryPrivacy && createContestObject.endOfContest && createContestObject.winnerSelectedDate )
			this.buttons[3].completed = ( createContestObject.scoreCard && createContestObject.scoreCard.votingCriteria )
			this.buttons[4].completed = ( createContestObject.scorePrivacy && createContestObject.scoreCard && createContestObject.scoreCard.judgingCriteria )
			this.buttons[5].completed = ( createContestObject.rewardCash || createContestObject.rewardOther )
			this.createContestObject = createContestObject
		})

		this.sessionService.session.subscribe( ( session : any ) => {
			this.session = session
		})

	}


	selectNavigation( currentButton : any ) {
		this.buttons = this.buttons.map(( button ) => {
			button.style = ( button.name.toLowerCase() === currentButton.name.toLowerCase() ) ? { 'background-color' : '#1D1A21' } : null
			return button
		})
		this.buttons[6].completed = ( currentButton.name === 'Review' && this.buttons[0].completed && this.buttons[1].completed && this.buttons[2].completed && this.buttons[3].completed && this.buttons[4].completed && this.buttons[5].completed )			
		if ( currentButton.url )
	    this.router.navigate([currentButton.url]);
	}

	createContest() {
		if (this.creatingContest)
			return
		this.creatingContest = true
		this.createContestObject.exchangeRate = this.session.exchangeRate
		let URI = 'contest'
		let chargeAmount : number = 0	
		for (let i = 0 ; i < this.createContestObject.rewards.length ; i++) {	
			if (this.createContestObject.rewards[i].rewardCash > 0)
				chargeAmount += parseFloat(this.createContestObject.rewards[i].rewardCash)
		}
		if ((this.createContestObject.rewardCash > 0 && this.createContestObject.rewardCash < 1))
		this.stripeCharge = this.createContestObject.rewardCash * 100 + 3 + chargeAmount * 100 * 1.03
			else 
		this.stripeCharge = this.createContestObject.rewardCash * 100 * 1.03 + chargeAmount * 100 * 1.03
		if ((<any>zeroDecimalCurrencies).indexOf(this.internationalService.getCurrencyCode(this.session.country)) > -1)
		  this.stripeCharge = Math.ceil(this.stripeCharge / 100)
		this.createContestObject.noGroupVideo = true 
		if ( this.createContestObject.group ) {
			this.createContestObject.groupUserId = this.createContestObject.group.userId  
			this.createContestObject.groupName = this.createContestObject.group.groupName  
			this.createContestObject.groupUrl = this.createContestObject.group.groupUrl  
			this.createContestObject.groupId = this.createContestObject.group._id  			
			this.createContestObject.groupProfilePicture = this.createContestObject.group.profilePicture
		}
		this.createContestObject.currency = this.internationalService.getCurrencyCode(this.session.country)
		this.dataService.postObject(URI, this.createContestObject)
			.subscribe((response : any) => {
				this.creatingContest = false
				if (response.error !== null)  
				  return this.err = response.error
				if (this.stripeCharge > 0)
					return this.chargeCard( response.newContest )
				else {
				  this.userData.myContests.push(response.newContest)
				  this.userDataService.renewUserData(this.userData)
					this.router.navigateByUrl( '/competition/' + response.newContest._id )
					return this.err = null
				}
		  }) 
	}

	chargeCard( contest : any ) {
		var handler = (<any>window).StripeCheckout.configure({
		  key: stripePublishableKey,
		  locale: 'auto',
		  currency: this.internationalService.getCurrencyCode(this.session.country) , 
		  token: (token: any) => {
				contest.token = token
				this.updateContestPaid(contest)
		  }
		})

		handler.open({
		  name: 'Create Contest' ,
		  description: 'Please pay for the cash prizes for' + contest.contestName , 
		  amount: this.stripeCharge , 
		});
	}

	updateContestPaid(contest : any) {
		this.creatingContest = true
		let URI = 'contest/updateContestPaid'
		this.dataService.postObject(URI, contest)
			.subscribe((response : any) => {
				this.creatingContest = false
				if (response.error !== null)
				  return this.err = response.error
			  this.userData.myContests.push(response.newContest)
			  this.userDataService.renewUserData(this.userData)
				this.router.navigateByUrl('/competition/' + response.newContest._id)
		  })
	}

}