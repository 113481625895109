import { Injectable } from '@angular/core';
import {
    Router, Resolve,
    ActivatedRouteSnapshot , 
    RouterStateSnapshot
} from '@angular/router';
import { IContest } from '../shared/interfaces'
import { DataService } from '../core/data.service'
import { SessionService } from '../core/session.service'
import { Observable , Observer } from 'rxjs';

@Injectable()
export class WelcomeResolveService {

    constructor(private dataService: DataService, private sessionService : SessionService , 
     private router: Router) { }
	
    welcomeData : any = {}

	resolve(route:ActivatedRouteSnapshot, state:RouterStateSnapshot) {
		return new Promise<void>( ( resolve , reject) => {
			Promise.all([
				this.getMostRecentContests(1) , 
				this.getFeaturedCompetitions() 
			]).then(()=> {
				return resolve(this.welcomeData)
			})
		})
	}
	getMostRecentContests( page : number ) {
		return new Promise<void>( ( resolve , reject ) => {
			let URI = 'contest/getWelcomeContests' 
	    	this.dataService.getObject(URI)
		        .subscribe((response : any) => {
	          		this.welcomeData.contests = response.contests;
	          		return resolve()
		        },
	        (err: any) => console.log(err),
	        () => console.log("contests"));
		})
	}

	getTopCashRewardsContests() {
		return new Promise<void>( ( resolve , reject ) => {
			let URI = 'contest/getContestsByTopCashReward'
	    	this.dataService.getObject(URI)
		        .subscribe((response : any) => {
		          for (var i = 0 ; i < response.contests.length ; i++) {
					var endOfContest = response.contests[i].endOfContest
					var dateAndTime = endOfContest.split('T')
					var date = dateAndTime[0].split('-')
					var year = date[0]
					var month = date[1]
					var day = date[2]
					if (day.charAt(0) === '0')
						day = day.charAt(1)

					var stringifiedMonth = ''
					switch (month) {
						case '01': {
							stringifiedMonth = 'Jan'
							break
						}
						case '02': {
							stringifiedMonth = 'Feb'
							break
						}
						case '03': {
							stringifiedMonth = 'Mar'
							break
						}
						case '04': {
							stringifiedMonth = 'Apr'
							break
						}
						case '05': {
							stringifiedMonth = 'May'
							break
						}
						case '06': {
							stringifiedMonth = 'Jun'
							break
						}
						case '07': {
							stringifiedMonth = 'Jul'
							break
						}
						case '08': {
							stringifiedMonth = 'Aug'
							break
						}
						case '09': {
							stringifiedMonth = 'Sep'
							break
						}
						case '10': {
							stringifiedMonth = 'Oct'
							break
						}
						case '11': {
							stringifiedMonth = 'Nov'
							break
						}
						case '12': {
							stringifiedMonth = 'Dec'
							break
						}
					}
				    response.contests[i].formattedEndOfContest = stringifiedMonth + ' ' + day + ' ' + year
		          }
	          		this.welcomeData.topCashRewardContests = response.contests;
	          		resolve()
		        },
	        (err: any) => console.log(err),
	        () => console.log("contests"));
		})
	}

	getFeaturedCompetitions() { 
		return new Promise<void>( ( resolve , reject ) => {
			let URI = 'contest/getFeaturedContests'
			this.dataService.getObject(URI)
		        .subscribe((response) => {
	          		this.welcomeData.featuredContests = response.contests
	          		return resolve()
		        },
	        (err: any) => console.log(err),
	        () => console.log("featuredContests"));
		})
	}	

	getTrendingContestEntries() {
		return new Promise<void>( ( resolve , reject ) => {
			let URI = 'contestEntry/getTrendingContestEntries'
			this.dataService.getObject(URI)
		        .subscribe((response) => {
	          		this.welcomeData.trendingContestEntries = response.contestEntries
	          		return resolve()
		        },
	        (err: any) => console.log(err),
	        () => console.log("trendingContestEntries"));
		})
	}

	getMostRecentGroups( page : number ) {
		return new Promise<void>( ( resolve , reject ) => {
			let URI = 'group/getMostRecentGroups/' + page
	    	this.dataService.getObject(URI)
		        .subscribe((response) => {
		        	console.log(response)
	          		this.welcomeData.groups = response.groups;
	          		return resolve()
		        })
		})
	}
}
