import { Component , OnInit } from '@angular/core'

import { DataService } from '../../core/index'

import { IContest } from '../../shared/interfaces'

@Component({
	templateUrl : './welcomeContests.html' , 
	styleUrls : ['./welcomeContests.css']
})

export class WelcomeContestsComponent {

	openContests : IContest[] = []
	publicEntryContests : IContest[] = []

	constructor(
		private dataService : DataService , 
	) {}

	ngOnInit () {
		this.getOpenContests()
		this.getPublicEntryContests()
	}

	getOpenContests() {
		let URI = 'contest/getOpenContests'
		this.dataService.getObject(URI)
			.subscribe( ( response : any ) => {
          		this.openContests = response.contests;
			})
	}


	getPublicEntryContests() {
		let URI = 'contest/getPublicEntryContests'
		this.dataService.getObject(URI)
			.subscribe( ( response : any ) => {
          		this.publicEntryContests = response.contests;
			})
	}

	linkToDownload() {
		var isIOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
		var isAndroid = !!navigator.platform && /android/.test(navigator.platform)
		if (isIOS) {
			window.location.href = "https://itunes.apple.com/us/app/spotidol-competitions/id1441184363?mt=8"
		}
		if (isAndroid) {
			window.location.href = "http://play.google.com/store/apps/details?id=com.spotidol.spotidol"
		} else 
			window.location.href = "https://itunes.apple.com/us/app/spotidol-competitions/id1441184363?mt=8"
	}

}