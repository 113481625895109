<div class="col-xs-12 no-margins no-padding" style="z-index: 3 ; border-bottom : solid 1px gray;">
	<div style="display: flex; flex-direction: row; justify-content: space-between;" class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1">	
		<div style="display: flex; align-items: center;">
			<navbar-logged-in *ngIf="loggedIn"></navbar-logged-in>
			<navbar-not-logged-in style="display: inline-flex; align-items: center;"  *ngIf="!loggedIn"></navbar-not-logged-in>
			<div>
				<select 
					class="white-text normal-select-countries"
					style="background: none; margin-left : 10px;" 
					[(ngModel)]="country" 
					(change)="changeCountry(country)"
				>				
					<option 
						*ngFor="let countryCode of countryCodes" 
						value="{{ countryCode }}" 
						style="background-color: #121014;"
					>{{ countryCode }}</option>
				</select>
			</div>
		</div>
		<div 
			class="white-text no-padding no-margins" 
		>
			<a 
				class="hide-on-phones" 
				(click)="showSearchInput()"
			>
				<h4 style="
					color: white;
					display: inline;
				    margin-right: 5px;
				    font-size: 12px;
				    position: relative;
			    ">Search</h4>
			</a>
			<button class="inline no-margins menu-button btn btn-lg menu-height" style="padding: 10px; border: none; background: none;"
				(click)="showSearchInput()"
			>
				<img src="./app/assets/images/search.png" height="30px" width="30px" />
			</button>

			<a 
				(click)="navigateHome()" 
				class="home-button"
				style="width: 100%;" 
			>
				<img (click)="navigateHome()" class="inline navbar-height vertical-center text-center spotidol-com-logo" src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fspotidolcom%20logo%20white.png?alt=media&token=1dc3985f-7450-4e70-a2a0-a182283d028c" />
				<img (click)="navigateHome()" class="inline navbar-height vertical-center text-center spotidol-circle-logo" src="/app/assets/images/spotidolEclipse.png" />
			</a>
		</div>
	</div>
	<div *ngIf="referralCodeActive" class="col-xs-12" style="position: absolute; top: 60px; z-index: 3; padding: 0px;">
		<referral-code-active></referral-code-active>
	</div>

	<div 
	    [@showSearchTrigger]="showSearchState" 
	    class="navbar-search-container hide-on-phones"
	>
		<div class="navbar-search-by inline col-lg-6 col-md-8 col-sm-12">
			<span>Search By: </span>
			<button class="search-by-button" [style.backgroundColor]="competitionsButtonBackground"  (click)="changeSearchBy('competitions')">Competitions</button>
			<button class="search-by-button" [style.backgroundColor]="groupsButtonBackground" (click)="changeSearchBy('groups')">Groups</button>
			<button class="search-by-button" [style.backgroundColor]="usersButtonBackground" (click)="changeSearchBy('users')">Users</button>
		</div> 
		<input id="navbar-search" (keyup)="setSearchTimer()" [(ngModel)]="navbarSearch" class="navbar-search-input col-lg-6 col-md-4 col-sm-12" type="text" placeholder="Type Here...">
	</div>

	<div 
    [@showSearchTrigger]="showSearchState" 
    class="navbar-search-container-phone show-on-phones"
	>
		<div class="navbar-search-by-phone inline col-lg-6 col-md-8 col-sm-12">
			<span>Search By: </span>
			<button class="search-by-button" [style.backgroundColor]="competitionsButtonBackground"  (click)="changeSearchBy('competitions')">Competitions</button>
			<button class="search-by-button" [style.backgroundColor]="groupsButtonBackground" (click)="changeSearchBy('groups')">Groups</button>
			<button class="search-by-button" [style.backgroundColor]="usersButtonBackground" (click)="changeSearchBy('users')">Users</button>
		</div> 
		<div>
			<input id="navbar-search" (keyup)="setSearchTimer()" [(ngModel)]="navbarSearch" class="navbar-search-input-phone" type="text" placeholder="         Type Here...">
		</div>
	</div>
  <div id="helpModal" class="cssModal" role="dialog">
	  <div id="helpModalContent" class="cssModalContent modal-content row" style="max-width: 650px ;background-color: #1D1A21;">
	  	<onboarding (onboardingClosed)="closeHelp()"></onboarding>
	  </div>
  </div>

	<div class="display-on-top no-margins no-padding bottom col-xs-12 purple-gray-background" style="z-index: 3;">
		<audio-player></audio-player>
	</div>
</div>