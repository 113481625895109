<div>
	<div class="fb-share-button" data-href="https://spotidol.com" data-layout="button_count" data-size="small" data-mobile-iframe="true"><a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https://spotidol.com/{{link}}&amp;src=sdkpreparse" class="fb-xfbml-parse-ignore"><img src="https://storage.googleapis.com/spotidol/fshare.jpg" style="width: 80px ; height: 31px;"></a></div>
	<div class="block">
		<a target="_blank" href="http://twitter.com/share?url=https://spotidol.com/{{link}}&hashtags=spotidol,competition,contest,group"><img src="https://storage.googleapis.com/spotidol/tweetbutton.jpg" style="width: 80px; height: 31px; "></a>
	</div>
	<button (click)="copyUrl()" style="width: 80px; height: 31px; background-color: #352682; padding-top: 8px; border: none;">
		<p style="text-align: center; color: white;">COPY URL</p>
	</button>
	<div *ngIf="copiedUrl" style="width: 80px; height: 31px; background-color: #352682; padding-top: 8px; border: none;">
		<p style="text-align: center; color: white;">Copied Url</p>
	</div>
</div>