<div class="col-xs-10 col-xs-offset-1">
	<div class="row text-shadow">
		<div class="affiliates-header">
			<h1>Be a Spotidol Affiliate</h1>
			<h2>Profit From Competitions</h2>
		</div>
	</div>
	<div class="row" style="color: white;">
		<div class="col-xs-12 col-sm-6 lighter-background affiliates-sign-up">
			<div 
				style="
					height: 100%;
	                display: flex;
	                flex-direction: column;
	                justify-content: space-around;
	                align-items: center;
	            "
	            *ngIf="!submittedEmail"
			>
				<h3 style="color: black;">Sign Up Now. It's Free.</h3>
				<input class="margin-bottom col-centered white-border input-styling block sign-up-styling" [(ngModel)]="email" style="background-color: #251133; border: 1px solid white;" placeholder="Enter Your Email Here" />
				<button style="padding: 6px;" class="btn btn-md block no-padding no-margins purple-background medium-small-text white-text" (click)="submitEmail()">Submit</button>
			</div>
			<div 
				style="
					height: 100%;
	                display: flex;
	                flex-direction: column;
	                justify-content: space-around;
	                align-items: center;
	                text-align: center;
	            "
	            *ngIf="submittedEmail"
			>
				<h3>Congratulations on taking the first step to becoming a Spotidol affiliate! We will email you with the next steps.</h3>
			</div>
		</div>
        <div
	        style="
	            background: url(/app/assets/images/person-with-money.jpg);
	            background-position: center;
	            background-repeat: no-repeat;
	            background-size: cover;
	            text-align: left;
	            padding: 0px;
                overflow: hidden;
	        "
	        class="col-xs-12 col-sm-6 affiliates-sign-up"
	    >
	        <div 
	            class="transparent-background"
	            style="
	                display: flex;
	                flex-direction: column;
	                height: 100%;
	                width: 100%;
	                padding: 50px;
	                justify-content: center;
	                border-top-right-radius: 5px;
	                border-bottom-right-radius: 5px; 
	                overflow: hidden;
	            "
	        >
				<h3 style="text-align: center;">Sign Up As An Affiliate to Start Earning Today.</h3>
			</div>
		</div>
	</div>
	<div class="show-on-phones">	
		<div class="row">
			<div class="col-sm-12 col-md-4">
				<div class="affiliates-card">
					<h3>How To Earn</h3>
					<div>
						<p>Help others host competitions on Spotidol.</p>
						<p>Earn 10% on every entry for all competitions they host.</p>
						<p>That means you can earn with them forever!</p>
					</div>
				</div>
			</div>
			<div class="col-sm-12 col-md-4">
				<div class="affiliates-card">
					<h3>How To Be An Affiliate</h3>
					<div>
						<p>Enter your email on this page.</p>
						<p>We will email you with details & your referral code.</p>
						<p>Once you gain your first referrals, you can review how much you've earned & cash out from the Cash Out page.</p>
					</div>
				</div>
			</div>
			<div class="col-sm-12 col-md-4">
				<div class="affiliates-card">
					<h3>Other Affiliate Perks</h3>
					<div>
						<p>Closed Beta access to future products & features.</p>
						<p>Invites to exclusive events.</p>
						<p>Extensive support by the Spotidol Team</p>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="hide-on-phones">	
		<div class="row affiliates-card-container" style="margin-top: 50px;">
			<div class="col-sm-12 col-md-4 affiliates-card-container">
				<div class="affiliates-card">
					<h3>How To Earn</h3>
					<div>
						<p>Help others host competitions on Spotidol.</p>
						<p>Earn 10% on every entry & cash prize for all competitions they host.</p>
						<p>That means you can earn with them forever!</p>
					</div>
				</div>
			</div>
			<div class="col-sm-12 col-md-4 affiliates-card-container">
				<div class="affiliates-card">
					<h3>How To Be An Affiliate</h3>
					<div>
						<p>Enter your email on this page.</p>
						<p>We will email you with details & your referral code.</p>
						<p>Once you gain your first referrals, you can review how much you've earned & cash out from the Cash Out page.</p>
					</div>
				</div>
			</div>
			<div class="col-sm-12 col-md-4 affiliates-card-container">
				<div class="affiliates-card">
					<h3>Other Affiliate Perks</h3>
					<div>
						<p>Closed Beta access to future products & features.</p>
						<p>Invites to exclusive events.</p>
						<p>Extensive support by the Spotidol Team.</p>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div 
		class="row" 
		style="
		    text-align: center;
		    color: white;
		    display: flex;
		    flex-direction: column;
		    align-items: center;
		    justify-content: space-around;
		    height: 50vh;
		    min-height: 250px;
	    "
	    *ngIf="!submittedEmail"
    >
		<h2>Sign Up As An Affiliate Now!</h2>
		<input class="margin-bottom col-centered white-border input-styling block sign-up-styling" style="background-color: #251133; border: 1px solid white;" placeholder="Enter Your Email Here" [(ngModel)]="email" />
		<button style="padding: 6px;" class="btn btn-md block no-padding no-margins purple-background medium-small-text white-text" (click)="submitEmail()">Submit</button>
	</div>
	<div 
		class="row" 
		style="
		    text-align: center;
		    color: white;
		    display: flex;
		    flex-direction: column;
		    align-items: center;
		    justify-content: center;
		    height: 50vh;
		    min-height: 250px;
		    text-align: center;
	    "
	    *ngIf="submittedEmail"
    >
		<h3>Congratulations on taking the first step to becoming a Spotidol affiliate! We will email you with the next steps.</h3>
	</div>
	<a class="row" routerLink="/careers">
		<div class="career-section text-shadow">
			<h2>Interested in a career with competitions?</h2>
			<h3>Join Our Team & Help People Live Their Dreams</h3>
			<p>View Career Options</p>
		</div>
	</a>
</div>
<footer></footer>