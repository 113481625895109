import { Injectable } from '@angular/core';
import { Router } from '@angular/router'
import { IUser } from '../shared/interfaces'

import { DataService } from './data.service'

 

import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()

export class UserDataService {

	userDataInit : any = {
		createdGroups : [] ,
		myGroups : [] ,
		myFans : [] , 
		user : {} ,
		myContests : [] ,
		myContestEntries : [] , 
		updated : false 
	}
	
	private userDataSubject = new BehaviorSubject<any>(this.userDataInit)
	public userData : Observable<any> = this.userDataSubject.asObservable()

	constructor(
		private router : Router 
	) {}

	renewUserData( newUserData : any ) {
		newUserData.updated = !newUserData.updated
		console.log(newUserData)
		this.userDataSubject.next(newUserData)
	}

}