import { Component , OnInit , Input } from '@angular/core'
import { FormControl , FormGroup  } from '@angular/forms'
import { ActivatedRoute , Router } from '@angular/router' 

import { DataService , SessionService } from '../../core/index'


@Component({
  selector: 'login', 
	templateUrl: "./login.html" , 
	styleUrls: ["./login.css"]
})

export class LoginComponent {

	loginForm : FormGroup

	@Input() loggedIn : boolean
	@Input() username : string
	@Input() navbarLogin : boolean
	loginFailed : boolean
	usernameNotDefined : boolean
	err : string

	constructor(
		private dataService : DataService , 
		private sessionService : SessionService , 
		private router : ActivatedRoute ,
		private route : Router 
	) {}

	ngOnInit() {
		if (this.username === null) {
			this.usernameNotDefined = true
		}
		if (this.router.snapshot.params['failed'] === 'failed') {
				this.loggedIn = false
				this.loginFailed = true
		}
		if (this.router.snapshot.params['failed'] === 'success') {
				window.location.replace('/home')
		}
		let username = new FormControl()
		let password = new FormControl()
		this.loginForm = new FormGroup({
			username: username ,
			password: password
		})
		this.checkSession()
	}


	checkSession() {
		this.sessionService.session.subscribe( (session:any) => {
			console.log(session)
			this.loggedIn = session.loggedIn
		})
	}

	login( formValues : any ) {
		let URI = 'user/login'
		this.dataService.postObject(URI, formValues)
			.subscribe((response : any) => {
				if (response.error) {
					this.err = response.error
					if (this.router.snapshot.params['failed'] !== 'failed')
						return this.route.navigateByUrl('/login/failed')
					return
				}
				if (response.username === undefined) {
					this.usernameNotDefined = true
				} else {
					this.username = response.username
				}
				this.loggedIn = true
				window.location.replace('/home')
			}, (err: any) => {this.route.navigateByUrl('/login/failed')}
		   	)
	}
}