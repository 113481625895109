<div class="col-xs-12 contest-thumbnail-height" style="margin: 0px; padding: 5px;">
	<div
		style="
			height: 100%;
			overflow: hidden;
			border-radius: 20px;
	    background-color: #332633;
	    box-shadow: 0 0 0 1px rgba(0,0,0,.15), 0 2px 3px rgba(0,0,0,.2);
		"
	>	
		<a routerLink="/competition/{{ contest._id }}">
		  <div 
		  	class="contest-thumbnail-container" 
		  	style="
  				cursor: pointer; 
		  		margin: auto;
			    width: 100%;
			    height: 100%;
			    position: relative;
			    background-repeat: no-repeat;
			    background-position: center;
			    background-size: cover;
				  text-shadow: 0 0 0.25em #000, 0 0 0.25em #000, 0 0 0.25em #000;
		  	"
				[ngStyle] = "contestThumbnailStyles"
		  >
				<div 
					style="
				    padding: 10px;
						background: linear-gradient(rgba(0 , 0 , 0 , 0.5), rgba( 0 , 0 ,0 ,0));
						width: 100%; 
				  " 

					class="contest-thumbnail no-padding no-margins"
				>
					<h2 class="block contest-thumbnail-width competition-line-height contest-thumbnail-margin no-margins no-padding" style="
					  /*white-space: nowrap;
					  overflow: hidden;
					  text-overflow: ellipsis;*/
				    color: black;
		    		margin-left: 0px;
				  ">
						<a class="no-padding no-margins" style="color: white;" routerLink="/competition/{{ contest._id }}">
							{{ contest?.contestName }}
						</a>
					</h2>
					<div class="block competition-line-height" *ngIf="!noRewards">
						<h4 
							class="inline-block no-margins no-padding"
							style="color: white;" 
						>
							<img height="18" width="18"  src="/app/assets/images/trophy-white.png" />
							<span *ngIf="contest.rewardCash > 0" style="
						    position: relative;
						    top: 3px;
						    font-size: 21px;
			    		">
			    			{{ rewardCurrencySymbol }}{{ contest?.displayRewardCash }}
			    		</span>
			    		<span 
			    			style="
							    position: relative;
							    top: 3px;
						    "
			    			*ngIf="contest.rewardOther"
			    		>{{ contest.rewardOther }}</span>
						</h4>
					</div>
					<a class="block no-padding no-margins" 
			    	routerLink = "/competition/{{ contest._id }}"
			    >
						<p 
							class="block"
							style="color: white;" 
						>
							{{ contest?.groupName }}
						</p>
					</a>
				</div>
				<div
					style="
						background: linear-gradient(rgba( 0 , 0 ,0 ,0) , rgba(0 , 0 , 0 , 0.5) );
						width: 100%; 
						min-height: 70px;
						
						position: absolute;
						bottom: 0px;
						left: 0px;
					"
				>
					<div style="
						position: absolute;
						bottom: 0px;
						width: 100%;
					">
						<div
							style="
							  z-index: 2;
							  margin: 0px auto;
							  text-align: center;
							"
						>
							<h2 style="margin: 0px ; padding: 0px ; width: 100% ; color: red; text-align: center; font-weight: 1000; letter-spacing: 0.03em;">
				 				<span style="font-size: 24px ; font-family: digital-7, sans-serif;">
									{{ countdownText }} 
								</span>
								<countdown [startTime]="countdownStart"></countdown>
							</h2>
						</div>
					</div>
				</div>
			</div>
		</a>
	</div>
</div>