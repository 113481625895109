import { Component , Input } from '@angular/core'

@Component({
	selector : 'contest-invite-modal' , 
	templateUrl : './contestInviteModal.html'
})

export class ContestInviteModalComponent {

	showSignup : boolean = true 
	showSignin : boolean = false 

	signedUserIn() {
		console.log( 'signed in' )
		window.location.reload()
	}

	toggleNotSignedInPage() {
		this.showSignin = this.showSignup
		this.showSignup = !this.showSignup
	}
}