import { Injectable } from '@angular/core';
import { Router } from '@angular/router'

import { HttpClient } from '@angular/common/http'

import { DataService } from './data.service'

 

import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()

export class SessionService {

	sessionInit : any = {
	 loggedIn : false , 
	 username : '' ,
	 isAdmin : false , 
	 blogger : false , 
	 redirectTo: null , 
	 country : 'US', 
	 exchangeRate  : 1 ,
	 uncheckedAlerts : 0
	}
	private sessionSubject = new BehaviorSubject<any>(this.sessionInit)
	public session : any = this.sessionSubject.asObservable()

	public currentSessionValue : any = {
	 loggedIn : false , 
	 username : '' ,
	 isAdmin : false , 
	 blogger : false , 
	 redirectTo: null , 
	 country : 'US', 
	 exchangeRate  : 1 ,
	 uncheckedAlerts : 0
	}

	constructor(
		private http : HttpClient , 
		private router : Router 
	) {}

	renewSession( newSession : any ) {
		if (!newSession.country)
			newSession.country = 'US'
		this.currentSessionValue = newSession
		this.sessionSubject.next(newSession)
	}

}