import { Component , OnInit , Input} from '@angular/core'


import { ActivatedRoute } from '@angular/router'

import { DataService } from '../../core/data.service'

import { IGroup  } from '../../shared/interfaces'

@Component({
	selector: 'recentGroups', 
	templateUrl: "./recentGroups.html"
})

export class RecentGroupsComponent implements OnInit { 

	groups : IGroup[] = []
	page : number = 1
	pages : number
	pageLoaded : boolean = false
	isPhone : boolean = false
	firstTime : boolean
	nextOnboardingStage : boolean

	constructor(
		private dataService : DataService , 
		private route : ActivatedRoute
	) {}

	ngOnInit () {
		if (window.screen.width < 761) 
			this.isPhone = true
		else {
			this.isPhone = false
		}

		if (this.route.snapshot.params['firstTime'] === 'firstTime') {
			this.firstTime = true
		}
	    this.route.data
	      .subscribe(( data : any ) => {
	      	let recentGroupsData = data.recentGroupsData
	      	this.groups = recentGroupsData.groups
	      	this.pages = recentGroupsData.pages
			this.pageLoaded = true
	      })
	}

	getMostRecentGroups( page : number ) {
		let URI = 'group/getMostRecentGroups/' + page
    	this.dataService.getObject(URI)
	        .subscribe((response) => {
	        	if (this.page === 1 )
	          		this.groups = response.groups;
	          	else 
	          		this.groups.push(...response.groups)
	        },
        (err: any) => console.log(err),
        () => console.log("groups"));
	}

	getNextPageOfGroups( page : number ) {
		this.page ++
		console.log(this.pages)
		if ( this.page > this.pages ) return 
		this.getMostRecentGroups(this.page)
	}

	goToNextOnboardingStage() {
		this.nextOnboardingStage = true
	}
}