<div>
	<h1 class="col-xs-offset-1 inline-block">My Competitions</h1>
	<div infiniteScroll
        [infiniteScrollDistance]="3"
        [infiniteScrollThrottle]="300"
        (scrolled)="getNextContestsPage()" 
    >
		<div class="recent-contests-margin hide-on-sm">
			<contest-thumbnail class="inline col-md-3 contest-thumbnail-height recent-contests-margin" *ngFor = "let contest of contests" [contest]="contest"></contest-thumbnail>
		</div>
		<div>
			<contest-thumbnail class="inline col-md-3 contest-thumbnail-height recent-contests-margin" *ngFor = "let contest of contests" [contest]="contest"></contest-thumbnail>
		</div>
	</div>
	<div *ngIf="!!!contests" class="col-xs-offset-1">You have not created any competitions yet!</div>
</div>