<div 
	style="
		color : white ; 
		background-color : #251133 ;
	    height: 100%;
    "
>
	<div class="col-xs-10 col-xs-offset-1 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3">
		<h1>FAQ</h1>
		<div>
			<h3>What is Spotidol?</h3>
			<p>Spotidol is a platform for you to be your best. On it, you can create & enter competitions to win cash &amp; rewards.</p>
		</div>
		<div>
			<h3>How Do Competitions Work?</h3>
			<p>People submit photos, videos, audio recordings, or text submissions. People can vote, but entries are judged to determine the winners.</p>
		</div>
		<div>
			<h3>How Do I Make Money on Spotidol</h3>
			<p>If you create competitions, you can charge for entry. If you win competitions, the reward might be cash.</p>
		</div>
		<div>
			<h3>Is Spotidol Limited Geographically?</h3>
			<p>You can use Spotidol in 155 different countries &amp; 110 different currencies.</p>
		</div>
		<div>
			<h3>How Do I Cash Out Money on Spotidol</h3>
			<p>You must be logged in to cash out. Once logged in, you can navigate to the menu &amp; click the cash out button.</p>
		</div>
		<div>
			<h3>How Much Does it Cost?</h3>
			<p>It's free to create competitions. Competition creators can charge for entry. If you want a cash reward, you have to pay for it.</p>
		</div>
		<div>
			<h3>What are Spotidol's Term of Use & Privacy Policy?</h3>
			<p>You can find Spotidol's Terms <a routerLink="/termsOfUse">here</a>, along with the privacy policy <a routerLink="/privacyPolicy">here</a> &amp; the official competition rules <a routerLink="/competitionRules">here</a>.</p>
		</div>
		<div>
			<h3>How do I create competitions?</h3>
			<a (click)="toggleDisplayHowToCreateCompetitionOnComputer()"><h4><span *ngIf="!displayHowToCreateCompetitionOnComputer">Show</span><span *ngIf="displayHowToCreateCompetitionOnComputer">Hide</span> Directions for a Computer</h4></a>
			<div *ngIf="displayHowToCreateCompetitionOnComputer">
				<p style="margin-top: 30px;">1. Select "Create" on the top right corner of your browser</p>
				<img style="max-width: 300px; height: auto;" src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fcreatecompetitionexample1.JPG?alt=media&token=0a9d8fd8-e426-4acf-9157-774c29826065">
				<p style="margin-top: 30px;">2. Select "Create Competition".</p>
				<img style="max-width: 300px; height: auto;" src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fcreatecompetitionexample2.JPG?alt=media&token=04946588-2605-4cb5-a7fd-efad1dea06a9">
				<p style="margin-top: 30px;">3. Groups allow you to contact players &amp; keep track of their progress over time. By having a group, past players get alerts to new competitions you create. Click on an image to select a group. You can also choose to have no group. Your competition will still be visible on your profile page.</p>
				<img style="max-width: 300px; height: auto;" src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fcreatecompetitionexample3.JPG?alt=media&token=32c49c3c-2731-4f60-b756-fbe97cd5cb46">
				<p style="margin-top: 30px;">4. You can then provide a name, description, &amp; hashtags. Each competition has entry, voting, judging, &amp; prizes.</p>
				<img style="max-width: 300px; height: auto;" src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fcreatecompetitionexample4.JPG?alt=media&token=0451b482-206f-4470-bb75-fae6917aa24a">
				<p style="margin-top: 30px;">5. Players have until the end of the competition to enter. Judges &amp; voters have until the date the winener's announced to judge &amp; vote.</p>
				<img style="max-width: 300px; height: auto;" src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fcreatecompetitionexample5.JPG?alt=media&token=3fcfa624-ea30-4205-8190-38db9a55ed0d">
				<p style="margin-top: 30px;">6. Registered uers can vote on entries. You can choose to have the votes go to the score, to a separate "crowd choice" award, &amp; a tie-breaker for winners.</p>
				<img style="max-width: 300px; height: auto;" src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fcreatecompetitionexample6.JPG?alt=media&token=eb138ad9-b5e4-4f5e-9aee-8914754e0b85">
				<p style="margin-top: 30px;">7. Entrants' overall scores are used to determine the winner. Judges scpre entries &amp; votes can go to the score. Judge's scores are averaged &amp; added across the criteria you set. You can provide a criteria title, max score, &amp; description. When finished, be sure to click +Add Judging Criteria.</p>
				<img style="max-width: 300px; height: auto;" src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fcreatecompetitionexample7.JPG?alt=media&token=ff9ef164-c570-4983-b60b-cf840a6f2006">
				<p style="margin-top: 30px;">8. Winners earn cash &amp; other prizes that you provide. To connect with winners, navigate to your group page &amp; select "View Players". You can see your player's emails &amp; profiles. For each prize, be sure to click +Add Prize. Click "Create Competition" when you are finished.</p>
				<img style="max-width: 300px; height: auto;" src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fcreatecompetitionexample8.JPG?alt=media&token=ddff9ffb-85e0-40ed-98b8-edf8ad278080">
			</div>
			<a (click)="toggleDisplayHowToCreateCompetitionOnMobile()"><h4><span *ngIf="!displayHowToCreateCompetitionOnMobile">Show</span><span *ngIf="displayHowToCreateCompetitionOnMobile">Hide</span> Directions for Mobile</h4></a>
			<div *ngIf="displayHowToCreateCompetitionOnMobile">
				<p style="margin-top: 30px;">1. After signing up/signing in, select the Create button on the bottom navbar.</p>
				<img style="max-width: 300px; height: auto;" src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fcreatecompetitionexamplephone1.PNG?alt=media&token=defc6997-cfd7-4d39-bbd2-7e057444ff54">
				<p style="margin-top: 30px;">2. Groups allow you to contact players &amp; keep track of their progress over time. By having a group, past players get alerts to new competitions you create. Click on an image to select a group. You can also choose to have no group. Your competition will still be visible on your profile page.</p>
				<img style="max-width: 300px; height: auto;" src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fcreatecompetitionexamplephone2.PNG?alt=media&token=df65983a-ba72-45b7-a0f7-4ae302abe0fc">
				<p style="margin-top: 30px;">3. You can then provide a name, description, &amp; hashtags. Each competition has entry, voting, judging, &amp; prizes.</p>
				<img style="max-width: 300px; height: auto;" src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fcreatecompetitionexamplephone3.PNG?alt=media&token=f187efca-daf2-466f-addc-74e2dba75c92">
				<p style="margin-top: 30px;">4. Players have until the end of the competition to enter. Judges &amp; voters have until the date the winener's announced to judge &amp; vote.</p>
				<img style="max-width: 300px; height: auto;" src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fcreatecompetitionexamplephone4.PNG?alt=media&token=ea1151e3-80e2-42e3-825b-c5b59349a41b">
				<p style="margin-top: 30px;">5. Registered uers can vote on entries. You can choose to have the votes go to the score, to a separate "crowd choice" award, &amp; a tie-breaker for winners.</p>
				<img style="max-width: 300px; height: auto;" src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fcreatecompetitionexamplephone5.PNG?alt=media&token=ebeaa69d-35a5-4979-9ccb-b40465b150fe">
				<p style="margin-top: 30px;">6. Entrants' overall scores are used to determine the winner. Judges scpre entries &amp; votes can go to the score. Judge's scores are averaged &amp; added across the criteria you set. You can provide a criteria title, max score, &amp; description. When finished, be sure to click +Add Judging Criteria.</p>
				<img style="max-width: 300px; height: auto;" src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fcreatecompetitionexamplephone6.PNG?alt=media&token=4f37dc87-1907-483e-91d3-18c56bf879ba">
				<p style="margin-top: 30px;">7. Winners earn cash &amp; other prizes that you provide. To connect with winners, navigate to your group page &amp; select "View Players". You can see your player's emails &amp; profiles. For each prize, be sure to click +Add Prize.</p>
				<img style="max-width: 300px; height: auto;" src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fcreatecompetitionexamplephone7.PNG?alt=media&token=a03213c6-85c4-4ded-84d7-4a59a7a4c120">
				<p style="margin-top: 30px;">8. Review the details of the competition you are about to create. When finished, click the forward arrow.</p>
				<img style="max-width: 300px; height: auto;" src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fcreatecompetitionexamplephone8.PNG?alt=media&token=d61edac4-71f3-46b0-a730-2462eea4b7ec">
			</div>
		</div>
		<div>
			<h3>How Do I Sign Up?</h3>
			<sign-up-redirect></sign-up-redirect>
		</div>
	</div>
	<footer></footer>
</div>