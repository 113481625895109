import { Component , Input , OnInit } from '@angular/core'
import { DataService , SessionService , UserDataService } from '../../core/index'
import { IGroup } from '../../shared/interfaces'


@Component({
	selector : 'admin' , 
	templateUrl: './admin.html'
})

export class AdminComponent implements OnInit {

	@Input() group : IGroup
	err : string 
	admins : string[]
	isGroupCreator : boolean
	username : string
	addAdminUsername : string

	constructor(
		private dataService : DataService , 
		private sessionService : SessionService , 
		private userDataService : UserDataService
	) {}

	ngOnInit() {
		this.sessionService.session
			.subscribe( ( session : any ) => {
				this.username = session.username
				if (this.group.username === session.username)
					this.isGroupCreator = true
				for (var i = 0; i < this.group.admins.length; i++) {
					this.group.admins[i].profilePicture = this.group.admins[i].profilePicture ? this.group.admins[i].profilePicture : '/app/assets/images/idol-icon-white.png'
				}
			})
	}

	addAdmin( username : string ) {
		let URI = 'group/addAdmin'
		let body = {
			groupId : this.group._id , 
			username : username
		}
		this.dataService.postObject(URI , body)
			.subscribe( ( response : any ) => {
				if (response.error)
					return this.err = response.error
				let tempUserData
				this.userDataService.userData
					.subscribe( ( userData : any ) => {
						tempUserData = userData
					})
				tempUserData.createdGroups = tempUserData.createdGroups.filter(( group : IGroup) => {
					return (group._id !== this.group._id)
				})
				this.err = null
				this.closeModal()
				tempUserData.createdGroups.push( response.group )
				this.userDataService.renewUserData( tempUserData )
				return this.group.admins.push( {
					username : this.username , 
					profilePicture : null 
				})
			})
	}

	removeAdmin( username : string ) {
		let URI = 'group/removeAdmin'
		let body = {
			groupId : this.group._id , 
			username : username	
		}
		this.dataService.postObject(URI , body)
			.subscribe( ( response : any ) => {
				if (response.error)
					return this.err = response.error
				let tempUserData
				this.userDataService.userData
					.subscribe( ( userData : any ) => {
						tempUserData = userData
					})
				tempUserData.createdGroups = tempUserData.createdGroups.filter(( group : IGroup) => {
					return (group._id !== this.group._id)
				})
				tempUserData.createdGroups.push( response.group )
				this.userDataService.renewUserData( tempUserData )
				let adminToRemove = this.group.admins.filter( ( admin : any ) => {return ( admin.username === username )})[0]
				return this.group.admins.splice( this.group.admins.indexOf(adminToRemove) , 1 )
			})
	}


	openModal() {
		document.getElementById('addAdminModal').style.display = "block"
		document.getElementById('addAdminModalContent').style.display = "block"
	}

	closeModal() {
		document.getElementById('addAdminModal').style.display = "none"
		document.getElementById('addAdmingModalContent').style.display = "none"
	}
}