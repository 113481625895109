import { Component , Input , OnInit } from '@angular/core'

import { DataService } from '../../../../core/data.service'
import { IContestEntry , IContestVote } from '../../../../shared/interfaces'

@Component({
	templateUrl : './voterScoreCardsModal.html' ,
	selector : 'voter-score-cards-modal'
})

export class VoterScoreCardsModalComponent { 

	@Input() contestEntry : IContestEntry 

	contestVotes : IContestVote[]
	displayVotes : [{
		username : string
		voteScore : number  
		criteria : any
	}] | any = []
	criteria : any
	error : string 
	loaded : boolean = false

	constructor(
		private dataService : DataService 
	) {}

	ngOnInit() {
		console.log('opened modal' , this.contestEntry )
		this.getVoterScoreCards()
	}

	getVoterScoreCards() {

		console.log('getting voter scorecards')

		let URI = 'contestVote/getContestEntryScoreCardVotes/' + this.contestEntry._id
		this.dataService.getObject(URI)
			.subscribe( ( response : {
			 	status : boolean , 
			 	error : string | null , 
			 	contestEntryVotes : IContestVote[]
			}) => {
				console.log('got voter scorecards' , response )
				if ( response.error ) 
					this.error = response.error
				else 
					this.contestVotes = response.contestEntryVotes
				for ( var i = 0 ; i < response.contestEntryVotes.length ; i++ ) {
					let vote = response.contestEntryVotes[i].voterScoreCards.filter( ( voterScoreCard : any ) => {
						return ( voterScoreCard.contestEntryId == this.contestEntry._id )
					})[0]
					this.displayVotes[i] = {
						username : response.contestEntryVotes[i].username , 
						criteria : vote.criteria ,
						voteScore : vote.voteScore 
					}
				}
				if ( this.displayVotes[0] )
					this.criteria = Object.keys(this.displayVotes[0].criteria)
				console.log(this.criteria)

				this.loaded = true
			})

	}

}