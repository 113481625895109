import { Component } from '@angular/core'

@Component({
	templateUrl : './faq.html' ,
	selector : 'faq'
})

export class FaqComponent {

	displayHowToCreateCompetitionOnComputer : boolean = false 
	displayHowToCreateCompetitionOnMobile : boolean = false 

	toggleDisplayHowToCreateCompetitionOnComputer() {
		this.displayHowToCreateCompetitionOnComputer = !this.displayHowToCreateCompetitionOnComputer
	}

	toggleDisplayHowToCreateCompetitionOnMobile() {
		this.displayHowToCreateCompetitionOnMobile = !this.displayHowToCreateCompetitionOnMobile
	}
	

}
