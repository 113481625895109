<div style=" color : white ; background : indianred ; overflow : hidden ; width: 100% ;">
	<div style="font-size: 140%;" class="col-md-offset-1 col-md-10">
		<h1 class="hide-on-phones" style="font-size: 400% ">Our Story</h1>
		<h1 class="show-on-phones" style="font-size: 200%">Our Story</h1>
		<div class="col-md-10 col-md-offset-1">
			<img style="width: 100% ; height : auto;" src="/app/assets/images/Raja-CEO-Headshot-2.jpg">
			<p>As a starving artist, I wondered why it was so difficult for creative people to profit for the value they provide to the world</p>
			<p>I asked, "how can creatives make as much money as possible on the internet?"</p>
			<p>This question began my quest of discovering how it might be possible for creatives to profit</p>
			<p>I made Spotidol exactly to solve this problem</p>
			<p class="col-md-offset-1"> -Raja Weise, Founder/CEO</p>
		</div>
	</div>
</div>