import { Component , Input , Output , EventEmitter , OnInit } from '@angular/core'
import { DataService , SessionService } from '../../core/index'

@Component({
	selector : 'update-profile-modal' ,
	templateUrl : './updateProfileModal.html' 
})


export class UpdateProfileModalComponent {

	twitterHandle : string 
	facebookHandle : string 
	instagramHandle : string 
	session : any

	headerText : string

	@Input() scene : 'uploadCompetitionEntry' | 'createCompetition'

	err : string 

	successUpdatingInstagramProfileLink : boolean
	successUpdatingFacebookProfileLink : boolean 
	successUpdatingTwitterProfileLink  : boolean

	facebookTimer : any 
	instagramTimer : any 
	twitterTimer : any



	@Output() closeUpdateProfileModal : any = new EventEmitter()

	constructor(
		private dataService : DataService , 
		private sessionService : SessionService  
	) {}

	ngOnInit() {
		
		this.headerText = ( this.scene === 'uploadCompetitionEntry' ) ? 'Before You Enter, Update Your Profile!' : 'Before You Create a Competition, Update Your Profile!'

		this.sessionService.session( ( session : any ) => {
			this.session = session
		})
	}

	setUpdateFacebookTimer() {
		clearTimeout( this.facebookTimer )
		this.facebookTimer = setTimeout( () => {
			this.updateFacebookLink()
		} , 2200 )
	}

	setUpdateInstagramTimer() {
		clearTimeout( this.instagramTimer )
		this.instagramTimer = setTimeout( () => {
			this.updateInstagramLink()
		} , 2200 )

	}

	setUpdateTwitterTimer() {
		clearTimeout( this.twitterTimer )
		this.twitterTimer = setTimeout( () => {
			this.updateTwitterLink()
		} , 2200 )
	}

	updateFacebookLink() {
		this.err = null
		let URI = "user/updateFacebookProfileLink"
		let params = {
			facebookProfileLink : this.facebookHandle 
		}
		this.dataService.postObject( URI , params )
			.subscribe( ( response : any ) => {
				if (response.error)
					return this.err = response.error
				return this.successUpdatingFacebookProfileLink = true
			})
	}


	updateInstagramLink() {
		this.err = null
		let URI = "user/updateInstagramProfileLink"
		let params = {
			instagramProfileLink : this.instagramHandle 
		}
		this.dataService.postObject( URI , params )
			.subscribe( ( response : any ) => {
				if (response.error)
					return this.err = response.error
				return this.successUpdatingInstagramProfileLink = true
			})
	}	


	updateTwitterLink() {
		this.err = null
		let URI = "user/updateTwitterProfileLink"
		let params = {
			twitterProfileLink : this.twitterHandle 
		}
		this.dataService.postObject( URI , params )
			.subscribe( ( response : any ) => {
				if (response.error)
					return this.err = response.error
				return this.successUpdatingTwitterProfileLink = true
			})
	}	

	closeUpdateProfile() {
		this.closeUpdateProfileModal.emit( null )
	}

}