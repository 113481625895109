import { Component , OnInit } from '@angular/core'
import { FormControl , FormGroup } from '@angular/forms'
import { Router } from '@angular/router'

import { DataService } from '../../core/data.service'

import { IUser } from '../../shared/interfaces'

@Component({
  selector: 'phoneVerification', 
  templateUrl: "./phoneVerification.html"
})

export class PhoneVerificationComponent {

	user : IUser
	phoneVerificationForm : FormGroup
	err : string
	isVerifying : boolean = false

	constructor(
		private dataService : DataService , 
		private router : Router
	) {}

	ngOnInit() {
		let phoneVerificationCode = new FormControl()
		this.phoneVerificationForm = new FormGroup({
			phoneVerificationCode : phoneVerificationCode
		})
	}

	verifyPhone( phoneVerificationCode : string ) {
		this.isVerifying = true
		let URI = 'user/phoneVerification' 
		this.dataService.postObject(URI, phoneVerificationCode)
	        .subscribe((response) => {
			this.isVerifying = false
	          this.user = response.user;
	          if (response.error !== null) {
	          	this.err = response.error
	          }
	          if (response.error === null) {
				this.router.navigateByUrl('/home')
	          }
	        },
	    (err: any) => console.log(err),
	    () => console.log("user"));
	}
}