import { Component } from '@angular/core'


@Component({
	selector : 'navbar-not-logged-in' ,
	templateUrl : './navbarNotLoggedIn.html' , 
	styleUrls : ['./navbarNotLoggedIn.css']
})

export class NavbarNotLoggedInComponent { 

	showMenu : boolean = false

	toggleMenu() {
		this.showMenu = this.showMenu ? false : true
	}
}
