import { Component , OnInit } from '@angular/core'

import { CreateContestSubjectService } from '../../index'
import { SessionService } from '../../../core/index'
import { InternationalService } from '../../../shared/international.service'

@Component({
	selector : 'create-contest-voting-details' ,
	templateUrl : './voting.html' 
})

export class CreateContestVotingDetailsComponent {

	createContestObject : any

	currencySymbol : string = '$'

	votingCriteria : {
		votingType : string 
		scorePerVote : number
	} = {
		votingType : 'crowdChoice' ,
		scorePerVote : 0
	}


	constructor( 
		private createContestSubjectService : CreateContestSubjectService , 
		private sessionService : SessionService , 
		private internationalService : InternationalService
	) { }

	ngOnInit() {
		this.createContestSubjectService.createContestObject.subscribe( ( createContestObject ) => {
			this.createContestObject = createContestObject
		})
		this.sessionService.session.subscribe( (session : any ) => {
			this.currencySymbol = this.internationalService.convertCurrencySymbol(session.country)
		})
	}

	submitDetails() {			
		this.createContestObject.scoreCard.votingCriteria = this.votingCriteria
		this.createContestSubjectService.renewCreateContestObject( this.createContestObject )
	}

}