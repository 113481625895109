import { Component , Input } from '@angular/core'
import { DataService } from '../../core/index'
import { IContest } from '../../shared/interfaces'

@Component({
	selector : 'invite' ,
	templateUrl : './invite.html'
})

export class InviteComponent {
	
	@Input() contest : IContest
	inviteFields : number[] = [1]

	constructor(
		private dataService : DataService
	) {}

	addInviteField( ) {
		this.inviteFields.push( this.inviteFields.length + 1 )
	}
}