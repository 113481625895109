import { Injectable } from '@angular/core';
import { Router } from '@angular/router'

import { DataService } from '../core/data.service'

 

import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()

export class SearchService {
	private searchSubject = new BehaviorSubject<any>(null)
	public searchInputObservable : any = this.searchSubject.asObservable()

	constructor(
		private router : Router 
	) {}

	changeSearchInput( searchInput : any ) {
		this.searchSubject.next(searchInput)
	}
}