<div style="
	    width: 100%;
	    overflow: hidden;
	    color: white;
	    background: #251133;
	    padding-top: 100px;
	"
>
	<div
		style="font-size: 140%;"
	    class="col-md-offset-1 col-md-10"
	>
		<div class="col-md-offset-1 col-md-10">
			<h3 style="font-size: 200%;" class="center-text">We are building towards a world where all people are viewed as idols</h3>
			<p class="center-text"s>In such a world, all people serve a purpose & have a way of living that is valued by others.</p>
			<h3 style="font-size: 130%" class="center-text">Join Us to Make Our Vision a Reality</h3>
		</div>
	</div>
</div>
