<div class="col-md-10 col-xs-12 contest-entry-margin">
	<div class="col-xs-12 purple-gray-background" style="height: 40px; top : 2px;">
		<a style="margin: 0px ; margin-bottom: 3px;" routerLink="/competition/{{ contestEntry.contestId }}">
			<img src="{{ contestEntryProfilePicture }}" style="width:32px;height:32px;border-radius: 16px; top : 2px;">
			<span style="color: white;">{{ contestEntry.contestName }}</span></a>
		<a style="margin : 0px; ; color: rgb(210 , 210 , 210) ; font-size: 12px; position: relative; top : -10px;" routerLink="/profile/{{ contestEntry.contestantUsername }}"><p class="col-xs-offset-1">
			{{contestEntry.contestantUsername
			}}
		</p></a>
		<div style="position: absolute; top: 8px; right: 10px;">
			<media-menu [reportedUserDetails]="contestEntry.reportedUserDetails" [mediaObject]="contestEntry" [mediaObjectType]="'contestEntry'" [whiteOrBlack]="'white'"></media-menu>
		</div>
	</div>
	<div style="margin-bottom: 0px; z-index: 2;" [hidden]=" !contestEntry.entryAttachment">	
		<div *ngIf="contestEntry.contestType == 'pdf'">
			<pdf-reader [pdfUrl]="contestEntry.entryAttachment" ></pdf-reader>
		</div>
		<div 
			[hidden]="!pictureSubmissionType" 
			class="col-xs-12 no-margins no-padding" 
			style="
				margin-bottom: 0px; 
				overflow: hidden;
		    width: 100%;
		    height: 600px;
		    background-color: black;

	    "
    >
		<div 
			style="
				width: 100% ; 
				height: 100% ; 
		    background-repeat: no-repeat;
		    background-size: contain;
		    background-position: center center;
		    padding: 0px;
		    margin: auto;
			" 
			class="col-xs-12"
			[ngStyle]="{ 'background-image' : pictureSubmission }"
		>
			<p style="margin-bottom: 0px; font-size: 0px;">a</p>
		</div>
			<!-- <div #picture class="pic-container block no-margins no-padding col-sm-offset-1 col-xs-offset-0 hide-on-phones" [ngStyle]="{ 'width' : pictureWidth , 'height' : pictureWidth , 'min-width' : pictureWidth , 'min-height' : pictureWidth , 'max-width' : pictureWidth , 'max-height' : pictureWidth}" style="margin-bottom: 0px;">
				<p style="margin-bottom: 0px; font-size: 0px;">a</p>
			</div>
			<div #picture class="pic-container block no-margins no-padding show-on-phones" [ngStyle]="{ 'width' : pictureWidth , 'height' : pictureWidth , 'min-width' : pictureWidth , 'min-height' : pictureWidth , 'max-width' : pictureWidth , 'max-height' : pictureWidth}" style="margin-bottom: 0px;">
				<p style="margin-bottom: 0px; font-size: 0px;">a</p>
			</div> -->
		</div>
		<div [hidden]="contestEntry.contestType != 'pictureGallery'" class="col-xs-12 no-margins no-padding" style="margin-bottom: 0px; overflow: hidden;">	
			<a routerLink='/competition/{{ contestEntry.contestId }}/pictureGallery/{{ contestEntry.contestantUsername }}' style="margin-bottom: 0px;
			    width: 100%;
			    height: 600px;
			    background-color: black;"
		    >
				<div 
					style="
						width: 100% ; 
						height: 100% ; 
				    background-repeat: no-repeat;
				    background-size: contain;
				    background-position: center center;
				    padding: 0px;
				    margin: auto;
					" 
					class="col-xs-12"
					[ngStyle]="{ 'background-image' : pictureSubmission }"
				>
					<p style="margin-bottom: 0px; font-size: 0px;">a</p>
				</div>
				<!-- <div class="pic-container block no-margins no-padding col-sm-offset-1 col-xs-offset-0 hide-on-phones" [ngStyle]="{ 'width' : pictureWidth , 'height' : pictureWidth , 'min-width' : pictureWidth , 'min-height' : pictureWidth , 'max-width' : pictureWidth , 'max-height' : pictureWidth}">
					<p style="margin-bottom: 0px; font-size: 0px;">a</p>
				</div>
				<div #pictureGallery class="pic-container block no-margins no-padding show-on-phones" [ngStyle]="{ 'width' : pictureWidth , 'height' : pictureWidth , 'min-width' : pictureWidth , 'min-height' : pictureWidth , 'max-width' : pictureWidth , 'max-height' : pictureWidth}" style="margin-bottom: 0px;">
					<p style="margin-bottom: 0px; font-size: 0px;">a</p>
				</div> -->
				<div [ngStyle]="{'width' : pictureWidth , 'height' : 60 , 'position' : 'absolute' , 'bottom' : '60px' , backgroundColor : 'rgba(0 ,0 ,0 , 0.3)'}">
					<h3 style="color: white;">+{{contestEntry.entryAttachments.length - 1}} more...</h3>
				</div>
			</a>
		</div>
		<div class="col-xs-12 no-margins no-padding">
			<div class="pic-container" *ngIf = "videoSubmissionType" [ngStyle]="{ 'width' : pictureWidth , 'height' : pictureWidth , 'min-width' : pictureWidth , 'min-height' : pictureWidth , 'max-width' : pictureWidth , 'max-height' : pictureWidth}" style="background-color: black;">
				<video
				    id="my-player"
				    class="video-js"
						[ngStyle]="{ 'width' : pictureWidth , 'height' : pictureWidth , 'min-width' : pictureWidth , 'min-height' : pictureWidth , 'max-width' : pictureWidth , 'max-height' : pictureWidth}"
				    controls
				    preload="auto"
				    style="background-color: black; width : 100% ; height: 70vh;"
				    data-setup='{}'>
				  <source src="{{ contestEntry?.entryAttachment }}"/>
				  <p class="vjs-no-js">
				    To view this video please enable JavaScript, and consider upgrading to a
				    web browser that
				    <a href="http://videojs.com/html5-video-support/" target="_blank">
				      supports HTML5 video
				    </a>
				  </p>
				</video>
			</div>
		</div>
		
		<div [hidden]= "!audioSubmissionType" class="col-xs-12 no-margins no-padding">		
			<div style="background: black;" id="audioCanvasContainer" #audioCanvasContainer>
				<canvas id="audioCanvas" #audioCanvas style="
			    width: 100%;
			    height: 130px;
		    "></canvas>
			</div>	
			<div style="background: black;" id="audioContainer" #audioContainer></div>	
			<a>
				<div *ngIf="songLoaded" style="height : 30px ; background-color : black; margin : 0 auto; z-index: 2; bottom : 10px; position: absolute;" class="col-xs-12">
					<div style="float: none ; position: relative ; background-color: black; " class="col-sm-offset-6 col-xs-offset-4">
						<a *ngIf="!audioPaused" (click)="pauseAudio()">
							<img src="https://storage.googleapis.com/spotidol/pause.png" style="width: 25px; height: 25px; margin : 2px ;" />
						</a>
						<a *ngIf="audioPaused" (click)="playAudio()">
							<img src="https://storage.googleapis.com/spotidol/play.png" style="width: 25px; height: 25px; margin : 2px ;" />
						</a>
						<span *ngIf="contestEntry.totalViews > 0" style="color: white;">{{ contestEntry.totalViews}} View<span *ngIf="contestEntry.totalViews > 1">s</span></span>
					</div>
				</div>
			</a>
		</div>

		<div *ngIf = "textSubmissionType" class="col-xs-12 no-margins no-padding" style="background-color: white;">
			<div class="col-xs-offset-1 col-xs-10 contestEntrySection roundBorders "><p [ngStyle]="{'font-size' : textSubmissionFontSize }">{{ contestEntry.entryAttachment }}</p>
			</div>
		</div>

		<div *ngIf = "contestEntry.contestType === 'code'" class="col-xs-12 no-margins no-padding" style="background-color: white;">
			<div class="col-xs-offset-1 col-xs-10 contestEntrySection roundBorders ">
				<a href="https://github.com/{{ contestEntry.entryAttachment }}">
					<div style="margin: 5px;">
						<img 
							src="https://image.flaticon.com/icons/svg/25/25231.svg" 
							style="
								height: 24px; 
								width : 24px;
							    top: -5px;
							    position: relative;
							"
						>
						<span style="font-size : 24px; color: black;">View on Github</span>
					</div>
				</a>
			</div>
		</div>
	</div>
	<contest-vote *ngIf="contestEntry.entryAttachment !== null" [contestEntry]="contestEntry"></contest-vote>
</div>