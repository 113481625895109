import { Component , OnInit } from '@angular/core'

import { ActivatedRoute } from '@angular/router'

import { DataService } from '../../core/index'

@Component({
	templateUrl : './unsubscribe.html'
})

export class UnsubscribeComponent {

	unsubscribed : boolean 
	err : string

	constructor(
		private route : ActivatedRoute ,
		private dataService : DataService
	) {}

	ngOnInit() {
    const subscriberCode = this.route.snapshot.params.subscriberCode;
    const username = this.route.snapshot.params.username;
    this.unsubscribe( username , subscriberCode )
	}

	unsubscribe( username : string , subscriberCode : string ) {
		console.log(username , subscriberCode)
		let URI = 'user/unsubscribe'
		let unsubscribeBody = {
			username : username ,
			subscriberCode : subscriberCode
		}
		this.dataService.postObject(URI , unsubscribeBody)
			.subscribe( ( response : any ) => {
				if (response.error) 
					return this.err = response.error
				return this.unsubscribed = true
			})
	}


}