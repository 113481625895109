import { Component , Output , EventEmitter } from '@angular/core'

import { UserDataService } from '../../../core/userData.service'
import { CreateContestSubjectService } from '../../index'

import { IGroup } from '../../../shared/interfaces'

@Component({
	selector : 'choose-group' ,
	templateUrl : './chooseGroup.html' , 
	styleUrls : ['./chooseGroup.css']
})

export class ChooseGroupComponent {
	
	groups : IGroup[] = []
	chosenGroup : IGroup = null
	createContestObject : any

	constructor(
		private userDataService : UserDataService ,
		private createContestSubjectSerivce : CreateContestSubjectService
	) {}

	ngOnInit() {	
		if ( this.groups.length > 0 ) 
			return
		this.userDataService.userData.subscribe( ( userData : any ) => {
			for ( var i = 0 ; i < userData.createdGroups.length ; i++ ) {
				if (!(this.groups.filter( ( g : IGroup )=> { return (g._id === userData.createdGroups[i]._id ) })[0])) {
					let group = userData.createdGroups[i]
					group.profilePicture = group.profilePicture ?  group.profilePicture  : 'https://storage.googleapis.com/spotidol/spotidolBannerPicture.png'
					this.groups.push( userData.createdGroups[i] )
				}
			}
		})
		this.createContestSubjectSerivce.createContestObject.subscribe( ( createContestObject ) => {
			this.createContestObject = createContestObject
			this.chosenGroup = createContestObject.group
		})
	}

	chooseGroup( group : IGroup ) {
		this.chosenGroup = group
		this.createContestObject.group = group
		this.createContestSubjectSerivce.renewCreateContestObject( this.createContestObject )
	}


}