<div>
	<div
		class="col-xs-12"
		style="
			background-repeat: no-repeat;
			background-position: center;
	    background-size: cover;
	    min-height: 400px;
	    color: white;
	    padding: 0px;
	    display: flex;
	    flex-flow: column;
			background-image: url(/app/assets/images/global.jpg);
		"
	>
		<div 
			style="
		    background-color: rgba(0 , 0 , 0 , 0.65);
		    width: 100%;
		    height: 100%;
		    flex: 1 1 100%;
		    overflow: hidden;
			"
		>		
			<div style="text-align: center;" class="col-xs-12">
				<h1 class="hide-on-phones" style="font-size: 560% ; color: white;">Be A Sponsor</h1>
				<h1 class="show-on-phones" style="font-size: 260%  ; color: white ; ">Be A Sponsor</h1>
				<h2>Spotidol Competitions Give Everyone Equal Opportunity To Achieve Their Dreams</h2>
			</div>
			<div class="col-sm-12">
				<div>
					<span style="text-align: center; padding: 0px;" class="col-xs-6 jumbo-text">
						<p class="jumbo-text" style="margin: 0px ; padding: 0px;">40+<p>
						<p style="font-size: 20px; margin: 0px; padding: 0px;">Countries</p>
					</span>
					<span style="text-align: center; padding: 0px;"class="col-xs-6">
						<p class="jumbo-text" style="margin: 0px ; padding: 0px;">6<p>
						<p style="font-size: 20px; margin: 0px; padding: 0px;">Continents</p>
					</span>
				</div>
			</div>
			<div class="col-xs-12" style="margin-bottom: 10px;">
				<button 
					routerLink="/support"
					style="
				    border-color: #6D507F;
				    border-style: solid;
				    border-width: 1px;
				    margin: auto auto;
			    " 
			    class="block enter-competition-button btn-lg" 
			  >Be A Sponsor</button>
			</div>
		</div>
	</div>
	<div
		class="col-xs-12"
		style="
			background-repeat: no-repeat;
			background-position: center;
	    background-size: cover;
	    min-height: 400px;
	    color: white;
	    display: flex;
	    flex-flow: column;
	    padding: 0px;
			background-image: url(https://storage.googleapis.com/spotidol/1200px-Toxxx_Studio_Sessions.jpg);
		"
	>
		<div 
			style="
		    background-color: rgba(0 , 0 , 0 , 0.65);
		    width: 100%;
		    height: 100%;
		    flex: 1 1 100%;
		    overflow: hidden;
			"
		>		
			<div class="col-md-offset-1 col-md-10 col-sm-12">
				<h1 class="hide-on-phones" style="font-size: 340% ; color: white ;">Reasons To Sponsor</h1>
				<h1 class="show-on-phones" style="font-size: 200%  ; color: white ; ">Reasons To Sponsor</h1>
				<div>
					<div class="col-md-6">
						<h3>Create People's Most Memorable Experiences</h3>
						<p style="width: 80%;">We can help create an unforgettable experience for your best customers.</p>
					</div>
					<div class="col-md-6">
						<h3>Collect Engaging Content &amp; the Best Talent</h3>
						<p style="width: 80%;">Get content crafted by the best for your brand which you can use for any purpose.</p>
					</div>
				</div>
			</div>
			<div class="col-xs-12" style="margin-bottom: 10px;">
				<button 
					routerLink="/support"
					style="
				    border-color: #6D507F;
				    border-style: solid;
				    border-width: 1px;
				    margin: auto auto;
			    " 
			    class="block enter-competition-button btn-lg" 
			  >Be A Sponsor</button>
			</div>
		</div>
	</div>
	<div
		class="col-xs-12"
		style="
			background-repeat: no-repeat;
			background-position: center;
	    background-size: cover;
	    min-height: 400px;
	    color: white;
	    display: flex;
	    flex-flow: column;
	    padding: 0px;
			background-image: url(/app/assets/images/People_working_on_a_Design.jpg);
		"
	>
		<div 
			style="
		    background-color: rgba(0 , 0 , 0 , 0.65);
		    width: 100%;
		    height: 100%;
		    flex: 1 1 100%;
		    overflow: hidden;
			"
		>
			<div class="col-sm-12 col-md-offset-1 col-md-10">
				<h1 class="hide-on-phones" style="font-size: 340% ; color: white ;">How We Help</h1>
				<h1 class="show-on-phones" style="font-size: 200%  ; color: white ; ">How We Help</h1>
				<div style="margin: 0 auto; ">
					<div class="col-md-6">
						<h3 style="">Full-Service Management</h3>
						<p style="width : 80%;">We will manage the competition from beginning to end &amp; beyond.</p>
					</div>
					<div class="col-md-6">
						<h3 style="">Tested Method</h3>
						<p style="width : 80%;">We apply a methodical approach to provide the greatest quality of experience at scale.</p>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div 
		class="col-xs-10 col-xs-offset-1"
		style="margin-bottom: 20px;" 
	>
		<h3 style="text-align: center;">Help Us Make Dreams Happen For People All Around The World</h3>
		<div
			style="
		    max-width: 560px;
		    padding: 10px;
		    margin: 5px auto;
		    min-height: 140px;
	    "
		>
			<img src="/app/assets/images/sheila.jpg"
				style="
					width: 120px;
					height: 120px;
					border-radius: 60px;
			    float: left;
			    margin-right: 10px;
				" 
			/>
			<span style="
		    top: 3px;
		    position: relative;
		  ">
	    	"All my life, I've dreamed about getting my music in America. They all say it's impossible to achieve so I should forget it. Now look where I'm at."
	    	<br />
	    	<a routerLink="/profile/">Sheila, 20</a> - <a routerLink="/competition/">Top Original Hip-Hop/RnB Artist Competition</a>
	    	<br />
	    	Kumasi, Ghana
	  	</span>
		</div>
		<div
			style="
		    max-width: 560px;
		    padding: 10px;
		    margin: 5px auto;
		    min-height: 140px;
	    "
		>
			<img src="/app/assets/images/david.jpg"
				style="
					width: 120px;
					height: 120px;
					border-radius: 60px;
			    float: left;
			    margin-right: 10px;
				" 
			/>
			<span>
		    "This was a great opportunity for me. It was really tough but I learned a lot. There were so many talented producers. I really enjoyed this & hope to see such similar events coming up soon. Thanks to Spotidol for this wonderful opportunity & for support."
		    <br /><a routerLink="/profile/passion.of.flying">David, 16</a> - <a routerLink="/competition/">Top Original Hip-Hop/RnB Artist Competition</a>
		    <br />
		    Mumbai, India
		  </span>
		</div>
		<div
			style="
		    max-width: 560px;
		    padding: 10px;
		    margin: 5px auto;
		    min-height: 140px;
	    "
		>
			<img src="/app/assets/images/joel.jpg"
				style="
					width: 120px;
					height: 120px;
					border-radius: 60px;
			    float: left;
			    margin-right: 10px;
				" 
			/>
			<span style="
		    position: relative;
		    top: 20px;
	    ">
			  "Gargantuan thanks to Spotidol for hosting the competition & giving people like myself opportunities I never thought possible."
			  <br />
			  <a routerLink="/profile/highitsjoel">Joel, 22</a> - <a routerLink="/competition/">Top Original Hip-Hop/RnB Artist Competition</a>
			  <br />
				Texas, US
		  </span>
		</div>
		<div class="col-xs-12" style="margin-bottom: 10px;">
			<button 
				routerLink="/support"
				style="
			    border-color: #6D507F;
			    border-style: solid;
			    border-width: 1px;
			    margin: auto auto;
		    " 
		    class="block enter-competition-button btn-lg" 
		  >Be A Sponsor</button>
		</div>
	</div>
	<footer></footer>
</div>