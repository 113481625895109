<div>
  <div id="uploadContestEntryModal" class="cssModal" style=" display: block;" role="dialog">
      <div id="uploadContestEntryModalContent" class="cssModalContent modal-content row" style="max-width: 700px; display: block;">
        <div>
            <div class="medium-text">
              <div *ngIf="showSignup">
                <upload-contest-entry-sign-up (signedUserIn)="submittedSignUp($event)"></upload-contest-entry-sign-up>
            
                <div style="border-radius: 5px; border-width: 1px; border-color: white; border-style : solid ; padding: 15px; margin-top: 20px; max-width: 300px; color : white; text-align: center;" class="col-centered bold">
                    <span>Already a Member?</span>
                    <a (click)="toggleNotSignedInPage()" style="color: white;"><p>Log In</p></a>
                </div>
              </div>

              <div *ngIf="showSignin">
                <upload-contest-entry-login (signedUserIn)="submittedSignUp($event)"></upload-contest-entry-login>

                <div style="border-radius: 5px; border-width: 1px; border-color: white; border-style : solid ; padding: 15px; margin-top: 20px; max-width: 300px; color : white; text-align: center;" class="col-centered bold">
                    <span>Not a Member?</span>
                    <a (click)="toggleNotSignedInPage()"><p>Sign Up</p></a>
                </div>
              </div>
            </div>
          </div>
      </div>
  </div>
</div>