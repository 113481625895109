<div class="row">
	<div style="color : white ;" class="text-shadow col-xs-12 col-sm-10 col-sm-offset-1">
		<div class="row">	
			<a routerLink="/signUp">			
				<div 
					style="
						height: 20vh ; 
						min-height: 200px; 
						background-image: url(/app/assets/images/gold-medal-background.jpg);
				    margin-top: 40px;
				    background-size: cover;
				    background-position: center;
			    "
			  >
			    <div 
			    	style="
				    	display: flex; 
				    	flex: 1; 
				    	flex-direction: column; 
				    	background-color: rgba( 255 , 155 , 0 , 0.2 );
				    	height: 100%;
					    padding: 12px;
					    justify-content: space-between;
					    color : white; 
				    "
			    >
						<h1 style="margin: 0px;">Host Competitions</h1>
						<h3 style="margin: 0px;">Build Champions</h3>
						<a style="margin-right: 60px;">Get Started</a>
			    </div>
				</div>
			</a>
		</div>
		<div>
			<h3 *ngIf="openContests.length > 0" style="margin-top: 14px;">Open Entry Competitions</h3>
			<div class="row">
				<div class="col-xs-12">
						<contest-thumbnail *ngFor = "let contest of openContests" [contest]="contest"></contest-thumbnail>
				</div>
			</div>
		</div>
		<div class="row" style="margin-top: 14px;">
			<div style="text-align: center; ">		
				<div class="col-xs-12 col-sm-6">
					<div
						class="competition-feature-section"
						style="
				      background-image: linear-gradient( 45deg, rgb( 255 , 155 , 0  ) , rgb( 155 , 55 , 0  ) )  ;
						"
					>

						<h3>Host competitions to make it easy & fun to source talent</h3>
				</div>
				</div>		
				<div class="col-xs-12 col-sm-6">
					<div
						class="competition-feature-section"
						style="
				      background-image: linear-gradient( 45deg, rgb( 0 , 55 , 0 ) , rgb( 0 , 155 , 0 ) )  ;
						"
					>
						<h3>Enable virtual & live voting for private or public audiences.</h3>
				</div>
				</div>
			</div>
		</div>
		<div>
			<h3 *ngIf="publicEntryContests.length > 0" style="margin-top : 10px;">Public Entry Competitions</h3>
			<div class="row">
				<div class="col-xs-12">
					<contest-thumbnail *ngFor = "let contest of publicEntryContests" [contest]="contest"></contest-thumbnail>
				</div>
			</div>
		</div>
		<div class="row" style="margin-top: 14px;">
			<div style="text-align: center;">		
				<div class="col-xs-12 col-sm-6">
					<a routerLink="/hiphop">					
						<div
							class="competition-info-section"
							style="
					      background-image: url(https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fhiphoppic.jpg?alt=media&token=5bc50196-a22d-4d35-a1db-9407bbf62d9b);
							"
						>
							<div class="transparent-background competition-info-container">
								<h2>Hip-Hop Competitions Top 40</h2>
								<h3>Connecting hip-hop artists with the top of the game</h3>
								<a style="display: flex; align-self: flex-end;">Learn More</a>
							</div>
						</div>
					</a>
				</div>
				<div class="col-xs-12 col-sm-6">
					<a routerLink="/scienceFair">					
						<div
							class="competition-info-section"
							style="
					      background-image: url(app/assets/images/scienceFair/tvsf-header.jpg);
							"
						>
							<div class="transparent-background competition-info-container">
								<h2>Virtual Science Fairs</h2>
								<h3>Providing a platform for anyone to create & enter STEM competitions</h3>
								<a style="display: flex; align-self: flex-end;">Learn More</a>
							</div>
						</div>
					</a>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="row">
  <div 
    class="col-xs-12 col-md-offset-1 col-md-10 mock-up-section"
  >      
    <div class="mock-up-background">
      
    </div>
    <div 
      class="col-sm-6 col-xs-12"
      style="
        display: flex; 
        flex-direction: column;
      " 
    >
      <h2 class="jumbo-text header-font">
        Create &amp; Enter Competitions For Free
      </h2>
      <h2 style="margin-top: 0px;">
        Take Competitions to Go
      </h2>
      <p>Spotidol is a free platform to create &amp; enter competitions. Build networks of champions. Profit with sponsors. Connect winners from around the world. Provide &amp; gain feedback on your work.</p>
      <div 
        style="    
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-evenly;
        "
      >        
        <button
          class="enter-competition-button btn-lg dark-purple-background" 
          routerLink="/signUp"
          style="
            border-color: #6D507F;
            background-color: #38194C;
            border-style: solid;
            border-width: 1px;
            display: block;
          "
        >
          Create Competition
        </button>
        <a 
          (click)="linkToDownload()"
        >   
          <img 
            src="/app/assets/images/download-app.png" 
            style="
              height: 100px;
            " 
          />
        </a>
      </div>
    </div>
    <div class="col-sm-6 col-xs-12">
      <img 
        src="https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fspotidol-renders.png?alt=media&token=3d9808fe-6f46-4ac4-ada1-bb8586b811af" 
        style="width: 100%; height: auto;" 
      />
    </div>
  </div>
</div>
<div class="col-xs-12">
	<sign-up-redirect></sign-up-redirect>
</div>
<footer></footer>