import { Component } from '@angular/core'

import { DataService } from '../../../core/index'

import { IDream } from '../../index'

@Component({
	selector : 'create-dream' , 
	templateUrl : './createDream.html'
})

export class CreateDreamComponent {
	
	description : string 
	error : boolean 
	newDreams : IDream[] = []

	constructor(
		private dataService : DataService
	) {}

	createDream() {

		let URI = "dream/" , 
			dreamBody = {
				description : this.description
			} 

		this.dataService.postObject( URI , dreamBody )
			.subscribe( ( response : { error : string , dream : IDream } ) => {
				if ( response.error ) 
					return this.error = true 
				this.newDreams.push( response.dream )
			})

	}

}