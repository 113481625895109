import { Component , Input } from '@angular/core'
import { DataService } from '../../../core/index'

@Component({
	selector : 'update-profile-instagram-link' , 
	templateUrl : './updateProfileInstagramLink.html'
})
export class UpdateProfileInstagramLinkComponent {

	@Input() username : string
	instagramLink : string 
	err : string
	successUpdatingInstagramProfileLink : boolean

	constructor(
		private dataService : DataService
	) {}

	updateInstagramLink() {
		this.err = null
		let URI = "user/updateInstagramProfileLink"
		let params = {
			username : this.username , 
			instagramProfileLink : this.instagramLink 
		}
		this.dataService.postObject( URI , params )
			.subscribe( ( response : any ) => {
				if (response.error)
					return this.err = response.error
				return this.successUpdatingInstagramProfileLink = true
			})
	}	

	openModal() {
		document.getElementById('uploadProfileInstagramLinkModal').style.display = "block"
		document.getElementById('uploadProfileInstagramLinkModalContent').style.display = "block"
	}

	closeModal() {
		document.getElementById('uploadProfileInstagramLinkModal').style.display = "none"
		document.getElementById('uploadProfileInstagramLinkModalContent').style.display = "none"
		this.successUpdatingInstagramProfileLink = false 
	}

}