<div *ngIf="loggedIn">
	<div class="dropdown" dropdown>
	    <button class="btn btn-primary" dropdown-open style="width: 80px; border-radius: 0px;">Report</button>
	    <ul class="dropdown-menu">
	    	<li dropdown-not-closable-zone><textarea placeholder="please give details" [(ngModel)]="details" ></textarea></li>
	        <li class="dropdown-menu-item"><a (click)="reportUser('pornographic')" >pornographic</a></li>
	        <li class="dropdown-menu-item"><a (click)="reportUser('cheating')" >cheating</a></li>
	        <li class="dropdown-menu-item"><a (click)="reportUser('illegal')" >illegal</a></li>
	        <li class="dropdown-menu-item"><a (click)="reportUser('hacking')" >hacking</a></li>
	        <li class="dropdown-menu-item"><a (click)="reportUser('other')" >other</a></li>
	    </ul>
	</div>
	<div id="reportUserModal" class="cssModal">
  		<div id="reportUserModalContent" class="cssModalContent darker-background modal-content row">
      	<button type="button" class="btn btn-default top-left" (click)="closeModal()">Close</button>
  			<div class="modal-header  medium-small-text">
  				report user
  			</div>
				<div  class=" medium-small-text" *ngIf="!submittedReportToServer">
					one moment please...
				</div>
	  			<div  class=" medium-small-text" *ngIf="!!err"><p> {{ err }} </p></div>
	  			<div  class=" medium-small-text" *ngIf="successSubmittingReportToServer"> success! </div>
    	</div>
    </div>
</div>
