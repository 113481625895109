<div style="color : white;">
	<h2 style="text-align: center;">Competition Details</h2>
	<div class="col-xs-12" >
		<input class="roundBorders dark-purple-background input-styling col-xs-12" [(ngModel)]="createContestObject.contestName" id="contestName" type="text" placeholder="Competition Name">
	</div>
	<div class="col-xs-12">
		<textarea class="roundBorders dark-purple-background input-styling col-xs-12" 
		style="height: 200px;" [(ngModel)]="createContestObject.description" id="description" placeholder="Description"></textarea>
	</div>
	<div class="col-xs-12">
		<input class="roundBorders dark-purple-background input-styling col-xs-12" [(ngModel)]="tagString" id="tags" type="text" placeholder="#hash #tags">
	</div>
	<div class="col-xs-12">
		<span>Competition Privacy </span>
		<select 
			class="roundBorders dark-purple-background input-styling" 
			[(ngModel)]="createContestObject.userContestHidden" 
			id="private" 
			type="text" 
			placeholder="Please Select One"
			style="margin: auto ; margin-bottom: 10px;" 
		>
			<option>Please Select One</option>
			<option value="private">Private (Link Only)</option>
			<option value="public">Public (Searchable)</option>
		</select>
	</div>
	<input (click)="submitDetails()" class="btn btn-info btn-lg" type= "submit" value="Submit">
	<p style="color : red ;">{{ err }}</p>
</div>