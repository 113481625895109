<!-- <div class="lighter-background  roundBorders ">
	<h2>Want To Have Fun And Profit?</h2>
	<p>Good, because this is where fans can earn money with groups, win rewards and become the top fan </p>
	<p>Because the greatest pleasures can come from winning</p>
	<p>Because the greatest learning experiences can come from losing</p>
	<p>Because sometimes it's not about winning or losing, but showing up and being seen</p> 
	<p> Because sometimes it's not about being seen, but trying your best</p>
	<h3 class="inline">Spotidol is</h3> <p class="inline">the competitions platform, where anyone can create almost any group, create content-submission competitions for the group, judge the competition entries, earn money by receiving entry fees and having fans of the group refer others to become a fan, and provide rewards to the winners of competitions.</p> 
	<h2>Our Mission</h2>
	<p>Our mission is to create and sustain a culture with the greatest content where people have fun and profit.</p>
</div> -->
<div  class="dark-purple-gray-background white-text">
	<h1 class="col-xs-offset-1">Contact Us</h1>
	<div *ngIf="!postedMessage">
		<p class="col-xs-offset-1 col-xs-10">Please tell us how we are doing or if you have any questions or concerns.
		<form [formGroup]="contactSupportForm" novalidate (ngSubmit)="postSupportMessage(contactSupportForm.value)">
			<div class="block">
				<label class="col-xs-offset-1 ">email</label>
				<br>
				<input class="col-xs-10 col-xs-offset-1 input-styling roundBorders purple-gray-background" type="email" formControlName="email">
				<br>
				<label class="col-xs-offset-1">Let Us Know</label>
				<br>
				<textarea class="response-form-height roundBorders col-xs-10 col-xs-offset-1 input-styling roundBorders purple-gray-background" formControlName="supportMessage"></textarea>
			</div>
			<br />
			<div class="col-xs-offset-1 col-xs-10 block">
				<button type="submit" class="btn btn-info btn-lg purple-background" style="border-style: none; ">Submit</button>
			</div>
		</form>
	</div>
	<div class="col-xs-10 col-xs-offset-1 roundBorders ">
		<a><div (click)="toggleCompetitions()" style="color: white;">
			<h2>Competitions</h2>
		</div></a>
			<div *ngIf="showCompetitions">
				<p>Competition rules may change at any time.</p>
				<p>Competitions are games of skill. Examples include dance competition, remix competitions, even meme competitions. The only limitation is your imagination.</p>
				<p>Submissions can be picture, text, video, or audio.  Competition creators then score each entry. Scores are revealed after the winner is announced.
				</p>
				<p>Competition creators set an entry fee and a reward. Competition creators provide the reward but receive the entry fees. See below to learn more about rewards.</p>
				<p>Competition creators set a competition end time & winner selected time. Competition creators have until the winner selected time to score all entries. After the winner selected time, the entrant that has the highest score is chosen and announced as the winner.</p>
				<p>In the event of a tie, the person who submitted their entry first is chosen as the winner</p>
			</div>
	</div>
	<div class="col-xs-10 col-xs-offset-1 roundBorders ">
		<a><div style="color : white;" (click)="toggleRewards()"><h2>Rewards</h2></div></a>
			<div *ngIf="showRewards">
				<p>Winners earn cash & other rewards.</p>
				<p>Competition creators can provide details about other types of rewards for competitions as well. To send the reward to the winner, the competition creator must contact the winner of the competition via the winner's email provided by the entrant.
				</p>
			</div>
	</div>
	<div class="col-xs-10 col-xs-offset-1 roundBorders ">
		<a><div style="color : white;" (click)="togglePayments()"><h2>Payments</h2></div></a>
			<div *ngIf="showPayments">
					<p>We use the third party company Stripe to process all payments. We do not store your card number, social security number, or business tax ID</p>
				<!-- <h3>Become a Role</h3>
					<p>Competition creators choose the price to become a role and receive the payments. Becoming a role allows users to enter into competitions by the competition creator. The minimum price competition creators can charge (besides free) is 50 cents.</p> -->
			<!-- 	<h3>321Deal</h3>
					<p>The 321Deal allows fans to earn money and fanpoints by referring others to become a fan of the group. Group creators can choose to have the 321Deal, and if they do, they can set a price to get the 321Deal and receive the payments. If a fan has the 321Deal, each person they refer to become a fan of the group, the first fan gets 30% of the price to become a fan and 3 
						<img class="inline" height="18" width="18" src="/app/assets/images/fanpoint.png" /> . Each fan that other fan refers, they get 20% and 2 
						<img class="inline" height="18" width="18" src="/app/assets/images/fanpoint.png" />. Each fan that last fan refers, they get 10% and 1 
						<img class="inline" height="18" width="18" src="/app/assets/images/fanpoint.png" />. The minimum price group creators can charge to for the 321Deal (besides free) is 50 cents.</p> -->
				<h3>Fees</h3>
					<p>Spotidol receives 20% of all payments to users, along with a 5% surcharge on all payments (minimum surcharge of 3 cents).</p>
					<p>The minimum entry fee and reward (besides free) is 50 cents. The minimum tip is 75 cents. </p>
				<h3>Payouts</h3>
				<p>To receive payouts, you must completely fill out all of your user details. Please provide up to 14 business days to receive payouts.</p>
				<p>The minimum individual payout is 50 cents. The maximum individual payout is $2999. Payouts greater than $2999 will payout $2999. To receive payouts for greater than $2999, you must initiate multiple payouts.</p>
				<p>Due to processing, we currently do not autoamtically support payouts for users outside of the US. If you want to make a payout outside of the US, please contact support.</p>
			</div>
	</div>
	<!-- <a routerLink="/blog"><h2>Check out our blog!</h2></a> -->
	<div class="row">
		<p *ngIf="postedMessage">Thank you for your feedback! We will respond to you shortly.</p>
		<h2 class="col-xs-offset-1 col-xs-10"><a href="/competitionRules" style="color : white;">Official Competition Rules</a></h2>
		<h2 class="col-xs-offset-1 col-xs-10"><a href="/privacyPolicy" style="color : white;">Privacy Policy</a></h2>
		<h2 class="col-xs-offset-1 col-xs-10"><a href="/termsOfUse" style="color : white;">Terms Of Use</a></h2>
		<p *ngIf="err">{{ err }}</p>
		<!-- <div class="col-xs-12">
			<update-logs></update-logs>
		</div> -->
	</div>
	<footer></footer>
</div>