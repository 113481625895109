<div *ngIf="
  (
    contestEntry 
    &&
    (!contestEntry.entryAttachment)
  )
  ||
  !contestEntry"
>
  <div (click)="getFanAndContest()">
    <button type="button" class="white-text btn btn-info btn-lg purple-gray-background"
      style="
        border-width: 0px;
        margin: auto;
        display: block;">Enter</button>
  </div>
  <div id="uploadContestEntryModal{{contest._id}}" class="cssModal" role="dialog">
      <div id="uploadContestEntryModalContent{{contest._id}}" class="cssModalContent modal-content row">
    		<div>
            <button type="button" class="btn btn-default" (click)="closeModal()">Close</button>
            <div class="medium-text" *ngIf="userNotSignedIn">
              <div *ngIf="showSignup">
                <upload-contest-entry-sign-up (signedUserIn)="submittedSignUp($event)"></upload-contest-entry-sign-up>
            
                <div style="border-radius: 5px; border-width: 1px; border-color: white; border-style : solid ; padding: 15px; margin-top: 20px; max-width: 300px; color : white; text-align: center;" class="col-centered bold">
                    <span>Already a Member?</span>
                    <a (click)="toggleNotSignedInPage()" style="color: white;"><p>Log In</p></a>
                </div>
              </div>

              <div *ngIf="showSignin">
                <upload-contest-entry-login (signedUserIn)="submittedSignUp($event)"></upload-contest-entry-login>


                <div style="border-radius: 5px; border-width: 1px; border-color: white; border-style : solid ; padding: 15px; margin-top: 20px;  width: 300px; color : white;" class="col-centered bold">
                  <span>Not a Member?</span>
                  <a (click)="toggleNotSignedInPage()"><span>Sign Up</span></a>
                </div>
              </div>
            </div>

            <div *ngIf="errorSubmittingContestEntryToServer"><p>{{ err }}</p></div>
            <div *ngIf="successSubmittingContestEntryToServer">Success! Please click below to close.</div>
            <div id="enterContestFormDetails" *ngIf="!successSubmittingContestEntryToServer && ( !group || myFan ) && !userNotSignedIn" [hidden]="submitting">
              <h3 style="width : 100%">Add your Github repo link.</h3>
              <div style="word-break: keep-all;">
                <span>github.com/</span>
                <input
                  type="text" 
                  style="
                    border: none;
                    border-bottom: white 1px solid;
                    padding-bottom: 0px;
                    top: -3px;
                    position: relative;
                  " 
                  class="dark-purple-gray-background" 
                  placeholder="spotidol/mobile" 
                  [(ngModel)]="textEntryBody"
                >
              </div>
              <button type="submit" class="white-text btn btn-info btn-lg purple-gray-background" (click)="uploadTextEntry(textEntryBody)" >Enter</button>
            </div>
        </div>
  		</div>
  	</div>
</div>

<div *ngIf="contestEntry && contestEntry.entryAttachment ">
  <div>
    <h2>My Entry</h2>
    <contest-entries-page-contest-entry 
      [contestEntry]="contestEntry" 
      [contest]="contest" 
      [isMyGroup]="false" 
      [fan]="myFan" 
      [group]="group" 
      [userInstance]="null"
    ></contest-entries-page-contest-entry>
  </div>
</div>