<div>	
	<div style="position: absolute; top: 20px; right: 8px;">
		<media-menu [reportedUserDetails]="contestEntry.reportedUserDetails" [mediaObject]="contestEntry" [mediaObjectType]="'contestEntry'" [whiteOrBlack]="'black'"></media-menu>
	</div>
	<div #picture class="pic-container block no-margins no-padding col-sm-offset-1 col-xs-offset-0 hide-on-phones" [ngStyle]="{ 'width' : pictureWidth , 'height' : pictureWidth , 'min-width' : pictureWidth , 'min-height' : pictureWidth , 'max-width' : pictureWidth , 'max-height' : pictureWidth}">
		<p>&nbsp;</p>
	</div>
	<div #picture class="pic-container block no-margins no-padding show-on-phones" [ngStyle]="{ 'width' : pictureWidth , 'height' : pictureWidth , 'min-width' : pictureWidth , 'min-height' : pictureWidth , 'max-width' : pictureWidth , 'max-height' : pictureWidth}">
		<p>&nbsp;</p>
	</div>
</div>