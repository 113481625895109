<div>
	<div style="
		color : white ;
    position: absolute;
    -ms-transform: translateY(-30%);
    -webkit-transform: translateY(-30%);
    transform: translateY(-30%);
    top: 30%;
    width: 100vw;
  ">
		<h2 
			style="
	    " 
	    class="jumbo-text-2"
    >Be Your Best</h2>
		<div style="
			width: 80% ;
			margin: auto;
			margin-top: 30px;
		">		
			<input 
				type="text" 
				name="Search" 
				[(ngModel)]="searchInput"
				placeholder="Search Competitions" 
			  style="
			    width: 80%;
			    height: 40px;
			    border-radius: 10px;
			    background-color: rgb( 56 , 25 , 76 );
			    border-color: #6D507F;
			    border-style: solid;
			    vertical-align: top;
			    border-width: 1px;
			    font-size: 22px;
			    padding: 4px;
			    display: inline;
			    margin: 0px;
			    border-top-right-radius: 0px;
			    border-bottom-right-radius: 0px;
			    padding-right: 0px;
	    	"
	    /><button 
				style="
					width: 15%;
						max-width: 70px;
						height: 40px;
				    border-color: #6D507F;
				    border-style: solid;
				    border-width: 1px;
				    vertical-align: top;
				    display: inline-block;
				    margin: 0px;
				    border-bottom-left-radius: 0px;
				    border-top-left-radius: 0px;
				    position: relative;
				    left: -4px;
				"
			  (click)="searchCompetitions()" 
			  class="block enter-competition-button btn-lg" 
			><img 
				src="./app/assets/images/search.png" 
				height="20px" 
				width="20px" 
				style="
				  position:absolute;
				  top: 50%;
				  left:50%;
				  -webkit-transform: translate(-50%, -50%);
				  -ms-transform: translate(-50%, -50%);
				  transform: translate(-50%, -50%);
				" 
			/></button>
		</div>
<!-- 	</div> -->
</div>