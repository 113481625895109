<div class="col-xs-12 col-lg-10 col-lg-offset-1">
	<div *ngIf="isMyGroup || group.active">
		<div class="groupMain container goldFont noPadding noTopOrBottomMargin">
		</div>
		<div class="row relative">	
			<div class="col-xs-12 col-sm-4 group-details-container" style="padding: 0px;">		
				<div class="group-details-section">			
					<div class="col-xs-12 fill-screen large-content-height" *ngIf="group.profilePicture == null"  style="background-image: url(https://storage.googleapis.com/spotidol/spotidolBannerPicture.png)">
						<div style="position: absolute; bottom: 20px; right: 25px;">
							<media-menu [reportedUserDetails]="reportedUserDetails" [mediaObject]="group" [mediaObjectType]="'group'" [whiteOrBlack]="'white'"></media-menu>
						</div>`
						<div class="floatRight" *ngIf="isMyGroup">
							<button *ngIf="group.active" (click)="updateGroup('active' , false)" class="btn-info btn-sm">Deactivate Group</button>
							<button *ngIf="!group.active" (click)="updateGroup('active' , true)" class="btn-info btn-sm">Activate Group</button>
						</div>
						<div class="block white-text  text-shadow">
							<a class="noTopOrBottomMargin group-name  text-shadow"> 
								<div *ngIf="!isMyGroup" class="text-shadow">
									<h2 class="inline group-name bold-font white-text  text-shadow">
										{{ group.groupName }}
									</h2>
									<p class="inline no-margins word-break white-text  text-shadow">@{{ group?.groupUrl }}</p>
								</div>
								<div *ngIf="isMyGroup" class="text-shadow">
									<form class="block" (submit)="updateGroup('groupName' , this.newGroupName)">	
										<p class="word-break floatLeft  text-shadow">
											<img class="floatLeft pencil-logo inline no-margins no-padding" src="/app/assets/images/pencil-logo.png" height="16px" width="16px">
											<input (input)="setUpdateGroupTimer('groupName' , this.newGroupName)" type="text" class="text-shadow floatLeft transparent-input" placeholder="{{ group?.groupName }}" [(ngModel)]="newGroupName" [ngModelOptions]="{standalone: true}">
							    			<button type="submit" style="display:none">hidden submit</button>
										</p>
									</form>
									<form class="block word-break" (submit)="updateGroup('groupUrl' , this.newGroupUrl)">	
										<div class="word-break floatLeft  text-shadow" >
											<img class="floatLeft pencil-logo inline no-margins no-padding" src="/app/assets/images/pencil-logo.png" height="16px" width="16px">
											<p class="floatLeft text-shadow ">@</p><input (input)="setUpdateGroupTimer('groupUrl' , this.newGroupUrl)" type="text" placeholder="{{ group?.groupUrl }}" class="floatLeft transparent-input text-shadow " [(ngModel)]="newGroupUrl" [ngModelOptions]="{standalone: true}">
										</div>
						    			<button type="submit" style="display:none">hidden submit</button>
									</form>
								</div>
							</a>
						</div>
						<div class="bottom col-xs-7" style="
							bottom: 10px
    					line-height: 30px;
    				"  *ngIf="group.tags">
							<div 
								*ngFor="let tag of group.tags" 
								style="
							    margin: 5px;
							    padding: 5px;
							    background-color: rgba(152, 140, 229, 0.6);
							    color: white;
							    word-break: keep-all;
							    white-space: nowrap;
							    border-radius: 10px;
							    text-align: center;
		    					display: inline;
							  "
					    >
								<a routerLink="/search/{{ tag }}"><span class="white-text">{{ tag }}</span></a>
								<a>
									<img *ngIf="isMyGroup" (click)="updateGroup('removeTag' , tag)" class="inline no-margins no-padding" src="/app/assets/images/cancel-btn.png" height="16px" width="16px">
								</a>
							</div>
							<div
								*ngIf="isMyGroup" 
								style="
							    margin: 5px;
							    padding: 5px;
							    word-break: keep-all;
							    background-color: rgba(152, 140, 229, 0.6);
							    color: white;
							    border-radius: 10px;
							    text-align: center;
		    					display: inline;
							  "
							>
						    	<img class="no-wrap inline no-margins no-padding" src="/app/assets/images/plus-btn.png" height="16px" width="16px">
								<form class="inline" (submit)="updateGroup('addTags' , this.newTags)">	
									<input 
										type="text" 
										class="transparent-input tag-input inline" 
										style="color: white;" 
										placeholder="Add Tag" 
										[(ngModel)]="newTags" 
										[ngModelOptions]="{standalone: true}"
									>
					    			<button type="submit" style="display:none">hidden submit</button>
								</form>
							</div>
						</div> 
					</div>

					<div class="col-xs-12 fill-screen large-content-height noTopOrBottomPadding noTopOrBottomMargin" *ngIf="!!group.profilePicture"  [ngStyle]="{'background-image': 'url(' + group.profilePicture
					+ ')'}">
						<div *ngIf="!isMyGroup">	
							<report-user [reportedUserDetails]="reportedUserDetails"></report-user>
						</div>
						<div class="floatRight" *ngIf="isMyGroup">
							<button *ngIf="group.active" (click)="updateGroup('active' , false)" class="btn-info btn-sm">Deactivate Group</button>
							<button *ngIf="!group.active" (click)="updateGroup('active' , true)" class="btn-info btn-sm">Activate Group</button>
						</div>
						<div class="block">				
							<a routerLink="/group/{{ group?.groupUrl }}" class="noTopOrBottomMargin group-name noTopOrBottomPadding"> 
								<div *ngIf="!isMyGroup">
									<h2 class="inline group-name bold-font white-text text-shadow ">
										{{ group.groupName }}
									</h2>
									<p class="word-break inline no-margins white-text text-shadow ">@{{ group?.groupUrl }}</p>
								</div>
								<div *ngIf="isMyGroup">
									<form class="block" (submit)="updateGroup('groupName' , this.newGroupName)">	
										<p class="floatLeft word-break">
											<img class="floatLeft pencil-logo inline no-margins no-padding" src="/app/assets/images/pencil-logo.png" height="16px" width="16px">
											<input (input)="setUpdateGroupTimer('groupName' , this.newGroupName)" type="text" class="floatLeft transparent-input text-shadow " placeholder="{{ group?.groupName }}" [(ngModel)]="newGroupName" [ngModelOptions]="{standalone: true}">
					    				<button type="submit" style="display:none">hidden submit</button>
										</p>
									</form>
									<form class="word-break block" (submit)="updateGroup('groupUrl' , this.newGroupUrl)">	
										<div class="floatLeft word-break">
											<img class="floatLeft pencil-logo inline no-margins no-padding" src="/app/assets/images/pencil-logo.png" height="16px" width="16px"><p class="floatLeft text-shadow ">
											@</p><input (input)="setUpdateGroupTimer('groupUrl' , this.newGroupUrl)" type="text" placeholder="{{ group?.groupUrl }}" class="transparent-input text-shadow " [(ngModel)]="newGroupUrl" [ngModelOptions]="{standalone: true}">
										</div>
						    			<button type="submit" style="display:none">hidden submit</button>
									</form>
								</div>
							</a>
						</div>
						<div class="bottom col-xs-12" style="bottom: 10px; line-height: 30px; white-space: normal;" *ngIf="group.tags">
							<div 
								*ngFor="let tag of group.tags"
								style="
							    margin: 5px;
							    padding: 5px;
							    background-color: rgba(152, 140, 229, 0.6);
							    color: white;
							    border-radius: 10px;
							    white-space: pre-line;
							    word-break: keep-all;
							    text-align: center;
		    					display: inline;
							  "
							>
								<a routerLink="/search/{{ tag }}"><span class="white-text">{{ tag }}</span></a> 
								<a>
								<img *ngIf="isMyGroup" (click)="updateGroup('removeTag' , tag)" class="inline no-margins no-padding" src="/app/assets/images/cancel-btn.png" height="16px" width="16px"></a>
							</div>
							<div 
								*ngIf="isMyGroup"
								style="
							    margin: 5px;
							    padding: 5px;
							    background-color: rgba(152, 140, 229, 0.6);
							    word-break: keep-all;
							    color: white;
							    border-radius: 10px;
							    text-align: center;
		    					display: inline;
							  "
							>
								<a class="inline">
							    	<img class="no-wrap inline no-margins no-padding" src="/app/assets/images/plus-btn.png" height="16px" width="16px"> 
									<form class="inline" (submit)="updateGroup('addTags' , this.newTags)">	
										<input (click)="addHashtag()" class="transparent-input tag-input inline" type="text" placeholder="Add Tag" [(ngModel)]="newTags" [ngModelOptions]="{standalone: true}">
						    			<button type="submit" style="display:none">hidden submit</button>
									</form>
							  	</a>
							</div>
						</div> 
					</div>
					<div class="row">
						<group-upload  *ngIf="groupLoaded && isMyGroup" [group]="group" class="inline no-margins no-padding"></group-upload>
						<div class="col-xs-offset-1" *ngIf="isMyGroup">
							<div class="col-xs-offset-1" *ngIf="updateGroupSuccess">
								<h2>Success Updating Group!</h2>
							</div>
							<div *ngIf="!!updateGroupError"> {{ updateGroupError }} </div>
						</div>
						<div style="background-color: #121014; padding: 5px;">
							<div class="col-xs-12 white-text" style="background-color: #121014;">
								<admin [group]="group"></admin>
							</div>
						</div>
					</div>
			</div>
		</div>
			<div *ngIf="topFans" class="col-xs-12 col-md-8 inline">
				
				<div *ngFor="let fan of topFans" class="fan-score-container">
					<div>
						<a routerLink="/profile/{{ fan.username }}"><span style="color: white;">
							{{ fan.position }}
							<img 
								*ngIf="fan.profilePicture"src="{{ fan.profilePicture }}" 
								style="height : 36px ; width : 36px ; border-radius : 18px;"
							/>
							<img 
								*ngIf="!fan.profilePicture"src="https://storage.googleapis.com/spotidol/spotidolEclipse.png" 
								style="height : 36px ; width : 36px ; border-radius : 18px;"
							/>
							{{ fan.username }}
						</span></a>
					</div>
					<span>{{ fan.score }}</span>
				</div>
				<a routerLink="/players/{{ group.groupUrl }}"><p style="color: white; font-size: 16px; margin: 10px;">View All</p></a>
			</div>
			<div *ngIf="openContests && openContests.length > 0"
            class="col-xs-12 col-md-8 inline" style="padding: 0px;">
				<open-contest-thumbnail *ngFor = "let contest of openContests" [contest]="contest"></open-contest-thumbnail>
			</div>
			<div *ngIf="pastContests && pastContests.length > 0 && (openContests && openContests.length > 0)"
            class="col-xs-12" style="padding: 0px;">
				<div>
					<contest-thumbnail *ngFor = "let contest of pastContests" [contest]="contest"></contest-thumbnail>
				</div>
			</div>
			<div *ngIf="pastContests && pastContests.length > 0 && (!openContests || openContests.length === 0)"
            class="col-xs-12 col-md-8" style="padding: 0px;">
				<div>
					<open-contest-thumbnail *ngFor = "let contest of pastContests" [contest]="contest"></open-contest-thumbnail>
				</div>
			</div>
		</div>
	</div>
</div>
<div *ngIf="!group || (!isMyGroup && !group.active)"><h1>Cannot Find Group</h1></div>
<div *ngIf="!!err"> {{ err }} </div>
