import { Injectable } from '@angular/core';
import {
  Router, Resolve , 
  ActivatedRouteSnapshot , 
  RouterStateSnapshot
} from '@angular/router';
import { DataService } from '../../core/data.service'
import { SessionService } from '../../core/session.service'
import { IContest } from '../../shared/interfaces'

@Injectable()
export class RecentContestsResolveService {

	recentContestsData : any = {}

  	constructor(
		private dataService: DataService, 
		private sessionService : SessionService ,    
 		private router: Router
 	) { }

 	resolve( route : ActivatedRouteSnapshot , state : RouterStateSnapshot) {
		return new Promise<void>( ( resolve , reject ) => {
			Promise.all([
				this.getMostRecentContests(1) , 
				this.getTopCashRewardsContests() , 
				this.getOpenContests()
			])
			.then(() => {
				return resolve(this.recentContestsData)
			})
		})
	}

	getOpenContests() {
		return new Promise<void>( ( resolve , reject ) => {
			let URI = 'contest/getOpenContests'
			this.dataService.getObject(URI)
				.subscribe( ( response : any ) => {
	          		this.recentContestsData.openContests = response.contests;
	          		resolve()
				})
		})
	}

	getMostRecentContests( page : number ) {
		return new Promise<void>( ( resolve , reject ) => {
			let URI = 'contest/getRecentContests/' + page
	    	this.dataService.getObject(URI)
		        .subscribe((response : any) => {
		        	this.recentContestsData.pages = response.pages
		          for (var i = 0 ; i < response.contests.length ; i++) {
					var endOfContest = response.contests[i].endOfContest
					var dateAndTime = endOfContest.split('T')
					var date = dateAndTime[0].split('-')
					var year = date[0]
					var month = date[1]
					var day = date[2]
					if (day.charAt(0) === '0')
						day = day.charAt(1)

					var stringifiedMonth = ''
					switch (month) {
						case '01': {
							stringifiedMonth = 'Jan'
							break
						}
						case '02': {
							stringifiedMonth = 'Feb'
							break
						}
						case '03': {
							stringifiedMonth = 'Mar'
							break
						}
						case '04': {
							stringifiedMonth = 'Apr'
							break
						}
						case '05': {
							stringifiedMonth = 'May'
							break
						}
						case '06': {
							stringifiedMonth = 'Jun'
							break
						}
						case '07': {
							stringifiedMonth = 'Jul'
							break
						}
						case '08': {
							stringifiedMonth = 'Aug'
							break
						}
						case '09': {
							stringifiedMonth = 'Sep'
							break
						}
						case '10': {
							stringifiedMonth = 'Oct'
							break
						}
						case '11': {
							stringifiedMonth = 'Nov'
							break
						}
						case '12': {
							stringifiedMonth = 'Dec'
							break
						}
					}
				    response.contests[i].formattedEndOfContest = stringifiedMonth + ' ' + day + ' ' + year
		          }
	          		this.recentContestsData.contests = response.contests;
	          		resolve()
		        },
	        (err: any) => console.log(err),
	        () => console.log("contests"));
		})
	}

	getTopCashRewardsContests() {
		return new Promise<void>( ( resolve , reject ) => {
			let URI = 'contest/getContestsByTopCashReward'
	    	this.dataService.getObject(URI)
		        .subscribe((response : any) => {
		          for (var i = 0 ; i < response.contests.length ; i++) {
					var endOfContest = response.contests[i].endOfContest
					var dateAndTime = endOfContest.split('T')
					var date = dateAndTime[0].split('-')
					var year = date[0]
					var month = date[1]
					var day = date[2]
					if (day.charAt(0) === '0')
						day = day.charAt(1)

					var stringifiedMonth = ''
					switch (month) {
						case '01': {
							stringifiedMonth = 'Jan'
							break
						}
						case '02': {
							stringifiedMonth = 'Feb'
							break
						}
						case '03': {
							stringifiedMonth = 'Mar'
							break
						}
						case '04': {
							stringifiedMonth = 'Apr'
							break
						}
						case '05': {
							stringifiedMonth = 'May'
							break
						}
						case '06': {
							stringifiedMonth = 'Jun'
							break
						}
						case '07': {
							stringifiedMonth = 'Jul'
							break
						}
						case '08': {
							stringifiedMonth = 'Aug'
							break
						}
						case '09': {
							stringifiedMonth = 'Sep'
							break
						}
						case '10': {
							stringifiedMonth = 'Oct'
							break
						}
						case '11': {
							stringifiedMonth = 'Nov'
							break
						}
						case '12': {
							stringifiedMonth = 'Dec'
							break
						}
					}
				    response.contests[i].formattedEndOfContest = stringifiedMonth + ' ' + day + ' ' + year
		          }
	          		this.recentContestsData.topCashRewardContests = response.contests;
	          		resolve()
		        },
	        (err: any) => console.log(err),
	        () => console.log("contests"));
		})
	}
}