import { Component } from '@angular/core'

@Component({
	selector : 'upload-contest-entries-mobile-modal' , 
	templateUrl : './uploadContestEntriesMobileModal.html'
})

export class UploadContestEntriesMobileModalComponent {

  linkToDownload() {
    var isIOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    var isAndroid = !!navigator.platform && /android/.test(navigator.platform)
    if (isIOS)
      window.location.href = "https://itunes.apple.com/us/app/spotidol-competitions/id1441184363?mt=8"
    else 
      window.location.href = "http://play.google.com/store/apps/details?id=com.spotidol.spotidol"
  }

}