<div class="cssModal" style="display: block;">
		<div class="cssModalContent dark-purple-background modal-content row" style="display: block;">
		<button type="button" class="btn btn-default" style="position: absolute; top: 0px; left : 0px;" (click)="closeModal()">Close</button>
		<div *ngIf="mediaObjectType != 'contestEntry' || mediaObject.entryPrivacy != 'private' ">
			<div class="col-xs-12">
				<h2 style="text-align: center;">Great! Now Share Your {{ shareText }}!</h2>
			</div>
			<div class="col-xs-12">
				<div class="col-xs-3 col-xs-offset-3">
					<div class="fb-share-button" data-href="https://spotidol.com" data-layout="button_count" data-size="small" data-mobile-iframe="true"><a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https://spotidol.com/{{link}}&amp;src=sdkpreparse" class="fb-xfbml-parse-ignore"><img src="./app/assets/images/fshare.jpg" style="width: 120px ; height: 46px; display: block !important;"></a></div>
				</div>
				<div class="col-xs-3"> 
					<a target="_blank" href="http://twitter.com/share?url=https://spotidol.com/{{link}}&hashtags=spotidol,competition,contest,group"><img src="./app/assets/images/tweetbutton.jpg" style="width: 120px; height: 46px; display: block !important;"></a>
				</div>
			</div>
		</div>
		<div class="col-xs-12" *ngIf="mediaObjectType == 'contestEntry' && mediaObject.entryPrivacy == 'private' ">
			<h2 style="text-align: center;">Great! You have entered into this competition. Come back soon to see how you do!</h2>
		</div>
		<div class="col-xs-12">
			<h3 style="text-align: center;">Follow Spotidol</h3>
		</div>
		<div class="col-xs-offset-3 col-xs-6">
			<a href="https://www.facebook.com/spotidol">
				<p class="col-xs-4" style="text-align: center; color : white;">
					<img src="/app/assets/images/facebook-logo-gray.png" style="display: inline-block;" width="18" height="18">
					<span>Facebook</span>
				</p>
			</a>
			<a href="https://www.twitter.com/spotidolo">
				<p class="col-xs-4" style="text-align: center; color : white;">
					<img src="/app/assets/images/twitter-logo-gray.png" style="display: inline-block;" width="18" height="18">
					<span>Twitter</span>
				</p>
			</a>
			<a href="https://www.instagram.com/spotidol">
				<p class="col-xs-4" style="text-align: center; color : white;">
					<img src="/app/assets/images/instagram-logo-gray.png" style="display: inline-block; width : 18px ; height : 18px; ">
					<span>Instagram</span>
				</p>
			</a>
		</div>
		<div class="col-xs-12">
			<a href="https://www.surveymonkey.com/r/QYT2XJP"><p style="color: white; text-align: center;">Take Our Survey!</p></a>
		</div>
	</div>
</div>
