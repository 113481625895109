
import { Injectable } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import {
    Router, Resolve,
    ActivatedRouteSnapshot
} from '@angular/router';
import { DataService } from '../../core/data.service'
import { SessionService } from '../../core/session.service'

@Injectable()
export class RecentGroupsResolveService {

	recentGroupsData : any = {}

    constructor(private dataService: DataService, private sessionService : SessionService , 
     private router: Router) { }
	
	resolve() {
		return new Promise<void>( ( resolve , reject ) => {
			this.getMostRecentGroups(1)
			.then(() => {
		        let metaDescription = document.createElement('meta');
		        metaDescription.name = "description";
		        metaDescription.content = this.recentGroupsData.groups[0].groupName + ' , fans: ' + this.recentGroupsData.groups[0].totalFans + ', ' + this.recentGroupsData.groups[1].groupName + ' , fans: ' + this.recentGroupsData.groups[1].totalFans
		        document.getElementsByTagName('head')[0].appendChild(metaDescription);
				return resolve(this.recentGroupsData)
			})
		})
	}

	getMostRecentGroups( page : number ) {
		return new Promise<void>( ( resolve , reject ) => {
			let URI = 'group/getMostRecentGroups/' + page
	    	this.dataService.getObject(URI)
		        .subscribe((response) => {
		        	this.recentGroupsData.pages = response.pages
	          		this.recentGroupsData.groups = response.groups;
	          		return resolve()
		        },
	        (err: any) => console.log(err),
	        () => console.log("groups"));
		})
	}
}