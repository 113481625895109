import { Injectable } from '@angular/core'
import { IContest } from '../../shared/interfaces'
import { InternationalService } from '../../shared/international.service'

@Injectable()
export class RewardsService {
	
	constructor(
		private internationalService : InternationalService
	) {}

  setRewardCurrency( contest : IContest , session : any ) {
  	let rewardCurrencySymbol
    if (contest.rewardCash > 0) {
      if (this.internationalService.getCurrencyCode(session.country) !== contest.currency) {
        let exchangeRate = session.exchangeRate / contest.exchangeRate
        rewardCurrencySymbol = this.internationalService.convertCurrencySymbol(session.country)
        contest.rewardCash = Math.ceil(contest.rewardCash * exchangeRate * 100 ) / 100
        contest.displayRewardCash = parseFloat(contest.rewardCash.toFixed(2))
      } else {
        rewardCurrencySymbol = this.internationalService.convertCurrencySymbol(session.country)
        contest.rewardCash = Math.ceil(contest.rewardCash * 100 ) / 100
        contest.displayRewardCash = parseFloat(contest.rewardCash.toFixed(2))
      }
    }
    if ( contest.positions > 1 ) {
      for (var i = 0 ; i < contest.rewards.length ; i++ ) {
        if (contest.rewards[i].rewardCash > 0) {
          if (this.internationalService.getCurrencyCode(session.country) !== contest.currency) {
            let exchangeRate = session.exchangeRate / contest.exchangeRate
            rewardCurrencySymbol = this.internationalService.convertCurrencySymbol(session.country)
            contest.rewards[i].rewardCash = Math.ceil(contest.rewards[i].rewardCash * exchangeRate * 100 ) / 100
            contest.rewards[i].displayRewardCash = parseFloat(contest.rewards[i].rewardCash.toFixed(2))
          } else {
            rewardCurrencySymbol = this.internationalService.convertCurrencySymbol(session.country)
            contest.rewards[i].rewardCash = Math.ceil(contest.rewards[i].rewardCash * 100 ) / 100
            contest.rewards[i].displayRewardCash = parseFloat(contest.rewards[i].rewardCash.toFixed(2))
          }
        }
      }
    }

    return {
    	contest : contest , 
    	rewardCurrencySymbol : rewardCurrencySymbol
    }
	}
}