import { Component , OnInit } from '@angular/core'

import { DataService } from '../../core/index'

import { IContestEntry , IContest } from '../../shared/interfaces'

@Component({
	selector : 'welcome-playlist' ,
	templateUrl : './welcomePlaylist.html' ,
	styleUrls : ['./welcomePlaylist.css']
})

export class WelcomePlaylistComponent {

	contestEntries : IContestEntry[] = []
	contest : IContest 

	constructor(
		private dataService : DataService
	) {}

	ngOnInit() {
		let URI = "contestEntry/getWelcomePlaylist"
		this.dataService.getObject(URI)
			.subscribe( ( response ) => {
				this.contest = response.contest
				this.contestEntries = response.contestEntries
			})
	}

}