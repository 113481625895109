import { Injectable } from '@angular/core'

import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()

export class OpenAppService {

	openAppInit : any = null

	private openInAppSubject = new BehaviorSubject<any>(this.openAppInit)
	public openApp : any = this.openInAppSubject.asObservable()

	renewOpenApp( openApp : any ) {
		this.openInAppSubject.next( openApp )
	}
}