import { trigger, state, style, animate, transition } from '@angular/animations';

export var searchBarAnimation = trigger('showSearchTrigger', [
	state('show' , style({
		'transform': 'translateY(0%)'
	})), 
	state('hide' , style({
		'transform': 'translateY(-100%)'
	})), 
	transition('show <=> hide' , animate('250ms ease-out'))
]) 