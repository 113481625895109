<div *ngIf="contestEntries && contestEntries.length > 0" class="col-xs-12 col-md-10 col-md-offset-1" style="margin-top: 40px ; margin-bottom: 40px ;">
	<div class="col-xs-12 col-md-6 welcome-playlist-info"
	style="background-image : url({{ contest.groupProfilePicture }})">
		<div>
			<a class="welcome-playlist-contest-name" routerLink="/competition/{{ contest._id }}">{{ contest.contestName }}</a>
			<a class="welcome-playlist-group-url" routerLink="/group/{{ contest.groupUrl }}">@{{ contest.groupUrl}}</a>
		</div>
	</div>
	<div class="col-xs-12 col-md-6" style="padding: 0px; background-color: rgb( 100 , 100 , 100 );">
		<div style="display: flex; flex-direction: column; justify-content: space-around; height: 500px ; padding : 0px ; border-top : solid 1px gray ;">
			<welcome-player style="height: 100%; border-bottom: solid 1px gray ; display: flex ; align-items: center;" *ngFor="let contestEntry of contestEntries" [contestEntry]="contestEntry"></welcome-player>
		</div>
	</div>
</div>