import { Component , OnInit } from '@angular/core'

import { DataService } from '../../core/data.service'

import { IGroup } from '../../shared/interfaces'

@Component({
	selector: 'myGroups', 
	templateUrl: "./myGroups.html"
})

export class MyGroupsComponent implements OnInit { 

	groupsImAFanOf : IGroup[]
	groupsICreated : IGroup[]

	constructor(
		private dataService : DataService
	) {}

	ngOnInit () {
		this.getGroupsImAFanOf()
		this.getGroupsICreated()
	}

	getGroupsImAFanOf() {
		let URI = 'group/getGroupsByFanId'
    	this.dataService.getObject(URI)
	        .subscribe((response) => {
	          this.groupsImAFanOf = response.groups;
	        },
        (err: any) => console.log(err),
        () => console.log("groupsImAFanOf"));
	}

	getGroupsICreated() {
		let URI = 'group/getGroupsByUserId'
    	this.dataService.getObject(URI)
	        .subscribe((response) => {
	          this.groupsICreated = response.groups;
	        },
        (err: any) => console.log(err),
        () => console.log("groupsICreated"));
	}

 }