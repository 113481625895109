<div 
    class="row no-padding no-margins text-center"
    style="
        overflow: hidden;
        height: 100%;
        color: white;
    "
>  
    <h1>Sign Up To Continue</h1>
    <p>{{err}}</p>
        <div *ngIf="submittingSignUpToServer" style="margin : 80px auto;" class="loader"></div>
    <form clsas="margin-bottom login-input col-centered" *ngIf="!submittingSignUpToServer" [formGroup]="signUpForm" autocomplete="off" novalidate (ngSubmit)="signUp(signUpForm.value)">
        <div class="margin-bottom">
            <input class="margin-bottom col-centered white-border input-styling block" style="background-color: #251133; border: 1px solid white;" formControlName="email" id="email" type="text" placeholder="email">
        </div>
        <div class="margin-bottom">
            <input class="margin-bottom col-centered white-border input-styling block"  style="background-color: #251133; border: 1px solid white;" formControlName="username" id="username" type="text" placeholder="username">
        </div>
        <div class="margin-bottom">
            <input class="margin-bottom col-centered white-border input-styling block"  style="background-color: #251133; border: 1px solid white;" formControlName="password" id="password" type="password" placeholder="password">
        </div>
        <div class="margin-bottom">
            <input class="margin-bottom col-centered white-border input-styling block"  style="background-color: #251133; border: 1px solid white;" formControlName="confirmPassword" id="confirmPassword" type="password" placeholder="re-enter password">
        </div>
        <a (click)="toggleReferralInput()" *ngIf="!showReferralInput"><p style="text-align: center;">+Add Referral Code (Optional)</p></a>
        <div *ngIf="showReferralInput" class="margin-bottom">
            <input class="margin-bottom col-centered white-border input-styling block"  style="background-color: #251133; border: 1px solid white;" formControlName="referralCode" id="referralCode" type="text" placeholder="Referral Code">
        </div>
        <div class="margin-bottom">
            <button type="submit" class="margin-bottom col-centered btn btn-md block no-padding no-margins medium-small-text white-text" style="padding: 4px; border: 1px solid white; background-color: #251133;"><b>Sign Up</b></button>
        </div>
        <p *ngIf="submittingSignUpToServer">Signing up, one moment please.</p>

    </form>
</div>