import { Component , OnInit , OnDestroy , NgZone , Input , Sanitizer } from '@angular/core'
import { trigger, state , style , transition, animate , keyframes } from '@angular/animations'
import { ActivatedRoute , Router , Resolve } from '@angular/router'

import { FormControl , FormGroup , Validators } from '@angular/forms'

import { DataService , SessionService , UserDataService } from '../../core/index'
import { DateService } from '../../shared/date.service'
import { IContest , IFan , IGroup , IUser} from '../../shared/interfaces'
import { DomSanitizer } from '@angular/platform-browser';

@Component({
	selector: 'sign-up', 
	templateUrl: "./signUp.html" , 
	styleUrls : ["./signUp.css"] 
})

export class SignUpComponent implements OnInit {

 	sessionSubscription : any
 	submittingSignUpToServer : boolean 
 	successSubmittingSignUp : boolean
 	session : any
 	err : string
 	setSession : boolean = false
	signUpForm : FormGroup
	showReferralInput : boolean = false

	constructor(
		private dataService : DataService , 
		private dateService : DateService , 
		private userDataService : UserDataService , 
		private sessionService : SessionService , 
		private route : ActivatedRoute , 
		private sanitizer : DomSanitizer , 
		private router : Router 
	) {}


	ngOnInit() {
		let username = new FormControl('' , Validators.required)
		let email = new FormControl('' , Validators.required)
		let password = new FormControl('' , Validators.required)
		let confirmPassword = new FormControl('' , Validators.required)
		let referralCode = new FormControl('')
		this.signUpForm = new FormGroup({
			username : username , 
			email : email , 
			password : password , 
			referralCode : referralCode , 
			confirmPassword : confirmPassword
		})
		this.sessionSubscription = this.sessionService.session
			.subscribe( ( session : any )=> {
				if (this.setSession)
					return
				this.session = session
				this.setSession = true
				if (session.loggedIn === true)
					this.router.navigateByUrl('/home')
			})
	}

	ngOnDestroy(){
		this.sessionSubscription.unsubscribe()
	}


	toggleReferralInput() {
		this.showReferralInput = !this.showReferralInput
	}

	signUp(formValues : any) {
		if (!formValues.username)
			return this.err = 'Please provide a username'
		if (!formValues.email)
			return this.err = 'Please provide an email'
		if (!formValues.password)
			return this.err = 'Please provide your password'
		if (formValues.confirmPassword !== formValues.password) 
			return this.err = 'The passwords you entered do not match'

		formValues.country = this.session.country
		var URI = 'user/signUpViaEmail'

		this.submittingSignUpToServer = true

		this.dataService.postObject(URI, formValues)
			.subscribe((response : any) => {
				this.submittingSignUpToServer = false
				if (response.error) 
					return this.err = response.error
				let userData : any = {
					createdGroups : [
						response.group
					] ,
					myGroups : [] ,
					myFans : [] , 
					myContests : [] ,
					myContestEntries : []
				}
				this.userDataService.renewUserData(userData)
				this.err = null
				this.successSubmittingSignUp = true
				return window.location.replace('/home/firstTime')
			}, 
		    (err: any) => console.log(err),
		    () => this.submittingSignUpToServer = false)
	}

	validateEmail(email : any) {
	    var re = /\S+@\S+\.\S+/;
	    return re.test(email);
	}
}