import { Routes } from '@angular/router'

import { RootComponent, RootResolveService } from './root/index'

import { WelcomeComponent } from './welcome/welcome.component'
import { WelcomeResolveService } from './welcome/welcome.resolve.service'
import { HomeComponent } from './home/home.component'
import { OneOnOnePrivacyPolicyComponent , OneOnOneTermsOfUseComponent , FaqComponent , ValuesComponent , VisionComponent, MissionComponent , StoryComponent, HowToComponent , BlogPostsComponent, AboutComponent , SupportComponent , SupportMessagesComponent , 
		  ContestRulesComponent , PrivacyPolicyComponent , TermsOfUseComponent , CMOComponent , CareersComponent , BeASponsorComponent , PortfolioComponent , AffiliatesComponent } from './support/index'
import { PaymentHistoryComponent } from './paymentHistory/paymentHistory.component'

import { 
	UnsubscribeComponent , LoginComponent , UpdateUserComponent , SignUpComponent , 
	EmailVerificationComponent , PhoneVerificationComponent , 
	LogoutComponent , BannedComponent ,  
	ResetPasswordComponent , TotalOwedComponent , CashOutComponent 
} from './user/index'

import { UnderConstructionComponent } from './underConstruction/underConstruction.component'

import { 
	GroupComponent , GroupUploadComponent , CreateGroupComponent , 
	MyGroupsComponent , RecentGroupsComponent , FansComponent , 
	GroupResolveService , RecentGroupsResolveService 
} from './group/index'

import { ScienceFairComponent } from './scienceFair/index'
 
import { HipHopComponent } from './hipHop/hipHop.component'

import { PricingComponent } from './pricing/pricing.component'

import { ProfileComponent , ProfileResolveService } from './profile/index'

import { WinnersComponent } from './winner/index'

import { RecentContestsComponent , CreateContestReviewComponent , CreateContestEntryDetailsComponent , CreateContestPrizeDetailsComponent , CreateContestJudgingDetailsComponent , CreateContestVotingDetailsComponent , CreateContestComponent , CreateContestDetailsComponent , ChooseGroupComponent , MyContestsComponent , RecentContestsResolveService , WelcomeContestsComponent } from './contests/index'

import { ContestEntryPictureGalleryComponent , ContestEntryPictureGalleryResolveService , UploadContestEntryComponent , ContestEntriesComponent , MyContestEntriesComponent , 
  		ContestEntriesResolveService , CompetitionEntryComponent , CompetitionEntryResolveService } from './contestEntry/index'
import { SearchComponent } from './search/search.component'
import { PageNotFoundComponent } from './pageNotFound/pageNotFound.component'

export const appRoutes : Routes = [
	{
		path: '',
		component: RootComponent, 
		resolve : { RootData : RootResolveService } ,
		children: [
			{ path : 'createCompetition' ,
				component : CreateContestComponent , 
				children : [
					{ path : 'group' , component : ChooseGroupComponent } ,
					{ path : 'details' , component : CreateContestDetailsComponent } , 
					{ path : 'entry' , component : CreateContestEntryDetailsComponent } , 
					{ path : 'voting' , component : CreateContestVotingDetailsComponent } , 
					{ path : 'judging' , component : CreateContestJudgingDetailsComponent } , 
					{ path : 'prizes' , component : CreateContestPrizeDetailsComponent } , 
					{ path : 'review' , component : CreateContestReviewComponent } 
				]
			} , 
			{ path : 'welcome' , component : WelcomeComponent } , 
			{ path : 'profile/:username' , component : ProfileComponent , resolve: { profileData : ProfileResolveService }} , 
			{ path : 'underConstruction' , component : UnderConstructionComponent } ,
			{ path : 'home' , component : HomeComponent } ,
			{ path : 'home/:firstTime' , component : HomeComponent } ,
			{ path : 'competitionRules' , component : ContestRulesComponent }, 
		 	{ path : 'unsubscribe/:username/:subscriberCode' , component : UnsubscribeComponent } , 
			{ path : 'privacyPolicy' , component : PrivacyPolicyComponent } , 
			{ path : 'termsOfUse' , component : TermsOfUseComponent } , 
			{ path : 'about' , component : AboutComponent } ,
			{ path : 'blog' , component : BlogPostsComponent } ,
			{ path : 'hiphop' , component : HipHopComponent } ,
			{ path : 'faq' , component : FaqComponent } , 
			{ path : 'beASponsor' , component : BeASponsorComponent } , 
			{ path : 'affiliates' , component : AffiliatesComponent } , 
			{ path : 'support' , component : SupportComponent } ,
			{ path : 'supportMessages' , component : SupportMessagesComponent } ,  
			{ path : 'careers/cmo' , component : CMOComponent } , 
			{ path : 'careers' , component : CareersComponent } , 
			{ path : 'login/:failed' , component : LoginComponent } ,
			{ path : 'login' , component : LoginComponent } ,
			{ path : 'scienceFair' , component : ScienceFairComponent } , 
			{ path : 'logout' , component : LogoutComponent } , 
			{ path : 'pricing' , component : PricingComponent } , 
			{ path : 'signUp' , component : SignUpComponent } ,
			{ path : 'emailVerification/:emailVerificationCode' , component : EmailVerificationComponent } , 
			{ path : 'phoneVerification' , component : PhoneVerificationComponent } , 
			{ path : 'paymentHistory' , component: PaymentHistoryComponent }, 
			{ path : 'updateUser/:firstTime' , component : UpdateUserComponent } ,  
			{ path : 'updateUser' , component : UpdateUserComponent } , 
			{ path : 'winners' , component : WinnersComponent } , 
			{ path : 'resetPassword' , component : ResetPasswordComponent } , 
			{ path : 'totalOwed' , component : TotalOwedComponent } , 
			{ path : 'cashOut' , component : CashOutComponent } , 
			{ path : 'how-to' , component : HowToComponent } , 
			{ path : 'portfolio' , component : PortfolioComponent } , 
			{ path : 'groupUpload' , component : GroupUploadComponent } , 
			{ path : 'createGroup' , component : CreateGroupComponent } , 
			{ path : 'myGroups' , component : MyGroupsComponent } , 
			{ path : 'groups' , component : RecentGroupsComponent  , resolve: { recentGroupsData : RecentGroupsResolveService }} , 
			{ path : 'groups/:firstTime' , component : RecentGroupsComponent  , resolve: { recentGroupsData : RecentGroupsResolveService }} , 
			{ path : 'competition/:contestId/judge/:judgeInviteUrl', component : ContestEntriesComponent , resolve : { contestEntriesData : ContestEntriesResolveService } } , 
			{ path : 'competition/:contestId/commenter/:commenterInviteUrl', component : ContestEntriesComponent , resolve : { contestEntriesData : ContestEntriesResolveService } } , 
			{ path : 'competition/:contestId/viewer/:viewerInviteUrl', component : ContestEntriesComponent , resolve : { contestEntriesData : ContestEntriesResolveService } } , 
			{ path : 'competition/:contestId/pictureGallery/:contestantUsername', component : ContestEntryPictureGalleryComponent , resolve : { contestEntriesData : ContestEntryPictureGalleryResolveService } } ,  
			{ path : 'competition/:contestId/entry/:usernameSearch', component : ContestEntriesComponent , resolve : { contestEntriesData : ContestEntriesResolveService } } ,  
			{ path : 'competition/:contestId', component : ContestEntriesComponent , resolve : { contestEntriesData : ContestEntriesResolveService } } , 
			{ path : 'competitionEntry/:contestEntryId', component : CompetitionEntryComponent , resolve : { contestEntriesData : CompetitionEntryResolveService } } , 
			{ path : 'group/:groupUrl' , component : GroupComponent  , resolve: { groupData : GroupResolveService } } , 
			{ path : 'players/:groupUrl' , component : FansComponent } , 
			{ path : 'oneOnOne/privacyPolicy' , component : OneOnOnePrivacyPolicyComponent } , 
			{ path : 'oneOnOne/termsOfUse' , component : OneOnOneTermsOfUseComponent } , 			
			{ path : 'myCompetitions' , component : MyContestsComponent } , 
			{ path : 'competitions' , component : WelcomeContestsComponent } , 
			{ path : 'competitions/:firstTime' , component : RecentContestsComponent , resolve : { recentContestsData : RecentContestsResolveService } } , 
			{ path : 'myCompetitionEntries' , component : MyContestEntriesComponent } , 
			{ path : 'banned' , component : BannedComponent } , 
			{ path : 'vision' , component : VisionComponent } , 
			{ path : 'values' , component : ValuesComponent } , 
			{ path : 'mission' , component : MissionComponent } , 
			{ path : 'story' , component : StoryComponent } , 
			{ path : 'createContestEntry' , component : UploadContestEntryComponent } , 

			{ path : 'search/:search' , component : SearchComponent } , 
			{ path : 'search' , component : SearchComponent } , 
			{ path : '**' , component : WelcomeComponent , resolve: { welcomeData : WelcomeResolveService }}
		]
	}
]