import { Component , OnInit } from '@angular/core'

import { DataService } from '../../core/data.service'

@Component({
	selector: 'totalOwed', 
	templateUrl: "./totalOwed.html"
})

export class TotalOwedComponent implements OnInit {

	totalOwed : number

	constructor(
		private dataService : DataService
	) {}

	ngOnInit(){
		this.getTotalOwedToUsers()
	}

	getTotalOwedToUsers(){
		let URI = 'userCreditHistory/totalOwedToUsers'
    	this.dataService.getObject(URI)
	        .subscribe((response) => {
	          this.totalOwed = response.totalOwed
	        },
        (err: any) => console.log(err),
        () => console.log("totalOwed"));
	}

}