import { Component , Input , OnInit } from '@angular/core'

import { DataService , SessionService } from '../../core/index'

import { ContestVoteResolveService } from './contestVote.resolve.service'

import { IContestEntry , IContestVote } from '../../shared/interfaces'

import { InternationalService } from '../../shared/international.service'
import { stripePublishableKey } from '../../shared/keys/keys'
import { zeroDecimalCurrencies } from '../../shared/international/zeroDecimalCurrencies'

import { ContestVoteSubjectService } from './contestVote.subject.service'

@Component({
	templateUrl : './contestVote.html' ,
	selector : 'contest-vote' , 
	providers : [
		ContestVoteResolveService
	]
})

export class ContestVoteComponent {

	@Input() contestEntry : IContestEntry

	totalVotes : number = 0
	maxVotes : number = null 
	myVotes : number = 0
	myVotesLeft : number = 0
	totalVotesToBuy : number = 1
	convertedPricePerVote : number = 0
	totalVotesPrice : number = 0

	buyingVotes : boolean = false 
	justVoted : boolean = false
	loaded : boolean = false
	showSignUpLoginModal : boolean = false
	showSignup : boolean = true 
	showSignin : boolean = false

	contestVotes : IContestVote[]
	myVote : IContestVote
	session : any
	currencySymbol : string 

	constructor( 
		private dataService : DataService , 
		private internationalService : InternationalService ,
		private contestVoteSubjectService : ContestVoteSubjectService , 
		private contestVoteResolveService : ContestVoteResolveService ,
		private sessionService : SessionService
	){}

	ngOnInit() {
		this.maxVotes = this.contestEntry.maxVotes
		this.contestVoteResolveService.getContestVoteData(this.contestEntry).then((contestVoteData) => {
			for (var i = 0 ; i < Object.keys(contestVoteData).length ; i++ ) {
				this[Object.keys(contestVoteData)[i]] = contestVoteData[Object.keys(contestVoteData)[i]]
			}
			this.myVotes = this.getMyVoteTotal( this.myVote )
			this.contestVoteSubjectService.renewMyVotes( this.myVotes )
			this.contestVoteSubjectService.myVotes
				.subscribe( ( myVotes : number ) => {
					this.myVotes = myVotes 
				})
			this.loaded = true 
			this.getMyVotesLeft()
			this.getSession()
		})
	}

	getSession() {
		this.sessionService.session.subscribe( ( session : any) => {
			this.session = session
			this.setCurrency()
		})
	}

	setCurrency() {
    if (this.internationalService.getCurrencyCode( this.session.country ) !== this.contestEntry.currency )
      this.convertedPricePerVote = (this.contestEntry.pricePerVote * this.session.exchangeRate / this.contestEntry.exchangeRate)
  	else 
  		this.convertedPricePerVote = this.contestEntry.pricePerVote
    this.currencySymbol = this.internationalService.convertCurrencySymbol(this.session.country)
		this.totalVotesPrice = this.convertedPricePerVote
	}

	convertTotalVotesPrice() {
		this.totalVotesPrice = this.totalVotesToBuy * this.convertedPricePerVote
	}
 
  voteHandler() {
		if ( !this.session.loggedIn )
			return this.openSignUpModal()
		if ( this.myVotesLeft === 0 && this.contestEntry.pricePerVote > 0 )
			return this.openBuyVotesModal()
  	return this.voteOnContestEntry({
  		contestEntryId : this.contestEntry._id , 
  		totalVotes : 1
  	})	
  }


  processPayment( contestEntry : any ) {

  	let totalVotePriceBeforeCharge = this.convertedPricePerVote * this.totalVotesToBuy
    if (totalVotePriceBeforeCharge < 1 && totalVotePriceBeforeCharge > 0)
      var stripeCharge = totalVotePriceBeforeCharge * 100 + 5
    else 
      var stripeCharge = totalVotePriceBeforeCharge * 100 * 1.05
    if ((<any>zeroDecimalCurrencies).indexOf(this.internationalService.getCurrencyCode(this.session.country) ) > -1 )
      stripeCharge = Math.ceil(stripeCharge / 100)
    var handler = (<any>window).StripeCheckout.configure({
      key: stripePublishableKey, 
      locale: 'auto',
      currency : this.internationalService.getCurrencyCode(this.session.country) , 
      token: (token: any) => {
      	let voteBody = {
      		token : token , 
					contestEntryId : this.contestEntry._id , 
					totalVotes : this.totalVotesToBuy
				}
      	this.voteOnContestEntry( voteBody )
      }
    });

    handler.open({
      name: 'Buy Votes' ,
      description: 'Buying ' + this.totalVotesToBuy + ' vote' + ( this.totalVotesToBuy > 1 ? 's' : '' ) , 
      amount: stripeCharge
    });
  }


	voteOnContestEntry( voteBody : {
		token? : any 
		contestEntryId : string 
		totalVotes : number  
	}) {
		if ( this.myVotes >= this.maxVotes )
			return
		let URI = 'contestVote/' 
		if ( !voteBody.token ) {
			this.myVotes ++ 
			this.contestVoteSubjectService.renewMyVotes( this.myVotes )
			this.totalVotes ++
			this.getMyVotesLeft()
		}
		this.justVoted = true 
		setTimeout( ( ) => { this.justVoted = false } , 4000 )
		this.dataService.postObject( URI , voteBody )
			.subscribe( ( response : { 
				status : boolean , 
				error : string | null , 
				contestVote : IContestVote 
			}) => {
				if (response.error)
					return console.log(response.error)
				this.myVote = response.contestVote
				this.getMyVotesLeft()
				if ( voteBody.token ) {
					this.totalVotes = voteBody.totalVotes > 1 ? this.totalVotes + ( 1 * voteBody.totalVotes ) : ( this.totalVotes + 1 ) 
				}
			})
	}

	removeVoteOnContestEntry() {

		if ( this.myVotes <= 0 || this.totalVotes <= 0 )
			return
		let URI = 'contestVote/removeVote' , removeVoteBody = {
			contestEntryId : this.contestEntry._id
		}
		this.myVotes -- 
		this.contestVoteSubjectService.renewMyVotes( this.myVotes )
		this.totalVotes --
		this.getMyVotesLeft()
		this.justVoted = true 
		setTimeout( ( ) => { this.justVoted = false } , 4000 )
		this.dataService.postObject(URI , removeVoteBody)
			.subscribe( ( response : { status : boolean , error : string | null , contestVote : IContestVote }) => {
				if (response.error)
					return console.log(response.error)
				this.myVote = response.contestVote
			})
	}

	getMyVotesLeft() {
		let voteDifference = this.maxVotes - this.myVotes
		this.myVotesLeft = voteDifference > 0 ? voteDifference : 0
	}

	getMyVoteTotal( contestVote : IContestVote ) {
		return (contestVote) ? contestVote.votes.length : 0
	}

  openSignUpModal() {
    document.getElementById('signUpContestVoteModal').style.display = "block"
    document.getElementById('signUpContestVoteModalContent').style.display = "block"
  }

  closeSignUpModal() {
    document.getElementById('signUpContestVoteModal').style.display = "none"
    document.getElementById('signUpContestVoteModalContent').style.display = "none"
  }

  openBuyVotesModal() {
    document.getElementById('buyVotesModal' + this.contestEntry._id).style.display = "block"
    document.getElementById('buyVotesModalContent' + this.contestEntry._id).style.display = "block"
  }

  closeBuyVotesModal() {
    document.getElementById('buyVotesModal' + this.contestEntry._id).style.display = "none"
    document.getElementById('buyVotesModalContent' + this.contestEntry._id).style.display = "none"
  }


  signedUserIn( notSignedUp : boolean ) {
  	console.log('signed user in')
  	this.closeSignUpModal()
  }

	toggleNotSignedInPage() {
		this.showSignup = !this.showSignup
		this.showSignin = !this.showSignin
	}

}