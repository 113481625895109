<div 
	class="col-xs-12 purple-gray-background" 
	style="
		height: 40px; 
		position: relative; 
		top: -3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
	"
>
	<div class="col-xs-12 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3 purple-gray-background no-padding col-centered center-text" style="height: 40px; padding-top : 4px; z-index: 1 ; ">
    <span>  
  		<a (click)="voteButtonHandler()" 
        style="
          font-size: 24px;
          font-weight: 1000;
      "> VOTE 
      </a>
    </span>
		<span style="display: inline; margin: 0px; padding: 0px;" class="center-text  col-centered" *ngIf="session && session.loggedIn">
      <span 
      *ngIf="!isAdmin"
        class="col-centered center-text" 
        style="
          color : white ; 
          font-family: sans-serif;
          font-size: 18px;
        "
      >Voter Score: {{ voterScore }}</span>
      <a
        *ngIf="isAdmin"
        (click)="toggleVoterScoreCardsModal()"
      >
        <span 
          class="col-centered center-text" 
          style="
            color : white ; 
            font-family: sans-serif;
            font-size: 18px;
          "
        >Voter Score: {{ voterScore }}</span>
      </a>
		</span>
	</div>

  <div id="scoreEntryVoteModal{{ contestEntry._id }}" class="cssModal" role="dialog" style="z-index: 5;">
    <div id="scoreEntryVoteModalContent{{ contestEntry._id }}" class="cssModalContent modal-content row" style="z-index: 5;">
      <div>
        <button type="button" class="btn btn-default" (click)="closeScoreEntryModal()" style="position: absolute; top: 0px; left: 0px;">Close</button>
        <div class="medium-text">
          <h3 style="color: white; text-align: center;">Vote On {{ contestEntry.contestantUsername }}'s Entry</h3>
          <div *ngIf="scoreCard && scoreCard.judgingCriteria">  
            <div class="col-xs-12" *ngFor="let criteria of scoreCard.judgingCriteria; let i = index" [attr.data-index]="i">
              <div style="margin: 0 auto ;">
                <span>{{ criteria.name }}: </span>
                <input
                  style="display: inline;"  
                  type="text" 
                  placeholder="eg. 2400, 485, 2, 10" 
                  numberOnly="true" 
                  class="roundBorders darker-background input-styling" 
                  [(ngModel)]="myScoreCard[scoreCard.judgingCriteria[i].name]"
                />
                <span> Out of {{ criteria.score }} Points</span>
              </div>
            </div>
            <div class="col-xs-12"> 
              <button 
                style="              
                  display: block;
                  margin: 10px auto;
                  border-width: 0px;
                "   
                type="button" 
                class="white-text btn btn-info btn-lg purple-gray-background"
                (click)="voteOnContestEntry()"
              >
                Submit
              </button>
            </div>
            <p style="color: red ; text-align: center;" *ngIf="err">{{ err }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="signUpContestVoteModal" class="cssModal" role="dialog" style="z-index: 5;">
    <div id="signUpContestVoteModalContent" class="cssModalContent modal-content row" style="z-index: 5;">
      <div>
          <button type="button" class="btn btn-default" (click)="closeSignUpModal()" style="position: absolute; top: 0px; left: 0px;">Close</button>
          <div class="medium-text">
            <div *ngIf="showSignup" style="z-index: 5;">
              <upload-contest-entry-sign-up (signedUserIn)="submittedSignUp($event)"></upload-contest-entry-sign-up>
          
              <div style="border-radius: 5px; border-width: 1px; border-color: white; border-style : solid ; padding: 15px; margin-top: 20px; max-width: 300px; color : white; text-align: center;" class="col-centered bold">
                  <span>Already a Member?</span>
                  <a (click)="toggleNotSignedInPage()" style="color: white;"><p>Log In</p></a>
              </div>
            </div>

            <div *ngIf="showSignin" style="z-index: 5;">
              <upload-contest-entry-login (signedUserIn)="submittedSignUp($event)"></upload-contest-entry-login>

              <div style="border-radius: 5px; border-width: 1px; border-color: white; border-style : solid ; padding: 15px; margin-top: 20px; max-width: 300px; color : white; text-align: center;" class="col-centered bold">
                  <span>Not a Member?</span>
                  <a (click)="toggleNotSignedInPage()"><p>Sign Up</p></a>
              </div>
            </div>
          </div>
     	</div>
    </div>
  </div>



  <div 
    *ngIf="showVoterScoreCardModal" 
    style="
      position: fixed; /* Stay in place */
      z-index: 50;
      padding-top: 30px; /* Location of the box */
      left: 0;
      top: 0;
      width: 100%; /* Full width */
      height: 100%; /* Full height */
      overflow: auto; /* Enable scroll if needed */
      background-color: rgb(0,0,0); /* Fallback color */
      background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
      z-index: 5;
    "
  >
    <div 
      class="modal-content row" 
      style="
        position: relative;
        z-index: 50;
        background-color: #251133;
        margin: auto;
        padding: 20px;
        padding-bottom: 80px;
        border: 1px solid #888;
        width: 80%;
        z-index: 5;
      "
    >
      <div>
        <button type="button" class="btn btn-default" (click)="toggleVoterScoreCardsModal()" style="position: absolute; top: 0px; left: 0px;">Close</button>
        <voter-score-cards-modal [contestEntry]="contestEntry"></voter-score-cards-modal>
      </div>
    </div>
  </div>
</div>


