import { Component , Input , OnInit , AfterContentInit } from '@angular/core'
import { IContest } from '../../../shared/interfaces'
import { FadeAnimation } from '../../../shared/animations/index'

@Component({
	selector: 'featured-contest-phone' , 
	templateUrl : './featuredContestPhone.html' , 
	styleUrls : [ './featuredContestPhone.css' ] , 
  animations: [
  	FadeAnimation 
  ]
})

export class FeaturedContestPhoneComponent implements OnInit , AfterContentInit {
	
	@Input() contest : IContest

  isDisplayed = false;

  toggleAnimation() {
    this.isDisplayed = !this.isDisplayed;
  }

	constructor() {	}

	ngOnInit() {
		if (!this.contest.groupProfilePicture)
			this.contest.groupProfilePicture = 'spotidolBannerPicture.png'
	}

	ngAfterContentInit() {
		this.toggleAnimationTimeouts()
		setInterval(()=> { this.toggleAnimationTimeouts() } , 18000 )
	}

	toggleAnimationTimeouts() {
		setTimeout(()=>{this.toggleAnimation()} , 1000 )	
		setTimeout(()=>{this.toggleAnimation()} , 17900 )			
	}

}