import { Component , OnInit , Input } from '@angular/core'

import { DataService } from '../../core/index'

import { IWinner  } from '../index'


@Component({
	templateUrl: "./winners.html"
})

export class WinnersComponent implements OnInit {

	winners : IWinner[] = []

	constructor( 
		private dataService : DataService
	) {}

	ngOnInit() {
		this.getWinners()
	}

	getWinners( ) {
		let URL = 'winner/getAllWinners' 
		this.dataService.getObject(URL)
			.subscribe(( response : { error : string , winners : IWinner[] } ) => {
				if ( response.error )
					return
				return this.winners = response.winners
		})
	}
	
}