
<div class="col-xs-12">
	<div class="row">
		<div 
			class="col-md-4"
			style="
		    padding: 0px;
		    margin: 0px;
		    position: relative;
		    top: -2px;
	    " 
		>		
			<div 
				class="competition-details-section" 
			>
				<contest-header [contest]="contests[0]" [countdownStart]="countdownStart" [countdownText]="countdownText"></contest-header>
				<div>
					<contest *ngFor = "let contest of contests" [group]="group" [contest]="contest" [viewType]="'contestEntries'" [showEnterContestButton]="showEnterContestButton"></contest>
				</div>
				<div class="col-xs-12" style="background-color: #F5F5FF;">
					<div 
						class="col-xs-offset-1 col-xs-11"
					>
		      	<span
							*ngIf=" contestEntries && (contestEntries.length > 0) && ( contests[0].entryPrivacy != 'private' || isGroupAdmin || isJudge || isCommenter || isViewer )"
						>
							<label>search by username:</label> <input type="text" [(ngModel)]="searchValue" (change)="searchContestEntries(searchValue)">
		      	</span>
					</div>
				</div>
			</div>	
			<div>
	      <div class="col-centered desktop-groups" style="width: 316px;">
    		<h3 *ngIf="relatedGroups && relatedGroups.length > 0" style="text-align: center; color: white ;">Related Groups</h3>
	      	<div *ngFor="let group of relatedGroups" style="
	      		position: relative;
	      		overflow: hidden;
	      		display: inline-block;
	      	">
						<groupThumbnail [group]="group"></groupThumbnail>
	      	</div>
				</div>
			</div>
		</div>
		<div style="z-index: 2;" class="col-xs-12 col-md-8" style="padding: 0px;">
			<button 
				*ngIf="isGroupAdmin && !contests[0].winnerSelected"
				style="
				    color: red;
				    padding: 10px;
				    margin-top : 8px;
				    font-size: 16px;
				    border: none;
				    border-radius: 5px ;
			    "
			  (click)="openEndCompetitionModal()"
			>End Competition</button>
			<div *ngIf="myCompetitionEntry && myCompetitionEntry.entryAttachment">
				<contest-entries-page-contest-entry 
					[session]="session"
					[contestEntry]="myCompetitionEntry" 
					[contest]="contests[0]" 
					[isGroupAdmin]="isGroupAdmin" 
					[isJudge]="isJudge" 
					[isCommenter]="isCommenter"
					[isViewer]="isViewer"
					[fan]="fan" 
					[group]="group" 
					[scoreCard]="scoreCard"
					[userInstance]="userInstance"
				></contest-entries-page-contest-entry>
			</div>
			<div
				*ngIf="contests[0].entryPrivacy != 'private' || isGroupAdmin || isJudge || isCommenter || isViewer"
	    >
				<div 
					*ngIf=" contestEntries && (contestEntries.length > 0)"
				>
					<contest-entries-page-contest-entry 
						[contestEntry]="contestEntries[0]" 
						[contest]="contests[0]" 
						[session]="session"
						[isGroupAdmin]="isGroupAdmin" 
						[isJudge]="isJudge" 
						[isCommenter]="isCommenter"
						[isViewer]="isViewer"
						[fan]="fan" 
						[group]="group" 
						[scoreCard]="scoreCard"
						[userInstance]="userInstance"
					></contest-entries-page-contest-entry>
					<div clas="col-xs-offset-1 col-xs-11" *ngIf="noFoundContestEntries">
						No competition entries match that username
					</div>
					<div 
						*ngIf="contests[0].entryPrivacy != 'private' || isGroupAdmin || isJudge || isCommenter || isViewer"
						infiniteScroll
		        [infiniteScrollDistance]="1"
		        [infiniteScrollThrottle]="300"
		        (scrolled)="setLoadedEntries()" 
			    >
			    	<div
			    		*ngFor="let contestEntry of displayedEntries"  
			    		style="
					    	margin-top: 5px;
					    "
			    	>
				    	<contest-entries-page-contest-entry 
				    		*ngIf="contestEntry !== contestEntries[0]"
				    		[contestEntry]="contestEntry" 
							[session]="session"
				    		[contest]="contests[0]"  
								[isGroupAdmin]="isGroupAdmin" 
								[isJudge]="isJudge" 
								[isCommenter]="isCommenter"
								[isViewer]="isViewer"
				    		[fan]="fan" 
				    		[group]="group" 
				    		[userInstance]="userInstance" 
								[scoreCard]="scoreCard"
				    	></contest-entries-page-contest-entry>
			    	</div>
					</div>
				</div>
				<div
				>
					<h3 *ngIf="registrations.length > 0" style="color : white;">Registrations</h3>
					<div *ngFor="let registration of registrations">
						<a routerLink="/profile/{{ registration.contestantUsername }}"><h4>{{ registration.contestantUsername }}</h4></a>
					</div>
				</div>
			</div>
		</div>
		<div 
			class="col-xs-12 col-md-8 desktop-groups hide-on-phones" 
			style="
		    margin-top: 10px;
		    border-radius: 5px;
		    height: 200px;
		    z-index : -1 ;
	    "
    >	
			<div 
				*ngIf="contests[0] && ( contests[0].entryPrivacy != 'private' || isGroupAdmin || isJudge || isCommenter || isViewer ) && (!contestEntries || (contestEntries.length === 0))"
				style="width: 100% ; height: 100% ; padding: 20px; text-align: center;background-color: gray; border-radius : 5px;" 
	    >
		  	<p
			  	style="
				    color: white;
				    font-size: 48px;
				    font-weight: 900;
			    "
			    *ngIf="loadedContestEntries"
		    >No Entries</p>
		    <div *ngIf="!loadedContestEntries" style=" margin : 5vh auto;" class="loader"></div>
	  	</div>
			<div 
				*ngIf="contests[0] && contests[0].entryPrivacy == 'private' && !isGroupAdmin && !isJudge && !isCommenter && !isViewer"
				style="width: 100% ; height: 100% ; padding: 20px; text-align: center;background-color: gray; border-radius : 5px;" 
	    >
		  	<p style="
			    color: white;
			    font-size: 48px;
			    font-weight: 900;
		    ">Entries Are Private</p>
	  	</div>
		</div>	
		<div>
      <div class="col-centered mobile-groups" style="width: 316px;">
    		<h3 *ngIf="relatedGroups && relatedGroups.length > 0" style="text-align: center; color : white;">Related Groups</h3>
      	<div *ngFor="let group of relatedGroups" style="
      		position: relative;
      		overflow: hidden;
      		display: inline-block;
      	">
			<groupThumbnail [group]="group"></groupThumbnail>
      	</div>
			</div>
			</div>

	  <contest-invite-modal *ngIf="showInviteModal"></contest-invite-modal>
	</div>
</div>

  <div id="endCompetitionModal" class="cssModal" role="dialog">
    <div id="endCompetitionModalContent" class="cssModalContent modal-content row" style="max-width: 700px;">
      <div>
        <button type="button" class="btn btn-default" (click)="closeEndCompetitionModal()" style="position: absolute; top: 0px; left: 0px;">Close</button>
        <h3>Are you sure you want to end the competition? Make sure all of the entries have the proper scores.</h3>
				<button 
					*ngIf="isGroupAdmin && !contests[0].winnerSelected"
					(click)="endCompetition()"
					style="
					    color: red;
					    padding: 10px;
					    margin-top : 8px;
					    font-size: 16px;
					    border: none;
					    border-radius: 5px ;
				    "
				>End Competition</button>

      </div>
    </div>
  </div>