<button 
 	style="
    height: 70px;
    margin: 0px;
    left: -5px;
    position: relative;
    margin-top: 5px;
    border: none;
    color: white;
    font-size: 14px;
    font-weight: 700;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  " 
  class="dark-purple-background" 
(click)="submitComment()"
>
	<p>Comment</p>
</button>
