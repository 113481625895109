<div class="col-xs-12 text-shadow" style="height: 75vh; overflow: hidden; position: relative; margin: 0px ; padding: 0px ; background-image: url(/app/assets/images/spotidolHomeBanner.png) ; background-repeat: no-repeat; background-size: cover; background-position: center;">
	<div style="text-align: center; margin: 0px ; padding: 0px ; background-image: url(/app/assets/images/spotidolHomeBanner.png) ; background-repeat: no-repeat; background-size: cover; background-position: center;">
		<p [hidden]="hideVideo" style="text-align: center; margin: 0px ; padding: 0px ;">
	 		 <video style="position: absolute; object-fit: cover; object-position: center; min-width: 120vw; min-height: 95vh ; position: absolute;" #target id="target" class="video-js vjs-fill" muted playsinline loop controls="false"></video>
		</p>
	</div>
	<div 
		class="col-xs-12 text-shadow" 
		style="
			height: 75vh; 
			overflow: hidden; 
			position: relative; 
			width: 100%;
			color : white ;
		    background-color: rgba(37,17,51 , 0.68);
		    margin: 0px;
		"
	>
		<h2 class="welcome-video-title-1 col-md-6 col-sm-12 header-font">Create & Enter Competitions. Win Prizes.</h2>
		<a>
			<div 
				class=" col-md-6 col-sm-8 col-xs-12 transparent-background"
				style="
					position: absolute;
					bottom: 0px;
					right: 0px; 
					padding: 10px;
				    border-top-left-radius: 10px;
				    border-top-right-radius: 10px;
				" 
			>	
				<div style="padding: 5px;">
					<h3 class="welcome-video-title-2" style="color: white;">Join Thousands in 176 Countries to Live Your Dreams
					</h3>
				</div>
			</div>
		</a>
	</div>
</div>