import { Component , Renderer2 , Input , OnInit , AfterContentInit , ElementRef , ViewChild } from '@angular/core'
import { DataService } from '../../core/index'
import { setImageProps } from '../../shared/functions/setImageProps.function'
import { Router } from '@angular/router'
import moment from 'moment'
// import * as exif from 'exif-js'
import { Howler , Howl } from 'howler'
import * as WaveSurfer from 'wavesurfer'

import { IContestEntry , IFan , IGroup , IContest } from '../../shared/interfaces'
import { IScoreCard } from '../../contests/scoreCard/scoreCard.interface'

import { IAudioPlayer , AudioPlayerSubject } from '../../audioPlayer/index'

declare var Fingerprint2 : any

declare var window : any

@Component({
	selector : 'contest-entries-page-contest-entry' ,
	templateUrl : "./contestEntriesPageContestEntry.html" 
})

export class ContestEntriesPageContestEntryComponent implements OnInit {

	componentId : string

	@Input() contestEntry : IContestEntry
	@Input() isGroupAdmin : boolean
	@Input() isJudge : boolean
	@Input() isCommenter : boolean
	@Input() fan : IFan | IFan[]
	@Input() group : IGroup
	@Input() userInstance : any
	@Input() contest : IContest
	@Input() session : any
	@Input() scoreCard : IScoreCard

	@ViewChild("picture") pictureElement : ElementRef
	@ViewChild("pictureGallery") pictureGalleryElement : ElementRef
	
	@ViewChild("audioContainer") audioContainerElement : ElementRef
	
	@ViewChild("audioCanvas") audioCanvasElement : ElementRef
	@ViewChild("audioCanvasContainer") audioCanvasContainerElement : ElementRef
	audioContextImage : any
	audioCanvasHeight : number
	audioCanvasWidth : number
	audioVisualizerInterval : any
	audioPlayFrom : number
	scaledCanvasWidth : number 
	hasBeenPlayed : boolean = false 

	audioSound : any 
	songLoaded : boolean

	songFile : any
	
	score : number
	votes : number = 0


	winnerSelected : boolean
	contestEnded : boolean
	imgElement : any
	pictureSubmission : string
	pictureWidth : number = 0
	pictureHeight : number = 0
	contestEntryProfilePicture : string 

	textSubmissionFontSize : string = '12px'

	pictureSubmissionType : boolean
	videoSubmissionType : boolean
	audioSubmissionType : boolean
	textSubmissionType : boolean
	submittedContestWinnerToServer : boolean
	
	isMyEntry : boolean

	isIos : boolean

	audioPaused : boolean = true
	audioMuted : boolean
	audioPlayer : IAudioPlayer 
	skipTo : number = 0

	err : string


	constructor(
		private router : Router ,
		private dataService : DataService , 
		private audioPlayerSubject : AudioPlayerSubject , 
    private renderer: Renderer2
	) {}

	ngOnInit() {
		console.log('loaded entry' , this.contestEntry )
		this.componentId = Math.random().toString(36).substring(2)
		this.isIos = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
		this.audioPlayer = {
			username : this.contestEntry.contestantUsername ,
			audioMuted : false , 
			audioPaused : false ,
			loaded : false ,
			shouldSkip : true , 
			url : this.contestEntry.entryAttachment ,
			competitionId : this.contestEntry.contestId , 
			competitionName : this.contestEntry.contestName , 
			objectId : this.contestEntry._id , 
			skipTo : 0
		}
		this.isMyEntry = ( this.contestEntry.contestantUsername == this.session.username ) 
		this.getContestEntryVotes()    
		this.contestEnded = moment().isAfter(this.contest.endOfContest)
		this.winnerSelected = moment().isAfter(this.contest.winnerSelectedDate)
		this.contestEntryProfilePicture = this.contestEntry.profilePicture ? this.contestEntry.profilePicture : '/app/assets/images/idol-icon-white.png'
		console.log(this.winnerSelected , this.isGroupAdmin , this.isJudge , this.isCommenter)
	}

	ngAfterContentInit() {
		this.setViewType()
	}

	setViewType() {
		let audioElement = document.createElement('div')
		window.setTimeout( () => {
			switch (this.contestEntry.contestType) {
			  case "picture" : {
				this.pictureSubmissionType = true
				this.pictureSubmission = 'url(' + this.contestEntry.entryAttachment + ')'
				break 
			  }
			  case "pictureGallery" : {
				this.pictureWidth = ( window.innerWidth > 767 ) ? (window.innerWidth * 5 / 12) : window.innerWidth * 11 / 12 
				this.imgElement = setImageProps( window.innerWidth , this.contestEntry.entryAttachments[0])
				this.imgElement.className = "center-pic"
				this.pictureSubmission = 'url(' + this.contestEntry.entryAttachments[0] + ')'
				break
			  }
			  case "video" : {
				this.pictureWidth = ( window.innerWidth > 767 ) ? (window.innerWidth * 5 / 12) : window.innerWidth * 11 / 12 
				this.videoSubmissionType = true
				break
			  }

			  case "audio" : {

			  	if (this.contestEntry.audioAnalysis && this.contestEntry.audioAnalysis.length > 0 ) {
		  			this.draw( this.contestEntry.audioAnalysis )
						this.songLoaded = true 
						this.initializeAudioSubscriber()
			  	} else {
						if ( window.innerWidth <= 767 ) {
							this.songLoaded = true 
							this.initializeAudioSubscriber()
						} else {
							this.initializeAudioSubscriber()
							console.log(this.audioContainerElement)
							audioElement.id = 'waveform' + this.componentId 
							this.audioContainerElement.nativeElement.append( audioElement )
							audioElement.style.width = '100%'

							let wavesurfer = WaveSurfer.create({
							container: '#waveform' + this.componentId  ,
							waveColor: 'violet',
							progressColor: 'purple' ,
							hideScrollbar : true ,
							scrollParent : false 
							});

							if ( this.songFile === wavesurfer ) 
								return 


							wavesurfer.on('ready', () => {
								this.songLoaded = true 
								this.songFile = wavesurfer
								if ( this.isIos ) 
									this.songFile.mute( this.audioMuted )
								else 
									this.songFile.setMute( this.audioMuted )
								this.initializeSkipTool()
							});

							wavesurfer.load(this.contestEntry.entryAttachment);
						}

			  	}


					this.audioSubmissionType = true

					break

			  }
			  case "text" : {
				this.textSubmissionType = true
				this.textSubmissionFontSize = (this.contestEntry.entryAttachment.length < 55) ? '48px' : (this.contestEntry.entryAttachment.length < 108) ? '24px' : '12px'
				break
			  }
			}
		} , 100 )

	}

	draw (normalizedData) {
	  // set up the canvas
	  const canvas = this.audioCanvasElement.nativeElement
	  let ctx = canvas.getContext("2d");
	  const padding = 10;
		let dpi = window.devicePixelRatio ;
	  canvas.height = 130
	  this.audioCanvasHeight = canvas.height
	  this.audioCanvasWidth = canvas.width
	  canvas.style.height = canvas.height + 'px'
	  // canvas.style.width = canvas.width * dpi * 2 + 'px'	
	  canvas.height = canvas.height * dpi * 2 
	  canvas.width = canvas.width * dpi * 2
	  ctx = canvas.getContext("2d");
	  this.scaledCanvasWidth = canvas.width 
	  ctx.translate(0, (canvas.height / 2)); // set Y = 0 to be in the middle of the canvas
		ctx.scale(dpi  , dpi);

		canvas.onmousedown = (e) => {
			let dpi = window.devicePixelRatio ;
	    const rect = canvas.getBoundingClientRect()
	    let x = (e.clientX - rect.left) , 
	    xPercent = x / rect.width
	    this.skipTo = xPercent

	    if ( this.audioPaused ) {
		    this.drawNewLines( '#34ebe8' , this.contestEntry.audioAnalysis , this.skipTo )
	    	return
	    } else {
				clearInterval(this.audioVisualizerInterval)		
				let audioPlayer = {
					username : this.contestEntry.contestantUsername ,
					audioMuted : false , 
					audioPaused : false ,
					loaded : this.audioPlayer.loaded ,
					url : this.contestEntry.entryAttachment ,
					competitionId : this.contestEntry.contestId ,  
					shouldSkip : true , 
					competitionName : this.contestEntry.contestName , 
					objectId : this.contestEntry._id , 
					skipTo : this.skipTo
				}
				this.audioPaused = false 	
				this.playVisualizer( this.skipTo * this.contestEntry.audioLength * 1000 ) 
				console.log( 'from skip' , audioPlayer )
				this.audioPlayerSubject.updateAudioPlayer( audioPlayer )
	    }

	  }
	  // draw the line segments
	  const width = ( canvas.width / ( dpi) ) / (normalizedData.length)
	  for (let i = 0; i < normalizedData.length; i++) {
	    const x = width * i;
	    let height : any = normalizedData[i] * 130 - padding;
	    if (height < 0) {
	        height = -height;
	    } else if ( height < 16 ) {
	    	height = height * (  16 / height ) 
	    } else if (height > canvas.height ) {
	        height = canvas.height
	    }
	    let color = "#ffab00"
	    this.drawLineSegment(ctx, x, color , height , width, (i + 1) % 2);
	  }
	};

	drawNewLines( newLineColor , normalizedData , cursorX ) {


	  const canvas = this.audioCanvasElement.nativeElement
	  let ctx = canvas.getContext("2d");

	  const padding = 10;
		let dpi = window.devicePixelRatio ;
	  canvas.height = 130
	  canvas.style.height = this.audioCanvasHeight + 'px'
	  // canvas.style.width = canvas.width * dpi * 2 + 'px'	
	  canvas.height = this.audioCanvasHeight * dpi * 2 
	  canvas.width = this.audioCanvasWidth * dpi * 2
	  ctx = canvas.getContext("2d");
	  ctx.translate(0, (canvas.height / 2)); // set Y = 0 to be in the middle of the canvas
	  this.scaledCanvasWidth = canvas.width  
		ctx.scale(dpi  , dpi);

		canvas.onmousedown = (e) => {
			let dpi = window.devicePixelRatio ;
	    const rect = canvas.getBoundingClientRect()
	    let x = (e.clientX - rect.left) , 
		    xPercent = x / rect.width
	    this.skipTo = xPercent

	    if ( this.audioPaused ) {
				clearInterval(this.audioVisualizerInterval)		
		    this.drawNewLines( '#34ebe8' , this.contestEntry.audioAnalysis , this.skipTo )
	    	return
	    } else {
				clearInterval(this.audioVisualizerInterval)		
				let audioPlayer = {
					username : this.contestEntry.contestantUsername ,
					audioMuted : false , 
					audioPaused : false ,
					loaded : this.audioPlayer.loaded ,
					shouldSkip : true , 
					url : this.contestEntry.entryAttachment ,
					competitionId : this.contestEntry.contestId , 
					competitionName : this.contestEntry.contestName , 
					objectId : this.contestEntry._id , 
					skipTo : this.skipTo
				}
				this.audioPaused = false 	
				this.playVisualizer( this.skipTo * this.contestEntry.audioLength * 1000 ) 
				console.log( 'from skip' , audioPlayer )
				this.audioPlayerSubject.updateAudioPlayer( audioPlayer )
	    }

	  }
	  // draw the line segments
	  const width = ( canvas.width /  dpi ) / (normalizedData.length)
	  for (let i = 0; i < normalizedData.length; i++) {
	    const x = width * i;
	    let height : any = normalizedData[i] * 130 - padding;
	    if (height < 0) {
	        height = -height;
	    } else if ( height < 16 ) {
	    	height = height * (  16 /  height ) 
	    } else if (height > canvas.height ) {
	        height = canvas.height
	    }
	    let color = (cursorX > ((i + 1) / normalizedData.length) ) ? newLineColor : "#ffab00"
	    this.drawLineSegment(ctx, x, color , height , width, (i + 1) % 2);
	  }

	}

	drawLineSegment(ctx, x, color , height, width, isEven) {
		ctx.clearRect(x , -ctx.height , x + width , ctx.height )
	  ctx.lineWidth = 0.4; // how thick the line is
	  ctx.strokeStyle = color ; // what color our line is
	  ctx.beginPath();
	  height = isEven ? height* 0.3 : -height  ;
	  ctx.moveTo(x +0.5 , 0.5);
	  ctx.lineTo(x +0.5 , height + 0.5 );
	  // ctx.arc(x + width / 2, height, width / 2, Math.PI, 0, isEven);
	  // ctx.lineTo(x + width, 0);
	  ctx.stroke();
	};
	
	getCursorPosition(canvas, event) {
	}

	// playFrom in miliseconds
	playVisualizer( playFrom ) {

		console.log('playing!! drawing new lines')
		let dpi = window.devicePixelRatio ;
		let timeoutAmout = 100 , 
			totalIntervals = (playFrom > 0) ? Math.floor( playFrom / timeoutAmout ) : 0 , 
			timeoutLeft = this.contestEntry.audioLength * 1000 - timeoutAmout * totalIntervals ,
			x = ( ( totalIntervals * timeoutAmout ) / (this.contestEntry.audioLength * 1000) )
			console.log('x1' , x , timeoutLeft , totalIntervals )
			this.audioVisualizerInterval = setInterval(()=> {
				x += 0.0002
		    this.drawNewLines( '#34ebe8' , this.contestEntry.audioAnalysis , x )
		    if ( x < 1 ) {
		    	totalIntervals ++ 
		    	this.skipTo = ( totalIntervals * timeoutAmout ) / (this.contestEntry.audioLength * 1000)
					x = ( ( totalIntervals * timeoutAmout ) / (this.contestEntry.audioLength * 1000) )
					console.log('x2' , x , timeoutLeft , totalIntervals)
		    } else {
		    	clearInterval(this.audioVisualizerInterval)
		    }
			} , timeoutAmout )
	}

	pauseVisualizer() {
		clearInterval(this.audioVisualizerInterval)		
	}

	initializeSkipTool() {
		this.songFile.on('seek' , ( skipTo : number ) => {
			this.skipTo = skipTo
			if ( this.audioPlayer.url === this.contestEntry.entryAttachment ) {
				this.audioPlayer.skipTo = this.skipTo
				this.audioPlayerSubject.updateAudioPlayer( this.audioPlayer )		
			}
		})
	}

	initializeAudioSubscriber() {
		this.audioPlayerSubject.audioPlayer
			.subscribe( ( audioPlayer : IAudioPlayer ) => {
				if ( audioPlayer.objectId !== this.contestEntry._id ) {
					this.audioPaused = true
					return this.pauseVisualizer()
				}
				if ( audioPlayer.audioPaused ) {
					if ( this.songFile )
						this.songFile.pause()
					this.audioPaused = true
					return this.pauseVisualizer()
				}
				if ( audioPlayer.loaded === true && !audioPlayer.audioPaused ) {
					clearInterval(this.audioVisualizerInterval)		
					if ( this.songFile ) 
						this.songFile.play()
					this.playVisualizer(this.skipTo * this.contestEntry.audioLength * 1000 )
					this.audioPaused = false				
				}
				this.audioPlayer = audioPlayer
			})	
	}

	playAudio() {
		if ( !this.hasBeenPlayed && this.userInstance.username !== this.contestEntry.contestantUsername ) { 
			this.hasBeenPlayed = true 
			let URI = 'contestEntry/addView'
			this.dataService.postObject( URI , { id : this.contestEntry._id })
				.subscribe( (response : any ) => {
					this.contestEntry.totalViews ++
				})
		}
		let audioPlayer = {
			username : this.contestEntry.contestantUsername ,
			audioMuted : false , 
			audioPaused : false ,
			loaded : this.audioPlayer.loaded ,
			url : this.contestEntry.entryAttachment ,
			competitionId : this.contestEntry.contestId , 
			shouldSkip : false , 
			competitionName : this.contestEntry.contestName , 
			objectId : this.contestEntry._id , 
			skipTo : this.skipTo
		}
		this.audioPaused = false 	
		if ( this.audioPlayer.loaded )
			this.playVisualizer( this.skipTo * this.contestEntry.audioLength * 1000 ) 
		console.log( 'from play' , audioPlayer )
		this.audioPlayerSubject.updateAudioPlayer( audioPlayer )
	}

	pauseAudio() {
		this.pauseVisualizer()
		let audioPlayer = {
			username : this.contestEntry.contestantUsername ,
			audioMuted : this.audioPlayer.audioMuted , 
			audioPaused : true ,
			loaded : this.audioPlayer.loaded , 
			url : this.contestEntry.entryAttachment ,
			competitionId : this.contestEntry.contestId , 
			shouldSkip : false ,
			competitionName : this.contestEntry.contestName , 
			objectId : this.contestEntry._id ,  
			skipTo : this.skipTo
		}
		console.log( 'from pause' , audioPlayer )
		this.audioPlayerSubject.updateAudioPlayer( audioPlayer )
	}

	getContestEntryVotes() {
		let URI = 'contestVote/' + this.contestEntry._id
		this.dataService.getObject(URI)
		  .subscribe((response : any) => {
				if (response.error)
					return this.err = response.error
				return this.votes = response.count
		  })
	}

	voteOnEntry( contestEntry : IContestEntry ) {
		this.votes++
		let fingerprint
		new Fingerprint2().get( ( result : any , components : any )=>{
			console.log('fingerprint' , result)
			fingerprint = result

			let URI = 'contestVote' , 
				contestVoteBody = {
					fingerprint : fingerprint , 
					groupId : this.contestEntry.groupId ,
					contestId : contestEntry.contestId , 
					contestEntryId : contestEntry._id ,
					contestName : contestEntry.contestName ,
					contestantUsername : contestEntry.contestantUsername , 
					groupName : contestEntry.groupName ,
					groupUrl : contestEntry.groupUrl
				}

			this.dataService.postObject(URI , contestVoteBody)
				.subscribe((response : any) => {})
			
		})
	}
	
	closeVoteModal() {
		document.getElementById('voteModal').style.display = "none"
		document.getElementById('voteModalContent').style.display = "none"
	}
}