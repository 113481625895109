
  <div class="inline noPadding">
    <a><div class="btn btn-info btn-md" data-toggle="modal" data-target="#uploadProfilePicture"><p class="inline">Upload Picture</p></div></a>
  </div>
	<div id="uploadProfilePicture" class="modal fade" role="dialog">
		<div class="modal-dialog">
  		<div class="modal-content">
      <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
			<div class="modal-header">
        <h2>Upload Picture</h2>
			</div>
			<div *ngIf="!submittedPictureToServer" [hidden]="submittingFile">	
        <div class="drop-container" ngFileDrop (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput" [ngClass]="{ 'is-drop-over': dragOver }">
          <h1>Drag and Drop</h1>
          <p>Ideal Dimensions: 800px x 800px</p>
        </div>

        <label class="upload-button">
          or choose file
          <input type="file" class="btn btn-info btn-lg" ngFileSelect (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput">
        </label>

        <button type="button" class="start-upload-btn btn btn-info btn-lg" (click)="startUpload()">
          Start Upload
        </button>
		  </div>
      <div class=" medium-small-text" *ngIf="submittingFile">One moment while we submit your picture. </div>
      <div class=" medium-small-text" *ngIf="!!err && !submittingFile"><p> {{ err }} </p></div>
      <div class=" medium-small-text" *ngIf="submittedPictureToServer"> Success! Please revisit this page to view your new picture. </div>
      </div>
      </div>
  </div>
