
<div style="color: white;">
	<h2 style="text-align: center;">Judging Details</h2>
	<div class="col-xs-12">
		<label>Score Privacy</label>
		<select 
			class="roundBorders dark-purple-background input-styling" 
			[(ngModel)]="createContestObject.scorePrivacy" 
			id="scorePrivacy" 
			type="text" 
			placeholder="Please Select One"
			style="margin: auto ; margin-bottom: 10px;" 
		>
			<option>Please Select One</option>
			<option value="private">Private (Only judges & the entrant)</option>
			<option value="public">Public (Anyone with the link)</option>
		</select>
	</div>
	<h3 class="col-xs-12"  style="color: white ; text-align: center;">Judges Scorecard</h3>
	<div class="col-xs-12">
		<div class="col-xs-6">
			<input class="roundBorders dark-purple-background input-styling col-xs-12"  [(ngModel)]="newJudgingCriteria.name" placeholder="Criteria Title (eg. Creativity, Skill, Performance)">
			<input class="roundBorders dark-purple-background input-styling col-xs-12"  [(ngModel)]="newJudgingCriteria.score" mask="9*" placeholder="Max Score (eg. 1000, 525, 10)">
			<textarea class="roundBorders dark-purple-background input-styling col-xs-12" style="height: 155px;" [(ngModel)]="newJudgingCriteria.description" placeholder="Criteria Description (eg. Creativity is judged based on the uniqueness of the piece & the successful execution of a vision for the piece."></textarea>
			<a (click)="addJudgingCriteria()"><p class="col-xs-12" style="text-align: center; color : #42f548 ; ">+Add Judging Criteria</p></a>
			
		</div>
		<div class="col-xs-6">
			<h4 *ngIf="judgingCriterion.length === 0" style="text-align: center;">
				No criteria has been added.
			</h4>
			<div 
				*ngFor="let judgingCriteria of judgingCriterion"
				class="col-xs-12" 
				style="text-align: center; color: white;" 
			>
				<p>{{ judgingCriteria.name }} - Out of {{ judgingCriteria.score }} Points</p>
				<p>{{ judgingCriteria.description }}</p>
			</div>
			<a *ngIf="judgingCriterion.length > 0" (click)="removeJudgingCriteria()"><p class="col-xs-12" style="text-align: center; color : red ; ">-Remove Judging Criteria</p></a>
		</div>
	</div>
	<input (click)="submitDetails()" class="btn btn-info btn-lg" type= "submit" value="Submit">
</div>