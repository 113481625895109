import { Component , OnInit , Input } from '@angular/core'
import { DataService , SessionService } from '../../core/index'
import { DateService } from '../../shared/date.service'
import { DomSanitizer } from '@angular/platform-browser';

@Component({
	templateUrl: "./blogPosts.html"
})

export class BlogPostsComponent implements OnInit {

	@Input() session : any
	err : string
	userIsBlogger : boolean = false
	blogPosts : any[] = []
	title : string 
	creatorName : string
	body : string
	successUploadingBlog : boolean
	page : number = 1

	constructor( private dataService : DataService , 
				 private dateService : DateService , 
				 private sanitizer : DomSanitizer , 
				 private sessionService : SessionService 
	) {}

	ngOnInit() {
		this.sessionService.session.subscribe( (session:any) => {
			if (!session)
				return
			this.session = session
			this.userIsBlogger = session.blogger
			this.getBlogPosts(1)
		})
	}

	postBlogPost() {
		let URL = 'blogPost/'
		let blogPost = {
			title : this.title , 
			body : this.body , 
			creatorName : this.creatorName 
		}
		this.dataService.postObject(URL , blogPost)
			.subscribe(( response : any ) => {
				if ( response.error )
					return this.err = response.error
				this.err = null
				this.successUploadingBlog = true
			})
	}

	getBlogPosts( page : number ) {
		let URL = 'blogPost/' + page
		this.dataService.getObject(URL)
			.subscribe(( response : any ) => {
				if ( response.error )
					return this.err = response.error
				this.err = null
				for(var i = 0 ; i < response.blogPosts.length ; i++) {
					response.blogPosts[i].body = this.sanitizer.bypassSecurityTrustHtml(response.blogPosts[i].body)
					response.blogPosts[i].formattedCreated = this.dateService.stringifyTime(response.blogPosts[i].created)
				}
				this.blogPosts.push(...response.blogPosts)
				this.page ++
		})
	}
}