import { Component , OnInit } from '@angular/core'

@Component({
	templateUrl : './createContest.html'
})

export class CreateContestComponent {

	constructor( ) { }

	ngOnInit() {

	}

}
