<div 
	style="
		width: 100%;
		margin: 0px;
		padding: 0px;
		background-color: orange;
		color: white;
		text-align: center;
		min-height: 30px;
	" 
	*ngIf="showReferralCode"
>
	<span>Referral code activated! Get 10% cash back instantly on your next payment!</span>
	<a (click)="closeReferralCode()"><span style="color: red">Close</span></a>
</div>