import { Component , Input , OnInit } from '@angular/core'

import { DataService , SessionService } from '../../../core/index'
import { IScoreCard } from '../../../contests/scoreCard/scoreCard.interface'
import { IContestEntry , IContestVote } from '../../../shared/interfaces'

import { InternationalService } from '../../../shared/international.service'
import { stripePublishableKey } from '../../../shared/keys/keys'
import { zeroDecimalCurrencies } from '../../../shared/international/zeroDecimalCurrencies'

import { ScoreCardContestVoteResolveService } from './scoreCardContestVote.resolve.service'

@Component({
	templateUrl : './scoreCardContestVote.html' ,
	selector : 'score-card-contest-vote' , 
	providers : [
		ScoreCardContestVoteResolveService
	]
})

export class ScoreCardContestVoteComponent {

	@Input() contestEntry : IContestEntry
	@Input() scoreCard : IScoreCard
	voterScore : number = 0
	myVoteScore : number = 0
	myScoreCard : any = {}
	totalVotesToBuy : number = 1
	convertedPricePerVote : number = 0
	totalVotesPrice : number = 0

	isAdmin : boolean = false 

	err : string 

	buyingVotes : boolean = false 
	justVoted : boolean = false
	loaded : boolean = false
	showSignUpLoginModal : boolean = false
	showSignup : boolean = true 
	showSignin : boolean = false
	showVoterScoreCardModal : boolean = false

	contestVotes : IContestVote[]
	myVote : IContestVote
	session : any
	currencySymbol : string 

	constructor( 
		private dataService : DataService , 
		private internationalService : InternationalService ,
		private contestVoteResolveService : ScoreCardContestVoteResolveService ,
		private sessionService : SessionService
	){}

	ngOnInit() {
		if ( this.contestEntry.voterScore )
			this.voterScore = this.contestEntry.voterScore
		else 
			this.voterScore = 0 
		this.contestVoteResolveService.getContestVoteData(this.contestEntry).then((contestVoteData) => {
			for (var i = 0 ; i < Object.keys(contestVoteData).length ; i++ ) {
				this[Object.keys(contestVoteData)[i]] = contestVoteData[Object.keys(contestVoteData)[i]]
			}
			if (this.myVote) {
				let myContestEntryScoreCard = this.myVote.voterScoreCards.filter( ( scoreCard : any ) => {
					return ( scoreCard.contestEntryId == this.contestEntry._id )
				})[0]
				if ( myContestEntryScoreCard ) {
					this.myScoreCard = myContestEntryScoreCard.criteria
					console.log( myContestEntryScoreCard.voteScore )
					this.myVoteScore = myContestEntryScoreCard.voteScore
					if ( this.voterScore === 0 )
						this.voterScore = this.myVoteScore
				}
			} else {
				for ( var i = 0 ; i < this.scoreCard.judgingCriteria.length ; i++ ) {
					this.myScoreCard[this.scoreCard.judgingCriteria[i].name] = null
				}
			}
			this.isAdmin = ( this.contestEntry.admins.filter( ( admin : { username : string } ) => {
				return ( admin.username == this.session.username )
			})[0] ) ? true : false 
			this.loaded = true 
			this.getSession()
		})
	}

	getSession() {
		this.sessionService.session.subscribe( ( session : any) => {
			this.session = session
			this.setCurrency()
		})
	}

	setCurrency() {
    if (this.internationalService.getCurrencyCode( this.session.country ) !== this.contestEntry.currency )
      this.convertedPricePerVote = (this.contestEntry.pricePerVote * this.session.exchangeRate / this.contestEntry.exchangeRate)
  	else 
  		this.convertedPricePerVote = this.contestEntry.pricePerVote
    this.currencySymbol = this.internationalService.convertCurrencySymbol(this.session.country)
		this.totalVotesPrice = this.convertedPricePerVote
	}

	convertTotalVotesPrice() {
		this.totalVotesPrice = this.totalVotesToBuy * this.convertedPricePerVote
	}
 
  voteButtonHandler() {
		if ( !this.session.loggedIn )
			return this.openSignUpModal()
		return this.openScoreEntryModal()
  }

	voteOnContestEntry() {
		for ( var i = 0 ; i < Object.keys(this.myScoreCard).length ; i++ ) {
			let criteria = Object.keys(this.myScoreCard)[i]
			this.myScoreCard[criteria] = parseInt(this.myScoreCard[criteria])
		}
		let voteBody = {
			contestEntryId : this.contestEntry._id , 
			scoreCard : this.myScoreCard 
		}
		let improperScoringError
		for ( var i = 0 ; i < Object.keys(this.myScoreCard).length ; i++ ) {
			let criteria = Object.keys(this.myScoreCard)[i]
			let scoreCardCriteria = this.scoreCard.judgingCriteria.filter(( judgingCriteria : any) => {
				return (judgingCriteria.name === criteria)
			})[0]
			if ( parseInt(this.myScoreCard[criteria]) > scoreCardCriteria.score ) {
				this.err = 'Your score for ' + criteria + ' cannot be greater than ' + scoreCardCriteria.score
				improperScoringError = true
			}
		} 
		if ( improperScoringError )
			return 
		this.err = null
		let URI = 'contestVote/createScoreCardContestVote' 
		this.dataService.postObject( URI , voteBody )
			.subscribe( ( response : { 
				status : boolean , 
				error : string | null , 
				contestVote : IContestVote 
			}) => {
				if (response.error)
					return this.err = response.error
				let myContestEntryScoreCard = response.contestVote.voterScoreCards.filter( ( scoreCard : any ) => {
					return ( scoreCard.contestEntryId === this.contestEntry._id )
				})[0]
				let myVoteScore = myContestEntryScoreCard.voteScore 
				if ( !this.myVoteScore )
					this.voterScore += myVoteScore
				else {
					this.voterScore = this.voterScore - this.myVoteScore + myVoteScore
				}
				this.myVoteScore = myVoteScore
				this.myVote = response.contestVote
				return this.closeScoreEntryModal()
			})
	}

  openSignUpModal() {
    document.getElementById('signUpContestVoteModal').style.display = "block"
    document.getElementById('signUpContestVoteModalContent').style.display = "block"
  }

  closeSignUpModal() {
    document.getElementById('signUpContestVoteModal').style.display = "none"
    document.getElementById('signUpContestVoteModalContent').style.display = "none"
  }

  openScoreEntryModal() {
    document.getElementById( 'scoreEntryVoteModal' + this.contestEntry._id ).style.display = "block"
    document.getElementById( 'scoreEntryVoteModalContent' + this.contestEntry._id ).style.display = "block"
  }

  closeScoreEntryModal() {
    document.getElementById( 'scoreEntryVoteModal' + this.contestEntry._id ).style.display = "none"
    document.getElementById( 'scoreEntryVoteModalContent' + this.contestEntry._id ).style.display = "none"
  }

  toggleVoterScoreCardsModal() {
  	this.showVoterScoreCardModal = !this.showVoterScoreCardModal
  }


  closeVoterScoreCardsModal() {
    document.getElementById( 'voterScoreCardsModal' ).style.display = "none"
    document.getElementById( 'voterScoreCardsModalContent' ).style.display = "none"
  }


  signedUserIn( notSignedUp : boolean ) {
  	console.log('signed user in')
  	this.closeSignUpModal()
  }

	toggleNotSignedInPage() {
		this.showSignup = !this.showSignup
		this.showSignin = !this.showSignin
	}

}