import { Injectable , Input } from '@angular/core';
import {
    Router, Resolve , 
    ActivatedRouteSnapshot , 
    RouterStateSnapshot
} from '@angular/router';
import { GroupService } from './groupService/group.service'
import { DataService } from '../core/data.service'
import { SessionService } from '../core/session.service'

@Injectable()
export class GroupResolveService {

	groupData : any = {}
	groupUrl : string

  constructor(
  	private dataService: DataService, 
  	private sessionService : SessionService ,   
  	private groupService : GroupService ,  
 		private router: Router
 	) { }

	resolve( route : ActivatedRouteSnapshot , state : RouterStateSnapshot) {
		return new Promise<void>(( resolve , reject ) => {		
		    this.groupData.groupUrl = route.url[1]
		    Promise.all([
				this.getGroup(this.groupData.groupUrl) ,  
				this.checkSession()
			])
			.then(() => {			
		    	Promise.all([
					// this.getTopFansByGroupUrl(this.groupData.groupUrl , this.groupData.group.defaultKindOfFan) ,
			  		this.checkIfGroup(this.groupData.userInstance), 
		  			this.getGroupContests(this.groupData.groupUrl , 1), 
		  			this.getMyFan(this.groupData.groupUrl , this.groupData.group.defaultKindOfFan) 
				]).then(() => {
					return resolve(this.groupData)
				}) 
			})
		})
	}

	checkSession() {
		return new Promise<void>( ( resolve , reject ) => {
			let URI = "user/checkSession"
			this.dataService.getObject(URI).subscribe( res => {
				this.groupData.userInstance = res.sessionStatus
				return resolve()
			})
		})
	}
	

	getGroup( url : string ) {
		console.log('getting group')
		return new Promise<void>( ( resolve , reject ) => {
			let URI = 'group/url/' + url
	    	this.dataService.getObject(URI)
		        .subscribe((response) => {
		        	if (response.error !== null) {		        		
		        		this.groupData.err = response.error
		        		return resolve()
		        	}
	          	if (response.group === null)
		        		return resolve()
	          	this.groupData.group = response.group;
			        this.groupData.group.totalRevenue.sort( ( a : any , b : any ) => {
			        	return ( b.amount - a.amount )
			        })
							if (this.groupData.group.defaultKindOfFan !== 'fan') {			
								this.groupData.defaultKindOfFan = this.groupData.group.kindsOfFans.filter( ( fan : any ) => { return (fan.fanTitle === this.groupData.group.defaultKindOfFan)})
								this.groupData.defaultKindOfFan = this.groupData.defaultKindOfFan[0]
							} else {
								this.groupData.defaultKindOfFan = {}
								this.groupData.defaultKindOfFan.fanTitle = 'fan'
								this.groupData.defaultKindOfFan.priceToBecomeKindOfFan = this.groupData.group.priceToBecomeFan
								this.groupData.defaultKindOfFan.priceFor321DealForKindOfFan = this.groupData.group.priceFor321Deal
								this.groupData.defaultKindOfFan.is321DealActiveForKindOfFan = this.groupData.group.is321DealActive
							}
		        	this.groupData.reportedUserDetails = {}
					  	this.groupData.reportedUserDetails.userId = response.group.userId
					  	this.groupData.reportedUserDetails.objectId = response.group._id
					  	this.groupData.reportedUserDetails.objectType = 'group'
					  	return resolve()

		        },
	        (err: any) => console.log(err),
	        () => console.log("group"));
		})
	}

	getMyFan( url : string , role : string ) {
		return new Promise<void>( ( resolve , reject ) => {
			if (!this.groupData.userInstance.username)
				return resolve()
			let URI = 'fan/getMyFanByGroupUrlAndKind/?groupUrl=' + url + '&kindOfFan=' + role
	    	this.dataService.getObject(URI)
		        .subscribe((response) => {
		          this.groupData.fanLoaded = true
		          this.groupData.fan = response.fan;
		          if (response.fan) this.groupData.have321Deal = response.fan.has321Deal
      			  return resolve()
		        },
	        (err: any) => console.log(err),
	        () => console.log("myFan"));
		})
	}

	getTopFansByGroupUrl( url : string  , role : string ) {
		return new Promise<void>( ( resolve , reject ) => {
			let URI = 'fan/getTopFansByGroupUrlAndKind/?groupUrl=' + url + '&kindOfFan=' + role
	    	this.dataService.getObject(URI)
	        .subscribe((response) => {
	          this.groupData.topFans = response.fans
     		  	return resolve()
	        },
	        (err: any) => console.log(err),
	        () => console.log("topFans"));
	   	})

	}


	getGroupContests( groupUrl : string , page : number ) {
		return new Promise<void>( ( resolve , reject ) => {
			this.groupData.currentPage = page
			let URI = 'contest/getContestsByGroupUrl/?groupUrl=' + groupUrl + '&page=' + page
	    	this.dataService.getObject(URI)
		        .subscribe((response) => {
		          this.groupData.showEnterContestButton = true
		          for (var i = 0 ; i < response.contests.length ; i++) {
					var endOfContest = response.contests[i].endOfContest
					var dateAndTime = endOfContest.split('T')
					var date = dateAndTime[0].split('-')
					var year = date[0]
					var month = date[1]
					var day = date[2]
					if (day.charAt(0) === '0')
						day = day.charAt(1)

					var stringifiedMonth = ''
					switch (month) {
						case '01': {
							stringifiedMonth = 'Jan'
							break
						}
						case '02': {
							stringifiedMonth = 'Feb'
							break
						}
						case '03': {
							stringifiedMonth = 'Mar'
							break
						}
						case '04': {
							stringifiedMonth = 'Apr'
							break
						}
						case '05': {
							stringifiedMonth = 'May'
							break
						}
						case '06': {
							stringifiedMonth = 'Jun'
							break
						}
						case '07': {
							stringifiedMonth = 'Jul'
							break
						}
						case '08': {
							stringifiedMonth = 'Aug'
							break
						}
						case '09': {
							stringifiedMonth = 'Sep'
							break
						}
						case '10': {
							stringifiedMonth = 'Oct'
							break
						}
						case '11': {
							stringifiedMonth = 'Nov'
							break
						}
						case '12': {
							stringifiedMonth = 'Dec'
							break
						}
					}
				    response.contests[i].formattedEndOfContest = stringifiedMonth + ' ' + day + ' ' + year
		          }
	          		this.groupData.contests = response.contests;
				  	this.groupData.pageCount = response.pageCount
		          	if (this.groupData.contests[0] === undefined) {
		          		this.groupData.noContests = true
		          	} else {
		          		this.groupData.noContests = false
		          	}
		          	console.log( 'response' , response )
	      			return resolve()
		        },
	        (err: any) => console.log(err),
	        () => console.log("contests"));
		})
	}

	checkIfGroup(userInstance : any) {
		return new Promise<void>( ( resolve , reject ) => {
	      this.groupData.groupLoaded = true
	      if (!userInstance)
	      	return resolve()
	      this.groupData.isMyGroup = this.groupService.checkIfIsGroup(this.groupData.group.admins)
	      this.groupData.showEnterContestButton = !this.groupService.checkIfIsGroup(this.groupData.group.admins) 
	      return resolve()
	    })
	}

}