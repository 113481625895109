
<div class="col-xs-10 col-xs-offset-1">
	<a routerLink="/createCompetition/group">
		<div class="row text-shadow">
			<div class="create-competition-header">
				<h1>Create a Competition</h1>
				<h2>Have Fun & Connect With Others</h2>
			</div>
		</div>
	</a>
</div>
<h3  class="col-sm-12 col-md-10 col-md-offset-1" style="color: white; padding: 0px;">Your Competitions</h3>>
<div class="col-xs-12 col-sm-12 col-md-10 col-md-offset-1">
	<contest-thumbnail *ngFor="let contest of contests"  [contest]="contest" ></contest-thumbnail>
</div>
<div class="col-xs-12">
	<h3 *ngIf="!contests || contests.length == 0 " style="color: white; text-align: center;">You have no live competitions. Create one.</h3>
</div>