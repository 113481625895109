import { Component , OnInit , AfterContentInit , Input , OnDestroy} from '@angular/core'
import { DateService } from '../shared/date.service'
import { IContest , IGroup } from '../shared/interfaces'
import { ActivatedRoute , Router , NavigationStart } from '@angular/router'

import { SearchService } from './search.service'

import { DataService } from '../core/data.service'

@Component({
	selector: 'search', 
	templateUrl: "./search.html", 
	styleUrls: ["./search.css"]
})

export class SearchComponent implements OnInit {

	contests : IContest[]
	groups : IGroup[] = []
	users : any[] = []
	pages : any[]
	currentContestsPage : any
	searchInput : string
	searchBy : any
	searchBody : any
	searching : boolean = false 
	showContests : boolean = true
	showUsers : boolean = false 
	showGroups : boolean = false
	showContestSearchBy : boolean = false
	noGroupsFound : boolean
	noContestsFound : boolean
	hashtagSearch : boolean
	searchTimerIsSet : boolean
	searchTimer : any
	
	constructor(
		private dateService : DateService , 
		private dataService : DataService , 
		private searchService : SearchService , 
		private route : ActivatedRoute , 
		private router : Router
	) {}

	ngOnInit () {
		this.searching = true
    this.searchInput = this.route.snapshot.params['search'] || ''
		if (this.searchInput !== '') {
			this.searchBody = {
				search : this.searchInput, 
				page : 1
			}
			this.setSearchTimer()
		}
		this.searchService.searchInputObservable.subscribe(
			(searchValue : string) => {
				if (searchValue !== this.searchInput) {
					console.log(searchValue)
					this.searchBy = searchValue.split('&searchBy=')[1]
					this.searchInput = searchValue.split('&searchBy=')[0]
					this.searchBody = {
						search : this.searchInput, 
						page : 1
					}
					this.setSearchTimer()
				}
			}
		)
	}

	setSearchTimer() {
		clearTimeout( this.searchTimer )
		this.searchTimer = setTimeout( () => {
			console.log('making search')
			this.search(this.searchBody)
		} , 800 )

	}

	search( formValues : any ) {
		console.log( 'searching' , formValues )
		this.searchService.changeSearchInput(formValues.search)
		this.currentContestsPage = 1
		this.noContestsFound , this.noGroupsFound = false
		var page
		if (formValues.page !== null) 
			page = formValues.page
		else 
			page = 1
		if (formValues.search !== null && typeof formValues.search !== "function") {
			var hashTestFormValues : string = formValues.search
		} else {
			var hashTestFormValues : string = this.searchInput
		}
		if (hashTestFormValues === null) 
			return
		let hashTest = hashTestFormValues.indexOf("#")
		if ( hashTest !== -1) {
			this.hashtagSearch = true
			let searchArray = hashTestFormValues.split('#')
			this.searchInput = ''
			for(var i = 0; i < searchArray.length ; i++ ) {
				this.searchInput = this.searchInput + searchArray[i]
			}
		} else {
			this.searchInput = hashTestFormValues
		}

		switch(this.searchBy) {
			case 'all' : {
				this.searchContests( page , this.searchInput , formValues.contestSearchBy )
				break
			}
			case 'competitions' : {
				this.searchContests( page , this.searchInput , formValues.contestSearchBy )
				break
			}
			case 'groups' : {
				this.searchGroups( page , this.searchInput )
				break
			} 
			case 'users' : {
				this.searchUsers( page , this.searchInput )
				break
			}
			default : {
				this.searchContests( page , this.searchInput , formValues.contestSearchBy )
				break
			}
		}
	}

	searchUsers( page : number , search : string ) {
		let URI = 'user/search?search=' + search + '&page=' + page
		this.dataService.getObject(URI)
			.subscribe( ( response : any ) => {
				this.showContests = false
				this.showUsers = true 
				this.showGroups = false
				this.pages = response.pages
	      if (page === 1)
        	this.users = response.users
	      else
	      	this.users.push(...response.users)
				this.searching = false
			})
	}

	searchGroups( page : number , search : string ) {
		this.showContests = false
		this.showUsers = false 
		this.showGroups = true
		let URI = 'group/search?groupName=' + search + "&page=" + page
		this.dataService.getObject(URI)
			.subscribe( ( response : any ) => {
				this.searching = false
				this.contests = []
				this.users = []
				if (response.error)
					return console.log(response.error)
				this.pages = response.pages
		        if (page === 1)
		          	this.groups = response.groups
		        else
		          	this.groups.push(...response.groups)
			})
	}

	searchContests( page : number , search : string , searchBy : string ) {
		if (searchBy === undefined && this.hashtagSearch === true)
			var searchBy = 'tags'
		if (page === undefined)
			page = 1
		let URI = 'contest/searchContests?search=' + search + '&searchBy=' + searchBy + '&page=' + page
  	this.dataService.getObject(URI)
      .subscribe((response) => {
				this.showContests = true
				this.showUsers = false 
				this.showGroups = false
				this.searching = false
				this.users = []
				this.groups = []
      	if ( ( response.contests === null || response.contests.length === 0 ) && this.currentContestsPage === 1) {
      		this.showContests = false
      		this.noContestsFound = true
      	} else {
					this.showContests = true
		          for (var i = 0 ; i < response.contests.length ; i++) {
					var created = response.contests[i].created
					var dateAndTime = created.split('T')
					var date = dateAndTime[0].split('-')
					var month = date[1]
					var day = date[2]
					if (day.charAt(0) === '0')
						day = day.charAt(1)

					var stringifiedMonth = ''
					switch (month) {
						case '01': {
							stringifiedMonth = 'Jan'
							break
						}
						case '02': {
							stringifiedMonth = 'Feb'
							break
						}
						case '03': {
							stringifiedMonth = 'Mar'
							break
						}
						case '04': {
							stringifiedMonth = 'Apr'
							break
						}
						case '05': {
							stringifiedMonth = 'May'
							break
						}
						case '06': {
							stringifiedMonth = 'Jun'
							break
						}
						case '07': {
							stringifiedMonth = 'Jul'
							break
						}
						case '08': {
							stringifiedMonth = 'Aug'
							break
						}
						case '09': {
							stringifiedMonth = 'Sep'
							break
						}
						case '10': {
							stringifiedMonth = 'Oct'
							break
						}
						case '11': {
							stringifiedMonth = 'Nov'
							break
						}
						case '12': {
							stringifiedMonth = 'Dec'
							break
						}
					}
				    response.contests[i].formattedCreationDate = stringifiedMonth + ' ' + day
		          }
		          if (response.contests.length === 0) {
		          	this.noContestsFound = true 
		          }
		          if (this.currentContestsPage === 1)
		          	this.contests = response.contests
		          else
		          	this.contests.push(...response.contests)
		        
	        	}
	       	},
        (err: any) => console.log(err),
        () => console.log("contests"));

	}

	getNextContestsPage() {
		if ( this.noContestsFound )
			return
		this.currentContestsPage ++

		this.searchContests( this.currentContestsPage , this.searchInput , this.searchBy )
	}

}