import { Component , Input , OnInit } from '@angular/core'

import { AudioPlayerSubject , IAudioPlayer } from '../../../audioPlayer/index'

import { IContestEntry } from '../../../shared/interfaces'

@Component({ 
	selector : 'welcome-player' , 
	templateUrl : './welcomePlayer.html'
})

export class WelcomePlayerComponent {

	@Input() contestEntry : IContestEntry

	durationString : string
	audioPaused : boolean = false

	audioPlayer : IAudioPlayer

	constructor(
		private audioPlayerSubject : AudioPlayerSubject
	) { }

	ngOnInit() {
		this.initializeAudioSubscriber()
		this.durationString = Math.floor(this.contestEntry.audioLength / 60) + ':' + ( Math.floor(this.contestEntry.audioLength % 60) < 10 ? '0' + Math.floor(this.contestEntry.audioLength % 60) : Math.floor(this.contestEntry.audioLength % 60 ) )
	}

	initializeAudioSubscriber() {
		this.audioPlayerSubject.audioPlayer
			.subscribe( ( audioPlayer : IAudioPlayer ) => {
				if ( audioPlayer.objectId !== this.contestEntry._id ) {
					this.audioPaused = true
				}
				if ( audioPlayer.audioPaused ) {
					this.audioPaused = true
				}
				if ( audioPlayer.loaded === true && !audioPlayer.audioPaused &&  audioPlayer.objectId === this.contestEntry._id ) {
					this.audioPaused = false				
				}
				this.audioPlayer = audioPlayer
			})	
	}

	playAudio() {
		let audioPlayer = {
			username : this.contestEntry.contestantUsername ,
			audioMuted : false , 
			audioPaused : false ,
			loaded : this.audioPlayer.loaded ,
			url : this.contestEntry.entryAttachment ,
			competitionId : this.contestEntry.contestId , 
			shouldSkip : false , 
			competitionName : this.contestEntry.contestName , 
			objectId : this.contestEntry._id , 
		}
		this.audioPaused = false 	
		this.audioPlayerSubject.updateAudioPlayer( audioPlayer )
	}

	pauseAudio() {
		let audioPlayer = {
			username : this.contestEntry.contestantUsername ,
			audioMuted : this.audioPlayer.audioMuted , 
			audioPaused : true ,
			loaded : this.audioPlayer.loaded , 
			url : this.contestEntry.entryAttachment ,
			competitionId : this.contestEntry.contestId , 
			shouldSkip : false ,
			competitionName : this.contestEntry.contestName , 
			objectId : this.contestEntry._id ,  
		}
		this.audioPlayerSubject.updateAudioPlayer( audioPlayer )
	}


}