import { Component , Input , OnInit , ElementRef , ViewChild , AfterContentInit } from '@angular/core'
import { Router } from '@angular/router'

import { DataService , SessionService , UserDataService } from '../../core/index'

import { IContest , IFan , IGroup } from '../../shared/interfaces'
import { setThumbnailImage } from '../../shared/functions/setImageProps.function'

import { InternationalService } from '../../shared/international.service'

import { DateService } from '../../shared/date.service'

import { ContestService } from '../../contests/contest.service'

var moment = require('moment') 
import 'moment-timezone'

@Component({
  selector: 'open-contest-thumbnail', 
  templateUrl: "./opencontestThumbnail.html" , 
  styleUrls : ["./opencontestThumbnail.css"]
})

export class OpenContestThumbnailComponent implements OnInit {

  @Input() contest : IContest

  @ViewChild("competitionPicture") pictureElement : ElementRef
  imgElement : any

  session : any
  pictureSubmissionType : boolean
  textSubmissionType : boolean
  noRewards : boolean
  videoSubmissionType : boolean
  audioSubmissionType : boolean
  rewardCurrencySymbol : string = '$'
  entryCurrencySymbol : string = '$'
  currencyWasSet : boolean = false
  contestThumbnailStyles : {
    'background-image' : string
  }

  countdownText : string
  countdownStart : string | Date

  constructor(
    private sessionService : SessionService , 
    private internationalService : InternationalService ,
    private contestService : ContestService
  ) {
  }

  ngOnInit() {
    if (!this.contest)
      return
    this.sessionService.session.subscribe( ( session : any ) => {
      this.session = session
      this.setCurrency()
    })
    console.log(this.contest.chosenEntry)
    this.contest = this.contestService.setCompetitionNameLength(this.contest)
    this.noRewards = this.contestService.checkIfThereAreNoRewards(this.contest)
    let entryString
    if ((this.contest.contestType === 'picture' || this.contest.contestType === 'pictureGallery' ) && this.contest.chosenEntry)
      entryString = this.contest.chosenEntry
    else 
      entryString = ((!!this.contest.groupProfilePicture) ? this.contest.groupProfilePicture : "https://storage.googleapis.com/spotidol/spotidolBannerPicture.png")
    this.contestThumbnailStyles = {
      'background-image' : 'url("' + entryString + '")'
    }
    this.setHeaderText()
  }

  setHeaderText() {
      let countdownStart
      let countdownText : 'Starts' | 'Ends' | 'Voting Ends' | 'Winner Chosen' | 'Ended' | null = (this.contest.startTime && moment().isBefore( this.contest.startTime )) ?
        'Starts'
        :
        (this.contest.endOfContest && moment().isBefore( this.contest.endOfContest )) ? 
          'Ends' 
          :
          (this.contest.votingEndTime && moment().isBefore( this.contest.votingEndTime)) ? 
            'Voting Ends' 
            :
            (this.contest.winnerSelectedDate && moment().isBefore( this.contest.winnerSelectedDate )) ?
              'Winner Chosen' 
              :
              'Ended'
      switch (countdownText) {
        case "Starts":
          countdownStart = this.contest.startTime
          break;
        case "Ends" :
          countdownStart = this.contest.endOfContest
          break;
        case 'Voting Ends' :
          countdownStart = this.contest.votingEndTime
          break
        case 'Winner Chosen' :
          countdownStart = this.contest.winnerSelectedDate
          break
        default:
          break;
      }  
    this.countdownText = countdownText
    this.countdownStart = countdownStart
  }


  ngAfterContentInit() {
    let entryString
    if ((this.contest.contestType === 'picture' || this.contest.contestType === 'pictureGallery' ) && this.contest.chosenEntry)
      entryString = this.contest.chosenEntry
    else 
      entryString = ((!!this.contest.groupProfilePicture) ? this.contest.groupProfilePicture : "https://storage.googleapis.com/spotidol/spotidolBannerPicture.png")
    this.imgElement = setThumbnailImage( window.innerWidth , entryString )
    this.imgElement.className = "center-pic"

    this.pictureElement.nativeElement.append(this.imgElement)
  }

  setCurrency() {
    if (this.currencyWasSet)
      return 
    this.currencyWasSet = true 
    if (this.internationalService.getCurrencyCode(this.session.country) !== this.contest.currency) {
      let exchangeRate = this.session.exchangeRate / this.contest.exchangeRate
      this.contest.contestPrice = Math.ceil(this.contest.contestPrice * exchangeRate * 100 ) / 100
      this.contest.displayPrice = parseFloat(this.contest.contestPrice.toFixed(2))
      this.entryCurrencySymbol = this.internationalService.convertCurrencySymbol(this.session.country)
    } else {
      this.contest.displayPrice = this.contest.contestPrice
      this.entryCurrencySymbol = this.internationalService.convertCurrencySymbol(this.session.country)
    }
    this.setRewardCurrency()
  }

  setRewardCurrency() {
    if (this.contest.rewardCash > 0) {
      if (this.internationalService.getCurrencyCode(this.session.country) !== this.contest.currency) {
        let exchangeRate = this.session.exchangeRate / this.contest.exchangeRate
        this.rewardCurrencySymbol = this.internationalService.convertCurrencySymbol(this.session.country)
        this.contest.rewardCash = Math.ceil(this.contest.rewardCash * exchangeRate * 100 ) / 100
        this.contest.displayRewardCash = parseFloat(this.contest.rewardCash.toFixed(2))
      } else {
        this.rewardCurrencySymbol = this.internationalService.convertCurrencySymbol(this.session.country)
        this.contest.rewardCash = Math.ceil(this.contest.rewardCash * 100 ) / 100
        this.contest.displayRewardCash = parseFloat(this.contest.rewardCash.toFixed(2))
      }
    }
    if ( this.contest.positions > 1 ) {
      for (var i = 0 ; i < this.contest.rewards.length ; i++ ) {
        if (this.contest.rewards[i].rewardCash > 0) {
          if (this.internationalService.getCurrencyCode(this.session.country) !== this.contest.currency) {
            let exchangeRate = this.session.exchangeRate / this.contest.exchangeRate
            this.rewardCurrencySymbol = this.internationalService.convertCurrencySymbol(this.session.country)
            this.contest.rewards[i].rewardCash = Math.ceil(this.contest.rewards[i].rewardCash * exchangeRate * 100 ) / 100
            this.contest.rewards[i].displayRewardCash = parseFloat(this.contest.rewards[i].rewardCash.toFixed(2))
          } else {
            this.rewardCurrencySymbol = this.internationalService.convertCurrencySymbol(this.session.country)
            this.contest.rewards[i].rewardCash = Math.ceil(this.contest.rewards[i].rewardCash * 100 ) / 100
            this.contest.rewards[i].displayRewardCash = parseFloat(this.contest.rewards[i].rewardCash.toFixed(2))
          }
        }
      }
    }
  }


}