import { Component , OnInit } from '@angular/core'

import { CreateContestSubjectService } from '../../index'
import { SessionService } from '../../../core/index'
import { InternationalService } from '../../../shared/international.service'

const  ordinal = require('ordinal')

@Component({
	selector : 'create-contest-prize-details' ,
	templateUrl : './prizes.html' ,
	styleUrls : ['./prizes.css']
})

export class CreateContestPrizeDetailsComponent {

	createContestObject : any

	currencySymbol : string = '$'

	showAddCashPrize : boolean 
	showAddOtherPrize : boolean

	currentCashPrizePosition : number = 1
	currentOtherPrizePosition : number = 1

	ordinal : any

	rewards : any[]  = [{rewardCash : 0 , rewardOther : ''}]
	addedRewards : any = []

	constructor( private createContestSubjectService : CreateContestSubjectService ,
		private sessionService : SessionService , 
		private internationalService : InternationalService
	) { }

	ngOnInit() {
		this.ordinal = ordinal


		this.createContestSubjectService.createContestObject.subscribe( ( createContestObject ) => {
			this.createContestObject = createContestObject
			this.rewards = [ {
				rewardCash : createContestObject.rewardCash , 
				rewardOther : createContestObject.rewardOther
			} , ...this.createContestObject.rewards ] 
			this.addedRewards = this.rewards
			for ( var i = 0 ; i < this.rewards.length ; i++ ) {
				this.currentCashPrizePosition = ( this.rewards[i].rewardCash ) ? i + 2 : this.currentCashPrizePosition
				this.currentOtherPrizePosition = ( this.rewards[i].rewardOther ) ? i + 2: this.currentOtherPrizePosition
			}
		})
		this.sessionService.session.subscribe( (session : any ) => {
			this.currencySymbol = this.internationalService.convertCurrencySymbol(session.country)
		})
	}

	submitDetails() {
		this.createContestObject.rewardCash = this.rewards[0].rewardCash
		this.createContestObject.rewardOther = this.rewards[0].rewardOther
		if (!this.addedRewards[this.addedRewards.length - 1 ].rewardCash && !this.addedRewards[this.addedRewards.length - 1 ].rewardOther)
			this.addedRewards = this.addedRewards.slice( 0 , this.addedRewards.length - 1 )
		if ( this.addedRewards.length > 0 )
			this.createContestObject.rewards = this.addedRewards.slice( 1 )
		this.createContestObject.positions = this.addedRewards.length + 1
		this.createContestSubjectService.renewCreateContestObject( this.createContestObject )
	}

	toggleAddCashPrize() {
		this.showAddCashPrize = !this.showAddCashPrize
	}

	toggleAddOtherPrize() {
		this.showAddOtherPrize = !this.showAddOtherPrize
	}

	addCashPrize() {
		if (!this.rewards[this.currentCashPrizePosition - 1] && !this.rewards[this.currentCashPrizePosition - 1].rewardCash )
			return
		if (!this.rewards[this.currentCashPrizePosition])
			this.rewards[this.currentCashPrizePosition] = {}
		this.rewards[this.currentCashPrizePosition].rewardCash = 0
		this.rewards[this.currentCashPrizePosition].position = this.currentCashPrizePosition + 1
		this.currentCashPrizePosition += 1
		this.addedRewards = this.rewards
		this.toggleAddCashPrize()
	}


	addOtherPrize() {
		if (!this.rewards[this.currentOtherPrizePosition - 1].rewardOther )
			return
		if (!this.rewards[this.currentOtherPrizePosition])
			this.rewards[this.currentOtherPrizePosition] = {}
		this.rewards[this.currentOtherPrizePosition].rewardOther = ''
		this.rewards[this.currentOtherPrizePosition].position = this.currentOtherPrizePosition + 1
		this.currentOtherPrizePosition += 1
		this.addedRewards = this.rewards
		this.toggleAddOtherPrize()
	}

	removeCashPrize( position : number ) {
		this.currentCashPrizePosition -= 1
		if ( !this.rewards[ position ].rewardOther )
			return this.rewards[ position ] = {}
		return this.rewards[ position ].rewardCash = undefined
	}

	removeOtherPrize( position : number ) {
		this.currentOtherPrizePosition -= 1
		if ( !this.rewards[ position ].rewardCash )
			return this.rewards[ position ] = {}
		return this.rewards[ position ].rewardOther = undefined
	}

}