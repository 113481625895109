<div>
	<div>
		<div *ngIf="!noRewards" class="inline-block no-wrap contest-thumbnail-margin  no-margins no-padding">	
			<img class="contest-thumbnail-margin" height="18" width="18"  src="/app/assets/images/trophy-white.png" style="margin-right: 10px;" />	
			<span style="margin-right: 10px">
				<h4 class="inline-block competition-line-height  contest-thumbnail-margin no-margins no-padding" style="position: relative; top: 3px; margin-right : 5px;">
					<span *ngIf="contest.rewardCash > 0">
						{{ rewardCurrencySymbol }}{{ contest?.displayRewardCash }} 
					</span>
					<span>
						{{ contest?.rewardOther }}
					</span>
				</h4>
				<!-- <div class="inline-block no-wrap  contest-thumbnail-margin no-margins no-padding" style="margin-right : 5px;">
					<img class="contest-thumbnail-margin" height="18" width="18"  src="/app/assets/images/fanpoint.png" />
					<div class="inline-block rewards center-text contest-thumbnail-margin">
						<h4 class="inline-block competition-line-height contest-thumbnail-margin" style="position: relative; top: 3px;">{{ contest?.rewardFanpoints }}</h4>
					</div>
				</div> -->
			</span>
		</div>
		<div *ngIf="noRewards" class="col-xs-offset-1 inline-block no-wrap contest-thumbnail-margin  no-margins no-padding"><h4 class="inline-block competition-line-height  contest-thumbnail-margin no-margins no-padding" >No Rewards</h4></div>
	</div>
</div>