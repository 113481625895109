import { Component , Input , OnInit } from '@angular/core'

import { DataService } from '../../core/index'
import { IContest } from '../../shared/interfaces'

@Component({
	selector : 'update-contest-entry-privacy' , 
	templateUrl : './updateContestEntryPrivacy.html'
})

export class UpdateContestEntryPrivacyComponent {
	
	@Input() contest : IContest

	entryPrivacy : 'public' | 'private' | string
	entryPrivacySelectorShown : boolean = false 

	constructor( private dataService : DataService ) {}

	ngOnInit() {
		this.entryPrivacy = this.contest.entryPrivacy 
	}

	updateContestEntryPrivacy( entryPrivacy : 'public' | 'private' | string ) {
		let URI = 'contest/updateContestEntryPrivacy'
		this.dataService.postObject( 
			URI , 
			{ 
				contestId : this.contest._id ,
				entryPrivacy : entryPrivacy
			}
		).subscribe( ( response : any ) => {
			if ( response.error )
				return console.log( response.error )
			this.entryPrivacySelectorShown = false
		})

	}

	showEntryPrivacySelector() {
		this.entryPrivacySelectorShown = true
	}

}