import { Component , OnInit } from '@angular/core'

import { IAudioPlayer } from './index'
import { AudioPlayerSubject } from './audioPlayer.subject'
import { Howler , Howl } from 'howler'

@Component({
	selector : 'audio-player' ,
	templateUrl : './audioPlayer.html' 
})

export class AudioPlayerComponent {

	songFile : any = {}
	url : string = ''
	username : string = '' 
	competitionName : string = '' 
	competitionId : string = ''
	audioPaused : boolean  = false 
	audioMuted : boolean = false 
	durationLeft : number
	durationString : string
	skipTo : number = 0
	secondsPlayed : number 
	durationInterval : any
	audioObject : IAudioPlayer = {
		username : '' , 
		url : '' , 
		competitionName : '' , 
		competitionId : '' ,
		loaded : false ,
		audioMuted : false ,
		audioPaused : false ,
		objectId : '' , 
		skipTo : 0
	}

	constructor(
		private audioPlayerSubject : AudioPlayerSubject
	) {} 

	ngOnInit() {
		this.audioPlayerSubject.audioPlayer
			.subscribe( ( audioPlayer : IAudioPlayer ) => {
				console.log( 'from player' , audioPlayer , this.audioObject , this.audioPaused )
				if ( audioPlayer.objectId !== this.audioObject.objectId ) 
					this.initializeAudio( audioPlayer )
				this.audioObject = audioPlayer
				this.username = audioPlayer.username
				this.competitionId = audioPlayer.competitionId
				this.competitionName = audioPlayer.competitionName

				if ( !audioPlayer.audioPaused && audioPlayer.skipTo != this.skipTo ) {
					console.log('attempting skip')
					this.skipTo = audioPlayer.skipTo
					this.skipAudio( audioPlayer.skipTo )
				}
				if ( audioPlayer.audioPaused && audioPlayer.audioPaused !== this.audioPaused )
					return this.pauseAudio()
				if ( audioPlayer.loaded && !audioPlayer.audioPaused && audioPlayer.audioPaused !== this.audioPaused )
					return this.playAudio(audioPlayer.skipTo)
				if ( audioPlayer.audioMuted !== this.audioMuted )
					return this.toggleMute()
			})
	}

	initializeAudio( audioPlayer : IAudioPlayer ) {
		console.log('initializing audio')
		this.audioObject = audioPlayer
		this.audioObject.loaded = false 
		this.audioPlayerSubject.updateAudioPlayer( this.audioObject )
		this.url = audioPlayer.url
		if (this.songFile.unload)
			this.songFile.unload()
		this.songFile = new Howl({
		  src: [ this.url ] , 
		  html5 : true ,
		  onload : () => {
				if ( audioPlayer.skipTo > 0 ) {
					console.log('skipping from load')
					this.skipTo = audioPlayer.skipTo
					this.playAudio( audioPlayer.skipTo )
				} else {
					this.skipTo = 0
					this.playAudio( 0 )
				}
				this.audioObject.loaded = true 
				this.audioPlayerSubject.updateAudioPlayer( this.audioObject )
			}
		});
	}

	setDuration( portionPlayed : number ) {
		portionPlayed = portionPlayed || 0 
		clearInterval( this.durationInterval )
		if (!this.songFile)
			return 
		this.secondsPlayed = portionPlayed * this.songFile.duration()
		this.durationInterval = setInterval( () => {
			if ( !this.audioPaused ) {
				this.durationLeft = this.songFile.duration() - this.secondsPlayed
				if ( this.durationLeft > 0 ) {
					this.secondsPlayed ++ 
					this.durationString = Math.floor(this.durationLeft / 60) + ':' + ( Math.floor(this.durationLeft % 60) < 10 ? '0' + Math.floor(this.durationLeft % 60) : Math.floor(this.durationLeft % 60 ) )
				}
			}
		} , 1000 )
	}

	skipAudio( skipTo : number ) {
		if ( !this.songFile || !this.songFile.duration )
			return 
		let skipToSeconds = this.songFile.duration() * skipTo 
		this.songFile.seek( skipToSeconds )
		this.setDuration( skipTo )
	}

	playAudio( skipTo : number  ) {
		console.log('playing audio')
		this.songFile.play()
		this.skipAudio(skipTo)
		this.audioPaused = false
		if (this.audioObject.audioPaused !== this.audioPaused) {
			this.audioObject.audioPaused = this.audioPaused
			this.audioPlayerSubject.updateAudioPlayer( this.audioObject )
		}
	}

	pauseAudio() {
		this.songFile.pause()
		this.audioPaused = true
		if (this.audioObject.audioPaused !== this.audioPaused) {
			this.audioObject.audioPaused = this.audioPaused
			this.audioPlayerSubject.updateAudioPlayer( this.audioObject )
		}
	}

	toggleMute() {
		this.audioMuted = !this.audioMuted
		this.songFile.mute( this.audioMuted )
		if (this.audioObject.audioMuted !== this.audioMuted) {
			this.audioObject.audioMuted = this.audioMuted
			this.audioPlayerSubject.updateAudioPlayer( this.audioObject )
		}
	}

}