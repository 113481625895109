<div 
	style="
		height: 80px;
		position: relative;
	"
>
	<div>
		<img 
			src="{{ profilePicture }}"
			style="
			width : 70px ; 
			height : 70px ;
			margin : 5px ;
			border-radius: 40px;
		    display: inline;
		    vertical-align: top;
			"
		>
		<textarea
			[(ngModel)]="commentText"
			style="
		    height: 70px;
		    vertical-align: top;
		    margin: 5px;
		    margin-right: 0px;
		    display: inline;
		    padding: 0px;
		    border-top-left-radius: 4px;
		    border-bottom-left-radius: 4px;
			"
		></textarea>	
		<new-comment-button
			style="
				position: relative;
		    display: inline;
			"
			[contestEntryId]="contestEntryId" 
			[commentText]="commentText"
			(madeNewComment)="submittedComment($event)"
		></new-comment-button>
	</div>
</div>