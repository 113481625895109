import { Component , Input , OnInit } from '@angular/core'
import { DataService, SessionService } from '../../core/index'

import { IDream } from '../index'


@Component ({
	selector : 'dream-joiners' , 
	templateUrl : './dreamJoiners.html'
})

export class DreamJoinersComponent {

	@Input() dream : IDream
	dreamJoiners : [{
		username : string,
		profilePicture : string
	}]
	username : string
	profilePicture : string
	joinedDream : boolean

	constructor(
		private dataService : DataService ,
		private sessionService : SessionService
	) {}

	ngOnInit() {
		this.dreamJoiners = this.dream.joiners
		this.sessionService.session.subscribe( (session : any) => {
			this.username = session.username
			console.log( this.dream.joiners )
			if (this.dream.joiners.filter( (dreamJoinerObj) => { return dreamJoinerObj.username === this.username }) )
				this.joinedDream = true
			for (var i; i < this.dream.joiners.length; i++) {
				if (!this.dream.joiners[i].profilePicture)
					this.dream.joiners[i].profilePicture = '/app/assets/images/idol-icon-white.png'
			}
		})
	}

	openModal() {
		document.getElementById('dreamJoinersModal' + this.dream._id).style.display = 'block'
		document.getElementById('dreamJoinersModalContent' + this.dream._id).style.display = 'block'
	}

	closeModal() {
		document.getElementById('dreamJoinersModal' + this.dream._id).style.display = 'none'
		document.getElementById('dreamJoinersModalContent' + this.dream._id).style.display = 'none'
	}

}