import { Component , OnInit } from '@angular/core'
import { Router , NavigationEnd } from '@angular/router'

import { DataService , SessionService } from '../../core/index'
import { InternationalService } from '../../shared/international.service'

@Component({
	selector : 'navbar-logged-in' ,
	templateUrl : './navbarLoggedIn.html' , 
	styleUrls : ['./navbarLoggedIn.css']
})

export class NavbarLoggedInComponent { 


	showMenu : boolean = false

	countryCodes : any[]
	country : string  = 'US'
	username : string = ''

	constructor(private router : Router , 
				private sessionService : SessionService , 
				private dataService : DataService ,
				private internationalService : InternationalService
	) {
		router.events.subscribe((event) => {
  			console.log(this.router.url.split('/')[this.router.url.split('/').length -1 ])
      		if (event instanceof NavigationEnd) {
      			console.log(this.router.url.split('/')[this.router.url.split('/').length - 1 ])
				if (this.router.url.split('/')[this.router.url.split('/').length - 1] === 'firstTime') {
					this.openHelp()
				}
			}
		})
	}

	ngOnInit() {
		this.sessionService.session.subscribe( (session:any) => {
			this.country = session.country
			this.username = session.username
		})
		this.getCountryCodes()
	}

	toggleMenu() {
		this.showMenu = this.showMenu ? false : true
	}

	openHelp() {
		console.log('open help' , document.getElementById('helpModal'))
	    document.getElementById('helpModal').style.display = "block"
	    document.getElementById('helpModalContent').style.display = "block"
		this.toggleMenu()
	}

	closeHelp() {
	    document.getElementById('helpModal').style.display = "none"
	    document.getElementById('helpModalContent').style.display = "none"
	}
	logOut() {
		let URI = 'user/logOut'
		this.dataService.postObject(URI, '')
			.subscribe((response : any) => {
				let session = {
				 	loggedIn : false , 
					username : '' ,
					isAdmin : false , 
					blogger : false , 
					redirectTo: null , 
					country : 'US', 
					exchangeRate  : 1 ,
					uncheckedAlerts : 0
				}
				this.sessionService.renewSession(session)
				window.location.replace('/logout')
			})
	}

	getCountryCodes() {
		this.countryCodes = this.internationalService.getCountryCodes()
		this.countryCodes.sort()
	}

	changeCountry( countryCode : string ) {
		console.log(countryCode)
		let URI = 'user/setCountry/' + countryCode
		this.dataService.postObject(URI , '')
			.subscribe((response : any) => {
				if (response.error)
					return console.log(response.error)
				window.location.replace(this.router.url)
			})
	}


}
