<div 
style="
  overflow: hidden;
"

class="contest-entry-page-phone-background" 
>
	<div style="width: 100% ; margin-top : 10px; ">
		<div *ngIf="!noRewards">	
			<h4 style="text-align: center;">
				<img class="contest-thumbnail-margin" height="18" width="18"  src="/app/assets/images/trophy.png" />	
				<h4 
					class="inline-block competition-line-height  contest-thumbnail-margin no-margins no-padding" 
					*ngIf="contest.rewardCash > 0"
					style="
					    position: relative;
					    top: 3px;
				    " 	
				>1st {{ currencySymbol }}{{ contest?.rewardCash }} </h4>
				<span style="position: relative; top: 3px;"> {{ contest?.rewardOther }}</span>
			</h4>
		</div>
	</div>
	<div style="width: 100% ; margin-top : 10px; margin-bottom: 10px;">
		<div class="col-xs-4" style="margin: 0 auto;">
			<a (click)="toggleDetails()">
				<div *ngIf="!showDetails" style="margin : 0px auto; width: 80px;">
					<div style="padding : 5px; border-width: 2px; border-color: #38194c; border-style: solid; border-radius: 30px; margin: 0px auto; width: 60px; height : 60px;">
						<img 
							src="https://storage.googleapis.com/spotidol/description-purple.png" 
							style="
						    width: 44px;
						    height: 44px;
						    float: none;
						    position: relative;
						    display: block;
						    top: 1px;
						    margin: auto auto;
					    "
				    >
					</div>
					<h4 style="font-size: 12px; width: 80px; text-align: center; margin: 0 auto;">View Details</h4>
				</div>
				<div *ngIf="showDetails" style="margin: 0px auto; width: 80px;">
					<div style="padding : 5px; border-width: 2px; border-color: #38194c; border-style: solid; border-radius: 30px; background-color: #38194c ; margin: 0px auto; width: 60px; height : 60px;">
						<img 
							src="https://storage.googleapis.com/spotidol/description-white.png" 
							style="
						    width: 44px;
						    height: 44px;
						    float: none;
						    position: relative;
						    display: block;
						    top: 1px;
						    margin: auto auto;
					    "
				    >
					</div>
					<h4 style="font-size: 12px; width: 80px; text-align: center; margin: 0 auto;">Hide Details</h4>
				</div>
			</a>
		</div>
		<div class="col-xs-4" style="margin: 0 auto;">
			<a (click)="toggleTimeline()">
				<div *ngIf="!showTimeline" style="margin : 0px auto; width: 80px;">
					<div style="padding : 5px; border-width: 2px; border-color: #38194c; border-style: solid; border-radius: 30px; margin: 0px auto; width: 60px; height : 60px;">
						<img 
							src="https://storage.googleapis.com/spotidol/timeline-purple.png" 
							style="
						    width: 44px;
						    height: 44px;
						    float: none;
						    position: relative;
						    display: block;
						    top: 1px;
						    margin: auto auto;
					    "
				    >
					</div>
					<h4 style="font-size: 12px; width: 80px; text-align: center; margin: 0 auto;">View Timeline</h4>
				</div>
				<div *ngIf="showTimeline" style="margin: 0px auto; width: 80px;">
					<div style="padding : 5px; border-width: 2px; border-color: #38194c; border-style: solid; border-radius: 30px; background-color: #38194c ; margin: 0px auto; width: 60px; height : 60px;">
						<img 
							src="https://storage.googleapis.com/spotidol/timeline-white.png" 
							style="
						    width: 44px;
						    height: 44px;
						    float: none;
						    position: relative;
						    display: block;
						    top: 1px;
						    margin: auto auto;
					    "
				    >
					</div>
					<h4 style="font-size: 12px; width: 80px; text-align: center; margin: 0 auto;">Hide Timeline</h4>
				</div>
			</a>
		</div>
		<div class="col-xs-4" style="margin: 0 auto;">
			<a (click)="toggleOtherRewards()">
				<div *ngIf="!showOtherRewards" style="margin : 0px auto; width: 80px;">
					<div style="padding : 5px; border-width: 2px; border-color: #38194c; border-style: solid; border-radius: 30px; margin: 0px auto; width: 60px; height : 60px;">
						<img 
							src="https://storage.googleapis.com/spotidol/trophy-purple.png" 
							style="
						    width: 44px;
						    height: 44px;
						    float: none;
						    position: relative;
						    display: block;
						    top: 1px;
						    margin: auto auto;
					    "
				    >
					</div>
					<h4 style="font-size: 12px; width: 80px; text-align: center; margin: 0 auto;">View Rewards</h4>
				</div>
				<div *ngIf="showOtherRewards" style="margin: 0px auto; width: 80px;">
					<div style="padding : 5px; border-width: 2px; border-color: #38194c; border-style: solid; border-radius: 30px; background-color: #38194c ; margin: 0px auto; width: 60px; height : 60px;">
						<img 
							src="https://storage.googleapis.com/spotidol/trophy-white.png" 
							style="
						    width: 44px;
						    height: 44px;
						    float: none;
						    position: relative;
						    display: block;
						    top: 1px;
						    margin: auto auto;
					    "
				    >
					</div>
					<h4 style="font-size: 12px; width: 80px; text-align: center; margin: 0 auto;">Hide Rewards</h4>
				</div>
			</a>
		</div>
	</div>
	<div *ngIf="showTimeline" class="col-xs-12" style="padding: 0px;">
		<div class="center-text col-centered block col-xs-12">
			<div *ngIf="contest.earlyRegistration">
				<p>Pre-Registration 
					<span *ngIf="!preRegistrationEnded">Ends</span>
					<span *ngIf="preRegistrationEnded">Ended</span>
					 {{ displayEarlyRegistrationTime }}
					<span *ngIf="contest.earlyRegistration.registrationMax">
						: {{ contest.earlyRegistration.registrationTotal }}/{{ contest.earlyRegistration.registrationMax }} Registered
						<span style="color: red;" *ngIf="
							contest.earlyRegistration.registrationTotal >= contest.earlyRegistration.registrationMax
						"> SOLD OUT</span>
					</span>
				</p>
			</div>
			<div class="col-xs-12" *ngIf="contest.startTime">
				<p><span *ngIf="!contestStarted">Starts</span><span *ngIf="contestStarted">Started</span> {{ displayStartTime }}</p>
			</div>
			<div class="col-xs-12">
				<p>
					Ends {{ formattedEndOfContest}}
					<span>
						: {{ currencySymbol }}{{ contest.contestPrice }} 
					</span> 
				</p>
			</div>
			<div class="col-xs-12">
				<p>Winner Announced {{ formattedWinnerSelectedDate }}</p>
			</div>
		</div>
	</div>

	<div *ngIf="showDetails" class="col-xs-12">		
		<div 
			style="
				width : 100%; 
				display: block; 
				overflow: hidden;
    	"
    >
    	<div class="col-xs-12">
	    	<p *ngIf="contest.entryGoesToPot" style="margin: 10px;">
	    		Entry goes to the winning pot
	    	</p>
    	</div>
			<p 
				class="word-break block" 
				style="
					margin: 10px;
		    		margin-top: 0px;
				    white-space: pre-wrap;
		    	"
	    >{{ contest?.description }}</p>
			<div *ngIf="contest.entryPrivacy === 'private'" style="text-align: center; display: block; overflow: hidden;"><p>Entries are private</p></div>
			<div *ngIf="contest.scoreCard" class="col-xs-10 col-xs-offset-1">
				<h3 style="text-align: center;">Judging Score Card</h3>
				<div *ngFor="let criteria of contest.scoreCard.judgingCriteria">
					<p style="margin-bottom: 4px;"><span style="font-weight: 800;">{{ criteria.name }}</span> - Out of {{ criteria.score }} Points</p>
					<p style="margin-top: 4px;">{{ criteria.description }}</p>
				</div>
			</div>
			<div style="width : 100%; display: block; overflow: hidden;">
				<p *ngIf="contest.contestType == 'pictureGallery'" style="text-align: center;">Max Submissions: {{ contest.maxSubmissionsPerEntry }}</p>
			</div>
			<div style="width : 100%; display: block; overflow: hidden;">
				<p *ngIf="contest.maxVotes > 0" style="text-align: center;">{{ votingTypeText }}</p>
				<p *ngIf="contest.maxVotes > 0" style="text-align: center;">{{ contest.maxVotes }} <span *ngIf="contest.pricePerVote > 0">free</span> votes per voter.</p>
				<p *ngIf="contest.pricePerVote > 0" style="text-align: center;">{{ currencySymbol }}{{ contest.pricePerVote }} per vote.</p>
			</div>
		</div>
	</div>
	<div *ngIf="contest.tags" style="width: 100%; display: block; overflow: hidden; padding: 10px;">
		<div
			style="
		    margin: 5px;
		    padding: 5px;
		    background-color: rgba(152, 140, 229, 0.6);
		    color: white;
		    border-radius: 10px;
		    text-align: center;
	    	display: inline-block;
	    " 	
			*ngFor="let tag of contest.tags" 
	  >
			<a 
				style="color: white;" 
				routerLink="/search/{{ tag }}"
			>{{ tag }}</a>
		</div>
	</div> 
	<!-- <div *ngIf="myGroup && !!!contest.descriptionVideo && !contest.ended" class="col-xs-offset-1">
		<contest-video-upload [contest]="contest"></contest-video-upload>
	</div> -->

</div>
<div id="contestRewardsModal" class="cssModal">
		<div id="contestRewardsModalContent" class="cssModalContent darker-background modal-content row">
		<div>
			<style>
				.md-datepicker-calendar-pane {
				    z-index: 1200  !important; 
				}
				.datePicker {	
				    z-index: 1200 !important;
				}
			</style>
        	<button type="button" class="btn btn-default" style="position: absolute; top: 0px; left : 0px;" (click)="hideAllRewardsModal()">Close</button>
  			<div class="modal-header">
  				<h2>Rewards</h2>
  			</div>
  			<div class="col-xs-offset-1">
				<div class="col-xs-10 col-xs-offset-1 block  contest-thumbnail-margin  no-margins no-padding">		
					<p class="competition-line-height  contest-thumbnail-margin no-margins no-padding col-xs-12" style="font-size: 24px;">
						{{ ordinal(1) }}
						<span *ngIf="contest.rewardCash > 0">{{ currencySymbol }}{{ contest?.rewardCash }}</span>
					</p>
					<!-- <div class="inline-block   contest-thumbnail-margin no-margins no-padding">
						<img class="contest-thumbnail-margin" height="14" width="14"  src="/app/assets/images/fanpoint.png" />
						<div class="inline-block rewards center-text contest-thumbnail-margin">
							<p class="inline-block competition-line-height contest-thumbnail-margin">{{ contest?.rewardFanpoints }}</p>
						</div>
					</div> -->
					<p class="col-xs-12" style="white-space: pre-wrap;">{{ contest?.rewardOther }}</p>
				</div>

				<div *ngFor="let reward of contest.rewards" class="col-xs-10 col-xs-offset-1 block  contest-thumbnail-margin  no-margins no-padding">
					<div *ngIf="reward.position != 1" >
						<p class="competition-line-height  contest-thumbnail-margin no-margins no-padding col-xs-12" style="font-size: 24px;"><span>{{ ordinal(reward.position) }} </span><span *ngIf="reward.rewardCash > 0">{{ currencySymbol }}{{ reward.rewardCash }}</span>
						</p>
						<p class="col-xs-12" style="white-space: pre-wrap;">{{ reward.rewardOther }}</p>
					</div>
				</div>
  			</div>
  		</div>
  	</div>
</div>