
<div class="col-xs-12">
	<div class="row">
		<group-header [group]="group"></group-header>
		<div class="contestEntriesSection">	
			<div class="col-md-10 col-md-offset-1">
				<contest *ngFor = "let contest of contests" [group]="group" [contest]="contest" [viewType]="'contestEntries'" [showEnterContestButton]="showEnterContestButton"></contest>
			</div>
			<div><h2>{{ contestEntry.contestantUsername }}</h2></div>
			<div class="block col-xs-offset-1"> 
				<a *ngIf="contests[0].entryPrivacy != 'private' || (contests[0].entryPrivacy == 'private' && isMyGroup)" (click)="voteOnEntry(contestEntry)">	
					<img height="30" width="30" src="https://storage.googleapis.com/spotidol/vote-logo.png"><span class="vote-height">: {{ votes }}</span>
				</a>
				<span style="margin-left: 5px;" class="inline vote-height" *ngIf="(winnerSelected && contestEntry.score) || (winnerSelected && contestEntry.score === 0) || (isMyGroup) ">Score : {{ contestEntry.score }}</span>
			</div>

			<div *ngIf="!winnerSelected && isMyGroup">
				<button type="button" class="btn btn-info btn-lg" data-toggle="modal" data-target="#scoreContestEntryModal" (click)="openScoreEntryModal()">Score Entry</button>
			</div>
			<div *ngIf="contests[0].entryPrivacy != 'private' || (contests[0].entryPrivacy == 'private' && isMyGroup)" >
    			<picture-gallery-submission *ngFor="let attachment of contestEntry.entryAttachments" [attachment]="attachment" [contestEntry]="contestEntry"></picture-gallery-submission>
			</div>
		</div>
		<div id="scoreEntryModal{{contestEntry._id}}" class="cssModal">
	  		<div id="scoreEntryModalContent{{contestEntry._id}}" class="cssModalContent darker-background modal-content row">
	        	<button type="button" class="btn btn-default" (click)="closeScoreEntryModal()">Close</button>
				<input type="text" numberOnly="true" class="roundBorders lighter-background input-styling" [(ngModel)]="score" />
				<div *ngIf="!contests[0].winnerSelected && isMyGroup">
					<button type="button" class="btn btn-info btn-lg" data-toggle="modal" data-target="#scoreContestEntryModal" (click)="scoreEntry(contestEntry , score)">Score Entry</button>
				</div>
	  			<div class="white-text" *ngIf="err"><p> {{ err }} </p></div>
	  			<div class="white-text" *ngIf="submittedContestWinnerToServer"> success! </div>
	  		</div>
	    </div>
	</div>
</div>