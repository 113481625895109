import { Component , Input } from '@angular/core'

@Component({
	selector : 'user-thumbnail' , 
	templateUrl : './profileThumbnail.html' , 
	styleUrls : ["./profileThumbnail.css"]

})
export class ProfileThumbnailComponent {

	@Input() user : any

	constructor(){}

}