import { Component } from '@angular/core'

@Component({
	selector : 'create-content-button' , 
	templateUrl : './createContentButton.html' , 
	styleUrls : ['./createContentButton.css']
})

export class CreateContentButton {

	showCreateContentMenu : boolean = false

	toggleCreateContentMenu() {
		this.showCreateContentMenu = !this.showCreateContentMenu
	}

}