<div>
	<span>Commenter<span *ngIf="contest.commenters.length > 1">s</span>: </span>
	<span *ngFor="let commenter of commenters"><img style = "height: 24px; width: 24px; border-radius: 12px;" src = "{{ commenter.profilePicture }}" /> {{ commenter.username }}<a *ngIf="isGroupAdmin" (click)="removecommenter(commenter)"><img src="/app/assets/images/cancel-btn.png" height="16px" width="16px" /></a>  </span>
	<a *ngIf="isGroupAdmin" (click)="openModal()"><span>  Add Commenter</span><img src="/app/assets/images/plus-btn.png" height="16px" width="16px" /></a>
	<div id="addCommenterModal" class="cssModal">
		<div id="addCommenterModalContent" class="cssModalContent modal-content row">
  			<div class="modal-header ">
          		<button type="button" class="btn btn-default top-left" (click)="closeModal()">Close</button>
							<h2>Add Commenter</h2>
  			</div>
			<p *ngIf="err">{{ err }}</p>
			<span>Username: </span>
			<input style="color: black;" type="text" [(ngModel)]="addCommenterUsername" />
			<div class="block">
				<input class="btn btn-lg dark-purple-background" type="button" value="Add Commenter" (click)="addCommenter(addCommenterUsername)">
			</div>
		</div>
	</div>
</div>