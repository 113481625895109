<h1>Support Messages</h1>
<div
	infiniteScroll
	[infiniteScrollDistance]="3"
	[infiniteScrollThrottle]="300"
	(scrolled)="getNextSupportMessagesPage()" 
>
<support-message
	*ngFor="let supportMessage of supportMessages" 
	[supportMessage]="supportMessage"
>
</support-message>
</div>