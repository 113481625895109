import { Component , OnInit } from '@angular/core'

import { DataService , SessionService } from '../../core/index'

import { IUser } from '../../shared/interfaces'

@Component({
	selector: 'cashOut', 
	templateUrl: "./cashOut.html" , 
	styleUrls : ['./cashOut.css']
})

export class CashOutComponent implements OnInit {

	currencyValues : any[] = []
	email : string
	err : string
	success : boolean
	session : any
	user : IUser

	constructor(
		private dataService : DataService , 
		private sessionService : SessionService
	) {}

	ngOnInit(){
		this.getTotalOwedToUser()
		this.sessionService.session.subscribe( ( session : any ) => {
			this.session = session
		})
	}

	getTotalOwedToUser(){
		let URI = 'user/getUserBySession'
    	this.dataService.getObject(URI)
        .subscribe((response) => {
			  this.user = response.user
			  for (var i = 0 ; i < response.user.totalEarned.length ; i++ ) {
			  	let amountOwedForCurrency = response.user.amountOwed.filter(( amountOwed : any) => { return (amountOwed.currency === response.user.totalEarned[i].currency)})[0].amount
			  	this.currencyValues.push({
			  		currency : response.user.totalEarned[i].currency , 
			  		amountOwed : Math.floor(amountOwedForCurrency * 100) / 100 , 
			  		totalEarned : Math.floor(response.user.totalEarned[i].amount * 100) / 100
			  	})
		  	}
	      },
		    (error: any) => {
		    	this.err = error
      	}, () => console.log("amountOwed"));
	}

	cashOut() {
		let usdAmountOwed = this.currencyValues.filter(( currencyValue : any ) => {return (currencyValue.currency === 'USD')})[0].amountOwed
		if (!this.user.city || !this.user.state 
			 || !this.user.zipCode || !this.user.country 
			 || !this.user.dobMonth || !this.user.dobDay
			 || !this.user.dobYear || !this.user.firstName
			 || !this.user.lastName || !this.user.providedSsnLast4) {
				return this.err = 'you must complete all update user fields for payouts'
		}
		if (usdAmountOwed < 0.5)
			return this.err = 'the minimum balance to cash out is 50 cents'
		let URI = 'user/cashOut'
		this.dataService.postObject(URI, '')
			.subscribe((response) => {
				if (response.error) {
					return this.err = response.error
				}
				this.err = null
				if (response.user)
					return this.success = true

			})
	}



}