import { Injectable } from '@angular/core';
import {
  Router, Resolve , 
  ActivatedRouteSnapshot , 
  RouterStateSnapshot
} from '@angular/router';
import { DataService , SessionService , UserDataService } from '../../core/index'
import { IContest , IFan } from '../../shared/interfaces'

@Injectable()
export class ContestEntriesResolveService {

	contestEntriesData : any = {}
	usernameSearch : string = ''

  	constructor(
		private dataService: DataService, 
		private sessionService : SessionService ,  
		private userDataService : UserDataService ,   
 		private router: Router
 	) { }

 	resolve( route : any , state : RouterStateSnapshot) {
 		if (route.params.usernameSearch) {
 			this.usernameSearch = route.params.usernameSearch
 			this.contestEntriesData.usernameSearch = this.usernameSearch
 		}
		return new Promise<void>( ( resolve , reject ) => {
			Promise.all([
				this.getSession() , 
		  	this.getContest( route.params.contestId)
			]).then(() => {
				Promise.all([
					this.getGroup(this.contestEntriesData.contests[0].groupUrl),
					this.getScoreCard( route.params.contestId )
				]).then(() => {
					Promise.all([
					  this.checkIfGroup(this.contestEntriesData.group) , 
		  			  this.getMyFan() , 
			  		this.setSubmissionTypeViewType(this.contestEntriesData.contests[0].contestType)
					]).then(() => {
						console.log('finished third promises')
						return resolve(this.contestEntriesData)
					})
					
				})
			})
		})
 	}

 	getSession() {
 		return new Promise<void>( ( resolve , reject ) => {
 			this.sessionService.session.subscribe( ( session : any ) => {
				this.contestEntriesData.session = session
				return resolve()
	 		})
 		})
 	}

	getScoreCard( contestId : string ) {
 		return new Promise<void>( ( resolve , reject ) => {
			let URI = 'scoreCard/getScoreCardByContestId/' + contestId
			this.dataService.getObject( URI )
				.subscribe( (response : any ) => {
					if ( response.error )
						return console.log('get scorecard err:' , response.error)
					this.contestEntriesData.scoreCard = response.scoreCard
					return resolve()
				})
 		})
	}

	getGroup( groupUrl : string ) {
		return new Promise<void>( ( resolve , reject ) => {
			let URI = 'group/url/' + groupUrl
	    	this.dataService.getObject(URI)
		        .subscribe((response) => {
		          this.contestEntriesData.group = response.group;
		          return resolve()
		        },
	        (err: any) => console.log(err),
	        () => console.log("group"));
		})
	}

	getContest( contestId : string ) {
		return new Promise<void>( ( resolve , reject ) => {
			let URI = 'contest/getContestById/' + contestId
	    	this.dataService.getObject(URI)
	        .subscribe((response : any) => {

	        	console.log(response)

						var endOfContest = response.contest.endOfContest
						var dateAndTime = endOfContest.split('T')
						var date = dateAndTime[0].split('-')
						var year = date[0]
						var month = date[1]
						var day = date[2]

						if (day.charAt(0) === '0')
							day = day.charAt(1)

						var stringifiedMonth = ''
						switch (month) {
							case '01': {
								stringifiedMonth = 'Jan'
								break
							}
							case '02': {
								stringifiedMonth = 'Feb'
								break
							}
							case '03': {
								stringifiedMonth = 'Mar'
								break
							}
							case '04': {
								stringifiedMonth = 'Apr'
								break
							}
							case '05': {
								stringifiedMonth = 'May'
								break
							}
							case '06': {
								stringifiedMonth = 'Jun'
								break
							}
							case '07': {
								stringifiedMonth = 'Jul'
								break
							}
							case '08': {
								stringifiedMonth = 'Aug'
								break
							}
							case '09': {
								stringifiedMonth = 'Sep'
								break
							}
							case '10': {
								stringifiedMonth = 'Oct'
								break
							}
							case '11': {
								stringifiedMonth = 'Nov'
								break
							}
							case '12': {
								stringifiedMonth = 'Dec'
								break
							}
						}
				    response.contest.formattedEndOfContest = stringifiedMonth + ' ' + day + ' ' + year
				    this.contestEntriesData.contests = []
	        	this.contestEntriesData.contests[0] = response.contest
		        return resolve()
	        },
			    (err: any) => console.log(err),
			    () => console.log("contests"));
	  })
	}

	searchContestEntries( username : string , contestId : string ) {
		return new Promise<void>( ( resolve , reject ) => {
			let URI = 'contestEntry/getContestEntryByContestantUsername/?username=' + username + '&contestId=' + contestId
	    	this.dataService.getObject(URI)
		        .subscribe((response : any) => {
		        	if (response.contestEntry !== null) {
			        	var contestEntries = [response.contestEntry]
			        	for(var i = 0; i < contestEntries.length ; i++ ) {
						  	contestEntries[i].reportedUserDetails = {}
						  	contestEntries[i].reportedUserDetails.userId = contestEntries[i].contestantId
						  	contestEntries[i].reportedUserDetails.objectId = contestEntries[i]._id
						  	contestEntries[i].reportedUserDetails.objectType = 'contestEntry'


							var created = contestEntries[i].created
							var dateAndTime = created.split('T')
							var time = dateAndTime[1].split(':')
							var date = dateAndTime[0].split('-')
							var month = date[1]
							var day = date[2]
							if (day.charAt(0) === '0')
								day = day.charAt(1)

							var hour = time[0]
							var minute = time[1]
							if (hour.charAt(0) === '0')
								hour = hour.charAt(1)
							var hourNumber = parseInt(hour)
							if (hourNumber > 12) {
								hourNumber = hourNumber - 12
								hour = hourNumber + ''
								var timeString = hour + ':' + minute + ' PM UT'
							} else {
								var timeString = hour + ':' + minute + ' AM UT'
							}
							var stringifiedMonth = ''
							switch (month) {
								case '01': {
									stringifiedMonth = 'Jan'
									break
								}
								case '02': {
									stringifiedMonth = 'Feb'
									break
								}
								case '03': {
									stringifiedMonth = 'Mar'
									break
								}
								case '04': {
									stringifiedMonth = 'Apr'
									break
								}
								case '05': {
									stringifiedMonth = 'May'
									break
								}
								case '06': {
									stringifiedMonth = 'Jun'
									break
								}
								case '07': {
									stringifiedMonth = 'Jul'
									break
								}
								case '08': {
									stringifiedMonth = 'Aug'
									break
								}
								case '09': {
									stringifiedMonth = 'Sep'
									break
								}
								case '10': {
									stringifiedMonth = 'Oct'
									break
								}
								case '11': {
									stringifiedMonth = 'Nov'
									break
								}
								case '12': {
									stringifiedMonth = 'Dec'
									break
								}
							}
						    contestEntries[i].formattedCreationDate = stringifiedMonth + ' ' + day + ' ' + timeString
			        	}
		          		this.contestEntriesData.contestEntries = contestEntries
		        	} else {
		        		this.contestEntriesData.contestEntries = null
		        		this.contestEntriesData.noFoundContestEntries = true
		        	}
	        			this.contestEntriesData.totalPages = 0
		        	return resolve()
		        },
	        (err: any) => console.log(err),
	        () => console.log("contestEntries"));
	    })
	}

	getMyFan() {
		return new Promise<void>( ( resolve , reject ) => {
			if ( !this.contestEntriesData.contests[0].groupUrl ) {
				this.contestEntriesData.fan = true 
				return resolve()
			}
    	if ((this.contestEntriesData.session !== undefined) && (this.contestEntriesData.session.username !== undefined)) 
				return resolve()
			this.userDataService.userData.subscribe( ( userData : any ) => {
				if (!userData.myFans)
					return resolve()
				this.contestEntriesData.fan = userData.myFans.filter(( fan : IFan ) => { return ( fan.kindOfFan === this.contestEntriesData.contests[0].kindOfFan && fan.groupId === this.contestEntriesData.group._id)})
				return resolve()
			})

		})
	}

	setViewType( contest : IContest ) {
		let contestType = contest.contestType
	}

	setSubmissionTypeViewType( type : string ) {
		return new Promise<void>( ( resolve , reject ) => {
	    switch (type) {
	      case "picture" : {
	        this.contestEntriesData.pictureSubmissionType = true
	      	this.contestEntriesData.videoSubmissionType = false
	      	this.contestEntriesData.audioSubmissionType = false
	      	this.contestEntriesData.textSubmissionType = false
	        break 
	      }
	      case "video" : {
	      	this.contestEntriesData.videoSubmissionType = true
	        this.contestEntriesData.pictureSubmissionType = false
	      	this.contestEntriesData.audioSubmissionType = false
	      	this.contestEntriesData.textSubmissionType = false
	      	break
	      }
	      case "audio" : {
	      	this.contestEntriesData.audioSubmissionType = true
	        this.contestEntriesData.pictureSubmissionType = false
	      	this.contestEntriesData.videoSubmissionType = false
	      	this.contestEntriesData.textSubmissionType = false
	      	break
	      }
	      case "text" : {
	      	this.contestEntriesData.textSubmissionType = true
	        this.contestEntriesData.pictureSubmissionType = false
	      	this.contestEntriesData.videoSubmissionType = false
	      	this.contestEntriesData.audioSubmissionType = false
	      	break
	      }
	    }
	    return resolve()
	  })
	}

	setEnterContestButtonViewType( ended : boolean ) {
		if (ended === true) {
			return this.contestEntriesData.showEnterContestButton = false 
		} 
		return this.contestEntriesData.showEnterContestButton = true 
	}

	setScoreEntryButtonViewType() {
		if (this.contestEntriesData.contests[0].winnerSelected === true) {
			return this.contestEntriesData.showScoreContestEntryButton = false
		} else {
			return this.contestEntriesData.showScoreContestEntryButton = true
		}
	}

	checkIfGroup( group : any ) {
		return new Promise<void>( ( resolve , reject ) => {
      if ((this.contestEntriesData.session !== undefined || this.contestEntriesData.session !== null) && (this.contestEntriesData.session.username !== undefined) && ( this.contestEntriesData.contests[0].admins.filter( ( admin ) => { return admin.username === this.contestEntriesData.session.username })[0] )) {
      	this.contestEntriesData.isMyGroup = true
				this.contestEntriesData.showEnterContestButton = false
				this.setScoreEntryButtonViewType() 
		  } else {
				this.contestEntriesData.showScoreContestEntryButton = false
				this.setEnterContestButtonViewType(this.contestEntriesData.contests[0].ended)
		  }
			return resolve()
		})
	}

}