import { Component , OnInit , OnDestroy , Input} from '@angular/core'
import { Router , ActivatedRoute } from '@angular/router'

import { DataService , SessionService } from '../core/index'

import { IContestEntry , IContest } from '../shared/interfaces'

@Component({
	selector: 'home', 
	templateUrl: "./home.html"
})

export class HomeComponent implements OnInit {

	contestEntries : IContestEntry[] = []
	displayedEntries : IContestEntry[] = []
	contests : IContest[] = []

	page : number = 1
	pages : number 
	firstTime : boolean
	onboardingStage2 : boolean
	gettingHomePage : boolean

	sessionSubscription : any

	constructor(
		private route 			: Router ,
		private router 			: ActivatedRoute , 
		private dataService 	: DataService, 
		private sessionService 	: SessionService
	) {}

	ngOnInit () {
		this.getOpenCompetitions()
		this.sessionSubscription = this.sessionService.session.subscribe( (session:any) => {
			if (session.loggedIn === false) 
				this.route.navigateByUrl('/welcome')
		})
		this.getHomePage(this.page)
		if (this.router.snapshot.params['firstTime'] === 'firstTime')
			this.firstTime = true
	}

	ngOnDestroy() {
		this.sessionSubscription.unsubscribe()
	}

	getOpenCompetitions() {
		let URI = 'contest/getOpenContests'
		this.dataService.getObject(URI)
			.subscribe( ( response : any ) => {
				console.log(response)
				if (response.error )
					return console.log( response.error )
				this.contests = response.contests
				console.log( this.contests )
			})
	} 

	getHomePage(page : number ) {
		this.gettingHomePage = true 
		let URI = 'home/' + page 
    	this.dataService.getObject(URI)
	        .subscribe((response : any) => {
	        	this.gettingHomePage = false
	        	console.log(response.pages)
	        	this.pages = response.pages
	        	if (response.contestEntries !== null) {        		
		        	for(var i = 0; i < response.contestEntries.length ; i++ ) {

					  	response.contestEntries[i].reportedUserDetails = {}
					  	response.contestEntries[i].reportedUserDetails.userId = response.contestEntries[i].contestantId
					  	response.contestEntries[i].reportedUserDetails.objectId = response.contestEntries[i]._id
					  	response.contestEntries[i].reportedUserDetails.objectType = 'contestEntry'


						var created = response.contestEntries[i].created
						var dateAndTime = created.split('T')
						var time = dateAndTime[1].split(':')
						var date = dateAndTime[0].split('-')
						var month = date[1]
						var day = date[2]
						if (day.charAt(0) === '0')
							day = day.charAt(1)

						var hour = time[0]
						var minute = time[1]
						if (hour.charAt(0) === '0')
							hour = hour.charAt(1)
						var hourNumber = parseInt(hour)
						if (hourNumber > 12) {
							hourNumber = hourNumber - 12
							hour = hourNumber + ''
							var timeString = hour + ':' + minute + ' PM UT'
						} else {
							var timeString = hour + ':' + minute + ' AM UT'
						}
						var stringifiedMonth = ''
						switch (month) {
							case '01': {
								stringifiedMonth = 'Jan'
								break
							}
							case '02': {
								stringifiedMonth = 'Feb'
								break
							}
							case '03': {
								stringifiedMonth = 'Mar'
								break
							}
							case '04': {
								stringifiedMonth = 'Apr'
								break
							}
							case '05': {
								stringifiedMonth = 'May'
								break
							}
							case '06': {
								stringifiedMonth = 'Jun'
								break
							}
							case '07': {
								stringifiedMonth = 'Jul'
								break
							}
							case '08': {
								stringifiedMonth = 'Aug'
								break
							}
							case '09': {
								stringifiedMonth = 'Sep'
								break
							}
							case '10': {
								stringifiedMonth = 'Oct'
								break
							}
							case '11': {
								stringifiedMonth = 'Nov'
								break
							}
							case '12': {
								stringifiedMonth = 'Dec'
								break
							}
						}
					    response.contestEntries[i].formattedCreationDate = stringifiedMonth + ' ' + day + ' ' + timeString
		        	}
	        	}
	        	if (this.page === 1 || this.contestEntries === undefined)
	          		this.contestEntries = response.contestEntries
	          	else
	          		this.contestEntries.push(...response.contestEntries)
				this.setLoadedEntries()
	        },
        (err: any) => console.log(err),
        () => console.log("contestEntries"));

	}

	setLoadedEntries() {
		if (this.displayedEntries.length === this.contestEntries.length )
			this.getNextHomePage()
		console.log( 'setting entries' , this.displayedEntries )
		this.displayedEntries.push(...this.contestEntries.slice( this.displayedEntries.length , this.displayedEntries.length + 3 ))
	}

	getNextHomePage() {
		if (this.gettingHomePage)
			return
		console.log(this.pages)
		this.page ++
    	if ( this.page > this.pages ) return
		this.getHomePage(this.page)
	}

	nextOnboardingStage() {
		this.firstTime = false
		this.onboardingStage2 = true
	}

}