import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation , AfterViewInit } from '@angular/core';
import videojs from 'video.js';

import { DataService } from '../core/index'

import { IContest } from '../shared/interfaces'

@Component({
	templateUrl: './hipHop.html' , 
	styleUrls: ['./hipHop.css'], 
  encapsulation: ViewEncapsulation.None
})

export class HipHopComponent {
  @ViewChild('hipHopVideoTarget' ) target: ElementRef;
  // see options: https://github.com/videojs/video.js/blob/mastertutorial-options.html
  options: any
  player: videojs.Player;
  hideVideo : boolean = true

	contests : IContest[]

	constructor( 
	    private elementRef: ElementRef,
	    private dataService : DataService 
    ) {}

	ngOnInit() {
		this.getHipHopContests()
	}

  ngAfterViewInit() {
  	this.options = {
  		autoplay : true , 
  		muted : true , 
  		controls : false ,
  		loop : true , 
		  sources : [{
        src : "https://firebasestorage.googleapis.com/v0/b/spotidol-169915.appspot.com/o/assets%2Fsite%2Fhiphopvid.mp4?alt=media&token=60428ed1-4ad8-4a4c-98cb-b90bfe282b4f" , 
  			type : 'video/mp4'
  		}]
  	}
    // instantiate Video.js
    this.player = videojs(this.target.nativeElement, this.options, () => {
      setTimeout(()=>{this.hideVideo = false}) 
    });
  }
	

	getHipHopContests() {
		let uri = 'contest/getHipHopContests'
		this.dataService.getObject( uri )
			.subscribe( ( response : any ) => {
				if ( response.error )
					return console.log( response.error )
				return this.contests = response.contests
			})
	}

  ngOnDestroy() {
    // destroy player
    if (this.player) {
      this.player.dispose();
    }
  }

}