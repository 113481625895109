import { Component , OnInit , Input } from '@angular/core'
import { ActivatedRoute , Router } from '@angular/router'

import { DataService , SessionService } from '../core/index'

import { GroupService } from './groupService/group.service'

import { IGroup , IContest , IFan , IReportedUser , IKindOfFan } from '../shared/interfaces'
import { DateService } from '../shared/date.service'
import { InternationalService } from '../shared/international.service'
import { UserDataService } from '../core/index'
import moment from 'moment'
import * as pluralize from 'pluralize'
import "moment-timezone"

@Component({
	selector: 'group', 
	templateUrl: "./group.html" , 
  	styleUrls : ["./group.css"]

})

export class GroupComponent implements OnInit {

	contests : IContest[]
	groupUrl : string
	group : IGroup
	myFan : IFan
	topFans : any
	fans : IFan[]
	newGroupName : string
	newPriceToBecomeFan : string
	new321DealActive : boolean
	newPriceFor321Deal : string
	newGroupUrl : string
	newTags : string = ''
	groupActive : boolean 
	defaultKindOfFan : IKindOfFan
	defaultKindOfFanPluralized : string

	referee : string
	err : string
	updateGroupError : string

	showEnterContestButton : boolean = false 
	noContests : boolean = false
	isMyGroup : boolean = false
	have321Deal : boolean
	gettingContests : boolean
	groupLoaded : boolean
	fanLoaded : boolean
	updateGroupSuccess : boolean
	updateGroupTimer : any
	showRoles : boolean
	reportedUserDetails : any = {}
	session : any
	currencySymbol : string = '$'

	openContests : IContest[] = []
	pastContests : IContest[] = []

	newOrPastContest : string
	userInstance : any

	currentPage : number
	pageCount : number
	max : any

	constructor(
		private dataService : DataService , 
		private dateService : DateService , 
		private sessionService : SessionService , 
		private userDataService : UserDataService ,
		private internationalService : InternationalService , 
		private route : ActivatedRoute , 
		private router : Router , 
		private groupService : GroupService
	) {}

	ngOnInit () {
    this.route.data
      .subscribe((data : any) => {
    		var groupData = data.groupData
        this.contests = groupData.contests;

		if (groupData.contests.length > 0) {
			this.openContests = groupData.contests.filter( ( contest : IContest ) => {
				return (moment(contest.endOfContest).isAfter(new Date()))
			})
			this.pastContests = groupData.contests.filter( ( contest : IContest ) => {
				return !(moment(contest.endOfContest).isAfter(new Date()))
			})
		}

        this.userInstance = groupData.userInstance
        this.err = groupData.err
        this.group = groupData.group
        this.reportedUserDetails = groupData.reportedUserDetails
        this.groupLoaded = true
        this.fanLoaded = true
        this.groupActive = this.group.active
        this.myFan = groupData.fan
        this.have321Deal = groupData.have321Deal
        this.topFans = groupData.topFans
        this.defaultKindOfFan = groupData.defaultKindOfFan
        this.currentPage = groupData.currentPage
        this.showEnterContestButton = groupData.showEnterContestsButton
        this.noContests = groupData.noContests
        this.newGroupName = this.group.groupName
        this.newGroupUrl = this.group.groupUrl
        this.getTopFansByGroupUrl( this.group.groupUrl , 'fan' )
        this.sessionService.session.subscribe( ( session : any ) => {
        	this.isMyGroup = (this.group.admins.filter( (admin ) => {
        		return ( admin.username === session.username )
        	})[0]) ? true : false 
        })
        this.defaultKindOfFanPluralized = pluralize(this.group.defaultKindOfFan)
      	if (this.group.active === null)
      		this.group.active = true

		for (var i; i < this.group.admins.length; i++) {
			if (!this.group.admins[i].profilePicture)
				this.group.admins[i].profilePicture = '/app/assets/images/idol-icon-white.png'
		}

      });
	}

	
	getTopFansByGroupUrl( url : string  , role : string ) {
		console.log('getting top fans')
			let URI = 'fan/getTopFansByGroupUrlAndKind/?groupUrl=' + url + '&kindOfFan=' + role
	    	this.dataService.getObject(URI)
		        .subscribe((response) => {
							for ( var i = 0 ; i < response.fans.length ; i++ ) {
								response.fans[i].position = response.fans[i - 1] && response.fans[i-1].score == response.fans[i].score ? response.fans[i - 1].position : i + 1
							}
							console.log('top fans' , response.fans )
	     		  	return this.topFans = response.fans
		        },
	        (err: any) => console.log(err),
	        () => console.log("topFans"));
	}

	getMyFan( url : string ) {
		return new Promise<void>( ( resolve , reject ) => {
			if (!this.userInstance.username)
				return
			let URI = 'fan/getMyFanByGroupUrlAndKind/?groupUrl=' + url + '&kindOfFan=fan'
	    	this.dataService.getObject(URI)
		        .subscribe((response) => {
		          this.fanLoaded = true
		          this.myFan = response.fan;
		          if (response.fan) this.have321Deal = response.fan.has321Deal
      			  return 
		        },
	        (err: any) => console.log(err),
	        () => console.log("myFan"));
		})
	}


	makeTagsArray (tagString : string) {
		this.groupService.makeTagsArray(tagString)
	}

	addHashtag() {
		if ( this.newTags === '') {
			this.newTags = '#'
		}
	}

    setUpdateGroupTimer( updateParameter : string , updateParamaterValue : any ) {
        clearTimeout(this.updateGroupTimer)
        this.updateGroupTimer = setTimeout(this.updateGroup.bind(this, updateParameter , updateParamaterValue) , 1000)
    }

	updateGroup( updateParameter : string , updateParamaterValue : any ) {
        clearTimeout(this.updateGroupTimer)
		if (this.isMyGroup !== true ) {
			return this.err = 'You must be the group to update any group fields'
		}
        let URI = 'group/update/' + this.group._id
        switch(updateParameter) {
        	case "groupName" : {
		        var updateGroupBody : any = {
		        	groupName : updateParamaterValue
		        }
		        break
        	}
        	case "addTags" : {
        		let tags : any[] | any = this.groupService.makeTagsArray(updateParamaterValue)
        		if (this.err === null ) {
	        		var updateGroupBody : any = {
	        			tags : tags
	        		}
	        		this.group.tags.push(...tags)
        		}
        		break
        	}
        	case "removeTag" : {
        		var updateGroupBody : any = {
        			tag : updateParamaterValue , 
        			removeTag : true
        		}
        		this.group.tags = this.group.tags.filter( oldTag => {return (oldTag !== updateParamaterValue) })
        		break
        	}
        	case "priceToBecomeFan" : {
		        var updateGroupBody : any = {
		        	priceToBecomeFan : updateParamaterValue
		        }
		        break
        	}
        	case "321DealActive" : {
		        var updateGroupBody : any = {
		        	is321DealActive : updateParamaterValue
		        }
		        break
        	}
        	case "priceFor321Deal" : {
		        var updateGroupBody : any = {
		        	priceFor321Deal : updateParamaterValue
		        }
		        break
        	}
        	case "groupUrl" : {
		        var updateGroupBody : any = {
		        	groupUrl : updateParamaterValue
		        }
		        break
        	}
        	case "active" : {
        		var updateGroupBody : any = {
        			updateActive : true , 
        			active : updateParamaterValue
        		}
        		this.group.active = updateParamaterValue
        		break
        	}
        }
        console.log(updateGroupBody)
        this.dataService.postObject(URI , updateGroupBody)
        	.subscribe((response) => {
        		if (response.error) 
        			return this.updateGroupError = response.error
        		this.updateGroupSuccess = true
        		if (updateParameter === 'addTags') 
        			this.newTags = ''
        		this.userDataService.userData.subscribe( ( userData : any )=> {
        			let myGroups = userData.myGroups.filter(( group : IGroup )=> { 
        				return (group._id !== this.group._id) 
        			})
        			myGroups.push(response.group)
        			userData.myGroups = myGroups
        			this.userDataService.renewUserData(userData)
        		})
        		setTimeout(() => {
        			this.updateGroupSuccess = false
        		}, 7000)
        		return this.updateGroupError = null
        	})
	} 

	getNextContestsPage() {
		if (this.noContests === true)
			return
		if (this.currentPage === this.pageCount)
			return
		this.currentPage ++
	}

	getNextFestivalsPage() {
		if (this.currentPage === this.pageCount)
			return
		this.currentPage ++
	}

	toggleRoles() {
		this.showRoles = (this.showRoles ? false : true)
	}
}