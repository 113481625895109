<div class="col-xs-12">
	<div style="text-align: center;" *ngIf="url">
		<span style="display: inline-block; color: white; vertical-align: top;">
			<a routerLink="/competition/{{ competitionId }}">
				<p style=" color : white ; font-weight: 800; text-align: right; margin : 0px ;">{{ competitionName }}</p>
			</a>
			<a routerLink="/profile/{{ username }}">
				<p style="color : white ; text-align: right; margin : 0px ;">{{ username }}</p>
			</a>
		</span>
		<span style="display: inline-block; vertical-align: text-top; margin: 5px;">
			<a *ngIf="!audioPaused" (click)="pauseAudio()">
				<img src="https://storage.googleapis.com/spotidol/pause.png" style="width: 25px; height: 25px; margin : 0px ;" />
			</a>
			<a *ngIf="audioPaused" (click)="playAudio()">
				<img src="https://storage.googleapis.com/spotidol/play.png" style="width: 25px; height: 25px; margin : 0px ;" />
			</a>
		</span>
		<span style="display: inline-block; color : white ; font-weight: 700 ; vertical-align: top; margin-top: 7px;">{{ durationString }}</span>
	</div>
</div>
