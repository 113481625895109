import { Injectable } from '@angular/core';
import {
  Router, Resolve , 
  ActivatedRouteSnapshot , 
  RouterStateSnapshot
} from '@angular/router';
import { DataService , SessionService , UserDataService } from '../core/index'
import { IProfile } from './profile.interface'


@Injectable()
export class ProfileResolveService {

	profileUsername : string
	profileData : IProfile | any = {}

	constructor(
		private dataService : DataService , 
		private sessionService : SessionService ,  
		private userDataService : UserDataService ,   
 		private router: Router
	) {}

 	resolve( route : any , state : RouterStateSnapshot) {
		this.profileUsername = route.params.username
		this.profileData.username = route.params.username
		return new Promise<void>( ( resolve , reject ) => {
			Promise.all([
				this.getProfileCompetitions() , 
				this.getProfilePositions() , 
				this.getProfileCompetitionEntries() , 
				this.getProfileCreatedGroups() , 
				this.getUserData() 
			]).then( () => {
				this.checkIfIsUser()
					.then( () => {
						return resolve( this.profileData )
					})
			})
		})
	}

	getProfilePositions() {
		return new Promise<void>( ( resolve , reject ) => {
			let URI = 'fan/getFansByUsername/' + this.profileUsername
	    	this.dataService.getObject(URI)
	        .subscribe((response : any) => {
	        	console.log(response)
	        	if (response.error) 
	        		console.log(response.error)
	        	else
	        		this.profileData.fans = response.fans
	        	return resolve()
	        })
		})
	}

	getProfileCreatedGroups() {
		return new Promise<void>( ( resolve , reject ) => {
			let URI = 'group/getCreatedGroupsByUsername/' + this.profileUsername
	    	this.dataService.getObject(URI)
		        .subscribe((response : any) => {
		        	console.log(response)
		        	if (response.error) 
		        		console.log(response.error)
		        	else
		        		this.profileData.groups = response.groups
		        	return resolve()
		        })
		})
	}

	getProfileCompetitions() {
		return new Promise<void>( ( resolve , reject ) => {
			let URI = 'contest/getContestsByUsername/' + this.profileUsername
	    	this.dataService.getObject(URI)
		        .subscribe((response : any) => {
		        	console.log(response)
		        	if (response.error)
		        		console.log(response.error)
		        	else
		        		this.profileData.competitions = response.contests
		        	return resolve()
		        })
		})
	}

	getProfileCompetitionEntries() {
		return new Promise<void>( ( resolve , reject ) => {
			let URI = 'contestEntry/getContestEntriesByUsername/' + this.profileUsername
	    	this.dataService.getObject(URI)
		        .subscribe((response : any) => {
		        	console.log(response)
		        	if (response.error) 
		        		console.log(response.error)
		        	else {
	        			let contestEntries = response.contestEntries
		        		contestEntries = contestEntries.filter( ( ce : any) => {
		        			return (ce.entryAttachment)
		        		})
		        		this.profileData.competitionEntries = response.contestEntries
		        	}
		        	return resolve()
		        })
		})
	} 

	getUserData() {
		return new Promise<void>( ( resolve , reject ) => {
			let URI = 'user/getUserProfile/' + this.profileUsername
			this.dataService.getObject(URI)
				.subscribe( ( response : any ) => {
					console.log(response)
					if (response.error)
						console.log(response.error)
					else 
						this.profileData.userData = response.userData
					return resolve()
				})
		})
	}

	checkIfIsUser() {
		return new Promise<void>( ( resolve , reject ) => {
			this.sessionService.session
				.subscribe((session: any) => {
					if ( this.profileUsername === session.username )
						this.profileData.isUser = true
					else 
						this.profileData.isUser = false
					return resolve()
				})
		})
	}

}