import { Component } from '@angular/core'

@Component({
	templateUrl : './referralCode.html' , 
	selector : 'referral-code-active'
})

export class ReferralCodeComponent {

	showReferralCode : boolean = true

	closeReferralCode() {
		this.showReferralCode = false
	}

}